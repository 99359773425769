<template>
  <call-line-component v-if="$can('can_see_admin_calls')" :lines="8" :title="$t('admin_calls')" color="success" type="admin" />
</template>

<script>
/* Components */
import CallLineComponent from '@/app/widgets/call-line-widget/components/CallLineComponent'

export default {
  name: 'AdminCallComponent',
  components: {
    CallLineComponent
  }
}
</script>

<style scoped>

</style>
