import apiService from '@/modules/api/csp'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    call_id: '',
    callee_id_number: '',
    caller_id_number: '',
    recordings: [],
    kazooPager: {
      currKey: null,
      nextKey: null,
      prevKeys: [],
      pageSize: 10
    },
    file: null,
    loading: false,
    downloading: false,
    mediaLoaded: false,
    useTablePager: false,
    audioFileTitle: ''
  }),
  computed: {
    ...mapGetters('settings', ['sipDefaultAddressGetter', 'getGlobalSetting', 'audioSettingsGetter'])
  },
  methods: {
    getRecordings () {
      return this.recordings
    },
    async loadRecordings () {
      this.clearRecordings()

      const response = await this.$firestore.collection('agents')
        .where('account_id', '==', this.sipDefaultAddressGetter.account_id)
        .where('username', '==', this.sipDefaultAddressGetter.sip_address.toLowerCase())
        .get()

      if (!response.empty) {
        const data = response.docs[0].data()

        this.deviceID = data.device_id
      }

      const params = this.getParams()

      this.sendRequest(params)
    },
    async handleClick (row, recording) {
      this.selectedRow = row
      this.downloading = true

      await apiService.egress.getRecordedMedia(this.sipDefaultAddressGetter.account_id, recording.custom_channel_vars['Media-Recording-ID']).then(response => {
        const reader = new FileReader()
        reader.readAsDataURL(response.data)

        reader.onloadend = async () => {
          this.file = reader.result
          this.mediaLoaded = true

          this.downloading = false
          this.audioFileTitle = recording.custom_channel_vars['Bridge-ID']

          this.setAudioDevice()
        }
      })
    },
    async setAudioDevice () {
      this.$refs.playRecordedAudio.volume = this.audioSettingsGetter.recordingsVolume

      await this.$refs.playRecordedAudio.setSinkId(this.audioSettingsGetter.recordingsOutputDeviceId)

      this.$refs.playRecordedAudio.load()
      this.$refs.playRecordedAudio.play()
    },
    sendRequest (params) {
      this.loading = true
      this.recordings = []
      apiService.egress.getUserRecordings(this.sipDefaultAddressGetter.account_id, this.deviceID, params).then(result => {
        this.loading = false
        this.recordings = result.data.data

        this.kazooPager.currKey = result.data.start_key
        this.kazooPager.nextKey = result.data.next_start_key
      }).catch(() => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },
    onPrev () {
      const pageKey = this.kazooPager.prevKeys.pop()

      if (!pageKey) return

      const params = this.getParams()

      params.start_key = pageKey

      this.sendRequest(params)
    },
    onNext () {
      this.kazooPager.prevKeys.push(this.kazooPager.currKey)

      const params = this.getParams()

      params.start_key = this.kazooPager.nextKey

      this.sendRequest(params)
    },
    getParams () {
      const params = {
        page_size: this.kazooPager.pageSize,
        page: 1
      }

      if (this.call_id) {
        params.filter_call_id = this.call_id.trim()

        params.page_size = 1000
      }

      if (this.callee_id_number) {
        params.filter_callee_id_number = this.callee_id_number.trim()

        params.page_size = 1000
      }

      if (this.caller_id_number) {
        params.filter_caller_id_number = this.caller_id_number.trim()

        params.page_size = Math.pow(2, 32) - 1

        this.useTablePager = true
      }

      return params
    },
    clear () {
      this.clearFilters()
      this.clearRecordings()
    },
    clearFilters () {
      this.call_id = ''
      this.callee_id_number = ''
      this.caller_id_number = ''
    },
    clearRecordings () {
      this.recordings = []
      this.kazooPager = {
        currKey: null,
        nextKey: null,
        prevKeys: [],
        pageSize: 10
      }

      if (this.$refs.playRecordedAudio) this.$refs.playRecordedAudio.pause()

      this.file = null
      this.loading = false
      this.downloading = false
      this.mediaLoaded = false
      this.useTablePager = false
      this.audioFileTitle = ''
    }
  }
}
