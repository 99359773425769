<template>
  <code class="transparent pa-0">
    <v-btn
      class="text-icon-button"
      :color="conferenceState ? 'grey' : 'primary'"
      @click="conferenceModal"
      large
      dark
      width="100%"
      :disabled="!callGetter.isConference"
      height="100%"
    >
      <v-icon>{{ conferenceIcon }}</v-icon>
      <div class="font-weight-bold pt-2">{{ conferenceLabel }}</div>
    </v-btn>
    <DialogShared v-model="toggleConference" max-width="1200" :title="$t('conference_information')">
      <call-conference-component />
    </DialogShared>
  </code>
</template>

<script>
import { mapGetters } from 'vuex'
import CallConferenceComponent from '@/app/widgets/call-conference-widget/components/CallConferenceComponent'
/* Shared */
import DialogShared from '@/app/shared/dialog/DialogShared'

export default {
  name: 'ConferenceButton',
  components: {
    CallConferenceComponent,
    DialogShared
  },
  data: (vm) => ({
    toggleConference: false,
    disableConference: false,
    conferenceState: false,
    conferenceIcon: 'mdi-account-group',
    conferenceLabel: vm.$t('conference')
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ]),
    ...mapGetters('callConference', [
      'conferenceDataGetter'
    ])
  },
  watch: {
    conferenceDataGetter (val) {
      if (val) {
        this.conferenceState = true
        if (!this.conferenceDataGetter.count) {
          this.toggleConference = false
        }
      }
    }
  },
  methods: {
    conferenceModal () {
      this.toggleConference = !this.toggleConference
    }
  }
}
</script>

<style scoped>

</style>
