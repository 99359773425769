<template>
  <v-btn
    id="action_KeyR"
    cypress="registration"
    class="text-icon-button"
    @click.once="changeState"
    :disabled="!sipGetter.connected"
    :loading="sipGetter.registering"
    :color="sipGetter.registered ? 'success' : 'error'"
    hide-details
    width="100%"
    height="100%"
    :key="buttonKey"
  >
    <v-icon>{{ sipGetter.registered ? 'mdi-checkbox-marked-circle' : 'mdi-cancel' }}</v-icon>
    <div class="font-weight-bold pt-2">{{ sipGetter.registered ? $t('ready') : $t('not_ready') }}</div>
  </v-btn>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
/* Mixins */
import readyStateMixin from '@/mixins/sip/ready-state.mixin'
import clickOnceRefreshMixin from '@/mixins/click-once-refresh.mixin'

export default {
  name: 'ReadyStateButton',
  mixins: [clickOnceRefreshMixin, readyStateMixin],
  data: () => ({
    buttonKey: null
  }),
  computed: {
    ...mapGetters('global', [
      'sipGetter'
    ]),
    ...mapGetters('settings', ['sipDefaultAddressGetter'])
  },
  methods: {
    ...mapMutations('global', [
      'sipMutator'
    ]),
    changeState () {
      if (this.sipGetter.registered) {
        this.sipMutator({ type: 'registering', data: true })
        this.$sip.unregisterUA()
      } else {
        this.sipMutator({ type: 'registering', data: true })
        this.$sip.registerUA()
      }
      this.refreshButton(1500)
    }
  },
  mounted () {
    this.buttonKey = this.$uuidKey()
    this.$event.listen('connected', () => {
      this.setNotReadyState()

      if (this.sipGetter.reRegistering) {
        this.$sip.registerUA()
        this.sipMutator({ type: 'reRegistering', data: false })
      }
    })

    this.$event.listen('disconnected', () => {
      this.setNotReadyState()
    })

    this.$event.listen('registered', () => {
      this.setReadySate()
    })

    this.$event.listen('unregistered', () => {
      this.setNotReadyState()
    })

    this.$event.listen('registrationFailed', () => {
      this.setNotReadyState()
    })
  }
}
</script>
