<template>
  <v-card tile>
    <v-card-text>
      <span class="invite-text">{{ inviteMessage }}</span>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CallDetailsInviteMessageComponent',
  data: () => ({
    inviteMessage: ''
  }),
  computed: {
    ...mapGetters('callDetails', [
      'callDetailsGetter'
    ])
  },
  watch: {
    'callDetailsGetter.invite_message' (val) {
      if (val) this.inviteMessage = val
      else this.inviteMessage = ''
    }
  }
}
</script>

<style scoped>
  .invite-text{
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: inherit;
    color: white;
  }
</style>
