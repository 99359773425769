<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        dark
        color="primary"
        @click="showCallDetails(data)"
        small
        min-width="0"
        width="30"
        height="30"
        class="mx-1 my-1"
        v-on="on"
      >
        <v-icon small color="white">
          mdi-information-outline
        </v-icon>
      </v-btn>
    </template>
    <span> {{ $t('call_details')}}</span>
  </v-tooltip>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'CallDetailButton',
  props: ['data', 'reason'],
  components: {
  },
  methods: {
    ...mapMutations('callDetails', [
      'setCallDetailsMutator',
      'callDetailsModalMutator'
    ]),
    async showCallDetails (data) {
      data.reason = this.reason
      await this.setCallDetailsMutator(data)
      await this.callDetailsModalMutator()
    }
  }
}
</script>

<style scoped>

</style>
