<template>

  <div>
    <v-menu offset-y :close-on-content-click="false" >
      <template v-slot:activator="{ on, attrs }">
        <v-btn x-small text fixed top left v-bind="attrs" style="z-index: 999999; top: 15px; left: 100px"
               v-on="on">
          <v-icon small class="mr-1">
            mdi-widgets
          </v-icon> {{ $t('layout')}}
        </v-btn>

      </template>

      <v-list dense>

        <v-subheader >{{ $t('actions') }}</v-subheader>

        <div class="d-flex pr-3 pl-1" >

          <v-btn x-small @click.prevent="enableEditClick"
                 color="success"
                 class="ml-2 mr-2"
                 dark
          >{{ $t('edit_mode')}} </v-btn>

          <v-btn x-small @click="saveLayoutClick" v-if="editMode"
                 color="success"
                 class="ml-2 mr-2"
                 dark
          >{{ $t('save')}} </v-btn>

        </div>

        <v-subheader >{{ $t('widgets') }}</v-subheader>

        <v-row dense style="max-width: 500px;" class="pa-2">
          <v-col
            v-for="widget in widgetsGetter"
            :key="widget.id"
            cols="6" class="pa-2"
          >
            <v-btn x-small @click.prevent="addWidgetToDashboardClick(widget)"
                   color="red" block
                   class="v-btn-justify-start "
                   dark
            >{{ widget.displayName }} </v-btn>
          </v-col>
        </v-row>

      </v-list>
    </v-menu>

    <chs-golden-layout v-if="dashboard" :dashboard="dashboard"
                       @updateWidgetConfigEvent="onUpdateWidgetConfigEvent"
                       @destroyWidgetEvent="onDestroyWidgetEvent" @updateLayoutEvent="onUpdateLayoutEvent" ></chs-golden-layout>

  </div>

</template>

<script>

import apiService from '@/modules/api/csp'
import { mapActions, mapGetters } from 'vuex'

import ChsGoldenLayout from '@/app/widgets/dashboard-widget/components/ChsGoldenLayout'

export default {
  name: 'GlobalDashboardLayoutComponent',
  components: { ChsGoldenLayout },
  data: (vm) => ({
    editMode: false,
    dashboard: null
  }),

  computed: {
    ...mapGetters('dashboard', [
      'widgetsGetter'
    ])
  },

  beforeMount () {
    const id = this.$route.params.id
    this.loadWidgetsAction()
    this.loadDashboard(id)
  },

  methods: {

    ...mapActions('dashboard', ['loadWidgetsAction']),
    ...mapActions('notifications', ['notificationSnackBarAction']),

    backClick () {
      window.location.href = '/'
    },

    async loadDashboard (id) {
      await apiService.globalDashboard.getSingle(id).then((response) => {
        this.dashboard = response.data
      }).catch(() => {
        this.notificationSnackBarAction({
          text: this.$t('cant_load_dashboard'),
          color: 'error',
          show: true
        })
      })
    },

    onDestroyWidgetEvent (widget) {
      const current = this.dashboard.configuration.widgets.findIndex(item => item.id === widget.id)
      if (current >= 0) {
        this.dashboard.configuration.widgets.splice(current, 1)
      }
    },

    onUpdateWidgetConfigEvent (widget) {
      const current = this.dashboard.configuration.widgets.findIndex(item => item.id === widget.id)
      if (current >= 0) {
        this.dashboard.configuration.widgets[current] = widget

        this.saveLayoutClick()
      }
    },

    onUpdateLayoutEvent (layout) {
      this.dashboard.configuration.layout = layout
    },

    enableEditClick () {
      this.editMode = !this.editMode
      if (this.editMode) {
        this.$event.dispatch('gl_enable_edit_mode')
      } else {
        this.$event.dispatch('gl_disable_edit_mode')
      }
    },

    async saveLayoutClick () {
      await apiService.globalDashboard.update(this.dashboard.id, this.dashboard).then(async (response) => {
        this.notificationSnackBarAction({
          text: this.$t('dashboard_updated'),
          color: 'success',
          show: true
        })
      }).catch(() => {
        this.notificationSnackBarAction({
          text: this.$t('cant_update_dashboard'),
          color: 'error',
          show: true
        })
      })
    },

    addWidgetToDashboardClick (widget) {
      widget = { ...widget }
      widget.id = this.$uuidKey()
      this.dashboard.configuration.widgets.push(widget)
    }

  }

}
</script>

<style scoped>

</style>
