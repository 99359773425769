<template>
  <v-data-table
    :headers="headers"
    :items="items"
    disable-sort
    :height="height"
    :items-per-page="perPage"
    :hide-default-footer="hideFooter"
    :loading="loading"
    :show-expand="showExpand"
    :single-expand="singleExpand"
  >
    <template v-for="(index, name) in $scopedSlots" v-slot:[name]="context">
      <slot :name="name" v-bind="context" />
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'DataTableShared',
  props: ['headers', 'items', 'height', 'perPage', 'hideFooter', 'loading', 'showExpand', 'singleExpand']
}
</script>

<style scoped>

</style>
