<template>
  <v-card class="transparent" flat>
    <v-row align="center" dense>
      <v-col class="flex-grow-0">
        <span class="pt-0 text-h1 font-weight-medium">{{ day }}</span>
      </v-col>
      <v-col class="flex-grow-1">
        <div>
          <div class="text-h6">
            {{ time }}<br>
          </div>
          <div class="text-h6">
            {{ date }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import format from 'date-fns/format'

export default {
  name: 'ClockComponent',
  data: () => ({
    dateTime: new Date(),
    day: null,
    time: null,
    date: null
  }),
  mounted () {
    setInterval(() => {
      this.setTime()
      this.setDay()
      this.setDate()

      this.dateTime = new Date()
    }, 1000)
  },
  methods: {
    setDay () {
      this.day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(this.dateTime)
    },
    setTime () {
      this.time = format(this.dateTime, 'pp')
    },
    setDate () {
      const day = new Intl.DateTimeFormat('en', { weekday: 'long' }).format(this.dateTime)
      const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(this.dateTime)
      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(this.dateTime)

      this.date = month + ', ' + day + ', ' + year
    }
  }
}
</script>
