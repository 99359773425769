<template>
  <v-btn
      @click="setGlobalHold"
      class="text-icon-button"
      :color="callGetter.canGlobalHold ? 'grey' : 'primary'"
      large
      :loading="disableGlobalHold && callGetter.hasActiveCall"
      dark
      width="100%"
      height="100%"
      :disabled="!callGetter.canGlobalHold || disableGlobalHold || !callGetter.hasActiveCall"
      id="action_KeyG"
  >
    <v-icon>{{ globalHoldIcon }}</v-icon>
    <div class="font-weight-bold pt-2">{{ globalHoldLabel }}</div>
  </v-btn>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import apiService from '@/modules/api/csp'
import localStorageService from '@/util/local-storage-service'

/* Mixins */
import globalHoldMixin from '@/mixins/sip/global-hold.mixin'

export default {
  name: 'GlobalHoldButton',
  mixins: [globalHoldMixin],
  data: (vm) => ({
    globalHoldState: false,
    disableGlobalHold: false,
    globalHoldIcon: 'mdi-timer-sand-empty',
    globalHoldLabel: vm.$t('global_hold')
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ]),
    ...mapGetters('settings', ['sipDefaultAddressGetter', 'getGlobalSetting']),
    ...mapGetters('callInformation', ['incidentIDGetter'])
  },
  watch: {
    'callGetter.hasActiveCall' (val) {
      if (!val) this.disableGlobalHold = false
    }
  },
  methods: {
    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),
    async setGlobalHold () {
      // console.log('Set global hold')
      const callID = this.$sip.answeredCallSession._request.call_id
      const position = localStorageService.getPositionNumber()
      const sipAddress = this.sipDefaultAddressGetter.sip_address
      const incidentID = this.incidentIDGetter[callID]

      this.disableGlobalHold = true
      const realmGroupId = this.getGlobalSetting('global_hold_group_id')
      const data = {
        callId: callID,
        groupId: realmGroupId?.value?.text,
        logParams: {
          position: position ?? null,
          should_log: true,
          write_time: Date.now(),
          sip_address: sipAddress,
          incident_id: incidentID
        }
      }

      await apiService.egress.groups(this.sipDefaultAddressGetter.account_id, data).then(result => {
        this.disableGlobalHold = false
        this.notificationSnackBarAction({
          show: true,
          color: 'success',
          text: this.$t('global_hold_success')
        })

        this.logActivity(result)
      }).catch((error) => {
        this.logActivity(error, 'sip-global-hold-failed', 'critical')

        this.disableGlobalHold = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('global_hold_failed')
        })
      })

      // await apiService.egress.groupsList(this.sipDefaultAddressGetter.account_id, data).then(result => {
      //   console.log('# groupsList success', result)
      // }).catch(error => {
      //   console.log('# groupsList error', error)
      // })
    },
    logActivity (response, method = 'sip-global-hold-success', logLevel = 'info') {
      this.sendActivityLog(method, {
        status: response && response.status && response.status.toString(),
        response: (response && response.data && JSON.stringify(response.data)) || response.toString(),
        log_level: logLevel,
        response_headers: response && response.headers && JSON.stringify(response.headers)
      })
    }
  }
}
</script>

<style scoped>

</style>
