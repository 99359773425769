import sipHelper from '@/helpers/sip.helper'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('settings', ['getGlobalSetting']),
    ...mapGetters('global', ['callGetter'])
  },
  methods: {
    call (target, extraHeaders = [], eventHandlers = {}, conferenceCall = true) {
      target = sipHelper.cleanTarget(target)
      let destination = ''

      if (conferenceCall && this.getGlobalSetting('can_make_outgoing_conference').value.active) {
        destination = sipHelper.getTarget(target, this.$sip.domain, 800)
      } else {
        destination = sipHelper.getTarget(target, this.$sip.domain)
      }

      if (!this.callGetter.hasActiveCall) {
        this.$sip.call(destination, sipHelper.getOptions(extraHeaders, eventHandlers))
      }
    }
  }
}
