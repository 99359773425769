<template>
  <v-card flat color="transparent">
    <v-row no-gutters>
      <v-col class="d-flex justify-center">

        <v-avatar tile size="90px" v-if="logoGetter && logoGetter.file">
          <v-img :src="logoGetter.file" ></v-img>
        </v-avatar>
        <v-avatar v-else size="90px">

          <v-img
            height="100%"
            width="150px"
            :src="require('@/assets/images/demo/icons/demo_logo.svg')" >
          </v-img>
        </v-avatar>

      </v-col>
    </v-row>
  </v-card>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  name: 'LogoComponent',
  computed: {
    ...mapGetters('logo', [
      'logoGetter'
    ])
  },
  methods: {

  },

  mounted () {

  }
}
</script>
