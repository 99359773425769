<template>
  <v-card flat class="fill-height" color="transparent" ref="parent-height">
    <data-table-shared :headers="headers" :items="items" :height="fixedHeight -60" :perPage="100"/>
  </v-card>
</template>

<script>
/* Components */
import DataTableShared from '@/app/shared/table/DataTableShared'
/* Mixins */
import parentHeight from '@/mixins/parent-height.mixin'
import RearrangeNestedObject from '@/mixins/rearrange-nested-object.mixin'
import { mapGetters } from 'vuex'
export default {
  name: 'HeldInformationComponent',
  mixins: [parentHeight, RearrangeNestedObject],
  components: {
    DataTableShared
  },
  data: (vm) => ({
    headers: [
      { text: vm.$t('title'), value: 'name' },
      { text: vm.$t('details'), value: 'value' }
    ],
    items: []
  }),
  computed: {
    ...mapGetters('held', [
      'heldGetter',
      'heldUpdateGetter'
    ])
  },
  watch: {
    'heldUpdateGetter' () {
      this.items = []
      this.getNestedObject(this.heldGetter, 'items', true)
    }
  },

  mounted () {
    this.$event.listen('gl_resize', (e) => {
      this.modifyHeight()
    })
  }
}
</script>

<style scoped>

</style>
