import store from '@/store'
export default {
  methods: {
    getEsrkNumber (session) {
      let esrk = (
        session?._request?.headers?.['P-Asserted-Identity'] &&
        session?._request?.headers?.['P-Asserted-Identity'].length &&
        session?._request?.headers?.['P-Asserted-Identity'][0].raw
      ) || session?._request?.from || ''

      esrk += '' // Sometimes it is NameAddrHeader object sometimes string so we convert it into string
      esrk = esrk === '' ? esrk : esrk?.match(/sip:(.*)@/)[1]

      store.commit('callInformation/esrkNumberMutator', esrk)
    }
  }
}
