<template>
  <tabs-shared :items="getTabs"/>
</template>

<script>
/* Shared */
import tabsShared from '@/app/shared/tabs/TabsShared'
/* Components */
import CallInfoComponent from '@/app/widgets/call-information-widget/components/CallInfoComponent'
import HeldInformationComponent from '@/app/widgets/held-information-widget/components/HeldInformationComponent'
import AdrInformationComponent from '@/app/widgets/adr-information-widget/components/AdrInformationComponent'
import { mapGetters } from 'vuex'
export default {
  name: 'EmergencyCallTabsComponent',
  components: {
    tabsShared
  },

  data: (vm) => ({
    tab: null
  }),

  computed: {
    getTabs () {
      return [
        {
          tab: this.$t('call_information'),
          name: 'call_information',
          component: CallInfoComponent,
          order: this.getWidgetSettings.EmergencyCallTabsComponent.tabs.call_information.order,
          visible: this.$can('can_see_call_information') && this.getWidgetSettings.EmergencyCallTabsComponent.tabs.call_information.visible
        },
        {
          tab: this.$t('held'),
          name: 'held',
          component: HeldInformationComponent,
          eager: true,
          order: this.getWidgetSettings.EmergencyCallTabsComponent.tabs.held.order,
          visible: this.$can('can_see_held') && this.getWidgetSettings.EmergencyCallTabsComponent.tabs.held.visible
        },
        {
          tab: this.$t('adr'),
          name: 'adr',
          component: AdrInformationComponent,
          eager: true,
          order: this.getWidgetSettings.EmergencyCallTabsComponent.tabs.adr.order,
          visible: this.$can('can_see_adr') && this.getWidgetSettings.EmergencyCallTabsComponent.tabs.adr.visible
        }
      ]
    },
    ...mapGetters('layout', ['layoutGetter', 'getWidgetSettings'])
  }
}
</script>

<style scoped>

</style>
