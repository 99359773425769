<template>
  <v-data-table
    ref="parent-height"
    :dark="$vuetify.theme.dark"
    :expanded="expanded"
    :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
    :headers="headers"
    :height="fixedHeight - 150"
    :items="(items && items.value) || []"
    :items-per-page="10"
    :loading-text="$t('loading')"
    :options.sync="options"
    :search="search"
    :server-items-length="(totalItems && totalItems.value) || 0"
    class="px-4 py-2"
    cypress="contact_book_table"
    dense
    item-key="id"
    selectable-key="id"
    show-expand
    single-
    single-expand
    @update:options="paginationData"
  >
    <template v-slot:[`top`]>
      <div>
        <div class="d-flex">
          <DialogShared
            v-model="contactGroupsDialog"
            :title="$t('contact_groups')"
            eager
            max-width="800"
          >
            <ContactGroupsComponent
              @close="contactGroupsDialog = false"
            ></ContactGroupsComponent>
          </DialogShared>

          <DialogShared
            v-model="importContactsDialog"
            :title="$t('import_contacts')"
            eager
            max-width="800"
          >
            <ImportContactBooks
              @close="importContactsDialog = false"
            ></ImportContactBooks>
          </DialogShared>
          <DialogShared
            v-model="dialog.open"
            :title="dialog.title"
            eager
            max-width="800"
          >
            <validation-observer ref="observer">
              <v-card color="secondary_card" flat tile>
                <v-card-text>
                  <v-form
                    ref="form"
                    :disabled="loading"
                    novalidate
                    @submit.prevent="onSubmit"
                  >
                    <v-row class="pb-4">
                      <v-col class="text-right pt-2" cols="12">
                        <v-btn
                          :rounded="true"
                          color="success"
                          cypress="add_new_number"
                          small
                          @click="addRow"
                        >
                          {{ $t("add_new_number") }}
                          <v-icon> mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('contact_permission')"
                      rules="required"
                    >
                      <v-select
                        v-if="sipDefaultAddressGetter"
                        v-model="form.contact_permission"
                        :error-messages="errors"
                        :items="[
                          {
                            value: 'public',
                            label: $t('public'),
                          },
                          {
                            value: 'private',
                            label: $t('private'),
                          },
                          {
                            value: 'realm',
                            label:
                              $t('realm') + ' ' + sipDefaultAddressGetter.realm,
                          },
                        ]"
                        :label="$t('contact_permission')"
                        cypress="contact_permission"
                        item-text="label"
                        item-value="value"
                        name="contact_permission"
                        outlined
                      ></v-select>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('contact_groups')"
                      rules=""
                    >
                      <v-select
                        v-model="form.contact_book_group_ids"
                        :dark="$vuetify.theme.dark"
                        :error-messages="errors"
                        :items="(contactGroups && contactGroups.value) || []"
                        :label="$t('contact_groups')"
                        cypress="contact_groups"
                        item-text="name"
                        item-value="id"
                        multiple
                        name="contact_groups"
                        outlined
                        small-chips
                      ></v-select>
                    </validation-provider>

                    <v-radio-group v-model="form.is_primary">
                      <template
                        v-for="(item, index) in form.contact_book_phones"
                      >
                        <v-sheet
                          :key="index"
                          :rounded="true"
                          class="pa-4 mt-2"
                          color="transparent"
                          elevation="3"
                        >
                          <v-row>
                            <v-col class="pb-0" cols="8">
                              <v-radio
                                :key="index"
                                :cypress="`contact_book_primary_${index}`"
                                :label="$t('make_primary')"
                                :value="index"
                                class="my-0"
                                @click="changePrimary()"
                              ></v-radio>
                            </v-col>
                            <v-col class="text-right pb-0" cols="4">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    @click="removeRow(index)"
                                    v-on="on"
                                  >
                                    <v-icon color="error"> mdi-minus</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("remove_phone_number") }}</span>
                              </v-tooltip>
                            </v-col>
                            <v-col class="pb-0" cols="4">
                              <validation-provider
                                v-slot="{ errors }"
                                :name="$t('phone_number')"
                                :vid="`contact_phone_number_${index}`"
                                rules="required"
                              >
                                <v-text-field
                                  :key="`contact_phone_number_${index}`"
                                  v-model="item.phone"
                                  :cypress="`contact_phone_number_${index}`"
                                  :error-messages="errors"
                                  :label="$t('phone_number')"
                                  name="phone_number"
                                  outlined
                                  required
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col class="pb-0" cols="4">
                              <v-text-field
                                v-model="item.flash_hook"
                                :label="$t('contact_book_flash_hook')"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col class="pb-0" cols="4">
                              <validation-provider
                                v-slot="{ errors }"
                                :name="$t('name')"
                                :vid="`contact_name_${index}`"
                                rules="required"
                              >
                                <v-text-field
                                  :key="`contact_name_${index}`"
                                  v-model="item.name"
                                  :cypress="`contact_name_${index}`"
                                  :error-messages="errors"
                                  :label="$t('name')"
                                  name="name"
                                  outlined
                                  required
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                          </v-row>
                        </v-sheet>
                      </template>
                    </v-radio-group>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    :disabled="loading"
                    class="mr-5"
                    color="passive_button"
                    @click="onCancel()"
                  >
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn
                    :disabled="loading"
                    color="error"
                    cypress="submit_contact"
                    @click="onSubmit"
                  >
                    {{ $t("save") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </validation-observer>
          </DialogShared>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="!!!$can('can_add_contact')"
                class="mt-3"
                cypress="add_new_contact"
                depressed
                fab
                small
                text
                @click="create"
                v-on="on"
              >
                <v-icon color="error"> mdi-account-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("add_new_contact") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="!!!$can('can_add_contact')"
                class="mt-3"
                cypress="create_new_contact_group"
                depressed
                fab
                small
                text
                @click="onContactGroupClick"
                v-on="on"
              >
                <v-icon color="error"> mdi-account-group</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("contact_groups") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="$can('can_import_contacts')"
                class="mt-3"
                cypress="import_contacts"
                depressed
                fab
                icon
                small
                text
                @click="onImportClick"
                v-on="on"
              >
                <v-icon color="error"> mdi-database-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("import_contacts") }}</span>
          </v-tooltip>
          <div class="vertical-divider"></div>
          <v-text-field
            v-model="search"
            :disabled="loading"
            :label="$t('search')"
            class="mr-8"
            clearable
            cypress="contact_search"
            prepend-icon="mdi-magnify"
            @click:clear="clearSearch"
          ></v-text-field>
        </div>
        <div>
          <v-chip
            v-if="
              (contactGroups &&
                contactGroups.value &&
                contactGroups.value.length) > 0
            "
            class="mb-1 mr-1"
            small
            @click.prevent="removeGroupFilter"
          >
            {{ $t("all") }}
          </v-chip>
          <v-chip
            v-for="group in (contactGroups && contactGroups.value) || []"
            :key="group.id"
            :style="{ 'background-color': group.colour }"
            class="mr-1 pr-1 pl-1 mb-1"
            small
            @click.prevent="filterContacts(group)"
          >
            <v-icon v-if="group.icon" small>{{ group.icon }}</v-icon>
            {{ group.name | substringIf }}
          </v-chip>
        </div>
      </div>
    </template>

    <template v-slot:[`item.contact_permission`]="{ item }">
      <v-btn v-if="item.contact_permission === 'private'" icon small>
        <v-icon color="error" small>mdi-lock-open-remove-outline</v-icon>
      </v-btn>
      <v-btn v-if="item.contact_permission === 'public'" icon small>
        <v-icon color="success" small>mdi-lock-open-check-outline</v-icon>
      </v-btn>
    </template>

    <template v-slot:[`item.phone.name`]="{ item }">
      <v-btn
        class="mx-1 my-1"
        color="grey"
        depressed
        height="30"
        min-width="0"
        small
        width="30"
      >
        <v-icon class="grey" small> mdi-account-box-outline</v-icon>
      </v-btn>

      <v-tooltip v-if="item.phone" bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ item.phone.name | substringIf(0, 10, "...") }}</span
          >
        </template>
        <span>{{ item.phone.name }}</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.phone`]="{ item }">
      <v-tooltip v-if="item.phone" bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ item.phone.phone | substringIf(0, 20, "...") }}</span
          >
        </template>
        <span>{{ item.phone.phone }}</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.flash_hook`]="{ item }">
      <v-tooltip v-if="item.phone.flash_hook" bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ item.phone.flash_hook | substringIf(0, 20, "...") }}</span
          >
        </template>
        <span>{{ item.phone.flash_hook }}</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.actions`]="{ item, index }">
      <v-tooltip v-if="$can('can_edit_contact')" bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            :cypress="`contact_book_edit_${index}`"
            class="mx-1 my-1"
            color="primary"
            height="30"
            min-width="0"
            small
            width="30"
            v-on="on"
            @click.stop="edit(item)"
          >
            <v-icon color="white" small> mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("edit") }}</span>
      </v-tooltip>

      <v-tooltip v-if="$can('can_delete_contact')" bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            :cypress="`contact_book_remove_${index}`"
            class="mx-1 my-1"
            color="error"
            height="30"
            min-width="0"
            small
            width="30"
            v-on="on"
            @click.stop="remove(item.id)"
          >
            <v-icon color="white" small> mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("delete") }}</span>
      </v-tooltip>

      <call-back-button v-if="item.phone" :dialNumber="item.phone.phone" />
      <call-transfer-button v-if="item.phone" :dialNumber="item.phone.phone" />
      <call-info-refer-button
        v-if="item.phone"
        :dialNumber="item.phone.phone"
      />
    </template>

    <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
      <td class="text-start">
        <template v-if="item.contact_book_phones.length > 1">
          <v-btn
            :class="{ 'v-data-table__expand-icon--active': isExpanded }"
            class="v-data-table__expand-icon"
            icon
            @click="expand(!isExpanded)"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
      </td>
    </template>

    <template v-slot:expanded-item="{ item }">
      <td class="pa-0" colspan="5">
        <v-simple-table class="expanded-table" fixed-header>
          <tbody>
            <template v-for="phones in item.contact_book_phones">
              <tr v-if="!phones.is_primary" :key="phones.id" class="pa-0">
                <td>
                  {{ $t("name") }}:
                  <span :title="phones.name">
                    {{ phones.name | substringIf(0, 15, "...") }}
                  </span>
                </td>
                <td>
                  {{ $t("phone") }}:
                  <span :title="phones.phone">
                    {{ phones.phone | substringIf(0, 15, "...") }}
                  </span>
                </td>
                <td>
                  {{ $t("contact_book_flash_hook") }}:
                  <span :title="phones.flash_hook">
                    {{ phones.flash_hook | substringIf(0, 15, "...") }}
                  </span>
                </td>
                <td>
                  <call-back-button :dialNumber="phones.phone" />
                  <call-transfer-button :dialNumber="phones.phone" />
                  <call-info-refer-button
                    :dialNumber="phones.phone"
                  ></call-info-refer-button>
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </td>
    </template>
  </v-data-table>
</template>

<script>
/* Mixins */
import parentHeight from '@/mixins/parent-height.mixin'
import { mapActions, mapGetters } from 'vuex'

import DialogShared from '@/app/shared/dialog/DialogShared'
import CallBackButton from '@/app/shared/buttons/CallBackButton'
import CallTransferButton from '@/app/shared/buttons/CallTransferButton'
import CallInfoReferButton from '@/app/shared/buttons/CallInfoReferButton'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/rules/validation.rules.js'
import ImportContactBooks from '@/app/widgets/contact-book-widget/components/ImportContactBooks'
import ContactGroupsComponent from '@/app/widgets/contact-book-widget/components/ContactGroupsComponent'

export default {
  name: 'ContactBookComponent',
  mixins: [parentHeight],

  components: {
    ContactGroupsComponent,
    ImportContactBooks,
    DialogShared,
    ValidationObserver,
    ValidationProvider,

    CallBackButton,
    CallTransferButton,
    CallInfoReferButton
  },

  data: (vm) => ({
    search: '',
    expanded: [],
    group_id: null,
    loading: false,
    headers: [
      { text: vm.$t('name'), value: 'phone.name', sortable: true },
      { text: vm.$t('phone'), value: 'phone', sortable: false },
      {
        text: vm.$t('contact_book_flash_hook'),
        value: 'flash_hook',
        sortable: false
      },
      { text: vm.$t('type'), value: 'contact_permission', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ],
    options: {
      itemsPerPage: 10,
      sortDesc: ['desc']
    },
    dialog: {
      open: false,
      title: ''
    },
    contactGroupsDialog: false,
    importContactsDialog: false,
    form: {
      id: null,
      contact_permission: 'public',
      contact_book_group_ids: [],
      phone: '',
      name: '',
      note: '',
      icon: '',
      tandem_transfer: '',
      category: '',
      '911_text_transfer': '',
      quick_dial_harris: '',
      realm: null
    },

    contactPermOptions: [],
    items: {
      value: []
    },
    totalItems: 0,
    contactGroups: {
      value: []
    },
    oldCachedPage: null,
    oldCachedItemsPerPage: null
  }),

  computed: {
    ...mapGetters('contactBook', [
      'getContactBookGetter',
      'getContactGroupsGetter'
    ]),

    ...mapGetters('settings', ['sipDefaultAddressGetter'])
  },

  methods: {
    ...mapActions('contactBook', [
      'createContactBookAction',
      'deleteContactBookAction',
      'updateContactBookAction'
    ]),

    ...mapActions('notifications', ['notificationSnackBarAction']),

    /**
     * Clear search data
     */
    clearSearch () {
      this.search = ''
    },

    /**
     * Open create dialog
     * @returns {Promise<void>}
     */
    async create () {
      this.$refs.observer.reset()
      this.dialog.title = this.$t('create_contact')
      this.dialog.open = true

      this.form = {
        id: null,
        contact_permission: 'public',
        contact_book_group_ids: [],
        phone: '0',
        name: '',
        note: '',
        icon: '',
        tandem_transfer: '',
        category: '',
        '911_text_transfer': '',
        quick_dial_harris: '',
        is_primary: 0,
        contact_book_phones: [
          {
            phone: '',
            name: '',
            is_primary: 1,
            flash_hook: ''
          }
        ],
        realm: null
      }
    },

    async onImportClick () {
      this.$refs.observer.reset()
      this.importContactsDialog = true
    },
    async onContactGroupClick () {
      this.$refs.observer.reset()
      this.contactGroupsDialog = true
    },

    /**
     * Open edit dialog
     * @param item
     * @returns {Promise<void>}
     */
    async edit (item) {
      this.$refs.observer.reset()

      this.form = JSON.parse(JSON.stringify(item))
      this.form.is_primary = this.form.contact_book_phones.findIndex(
        (phones) => phones.is_primary === 1
      )
      this.form.contact_book_group_ids = this.form.contact_book_group_ids.map(
        (i) => parseInt(i)
      )
      this.form.contact_book_phones = this.form.contact_book_phones.map((i) => {
        i.id = parseInt(i.id)
        i.contact_book_id = parseInt(i.contact_book_id)

        return i
      })

      this.dialog.title = this.$t('update_contact')
      this.dialog.open = true
    },

    /**
     * Remove contact
     * @param id
     * @returns {Promise<void>}
     */
    async remove (id) {
      this.$confirm(
        this.$t('please_confirm_that_you_want_to_delete_this', {
          what: this.$t('contact')
        })
      ).then(async (confirmed) => {
        if (confirmed) {
          await this.deleteContactBookAction({ id })
        }
      })
    },

    async removeGroupFilter () {
      this.group_id = null

      this.items = await this.$localCacher.indexDB.contactBooks.get({
        limit: 10,
        group_id: this.group_id
      })

      this.totalItems = await this.$localCacher.indexDB.contactBooks.count()
    },
    async filterContacts (group) {
      this.group_id = group.id

      this.items = await this.$localCacher.indexDB.contactBooks.get({
        limit: 10,
        group_id: this.group_id
      })

      this.totalItems = await this.$localCacher.indexDB.contactBooks.count({
        group_id: this.group_id
      })
    },

    onCancel () {
      this.$refs.observer.reset()
      this.dialog.open = false
    },

    /**
     * Submit form
     * @returns {Promise<void>}
     */
    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        if (this.form.contact_permission === 'realm') {
          this.form.realm = this.sipDefaultAddressGetter.realm
        } else {
          this.form.realm = null
        }

        if (this.form.id) {
          await this.updateContactBookAction({
            id: this.form.id,
            data: this.form
          })
            .then((response) => {
              this.notificationSnackBarAction({
                show: true,
                color: 'success',
                text: this.$t('contact_updated')
              })
              this.loading = false
              this.dialog.open = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          await this.createContactBookAction({
            ...this.form
          })
            .then((response) => {
              this.notificationSnackBarAction({
                show: true,
                color: 'success',
                text: this.$t('contact_created')
              })
              this.loading = false
              this.dialog.open = false
            })
            .catch((error) => {
              this.$refs.observer.setErrors(error.response.data.errors)
              this.loading = false
            })
        }
      }
    },
    addRow () {
      this.form.contact_book_phones.push({
        phone: '',
        name: '',
        is_primary: 0
      })
    },
    removeRow (index) {
      if (this.form.contact_book_phones.length > 1) {
        if (this.form.is_primary === index) {
          this.form.contact_book_phones.splice(index, 1)
          this.form.contact_book_phones[0].is_primary = 1
          this.form.is_primary = 0
        } else {
          this.form.contact_book_phones.splice(index, 1)
          this.form.is_primary = this.form.contact_book_phones.findIndex(
            (phones) => phones.is_primary === 1
          )
        }
      }
    },
    changePrimary () {
      this.form.contact_book_phones.forEach((item) => {
        item.is_primary = 0
      })
      this.form.contact_book_phones[this.form.is_primary].is_primary = 1
    },
    async paginationData (data) {
      this.oldCachedPage = data.page
      this.oldCachedItemsPerPage = data.itemsPerPage

      this.items = await this.$localCacher.indexDB.contactBooks.get({
        offset: (data.page - 1) * data.itemsPerPage,
        limit: data.itemsPerPage,
        group_id: this.group_id,
        search: this.search,
        sort: data.sortBy,
        sortDesc: data.sortDesc
      })

      this.totalItems = await this.$localCacher.indexDB.contactBooks.count({
        group_id: this.group_id,
        search: this.search
      })
    }
  },

  async mounted () {
    this.loading = true

    this.items = await this.$localCacher.indexDB.contactBooks
      .get({
        limit: 10
      })
      .finally(() => {
        this.loading = false
      })

    this.contactGroups = await this.$localCacher.indexDB.contactGroups.get({})

    this.totalItems = await this.$localCacher.indexDB.contactBooks.count()

    this.modifyHeight()

    this.$event.listen('gl_resize', (e) => {
      this.modifyHeight()
    })
  },
  watch: {
    async search () {
      this.selected = []

      this.items = await this.$localCacher.indexDB.contactBooks.get({
        limit: 10,
        group_id: this.group_id,
        search: this.search,
        sort: this.options.sortBy,
        sortDesc: this.options.sortDesc
      })

      this.totalItems = await this.$localCacher.indexDB.contactBooks.count({
        group_id: this.group_id,
        search: this.search
      })

      this.options.page = 1
    }
  }
}
</script>

<style scoped>
.expanded-table td {
  font-size: 0.75rem !important;
  height: 30px !important;
}

.expanded-table td:last-child {
  width: 210px !important;
}
</style>
