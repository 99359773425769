<template>
  <v-btn
    :dark="$vuetify.theme.dark"
    id="button_Transfer"
    color="accent"
    class="dial-pad-keys dial_btn_transfer"
    @click.once="TransferToNumber"
    tile
    block
    height='80px'
    :disabled="disabled"
    :key="buttonKey"
  >
    <v-icon small color="white">
      mdi-call-merge
    </v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
/* Mixins */
import warmTransferMixin from '@/mixins/sip/warm-transfer.mixin'
import clickOnceRefreshMixin from '@/mixins/click-once-refresh.mixin'
import store from '@/store'

export default {
  name: 'DialPadTransferButton',
  mixins: [warmTransferMixin, clickOnceRefreshMixin],
  data: () => ({
    buttonKey: null
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ]),
    ...mapGetters('dialPad', [
      'callPhoneNumberGetter'
    ]),
    disabled () {
      return !this.callGetter.hasActiveCall || !this.callPhoneNumberGetter?.length
    }
  },
  methods: {
    async TransferToNumber () {
      this.warmTransfer(this.callPhoneNumberGetter)
      await store.commit('global/callMutator', {
        type: 'transferredTo',
        data: this.callPhoneNumberGetter
      })
      this.refreshButton()
    }
  },
  mounted () {
    this.buttonKey = this.$uuidKey()
  }
}
</script>

<style scoped>

</style>
