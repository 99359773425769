<template>
  <div>
    <v-btn
      class="pa-2"
      dark
      block
      height="100%"
      color="error"
      :disabled="!callGetter.hasActiveCall"
      @click="incorrectLocation"
    >
      <v-icon color="white">mdi-map-marker-off</v-icon>
    </v-btn>
    <DialogShared v-model="locationModal" max-width="800" :title="$t('incorrect_location')">
      <incorrect-location-component @close="locationModal=false"></incorrect-location-component>
    </DialogShared>
  </div>
</template>

<script>
import IncorrectLocationComponent from '@/app/widgets/incorrect-location-widget/components/IncorrectLocationComponent'
/* Shared */
import DialogShared from '@/app/shared/dialog/DialogShared'
import { mapGetters } from 'vuex'
export default {
  name: 'IncorrectLocationButton',
  components: { IncorrectLocationComponent, DialogShared },
  data: () => ({
    locationModal: false
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ])
  },
  methods: {
    incorrectLocation () {
      this.locationModal = !this.locationModal
    }
  }
}
</script>

<style scoped>

</style>
