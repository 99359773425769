import localStorageService from '@/util/local-storage-service'
import apiService from '@/modules/api/csp'

export default {
  methods: {
    async globalHold () {
      // Putting conference onhold
      const putConferenceOnHold = await apiService.egress.putConferenceOnHold(localStorageService.getAccountId(), this.getConferenceId(), {
        holder: 'global'
      })

      if (putConferenceOnHold?.data?.data?.status === 'ok') {
        // Getting infromation about conference
        await apiService.egress.getHoldConferences(localStorageService.getAccountId())
        // Getting conference participants
        const getConferenceParticipants = await apiService.egress.getConferenceParticipants(localStorageService.getAccountId(), this.getConferenceId())

        if (getConferenceParticipants?.data?.status === 'success') {
          // Find PSAP in the conference participants list
          const participant = getConferenceParticipants.data.data.find(item => item?.custom_channel_vars?.username === this.$sip.username)
          // Kicking participant from the conference
          await apiService.egress.kickConferenceParticipant(localStorageService.getAccountId(), this.getConferenceId(), participant?.participant_id)

          await apiService.egress.dialConference(localStorageService.getAccountId(), this.getConferenceId(), {
            endpoints: [`sip:${this.$sip.username}@${this.$sip.domain}`],
            caller_id_name: 'Conference XYZ',
            caller_id_number: '5551212'
          })
        }
      }
    },
    getConferenceId () {
      return this.$sip.answeredCallSession?._request?.headers?.Contact?.[0]?.raw?.match(/<sip:(.*?)@/)?.[1]
    }
  }
}
