<template>
  <v-btn
      class="text-icon-button"
      :color="irrPlaybackState ? 'grey' : 'primary'"
      large
      dark
      width="100%"
      :disabled="!sipGetter.hasActiveCall"
      height="100%"
  >
    <v-icon>{{ irrPlaybackIcon }}</v-icon>
    <div class="font-weight-bold pt-2"> {{ $t('irr_playback')}} <br> {{ $t('to_phone')}}</div>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IrrPlaybackButton',
  data: () => ({
    disableIrrPlayback: false,
    irrPlaybackState: false,
    irrPlaybackIcon: 'mdi-timer-sand-empty'
  }),
  computed: {
    ...mapGetters('global', [
      'sipGetter'
    ])
  }
}
</script>

<style scoped>

</style>
