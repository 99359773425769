var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogShared',{attrs:{"eager":"","max-width":"800","title":_vm.dialogTitle},model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}},[_c('validation-observer',{ref:"observer"},[_c('v-form',{staticClass:"pt-4",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('validation-provider',{attrs:{"name":"name","rules":"required","cypress":"contact_group_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","name":"name","label":_vm.$t('name'),"error-messages":errors},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('icon')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"clearable":"","autocomplete":"false","item-text":"label","item-value":"icon","disabled":_vm.loading,"error-messages":errors,"items":_vm.getIcons,"required":"","outlined":"","cypress":"contact_group_type"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(data.item.icon))]),_vm._v(" "+_vm._s(data.item.label)+" ")]}},{key:"selection",fn:function(data){return [_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(data.item.icon))]),_vm._v(" "+_vm._s(data.item.label)+" ")]}}],null,true),model:{value:(_vm.form.icon),callback:function ($$v) {_vm.$set(_vm.form, "icon", $$v)},expression:"form.icon"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[(_vm.form.colour)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"red","small":""},on:{"click":_vm.onClearColorClick}},[_vm._v(_vm._s(_vm.$t('clear_color')))]):_vm._e(),_c('v-color-picker',{staticClass:"transparent-background",attrs:{"dark":_vm.$vuetify.theme.dark,"hide-canvas":"","hide-inputs":"","hide-mode-switch":"","hide-sliders":"","show-swatches":"","cypress":"contact_group_colors"},model:{value:(_vm.colorPicker),callback:function ($$v) {_vm.colorPicker=$$v},expression:"colorPicker"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"passive_button","disabled":_vm.loading,"cypress":"contact_group_cancel"},on:{"click":function($event){return _vm.onCancel()}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"disabled":_vm.loading,"type":"submit","color":"red","cypress":"contact_group_create"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)],1)],1),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":"","cypress":"contact_group_add"},on:{"click":_vm.onCreate}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('add_new')))])])],1)],1),_c('v-data-table',{staticClass:"px-4 py-2 transparent-background",attrs:{"headers":_vm.headers,"items":(_vm.items && _vm.items.value) || [],"options":_vm.options,"item-key":"id","selectable-key":"id","single-select":"","dark":_vm.$vuetify.theme.dark,"loading":_vm.loading,"loading-text":_vm.$t('loading'),"footer-props":{itemsPerPageOptions : [5,10,15]},"server-items-length":(_vm.totalItems && _vm.totalItems.value) || 0},on:{"update:options":[function($event){_vm.options=$event},_vm.paginationData]},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [(item.icon)?_c('v-icon',[_vm._v(_vm._s(item.icon))]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"color":"green","small":"","cypress":("contact_group_edit_" + index)},on:{"click":function($event){$event.stopPropagation();return _vm.onEdit(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('edit')))])])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"error","small":"","cypress":("contact_group_delete_" + index)},on:{"click":function($event){$event.stopPropagation();return _vm.onDelete(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('delete')))])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }