<template>
  <v-btn
    :dark="$vuetify.theme.dark"
    id="action_KeyI"
    color="red accent-2"
    class="dial-pad-keys dial_btn_transfer"
    @click.once="sendInfoToNumber"
    tile
    block
    height='80px'
    :disabled="disabled || !$can('can_send_hook_flash')"
    :key="buttonKey"
  >
    <v-icon small color="white">
      mdi-flash
    </v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
/* Mixins */
import clickOnceRefreshMixin from '@/mixins/click-once-refresh.mixin'
import InfoReferMixin from '@/mixins/sip/info-refer-mixin'

export default {
  name: 'DialPadFlashHookButton',
  mixins: [InfoReferMixin, clickOnceRefreshMixin],
  data: () => ({
    buttonKey: null
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ]),
    disabled () {
      return !this.callGetter.hasActiveCall
    }
  },
  methods: {
    sendInfoToNumber () {
      this.sendInfo()
      this.refreshButton()
    }
  },
  mounted () {
    this.buttonKey = this.$uuidKey()
  }
}
</script>

<style scoped>

</style>
