<template>
  <v-card flat class="d-flex flex-column align-start clock_height"  color="transparent">
    <v-row no-gutters class="align-center flex-grow-0">
      <v-col class="flex-grow-0">
        <LogoComponent />
      </v-col>
      <v-col class="flex-grow-1">
        <ClockComponent class="ml-4" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
/* Tabs */

/* Components */
import LogoComponent from '@/app/widgets/logo-widget/components/LogoComponent'
import ClockComponent from '@/app/widgets/clock-widget/components/ClockComponent'

export default {
  name: 'LogoAndClockComponent',
  data: () => ({

  }),
  components: {
    LogoComponent,
    ClockComponent
  }
}
</script>

<style scoped>
  .clock_height{
    min-height: 100px;
  }

</style>
