<template>
  <v-data-table
    dense
    ref="parent-height"
    cypress="agents_table"
    class="px-4 py-2"
    :dark="$vuetify.theme.dark"
    :items="sortedItems"
    :height="fixedHeight - 80"
    item-key="id"
    :loading=loading
    :headers="headers"
    :loading-text="$t('loading')"
    selectable-key="id"
    :items-per-page="itemPerPage"
  >
    <template v-slot:[`item.name`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span :title="item.name"
                v-bind="attrs"
                v-on="on">
            {{ item.first_name + ' ' + item.last_name }}
          </span>
        </template>
        <span>{{ item.first_name + ' ' +  item.last_name }}</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <v-icon color="green" v-if="item.state == 'READY'">mdi-checkbox-marked-circle</v-icon>
      <v-icon color="orange" v-if="item.state == 'ON_CALL'">mdi-phone</v-icon>
      <v-badge
        color="error"
        overlap
        top
        right
        icon="mdi-heart"
        v-if="item.state == 'ON_EMERGENCY_CALL'"
      >
        <v-icon color="orange">mdi-phone</v-icon>
      </v-badge>
      <v-icon color="red" v-if="!item.state || item.state == 'NOT_READY'">mdi-cancel</v-icon>
    </template>

    <template v-slot:[`item.dial`]="{ item }">
      <call-back-button :dialNumber="item.presence_id" :conferenceCall="false" />
      <call-transfer-button :dialNumber="item.presence_id" />
      <observe-button :agent="{...item}" :dialNumber="item.presence_id" />
      <whisper-button :agent="{...item}" :dialNumber="item.presence_id" />
      <barge-button :agent="{...item}" :dialNumber="item.presence_id" />
    </template>
  </v-data-table>
</template>

<script>
/* Mixins */
import parentHeight from '@/mixins/parent-height.mixin'

/* Shared */
import CallBackButton from '@/app/shared/buttons/CallBackButton'
import CallTransferButton from '@/app/shared/buttons/CallTransferButton'
import ObserveButton from '@/app/shared/buttons/ObserveButton'
import WhisperButton from '@/app/shared/buttons/WhisperButton'
import BargeButton from '@/app/shared/buttons/BargeButton'

import { mapGetters, mapActions } from 'vuex'

/* Helpers */
import textHelper from '@/helpers/text.helper'
export default {
  name: 'AgentsComponent',
  mixins: [parentHeight],
  components: {
    CallBackButton,
    CallTransferButton,
    ObserveButton,
    WhisperButton,
    BargeButton
  },
  data: (vm) => ({
    loading: false,
    itemPerPage: 10,
    headers: [
      { text: vm.$t('full_name'), value: 'name', sortable: false },
      { text: vm.$t('status'), value: 'status', sortable: false },
      { text: vm.$t('dial'), value: 'dial', sortable: false }
    ],
    items: [],
    sortedItems: []
  }),
  computed: {
    ...mapGetters('settings', ['sipDefaultAddressGetter']),
    ...mapGetters('callAgents', ['callAgentsGetter'])
  },
  watch: {
    'sipDefaultAddressGetter.account_id' (val) {
      this.getUsers(val)
      this.readAgents()
    }
  },
  methods: {
    ...mapActions('callAgents', ['callAgentsAction']),
    prepareAgents () {
      this.sortAgents()

      if (!this.sipDefaultAddressGetter) return

      this.$socket.emit('join-realm', {
        realm: this.sipDefaultAddressGetter.realm,
        userID: this.sipDefaultAddressGetter.sip_address
      })
    },
    sortAgents () {
      if (!this.items) return

      const val = this.items

      this.sortedItems = [...val]

      const stateValues = {
        ON_EMERGENCY_CALL: 0,
        ON_CALL: 1,
        READY: 2,
        NOT_READY: 3,
        undefined: 4
      }

      this.sortedItems.forEach(item => {
        // let extention = item.name.split('-')

        // if (extention.length === 2) {
        //   extention = extention[1]
        // } else {
        //   extention = extention[0]
        //   extention = item.username.toLowerCase()
        // }

        item.sip_address = `${item.presence_id?.trim()}@${this.sipDefaultAddressGetter.realm}`
      })

      this.sortedItems.sort(function (a, b) {
        return stateValues[a.state] - stateValues[b.state]
      })

      this.sortedItems = [...this.sortedItems]
      this.$forceUpdate()
    },
    async getUsers (accountId) {
      this.loading = true
      await this.callAgentsAction({
        accountId: accountId,
        params: {}
      }).catch(() => {
        this.loading = false
      }).finally(() => {
        this.loading = false
      })
    },
    mergeAndSubstring (name, lastname) {
      const agent = `${name} ${lastname}`
      return textHelper.substringIf(agent, 0, 25, '...')
    },
    readAgents () {
      if (!this.sipDefaultAddressGetter) return

      let agents = []

      this.$firestore.collection('agents')
        .where('account_id', '==', this.sipDefaultAddressGetter.account_id)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach((doc) => {
            const agent = { ...doc.data() }

            agent.id = doc.id

            agents.push(agent)
          })

          this.items = [...agents]

          agents = []

          this.sortAgents()
        })
    }
  },
  async mounted () {
    this.readAgents()

    this.$event.listen('gl_resize', (e) => {
      this.modifyHeight()
    })
  }
}
</script>

<style scoped>

</style>
