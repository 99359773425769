<template>
  <div class="pa-1s">
    <DialogShared v-model="dialog" max-width="1200" scrollable :title="$t('add_note')">
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form class="pt-4" @submit.prevent="onSubmit" novalidate >
          <v-row dense>
            <v-col>
              <validation-provider v-slot="{ }" name="note" rules="required">
                <v-textarea v-model="note"></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn :disabled="loading" class="chs-dark-btn mr-4" @click="dialog=false">
                {{ $t('cancel')}}
              </v-btn>
              <v-btn :disabled="invalid" type="submit" class="chs-red-btn" >
                {{ $t('submit')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </DialogShared>
    <data-table-shared :headers="headers" :items="notes" :loading="loading" :perPage="10" :hide-footer="false">
      <template v-slot:top>
        <div class="d-flex pa-0 pl-2">
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn  text  class="mt-3" @click="dialog=true" v-on="on">
                <v-icon color="error">
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('add_note')}}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <div class="my-3">
          <div>
            <b>{{ dateFormatter(item.created_at) }} by {{ item.username}}</b>
          </div>
          {{ item.description }}
        </div>
      </template>
    </data-table-shared>
  </div>
</template>

<script>
/* Components */
import DataTableShared from '@/app/shared/table/DataTableShared'
import DialogShared from '@/app/shared/dialog/DialogShared'

import timeHelper from '@/helpers/time.helper'

import { mapGetters, mapActions } from 'vuex'

import { ValidationProvider, extend, ValidationObserver } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { format, utcToZonedTime } from 'date-fns-tz'

extend('required', required)
extend('required', {
  ...required,
  message: '{_field_} is required'
})

export default {
  name: 'CallDetailsNotesComponent',
  components: {
    ValidationObserver,
    ValidationProvider,
    DialogShared,
    DataTableShared
  },
  computed: {
    ...mapGetters('callDetails', [
      'callDetailsGetter'
    ])
  },
  data: (vm) => ({
    dialog: false,
    loading: false,
    note: null,
    headers: [
      {
        text: vm.$t('note'),
        value: 'description'
      }
    ],
    notes: []
  }),
  watch: {
    async 'callDetailsGetter' (val) {
      if (Object.keys(val).length) {
        await this.getNotes()
      }
    }
  },
  methods: {
    ...mapActions('notifications', ['notificationSnackBarAction']),
    ...mapActions('callDetails', ['getCallDetailNotes', 'saveCallDetailNotes']),
    dateFormatter (value) {
      const zonedDate = utcToZonedTime(value, Intl.DateTimeFormat().resolvedOptions().timeZone)
      return format(zonedDate, 'MM/dd/yyyy h:mma')
    },
    convertSecondsToHms (seconds) {
      return timeHelper.convertSecondsToHms(seconds)
    },
    async onSubmit () {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.loading = true
        await this.saveCallDetailNotes({ phone_number: this.callDetailsGetter.call_from, description: this.note }).then((response) => {
          this.notificationSnackBarAction({
            text: this.$t('note_created'),
            color: 'success',
            show: true
          })
          this.loading = false
          this.getNotes()
          this.dialog = false
          this.note = null
        }).catch((error) => {
          this.$refs.observer.setErrors(error.response.data.errors)
          this.loading = false
        })
      }
    },
    async getNotes () {
      await this.getCallDetailNotes({ phone: this.callDetailsGetter.call_from }).then((result) => {
        this.notes = result.data
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.notificationSnackBarAction({
          text: this.$t('api_error_notes_list_not_loaded'),
          color: 'error',
          show: true
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
