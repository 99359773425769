<template>
  <v-btn
    class="text-icon-button"
    :color="dropLastState ? 'grey' : 'red accent-4'"
    large
    dark
    width="100%"
    :disabled="!callGetter.hasActiveCall"
    @click="dropLast"
    height="100%"
  >
    <v-icon>{{ dropLastIcon }}</v-icon>
    <div class="font-weight-bold pt-2"> {{ $t('drop_last') }} </div>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
import warmTransferMixin from '@/mixins/sip/warm-transfer.mixin'

export default {
  name: 'dropLastButton',
  mixins: [warmTransferMixin],
  data: () => ({
    dropLastState: false,
    dropLastIcon: 'mdi-backspace-outline'
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ])
  },
  methods: {
    dropLast () {
      if (this.callGetter.transferredTo) {
        this.warmTransfer(this.callGetter.transferredTo, true)
      }
    }
  }
}
</script>

<style scoped>

</style>
