<template>
  <v-card tile class="mt-5">
    <v-card-title>
      {{ $t('conference_members')}}:
      <v-btn color="success" depressed small height="30" width="60" class="ml-3">
        <v-icon small>mdi-account-group</v-icon>
        <span class="pl-2">{{ conferenceDataGetter.count }}</span>
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="conferenceDataGetter.participants"
        dense
        class="pt-3"
        item-key="participants.call_id"
      >
        <template v-slot:[`item.caller_id_name`]="{ item }">
          <template v-if="item.custom_channel_vars.username">
            {{ item.custom_channel_vars.username }}
          </template>
          <template v-else>
            {{ item.caller_id_name }}
          </template>
        </template>

        <template v-slot:[`item.info`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                dark
                color="error"
                class="no-case pa-2 mr-2"
                @click="kickParticipant(item.participant_id)"
                x-small
                v-on="on"
                :loading="loading"
              >
                <v-icon x-small>
                  mdi-exit-to-app
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('kick_participant')}}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import apiService from '@/modules/api/csp'
import conferenceMixin from '@/mixins/sip/conference.mixin'
export default {
  name: 'CallConferenceComponent',
  mixins: [conferenceMixin],
  data: (vm) => ({
    loading: false,
    headers: [
      { text: vm.$t('caller_id_name'), value: 'caller_id_name', sortable: false },
      { text: vm.$t('caller_id_number'), value: 'caller_id_number', sortable: false },
      { text: vm.$t('actions'), value: 'info', sortable: false }
    ]
  }),
  computed: {
    ...mapGetters('callConference', ['conferenceDataGetter']),
    ...mapGetters('settings', ['sipDefaultAddressGetter'])
  },
  methods: {
    async kickParticipant (participantId) {
      this.loading = true
      const data = {
        accountId: this.sipDefaultAddressGetter.account_id,
        conferenceId: this.conferenceDataGetter.conferenceId,
        participantId: participantId
      }
      await apiService.egress.kickConferenceParticipant(data).then(async data => {
        await this.setConferenceData(this.$sip.answeredCallSession)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>

</style>
