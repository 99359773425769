import store from '@/store'
import apiService from '@/modules/api/csp'
import localStorageService from '@/util/local-storage-service'

export default {
  methods: {
    sendDTMF (session, dtmfCode) {
      console.log('DTMF Tone', dtmfCode)

      const data = {
        call_id: session._request.call_id,
        sip_address: store.getters['settings/sipDefaultAddressGetter'].sip_address,
        sip_message: JSON.stringify({
          from: 'Mixin',
          value: dtmfCode,
          duration: 500,
          interToneGap: 100,
          transportType: 'RFC2833'
        }),
        method: 'DTMF',
        direction: 'outgoing',
        type: 'json',
        write_time: Date.now(),
        position: localStorageService.getPositionNumber() ?? null
      }

      apiService.callLogs.saveCallLogs(data)

      session.sendDTMF(dtmfCode, {
        transportType: 'RFC2833',
        duration: 500,
        interToneGap: 100
      })
    }
  }
}
