<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        dark
        color="orange"
        small
        min-width="0"
        @click="callMonitoring(`*69${dialNumber}`, ['Call-Monitoring: BARGE'], 3)"
        width="30"
        height="30"
        class="mx-1 my-1"
        v-on="on"
        :disabled="!sipGetter.registered"
      >
        <v-icon small color="white">
          mdi-alert-circle-outline
        </v-icon>
      </v-btn>
    </template>
    <span>{{$t('barge') }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'
/* Mixins */
import callMonitoringMixin from '@/mixins/sip/call-monitoring.mixin'
export default {
  name: 'BargeButton',
  mixins: [callMonitoringMixin],
  props: ['dialNumber', 'agent'],
  computed: {
    ...mapGetters('global', [
      'sipGetter', 'callGetter'
    ])
  }
}
</script>

<style scoped>

</style>
