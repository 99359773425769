<template>
  <v-btn
      class="text-icon-button"
      :color="flashHookState ? 'grey' : 'red accent-2'"
      @click="sendInfoToNumber"
      large
      dark
      width="100%"
      :disabled="!callGetter.hasActiveCall || !$can('can_send_hook_flash')"
      height="100%"
      id="action_KeyI"
  >
    <v-icon>{{ flashHookIcon }}</v-icon>
    <div class="font-weight-bold pt-2">{{ flashHookLabel }}</div>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
import InfoReferMixin from '@/mixins/sip/info-refer-mixin'

export default {
  name: 'FlashHookButton',
  mixins: [InfoReferMixin],
  data: (vm) => ({
    flashHookState: false,
    flashHookIcon: 'mdi-flash',
    flashHookLabel: vm.$t('flash_hook')
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ])
  },
  methods: {
    sendInfoToNumber () {
      this.sendInfo()
    }
  }
}
</script>

<style scoped>

</style>
