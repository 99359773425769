import store from '@/store'

export default {
  methods: {
    localHold () {
      if (store.getters['global/callGetter'].localHold) {
        this.$sip.answeredCallSession.unhold()
      } else {
        if (store.getters['global/callGetter'].localMuted) {
          this.$sip.answeredCallSession.unmute()
        }
        this.$sip.answeredCallSession.hold()
        this.$event.dispatch('on-hold-call')
      }
    }
  }
}
