import callMixin from '@/mixins/sip/call.mixin'
import sendDtmfCodeMixin from '@/mixins/sip/send-dtmf-code.mixin'
import { mapGetters } from 'vuex'
export default {
  mixins: [callMixin, sendDtmfCodeMixin],
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ])
  },
  methods: {
    callMonitoring (target, extraHeaders = [], dtmfCode = null) {
      if (this.callGetter.hasActiveCall) {
        this.sendDTMF(this.$sip.answeredCallSession, dtmfCode)
      } else {
        this.call(target, extraHeaders, {}, false)
      }
    },
    eventHandlers (dtmfCode) {
      if (dtmfCode) {
        return {
          confirmed: data => {
            setTimeout(() => {
              this.sendDTMF(this.$sip.answeredCallSession, dtmfCode)
            }, 2000)
          }
        }
      } else return {}
    }
  }
}
