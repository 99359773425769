import adrParser from '@/modules/api/csp/parsers/adr'
import store from '@/store'

export default {
  methods: {
    async getAdr (session, populate = false) {
      return await adrParser(session).parse().then(async data => {
        if (populate) {
          await this.clearAdr()
          await store.commit('adr/commentMutator', data?.comment)
          await store.commit('adr/deviceInfoMutator', data?.deviceInfo)
          await store.commit('adr/providerInfoMutator', data?.providerInfo)
          await store.commit('adr/serviceInfoMutator', data?.serviceInfo)
          await store.commit('adr/subscriberInfoMutator', data?.subscriberInfo)
          await store.commit('adr/adrUpdateMutator')
        } else return data
      })
    },
    async clearAdr () {
      await store.commit('adr/clearCommentMutator')
      await store.commit('adr/clearDeviceInfoMutator')
      await store.commit('adr/clearProviderInfoMutator')
      await store.commit('adr/clearServiceInfoMutator')
      await store.commit('adr/clearSubscriberInfoMutator')
      await store.commit('adr/adrUpdateMutator')
    }
  }
}
