<template>
  <v-card class="fill-height" ref="parent-height" >

    <DialogShared max-width="1000" v-model="contactBookDialog" :title="$t('bind_contact')">
      <v-card tile flat color="secondary_card">
        <v-card-text>

          <ContactBookComponent @bindContact="bindContact"></ContactBookComponent>

        </v-card-text>
      </v-card>
    </DialogShared>

    <validation-observer ref="observer">
      <DialogShared max-width="800" v-model="dialog.open" :title="dialog.title">

        <v-card tile flat color="secondary_card">
          <v-card-text>

            <v-form ref="form" :disabled="loading" @submit.prevent="onSubmit" novalidate>

              <v-row dense>
                <v-col cols="4">
                  <v-switch inset v-model="form.is_folder" :label="$t('folder')" cypress="switch_folder_contact"></v-switch>
                </v-col>
                <v-col cols="8">
                  <validation-provider v-slot="{ errors }" :name="$t('name')" rules="required">
                    <v-text-field :label="$t('name')" name="name" outlined :disabled="loading" :error-messages="errors" cypress="speed_dial_name"
                      v-model="form.name"></v-text-field>
                  </validation-provider>
                </v-col>

              </v-row>
              <v-row>
                <v-col cols="12">

                  <validation-provider v-slot="{ errors }" :name="$t('contact_permission')" rules="required">
                    <v-select v-if="sipDefaultAddressGetter"
                              :error-messages="errors"
                              v-model="form.hot_contact_permission"
                              cypress="speed_dial_permissions"
                              :items="[ {
                            value: 'public',
                            label: $t('public')
                          },
                          {
                            value: 'private',
                            label: $t('private')
                          },
                          {
                            value: 'realm',
                            label: $t('realm') + ' ' + sipDefaultAddressGetter.realm
                          }]"
                              outlined name="contact_permission" item-text="label" item-value="value"
                              :label="$t('contact_permission')"></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="9">

                  <validation-provider v-slot="{ errors }" :name="$t('icon')" rules="required">
                    <v-autocomplete :label="$t('icon')" autocomplete="false" item-text="name" item-value="name" cypress="speed_dial_group"
                      :disabled="loading" :error-messages="errors" v-model="form.icon" :items="getIcons" required
                      outlined>
                      <template v-slot:item="data">
                        <v-icon class="mr-2"> {{ data.item.name }}</v-icon>
                        {{ data.item.name }}
                      </template>

                      <template v-slot:selection="data">
                        <v-icon class="mr-2"> {{ data.item.name }}</v-icon>
                        {{ data.item.name }}
                      </template>
                    </v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col cols="3">
                  <validation-provider v-slot="{ errors }" :name="$t('order_by')" rules="required">
                    <v-select :label="$t('order')" :disabled="loading" outlined v-model="form.order_by" cypress="speed_dial_order"
                      :items="getOrderByList" :error-messages="errors"></v-select>
                  </validation-provider>
                </v-col>

              </v-row>

              <v-row dense>
                <v-col>

                  <b>{{ $t('background_color') }}</b>
                  <br v-if="form.color" />
                  <v-btn v-if="form.color" class="mb-2" color="red" small @click="onClearColorClick">{{
                  $t('clear_color')}}</v-btn>

                  <v-color-picker :noAlpha="true" class="transparent-background full-width" :dark="$vuetify.theme.dark" cypress="speed_dial_colors"
                    hide-canvas hide-inputs v-model="form.color" hide-mode-switch hide-sliders swatches-max-height="100"
                    width="100%" show-swatches></v-color-picker>
                </v-col>
              </v-row>

              <v-row v-if="!form.is_folder">
                <v-col cols="6">
                  <b>{{ $t('contacts') }}</b>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn :disabled="loading" @click="addRow" icon v-on="on"  cypress="speed_dial_set_contact">
                        <v-icon color="error">
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </template>
                    <span> {{ $t('add_new')}}</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <v-row v-if="!form.is_folder">
                <v-col v-for="(contact,index) in contacts" :key="index" cols="12">

                  <validation-provider v-slot="{ errors }" :name="$t('contact')" rules="required">

                    <v-text-field name="contact_name" :disabled="loading" outlined :error-messages="errors" readonly
                      :value="contact.name">

                      <span slot="append" class="pointer" @click="chooseContact(contact, index)" cypress="speed_dial_bind_contact">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="green" v-bind="attrs" v-on="on">
                              mdi-book-account
                            </v-icon>
                          </template>
                          <span> {{ $t('bind_contact')}}</span>
                        </v-tooltip>
                      </span>

                      <div class="button-position" slot="append-outer" v-if=" contacts.length > 1">
                        <v-btn x-small color="error" :disabled="loading" fab elevation="0" @click="deleteRow(index)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>

                      </div>

                    </v-text-field>

                  </validation-provider>

                </v-col>
              </v-row>
              <v-row>
                <v-card-actions>
                  <v-btn class="mr-5" color="passive_button" :disabled="loading" @click="onCancel()">
                    {{ $t('cancel')}}
                  </v-btn>

                  <v-btn v-if="form.id && $can('can_delete_speed_dial')" @click="onDelete" :disabled="loading"
                    cypress="speed_dial_delete"
                    color="error"> {{ $t('delete') }}
                  </v-btn>

                </v-card-actions>

                <v-spacer />

                <v-card-actions>

                  <v-btn @click="onSubmit" :disabled="loading || (!form.is_folder && contacts.length == 0)" cypress="speed_dial_create_new"
                    color="error">
                    {{ $t('save')}}
                  </v-btn>

                </v-card-actions>
              </v-row>
            </v-form>

          </v-card-text>
        </v-card>

      </DialogShared>

    </validation-observer>

    <v-system-bar color="secondary_card" class="pa-0 pt-1" >
      <v-btn x-small text :disabled="!getCurrentFolderGetter" :ripple="false" @click="onBackToTopClick" >
        <v-icon>mdi-arrow-top-left</v-icon>
        {{ $t('top')}}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn x-small text :disabled="!getCurrentFolderGetter" @click="onFolderBackClick" :ripple="false">
        <v-icon>mdi-arrow-left-top</v-icon>
        {{ $t('back')}}</v-btn>
    </v-system-bar>

    <scroll-div  :height="fixedHeight - 30"  view-class="scroll-view">
      <v-row dense no-gutters class="pb-8" >
        <v-col v-for="(item, index) in (filteredSpeedDial && sortByProp(filteredSpeedDial.value,'order_by')) || []" :key="index" class="text-center my-1 px-1" cols="6"
          lg="4" md="4" sm="6" xl="4">

          <v-menu v-if="item.contact_books.length > 1" bottom origin="center center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" :id="`button_${item[index]}`" v-on="on" block
                :style="{ 'background-color': item.color || defaultColor }" dark min-height="70px"
                @contextmenu.prevent="openEditDialog(item)"
                class="no-transform pa-1"
              >
                <div>
                  <v-icon color="primary">
                    {{ item.icon }}
                  </v-icon>
                  <v-tooltip bottom v-if="item.contact_books.length > 0 ">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs"
                            v-on="on"
                            class="mt-4 mx-0">
                        {{ item.name | substringIf(0,15,'...') }}
                      </span>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                  <v-icon v-if="item.contact_books.length > 1 " color="primary" style="position: absolute; right: 10px">
                    mdi-chevron-down
                  </v-icon>
                </div>
              </v-btn>
            </template>
            <v-list class="grey text-white">
              <div v-for="(item, index) in item.contact_books" :key="index">
                <v-list-item v-if="checkIfExists(item, 'name')">
                  <v-list-item-title @click.prevent="callAndTransfer(item)" class="pointer text-white">
                    <div class="text-caption">{{ checkIfExists(item, 'name') | substringIf(0,20,'...') }}</div>
                    <div style="font-size: 10px">{{ checkIfExists(item, 'phone') }}</div>
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>

          <v-btn v-else @click="onButtonClick(item)" :id="`button_${item[index]}`" block
                 :cypress="item.name"
            :style="{ 'background-color': item.color || defaultColor }" dark min-height="70px"
            @contextmenu.prevent="openEditDialog(item)"
            class="no-transform pa-1"
          >
            <div class="pr-3">
              <v-icon color="primary">
                {{ item.icon }}
              </v-icon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs"
                        v-on="on"
                        class="mt-4 mx-0">
                    {{ item.name | substringIf(0,17,'...') }}
                  </span>
                </template>
                <div class="text-caption">{{ item.name }}</div>
                <div class="text-caption">{{ getPhoneNumber(item) }}</div>
              </v-tooltip>
              <v-icon v-if="item.is_folder" style="position: absolute; right: 10px">
                mdi-folder-move-outline
              </v-icon>
              <v-icon v-if="item.contact_books.length > 1 " color="primary">
                mdi-chevron-down
              </v-icon>
            </div>
          </v-btn>

        </v-col>

        <v-col class="my-1 px-1" v-if="$can('can_add_speed_dial')" cols="6" lg="4" md="4" sm="6" xl="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" block dark min-height="70px" @click="add" cypress="add_new_speed_dial">
                <v-icon color="hot-contact-plus-icon">
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <span> {{ $t('add_new') }} </span>
          </v-tooltip>
        </v-col>
      </v-row>
    </scroll-div>
  </v-card>
</template>

<script>
/* Mixins */
import parentHeight from '@/mixins/parent-height.mixin'
import callOrTransferMixin from '@/mixins/sip/call-or-transfer.mixin'

import { mapActions, mapGetters } from 'vuex'
import DialogShared from '@/app/shared/dialog/DialogShared'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/rules/validation.rules.js'
import ContactBookComponent from '@/app/widgets/speed-dial-widget/components/ContactBookComponent'
import { sortBy } from 'lodash'
import iconsHelper from '@/helpers/icons.helper'

export default {
  name: 'SpeedDialComponent',
  mixins: [parentHeight, callOrTransferMixin],
  components: {
    ContactBookComponent,
    DialogShared,

    ValidationObserver,
    ValidationProvider
  },
  data: (vm) => ({

    loading: false,
    currentIndex: 0,

    contacts: [],

    contactBookDialog: false,

    dialog: {
      open: false,
      title: ''
    },

    defaultColor: '#424242',

    form: {
      icon: 'mdi-phone',
      order_by: 1,
      name: null,
      contact_book_ids: [],
      color: null,
      is_folder: false,
      parent_id: null,
      hot_contact_permission: 'public',
      realm: null
    },

    allSpeedDial: [],
    filteredSpeedDial: []

  }),

  computed: {
    ...mapGetters('speedDial', ['getSpeedDialContactsGetter', 'getFilteredSpeedDialGetter', 'getCurrentFolderGetter']),
    ...mapGetters('settings', ['sipDefaultAddressGetter']),
    getIcons () {
      return iconsHelper.getIcons()
    },
    getOrderByList () {
      return Array(30).fill(1).map((x, y) => x + y)
    }
  },

  watch: {
    async getCurrentFolderGetter () {
      if (!this.getCurrentFolderGetter) {
        this.filteredSpeedDial = await this.$localCacher.indexDB.speedDial.get({
          parent_id: null
        })
        this.allSpeedDial = await this.$localCacher.indexDB.speedDial.get()
      } else {
        this.filteredSpeedDial = await this.$localCacher.indexDB.speedDial.get({
          parent_id: this.getCurrentFolderGetter.id
        })
      }
    }
  },

  methods: {

    ...mapActions('speedDial',
      [
        'createSpeedDialButtonAction',
        'deleteSpeedDialButtonAction',
        'updateSpeedDialButton',
        'filterSpeedDialButtonsAction',
        'setCurrentFolderAction'
      ]
    ),

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    sortByProp (item, prop) {
      return sortBy(item, prop)
    },

    onClearColorClick () {
      this.form.color = this.defaultColor
    },

    bindContact (contact) {
      this.contacts.splice(this.currentIndex, 1, {
        ...{
          id: contact.id,
          name: contact.phone.name
        }
      })
      this.contactBookDialog = false
    },

    onBackToTopClick () {
      this.filterSpeedDialButtonsAction(null)
      this.setCurrentFolderAction(null)
    },

    onFolderBackClick () {
      if (!this.getCurrentFolderGetter.parent_id) {
        this.filterSpeedDialButtonsAction(null)
        this.setCurrentFolderAction(null)
      } else {
        // find where to go back
        const parent = this.allSpeedDial.value.find((o) => {
          return o.id === this.getCurrentFolderGetter.parent_id
        })

        this.filterSpeedDialButtonsAction(parent)
        this.setCurrentFolderAction(parent)
      }
    },

    openEditDialog (item) {
      if (!this.$can('can_edit_speed_dial')) {
        return false
      }

      this.resetForm()
      this.dialog.open = true
      this.dialog.title = this.$t('update_speed_dial')
      this.form = { ...item }

      item.contact_books.forEach((o) => {
        this.contacts.push({
          id: o.id,
          name: o.phone.name
        })
      })
    },

    chooseContact (contact, index) {
      this.contactBookDialog = true
      this.currentIndex = index
    },

    add () {
      this.resetForm()
      this.dialog.open = true
      this.dialog.title = this.$t('create_speed_dial')
      this.form.hot_contact_permission = 'public'
    },

    addRow () {
      this.contacts.push({})
    },

    onButtonClick (item) {
      if (item.is_folder) {
        this.setCurrentFolderAction(item)
        this.filterSpeedDialButtonsAction(item)
      } else {
        this.callAndTransfer(item)
      }
    },
    callAndTransfer (item) {
      Array.isArray(item.contact_books) ? this.callOrTransfer(item?.contact_books?.[0].phone) : this.callOrTransfer(item?.phone)
    },
    getPhoneNumber (item) {
      if (!item.is_folder) {
        if (Array.isArray(item.contact_books)) {
          return item?.contact_books?.[0]?.phone?.phone
        } else return item?.phone
      } else return ''
    },
    checkIfExists (item, key) {
      if (key === 'name') {
        if (item?.phone?.name) return item.phone.name
      }
      if (key === 'phone') {
        if (item?.phone?.phone) return item.phone.phone
      }
      return false
    },

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.loading = true

        if (this.form.hot_contact_permission === 'realm') {
          this.form.realm = this.sipDefaultAddressGetter.realm
        } else {
          this.form.realm = null
        }

        this.form.contact_book_ids = this.contacts.map((object) => {
          return object.id
        })
        // clear contacts if its folder
        if (this.form.is_folder) {
          this.form.contact_book_ids = []
        }
        if (this.getCurrentFolderGetter) {
          this.form.parent_id = this.getCurrentFolderGetter.id
        }

        if (this.form.id > 0) {
          await this.updateSpeedDialButton(this.form).then((response) => {
            this.loading = false
            this.dialog.open = false
            this.resetForm()

            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('speed_dial_updated')
            })
          }).catch((data) => {
            this.loading = false

            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: data.message
            })
          })
        } else {
          await this.createSpeedDialButtonAction(this.form).then((response) => {
            this.loading = false
            this.dialog.open = false
            this.resetForm()

            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('speed_dial_created')
            })
          }).catch((data) => {
            this.loading = false

            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: data.message
            })
          })
        }
      }
    },

    resetForm () {
      this.$refs.observer.reset()
      this.form = {
        id: null,
        icon: 'mdi-phone',
        order_by: 1,
        contact_book_ids: [],
        name: null,
        color: this.defaultColor,
        parent_id: null,
        is_folder: false,
        hot_contact_permission: 'public'
      }

      this.contacts.length = 0
    },

    onCancel () {
      this.resetForm()
      this.dialog.open = false
    },

    onDelete () {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('button') })).then(confirmed => {
        if (confirmed) {
          this.loading = true

          this.deleteSpeedDialButtonAction({
            id: this.form.id
          }).then(() => {
            this.loading = false
            this.dialog.open = false
            this.resetForm()

            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('button_deleted')
            })
          }).catch((data) => {
            this.loading = false
            this.dialog.open = false
            this.resetForm()

            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: data.message
            })
          })
        }
      })
    },

    deleteRow (index) {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('contact') })).then(confirmed => {
        if (confirmed) {
          this.contacts.splice(index, 1)
        }
      }
      )
    }
  },

  async mounted () {
    this.filteredSpeedDial = await this.$localCacher.indexDB.speedDial.get({
      parent_id: null
    })
    this.allSpeedDial = await this.$localCacher.indexDB.speedDial.get()

    this.$event.listen('gl_resize', (e) => {
      this.modifyHeight()
    })
  }
}
</script>

<style scoped>

  .button-position{
    position: relative;
    top: -10px
  }
  .no-transform{
    text-transform: none !important;
  }

</style>
