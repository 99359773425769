import Timer from 'easytimer.js'

export default {
  data: () => ({
    timerText: '',
    timer: null
  }),
  methods: {
    startTimer () {
      this.timerText = '00:00:00'
      this.timer = new Timer()
      this.timer.start()
      this.timer.addEventListener('secondsUpdated', e => {
        this.timerText = this.timer.getTimeValues().toString()
      })
    },
    stopTimer () {
      this.timer.stop()
      this.timerText = ''
      this.timer = null
    }
  }
}
