<template>
  <v-row class="full-width fill-height" dense no-gutters>

    <v-col v-if="$can('can_see_speed_dial_dps')" class="text-center mt-2 mb-1 px-1 " cols="6" sm="6" md="4" lg="4" xl="4">

      <v-btn
        color="blue" c
        dark
        block height="100%"
        min-height="70px"
        @click="referToLostNumber()"
      >
        <v-icon>
          mdi-police-badge
        </v-icon>
        <span class="px-1">PD</span>
      </v-btn>
    </v-col>
    <v-col v-if="$can('can_see_speed_dial_ems')" class="text-center mt-2 mb-1 px-1" cols="6" sm="6" md="4" lg="4" xl="4">
      <v-btn
        color="blue lighten-3"
        dark
        block height="100%"
        min-height="70px"
        @click="referToLostNumber()"
      >
        <v-icon>
          mdi-hospital-box
        </v-icon>
        <span class="px-1">EMS</span>
      </v-btn>
    </v-col>
    <v-col v-if="$can('can_see_speed_dial_fire')" class="text-center mt-2 mb-1 px-1" cols="6" sm="6" md="4" lg="4" xl="4">
      <v-btn
        color="error"
        dark
        block height="100%"
        min-height="70px"
        @click="referToLostNumber()"
      >
        <v-icon>
          mdi-fire
        </v-icon>
        <span class="px-1">Fire</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'LostComponent',
  methods: {
    referToLostNumber () {
    }
  }
}
</script>

<style scoped>

</style>
