<template>
  <v-bottom-sheet
    eager
    v-model="toggle"
    inset
    hide-overlay
    persistent
    no-click-animation
  >
    <v-card tile>
      <v-slider
        color="green"
        track-color="error"
        :max="100"
        :step="0.0001"
        v-model="progress"
        @change="setCurrentTime($event)"
        class="animated px-2"
        ref="progressBar"
        @mouseup="mouseUp"
        @mousedown="mouseDown"
      ></v-slider>
      <v-list class="pa-0">
        <v-list-item>
          <v-list-item-content class="pa-0">
            <v-list-item-title class="d-flex align-center">
              {{ `${displayCurrentTime} - ${getTotalDuration}` }}
              <v-btn class="ml-2" color="transparent" @click="goLive" v-if="!this.isLive">
                <v-icon>mdi-record</v-icon>
                {{ $t('go_live')}}
              </v-btn>
            </v-list-item-title>
          </v-list-item-content>

          <v-spacer></v-spacer>

          <v-list-item-icon class="mt-0">
            <v-btn icon @click="startStop">
              <v-icon>{{ this.paused ? 'mdi-play' : 'mdi-pause' }}</v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card>
    <audio id="live-recording" ref="liveRecording"></audio>
  </v-bottom-sheet>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'LiveRecordingComponent',
  data: () => ({
    paused: true,
    toggle: false,
    running: false,
    duration: 0,
    currentTime: 0,
    mediaRecorder: null,
    recordingInterval: null,

    isLive: true,
    isMouseDown: false,
    mediaSource: new MediaSource(),
    sourceBuffer: null,
    disableRender: false,

    progress: 100
  }),
  computed: {
    ...mapGetters('recorder', [
      'toggleGetter'
    ]),
    ...mapGetters('settings', ['audioSettingsGetter']),
    displayCurrentTime () {
      return this.convertSecondsToHms(Math.ceil(this.currentTime))
    },
    getTotalDuration () {
      return this.convertSecondsToHms(Math.ceil(this.duration))
    }
  },
  watch: {
    toggleGetter (val) {
      this.toggle = val
    },
    currentTime () {
      this.calculateProgress()
    },
    duration () {
      this.calculateProgress()
    }
  },
  methods: {
    ...mapMutations('recorder', [
      'toggleMutator'
    ]),
    calculateProgress () {
      if (this.paused || this.disableRender) return

      this.progress = this.currentTime / this.duration * 100
    },
    setCurrentTime (val) {
      this.isLive = false
      this.disableAnimation(true)

      if (val === 100) {
        this.goLive()

        return
      }

      this.$refs.liveRecording.currentTime = val / 100 * this.duration
    },
    goLive () {
      this.disableAnimation(true)

      this.isLive = true
      this.progress = 100
    },
    mouseUp () {
      this.isMouseDown = false
    },
    mouseDown () {
      this.isMouseDown = true

      this.disableAnimation()
    },
    disableAnimation (temporary = false) {
      this.disableRender = true
      this.$refs.progressBar.$vnode.elm.classList.remove('animated')

      if (temporary === true && !this.isMouseDown) {
        setTimeout(() => {
          this.enableAnimation()
        }, 1000)
      }
    },
    enableAnimation () {
      this.disableRender = false
      this.$refs.progressBar.$vnode.elm.classList.add('animated')
    },
    convertSecondsToHms (seconds) {
      if (seconds > 3600) {
        return new Date(seconds * 1000).toISOString().substr(14, 5)
      } else {
        return new Date(seconds * 1000).toISOString().substr(11, 8)
      }
    },
    startStop () {
      if (this.paused) this.startPlaying()
      else this.pausePlaying()
    },
    startPlaying () {
      if (!this.running) return

      this.isLive = false

      this.disableAnimation(true)

      this.$refs.liveRecording && this.$refs.liveRecording.play()

      this.paused = false
      this.disableRender = false
    },
    pausePlaying () {
      this.$refs.liveRecording && this.$refs.liveRecording.pause()

      this.paused = true
    },
    createMediaRecorder (stream) {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop()
      }

      this.mediaRecorder = new MediaRecorder(stream)
      this.mediaRecorder.start()

      this.mediaRecorder.addEventListener('dataavailable', this.handleData)

      clearInterval(this.recordingInterval)

      this.recordingInterval = setInterval(() => {
        if (this.mediaRecorder && this.mediaRecorder.state !== 'recording') return

        this.mediaRecorder.requestData()
      }, 1000)
    },
    async handleData (event) {
      if (!this.running) return

      const data = await event.data.arrayBuffer()

      this.sourceBuffer.appendBuffer(data)

      this.duration += 1

      if (this.isLive) {
        this.currentTime = this.duration

        this.$refs.liveRecording.currentTime = this.currentTime

        return
      }

      this.currentTime = this.$refs.liveRecording.currentTime.toFixed(3)
    },
    endRecording () {
      this.toggleMutator(false)

      this.isLive = true
      this.running = false
      this.duration = 0
      this.progress = 100
      this.currentTime = 0

      clearInterval(this.recordingInterval)

      if (!this.paused) this.pausePlaying()

      this.$refs.liveRecording.src = null

      if (!this.mediaRecorder) return

      this.mediaRecorder.removeEventListener('dataavailable', this.handleData)
      this.mediaRecorder.stop()

      this.mediaRecorder = null
    },
    sourceOpen () {
      this.sourceBuffer = this.mediaSource.addSourceBuffer('audio/webm;codecs=opus')
    },
    startRecording (event) {
      event.eventData.session.connection.removeEventListener('track', this.create)
      event.eventData.session.connection.addEventListener('track', this.create)
    },
    async createSource () {
      this.$refs.liveRecording.volume = this.audioSettingsGetter.recordingsVolume

      await this.$refs.liveRecording.setSinkId(this.audioSettingsGetter.recordingsOutputDeviceId)

      this.$refs.liveRecording.load()

      this.$refs.liveRecording.src = URL.createObjectURL(this.mediaSource)

      this.mediaSource.addEventListener('sourceopen', this.sourceOpen)
    },
    create (event) {
      if (!this.running) {
        this.running = true

        this.createSource()
      }

      this.createMediaRecorder(event.streams[0])
    }
  },
  mounted () {
    this.$event.listen('setRemoteAudio', this.startRecording)

    this.$event.listen('incoming-hold', this.endRecording)
    this.$event.listen('outgoing-hold', this.endRecording)
    this.$event.listen('incoming-ended', this.endRecording)
    this.$event.listen('outgoing-ended', this.endRecording)
  }
}
</script>

<style>
.v-slider--horizontal {
  transition: none !important;
  margin-left: 0;
  margin-right: 0;
}
.v-slider--horizontal * {
  transition: none !important;
}
.v-input__slot {
  transition: none !important;
}
.v-slider__thumb-container {
  transition: none !important;
}

.animated.v-input__slider .v-slider--horizontal * {
  transition: 1s linear !important;
}
</style>
