const iconsHelper = {

  getIcons () {
    // This data is scraped from https://cdn.materialdesignicons.com/5.3.45/
    return [
      {
        unicode: 'F01C9',
        name: 'mdi-ab-testing'
      },
      {
        unicode: 'F1328',
        name: 'mdi-abjad-arabic'
      },
      {
        unicode: 'F1329',
        name: 'mdi-abjad-hebrew'
      },
      {
        unicode: 'F132A',
        name: 'mdi-abugida-devanagari'
      },
      {
        unicode: 'F132B',
        name: 'mdi-abugida-thai'
      },
      {
        unicode: 'F0003',
        name: 'mdi-access-point'
      },
      {
        unicode: 'F0002',
        name: 'mdi-access-point-network'
      },
      {
        unicode: 'F0BE1',
        name: 'mdi-access-point-network-off'
      },
      {
        unicode: 'F0004',
        name: 'mdi-account'
      },
      {
        unicode: 'F0005',
        name: 'mdi-account-alert'
      },
      {
        unicode: 'F0B50',
        name: 'mdi-account-alert-outline'
      },
      {
        unicode: 'F0B51',
        name: 'mdi-account-arrow-left'
      },
      {
        unicode: 'F0B52',
        name: 'mdi-account-arrow-left-outline'
      },
      {
        unicode: 'F0B53',
        name: 'mdi-account-arrow-right'
      },
      {
        unicode: 'F0B54',
        name: 'mdi-account-arrow-right-outline'
      },
      {
        unicode: 'F0006',
        name: 'mdi-account-box'
      },
      {
        unicode: 'F0934',
        name: 'mdi-account-box-multiple'
      },
      {
        unicode: 'F100A',
        name: 'mdi-account-box-multiple-outline'
      },
      {
        unicode: 'F0007',
        name: 'mdi-account-box-outline'
      },
      {
        unicode: 'F12DF',
        name: 'mdi-account-cancel'
      },
      {
        unicode: 'F12E0',
        name: 'mdi-account-cancel-outline'
      },
      {
        unicode: 'F1097',
        name: 'mdi-account-cash'
      },
      {
        unicode: 'F1098',
        name: 'mdi-account-cash-outline'
      },
      {
        unicode: 'F0008',
        name: 'mdi-account-check'
      },
      {
        unicode: 'F0BE2',
        name: 'mdi-account-check-outline'
      },
      {
        unicode: 'F0A89',
        name: 'mdi-account-child'
      },
      {
        unicode: 'F0A8A',
        name: 'mdi-account-child-circle'
      },
      {
        unicode: 'F10C8',
        name: 'mdi-account-child-outline'
      },
      {
        unicode: 'F0009',
        name: 'mdi-account-circle'
      },
      {
        unicode: 'F0B55',
        name: 'mdi-account-circle-outline'
      },
      {
        unicode: 'F0B56',
        name: 'mdi-account-clock'
      },
      {
        unicode: 'F0B57',
        name: 'mdi-account-clock-outline'
      },
      {
        unicode: 'F1370',
        name: 'mdi-account-cog'
      },
      {
        unicode: 'F1371',
        name: 'mdi-account-cog-outline'
      },
      {
        unicode: 'F000A',
        name: 'mdi-account-convert'
      },
      {
        unicode: 'F1301',
        name: 'mdi-account-convert-outline'
      },
      {
        unicode: 'F0E9B',
        name: 'mdi-account-cowboy-hat'
      },
      {
        unicode: 'F0631',
        name: 'mdi-account-details'
      },
      {
        unicode: 'F1372',
        name: 'mdi-account-details-outline'
      },
      {
        unicode: 'F06BC',
        name: 'mdi-account-edit'
      },
      {
        unicode: 'F0FFB',
        name: 'mdi-account-edit-outline'
      },
      {
        unicode: 'F0849',
        name: 'mdi-account-group'
      },
      {
        unicode: 'F0B58',
        name: 'mdi-account-group-outline'
      },
      {
        unicode: 'F05B5',
        name: 'mdi-account-hard-hat'
      },
      {
        unicode: 'F0899',
        name: 'mdi-account-heart'
      },
      {
        unicode: 'F0BE3',
        name: 'mdi-account-heart-outline'
      },
      {
        unicode: 'F000B',
        name: 'mdi-account-key'
      },
      {
        unicode: 'F0BE4',
        name: 'mdi-account-key-outline'
      },
      {
        unicode: 'F115E',
        name: 'mdi-account-lock'
      },
      {
        unicode: 'F115F',
        name: 'mdi-account-lock-outline'
      },
      {
        unicode: 'F000D',
        name: 'mdi-account-minus'
      },
      {
        unicode: 'F0AEC',
        name: 'mdi-account-minus-outline'
      },
      {
        unicode: 'F000E',
        name: 'mdi-account-multiple'
      },
      {
        unicode: 'F08C5',
        name: 'mdi-account-multiple-check'
      },
      {
        unicode: 'F11FE',
        name: 'mdi-account-multiple-check-outline'
      },
      {
        unicode: 'F05D3',
        name: 'mdi-account-multiple-minus'
      },
      {
        unicode: 'F0BE5',
        name: 'mdi-account-multiple-minus-outline'
      },
      {
        unicode: 'F000F',
        name: 'mdi-account-multiple-outline'
      },
      {
        unicode: 'F0010',
        name: 'mdi-account-multiple-plus'
      },
      {
        unicode: 'F0800',
        name: 'mdi-account-multiple-plus-outline'
      },
      {
        unicode: 'F120A',
        name: 'mdi-account-multiple-remove'
      },
      {
        unicode: 'F120B',
        name: 'mdi-account-multiple-remove-outline'
      },
      {
        unicode: 'F0803',
        name: 'mdi-account-music'
      },
      {
        unicode: 'F0CE9',
        name: 'mdi-account-music-outline'
      },
      {
        unicode: 'F0011',
        name: 'mdi-account-network'
      },
      {
        unicode: 'F0BE6',
        name: 'mdi-account-network-outline'
      },
      {
        unicode: 'F0012',
        name: 'mdi-account-off'
      },
      {
        unicode: 'F0BE7',
        name: 'mdi-account-off-outline'
      },
      {
        unicode: 'F0013',
        name: 'mdi-account-outline'
      },
      {
        unicode: 'F0014',
        name: 'mdi-account-plus'
      },
      {
        unicode: 'F0801',
        name: 'mdi-account-plus-outline'
      },
      {
        unicode: 'F0B59',
        name: 'mdi-account-question'
      },
      {
        unicode: 'F0B5A',
        name: 'mdi-account-question-outline'
      },
      {
        unicode: 'F0015',
        name: 'mdi-account-remove'
      },
      {
        unicode: 'F0AED',
        name: 'mdi-account-remove-outline'
      },
      {
        unicode: 'F0016',
        name: 'mdi-account-search'
      },
      {
        unicode: 'F0935',
        name: 'mdi-account-search-outline'
      },
      {
        unicode: 'F0630',
        name: 'mdi-account-settings'
      },
      {
        unicode: 'F10C9',
        name: 'mdi-account-settings-outline'
      },
      {
        unicode: 'F0017',
        name: 'mdi-account-star'
      },
      {
        unicode: 'F0BE8',
        name: 'mdi-account-star-outline'
      },
      {
        unicode: 'F0A8B',
        name: 'mdi-account-supervisor'
      },
      {
        unicode: 'F0A8C',
        name: 'mdi-account-supervisor-circle'
      },
      {
        unicode: 'F112D',
        name: 'mdi-account-supervisor-outline'
      },
      {
        unicode: 'F0019',
        name: 'mdi-account-switch'
      },
      {
        unicode: 'F04CB',
        name: 'mdi-account-switch-outline'
      },
      {
        unicode: 'F0CE3',
        name: 'mdi-account-tie'
      },
      {
        unicode: 'F10CA',
        name: 'mdi-account-tie-outline'
      },
      {
        unicode: 'F1308',
        name: 'mdi-account-tie-voice'
      },
      {
        unicode: 'F130A',
        name: 'mdi-account-tie-voice-off'
      },
      {
        unicode: 'F130B',
        name: 'mdi-account-tie-voice-off-outline'
      },
      {
        unicode: 'F1309',
        name: 'mdi-account-tie-voice-outline'
      },
      {
        unicode: 'F05CB',
        name: 'mdi-account-voice'
      },
      {
        unicode: 'F001A',
        name: 'mdi-adjust'
      },
      {
        unicode: 'F0936',
        name: 'mdi-adobe'
      },
      {
        unicode: 'F0F9D',
        name: 'mdi-adobe-acrobat'
      },
      {
        unicode: 'F001B',
        name: 'mdi-air-conditioner'
      },
      {
        unicode: 'F0D43',
        name: 'mdi-air-filter'
      },
      {
        unicode: 'F0DAC',
        name: 'mdi-air-horn'
      },
      {
        unicode: 'F1099',
        name: 'mdi-air-humidifier'
      },
      {
        unicode: 'F1466',
        name: 'mdi-air-humidifier-off'
      },
      {
        unicode: 'F0D44',
        name: 'mdi-air-purifier'
      },
      {
        unicode: 'F0BE9',
        name: 'mdi-airbag'
      },
      {
        unicode: 'F001C',
        name: 'mdi-airballoon'
      },
      {
        unicode: 'F100B',
        name: 'mdi-airballoon-outline'
      },
      {
        unicode: 'F001D',
        name: 'mdi-airplane'
      },
      {
        unicode: 'F05D4',
        name: 'mdi-airplane-landing'
      },
      {
        unicode: 'F001E',
        name: 'mdi-airplane-off'
      },
      {
        unicode: 'F05D5',
        name: 'mdi-airplane-takeoff'
      },
      {
        unicode: 'F084B',
        name: 'mdi-airport'
      },
      {
        unicode: 'F0020',
        name: 'mdi-alarm'
      },
      {
        unicode: 'F078E',
        name: 'mdi-alarm-bell'
      },
      {
        unicode: 'F0021',
        name: 'mdi-alarm-check'
      },
      {
        unicode: 'F078F',
        name: 'mdi-alarm-light'
      },
      {
        unicode: 'F0BEA',
        name: 'mdi-alarm-light-outline'
      },
      {
        unicode: 'F0022',
        name: 'mdi-alarm-multiple'
      },
      {
        unicode: 'F0E71',
        name: 'mdi-alarm-note'
      },
      {
        unicode: 'F0E72',
        name: 'mdi-alarm-note-off'
      },
      {
        unicode: 'F0023',
        name: 'mdi-alarm-off'
      },
      {
        unicode: 'F0024',
        name: 'mdi-alarm-plus'
      },
      {
        unicode: 'F068E',
        name: 'mdi-alarm-snooze'
      },
      {
        unicode: 'F0025',
        name: 'mdi-album'
      },
      {
        unicode: 'F0026',
        name: 'mdi-alert'
      },
      {
        unicode: 'F0027',
        name: 'mdi-alert-box'
      },
      {
        unicode: 'F0CE4',
        name: 'mdi-alert-box-outline'
      },
      {
        unicode: 'F0028',
        name: 'mdi-alert-circle'
      },
      {
        unicode: 'F11ED',
        name: 'mdi-alert-circle-check'
      },
      {
        unicode: 'F11EE',
        name: 'mdi-alert-circle-check-outline'
      },
      {
        unicode: 'F05D6',
        name: 'mdi-alert-circle-outline'
      },
      {
        unicode: 'F06BD',
        name: 'mdi-alert-decagram'
      },
      {
        unicode: 'F0CE5',
        name: 'mdi-alert-decagram-outline'
      },
      {
        unicode: 'F14BB',
        name: 'mdi-alert-minus'
      },
      {
        unicode: 'F14BE',
        name: 'mdi-alert-minus-outline'
      },
      {
        unicode: 'F0029',
        name: 'mdi-alert-octagon'
      },
      {
        unicode: 'F0CE6',
        name: 'mdi-alert-octagon-outline'
      },
      {
        unicode: 'F0767',
        name: 'mdi-alert-octagram'
      },
      {
        unicode: 'F0CE7',
        name: 'mdi-alert-octagram-outline'
      },
      {
        unicode: 'F002A',
        name: 'mdi-alert-outline'
      },
      {
        unicode: 'F14BA',
        name: 'mdi-alert-plus'
      },
      {
        unicode: 'F14BD',
        name: 'mdi-alert-plus-outline'
      },
      {
        unicode: 'F14BC',
        name: 'mdi-alert-remove'
      },
      {
        unicode: 'F14BF',
        name: 'mdi-alert-remove-outline'
      },
      {
        unicode: 'F11CE',
        name: 'mdi-alert-rhombus'
      },
      {
        unicode: 'F11CF',
        name: 'mdi-alert-rhombus-outline'
      },
      {
        unicode: 'F089A',
        name: 'mdi-alien'
      },
      {
        unicode: 'F10CB',
        name: 'mdi-alien-outline'
      },
      {
        unicode: 'F11C3',
        name: 'mdi-align-horizontal-center'
      },
      {
        unicode: 'F11C2',
        name: 'mdi-align-horizontal-left'
      },
      {
        unicode: 'F11C4',
        name: 'mdi-align-horizontal-right'
      },
      {
        unicode: 'F11C5',
        name: 'mdi-align-vertical-bottom'
      },
      {
        unicode: 'F11C6',
        name: 'mdi-align-vertical-center'
      },
      {
        unicode: 'F11C7',
        name: 'mdi-align-vertical-top'
      },
      {
        unicode: 'F06BE',
        name: 'mdi-all-inclusive'
      },
      {
        unicode: 'F1258',
        name: 'mdi-allergy'
      },
      {
        unicode: 'F002B',
        name: 'mdi-alpha'
      },
      {
        unicode: 'F0AEE',
        name: 'mdi-alpha-a'
      },
      {
        unicode: 'F0B08',
        name: 'mdi-alpha-a-box'
      },
      {
        unicode: 'F0BEB',
        name: 'mdi-alpha-a-box-outline'
      },
      {
        unicode: 'F0BEC',
        name: 'mdi-alpha-a-circle'
      },
      {
        unicode: 'F0BED',
        name: 'mdi-alpha-a-circle-outline'
      },
      {
        unicode: 'F0AEF',
        name: 'mdi-alpha-b'
      },
      {
        unicode: 'F0B09',
        name: 'mdi-alpha-b-box'
      },
      {
        unicode: 'F0BEE',
        name: 'mdi-alpha-b-box-outline'
      },
      {
        unicode: 'F0BEF',
        name: 'mdi-alpha-b-circle'
      },
      {
        unicode: 'F0BF0',
        name: 'mdi-alpha-b-circle-outline'
      },
      {
        unicode: 'F0AF0',
        name: 'mdi-alpha-c'
      },
      {
        unicode: 'F0B0A',
        name: 'mdi-alpha-c-box'
      },
      {
        unicode: 'F0BF1',
        name: 'mdi-alpha-c-box-outline'
      },
      {
        unicode: 'F0BF2',
        name: 'mdi-alpha-c-circle'
      },
      {
        unicode: 'F0BF3',
        name: 'mdi-alpha-c-circle-outline'
      },
      {
        unicode: 'F0AF1',
        name: 'mdi-alpha-d'
      },
      {
        unicode: 'F0B0B',
        name: 'mdi-alpha-d-box'
      },
      {
        unicode: 'F0BF4',
        name: 'mdi-alpha-d-box-outline'
      },
      {
        unicode: 'F0BF5',
        name: 'mdi-alpha-d-circle'
      },
      {
        unicode: 'F0BF6',
        name: 'mdi-alpha-d-circle-outline'
      },
      {
        unicode: 'F0AF2',
        name: 'mdi-alpha-e'
      },
      {
        unicode: 'F0B0C',
        name: 'mdi-alpha-e-box'
      },
      {
        unicode: 'F0BF7',
        name: 'mdi-alpha-e-box-outline'
      },
      {
        unicode: 'F0BF8',
        name: 'mdi-alpha-e-circle'
      },
      {
        unicode: 'F0BF9',
        name: 'mdi-alpha-e-circle-outline'
      },
      {
        unicode: 'F0AF3',
        name: 'mdi-alpha-f'
      },
      {
        unicode: 'F0B0D',
        name: 'mdi-alpha-f-box'
      },
      {
        unicode: 'F0BFA',
        name: 'mdi-alpha-f-box-outline'
      },
      {
        unicode: 'F0BFB',
        name: 'mdi-alpha-f-circle'
      },
      {
        unicode: 'F0BFC',
        name: 'mdi-alpha-f-circle-outline'
      },
      {
        unicode: 'F0AF4',
        name: 'mdi-alpha-g'
      },
      {
        unicode: 'F0B0E',
        name: 'mdi-alpha-g-box'
      },
      {
        unicode: 'F0BFD',
        name: 'mdi-alpha-g-box-outline'
      },
      {
        unicode: 'F0BFE',
        name: 'mdi-alpha-g-circle'
      },
      {
        unicode: 'F0BFF',
        name: 'mdi-alpha-g-circle-outline'
      },
      {
        unicode: 'F0AF5',
        name: 'mdi-alpha-h'
      },
      {
        unicode: 'F0B0F',
        name: 'mdi-alpha-h-box'
      },
      {
        unicode: 'F0C00',
        name: 'mdi-alpha-h-box-outline'
      },
      {
        unicode: 'F0C01',
        name: 'mdi-alpha-h-circle'
      },
      {
        unicode: 'F0C02',
        name: 'mdi-alpha-h-circle-outline'
      },
      {
        unicode: 'F0AF6',
        name: 'mdi-alpha-i'
      },
      {
        unicode: 'F0B10',
        name: 'mdi-alpha-i-box'
      },
      {
        unicode: 'F0C03',
        name: 'mdi-alpha-i-box-outline'
      },
      {
        unicode: 'F0C04',
        name: 'mdi-alpha-i-circle'
      },
      {
        unicode: 'F0C05',
        name: 'mdi-alpha-i-circle-outline'
      },
      {
        unicode: 'F0AF7',
        name: 'mdi-alpha-j'
      },
      {
        unicode: 'F0B11',
        name: 'mdi-alpha-j-box'
      },
      {
        unicode: 'F0C06',
        name: 'mdi-alpha-j-box-outline'
      },
      {
        unicode: 'F0C07',
        name: 'mdi-alpha-j-circle'
      },
      {
        unicode: 'F0C08',
        name: 'mdi-alpha-j-circle-outline'
      },
      {
        unicode: 'F0AF8',
        name: 'mdi-alpha-k'
      },
      {
        unicode: 'F0B12',
        name: 'mdi-alpha-k-box'
      },
      {
        unicode: 'F0C09',
        name: 'mdi-alpha-k-box-outline'
      },
      {
        unicode: 'F0C0A',
        name: 'mdi-alpha-k-circle'
      },
      {
        unicode: 'F0C0B',
        name: 'mdi-alpha-k-circle-outline'
      },
      {
        unicode: 'F0AF9',
        name: 'mdi-alpha-l'
      },
      {
        unicode: 'F0B13',
        name: 'mdi-alpha-l-box'
      },
      {
        unicode: 'F0C0C',
        name: 'mdi-alpha-l-box-outline'
      },
      {
        unicode: 'F0C0D',
        name: 'mdi-alpha-l-circle'
      },
      {
        unicode: 'F0C0E',
        name: 'mdi-alpha-l-circle-outline'
      },
      {
        unicode: 'F0AFA',
        name: 'mdi-alpha-m'
      },
      {
        unicode: 'F0B14',
        name: 'mdi-alpha-m-box'
      },
      {
        unicode: 'F0C0F',
        name: 'mdi-alpha-m-box-outline'
      },
      {
        unicode: 'F0C10',
        name: 'mdi-alpha-m-circle'
      },
      {
        unicode: 'F0C11',
        name: 'mdi-alpha-m-circle-outline'
      },
      {
        unicode: 'F0AFB',
        name: 'mdi-alpha-n'
      },
      {
        unicode: 'F0B15',
        name: 'mdi-alpha-n-box'
      },
      {
        unicode: 'F0C12',
        name: 'mdi-alpha-n-box-outline'
      },
      {
        unicode: 'F0C13',
        name: 'mdi-alpha-n-circle'
      },
      {
        unicode: 'F0C14',
        name: 'mdi-alpha-n-circle-outline'
      },
      {
        unicode: 'F0AFC',
        name: 'mdi-alpha-o'
      },
      {
        unicode: 'F0B16',
        name: 'mdi-alpha-o-box'
      },
      {
        unicode: 'F0C15',
        name: 'mdi-alpha-o-box-outline'
      },
      {
        unicode: 'F0C16',
        name: 'mdi-alpha-o-circle'
      },
      {
        unicode: 'F0C17',
        name: 'mdi-alpha-o-circle-outline'
      },
      {
        unicode: 'F0AFD',
        name: 'mdi-alpha-p'
      },
      {
        unicode: 'F0B17',
        name: 'mdi-alpha-p-box'
      },
      {
        unicode: 'F0C18',
        name: 'mdi-alpha-p-box-outline'
      },
      {
        unicode: 'F0C19',
        name: 'mdi-alpha-p-circle'
      },
      {
        unicode: 'F0C1A',
        name: 'mdi-alpha-p-circle-outline'
      },
      {
        unicode: 'F0AFE',
        name: 'mdi-alpha-q'
      },
      {
        unicode: 'F0B18',
        name: 'mdi-alpha-q-box'
      },
      {
        unicode: 'F0C1B',
        name: 'mdi-alpha-q-box-outline'
      },
      {
        unicode: 'F0C1C',
        name: 'mdi-alpha-q-circle'
      },
      {
        unicode: 'F0C1D',
        name: 'mdi-alpha-q-circle-outline'
      },
      {
        unicode: 'F0AFF',
        name: 'mdi-alpha-r'
      },
      {
        unicode: 'F0B19',
        name: 'mdi-alpha-r-box'
      },
      {
        unicode: 'F0C1E',
        name: 'mdi-alpha-r-box-outline'
      },
      {
        unicode: 'F0C1F',
        name: 'mdi-alpha-r-circle'
      },
      {
        unicode: 'F0C20',
        name: 'mdi-alpha-r-circle-outline'
      },
      {
        unicode: 'F0B00',
        name: 'mdi-alpha-s'
      },
      {
        unicode: 'F0B1A',
        name: 'mdi-alpha-s-box'
      },
      {
        unicode: 'F0C21',
        name: 'mdi-alpha-s-box-outline'
      },
      {
        unicode: 'F0C22',
        name: 'mdi-alpha-s-circle'
      },
      {
        unicode: 'F0C23',
        name: 'mdi-alpha-s-circle-outline'
      },
      {
        unicode: 'F0B01',
        name: 'mdi-alpha-t'
      },
      {
        unicode: 'F0B1B',
        name: 'mdi-alpha-t-box'
      },
      {
        unicode: 'F0C24',
        name: 'mdi-alpha-t-box-outline'
      },
      {
        unicode: 'F0C25',
        name: 'mdi-alpha-t-circle'
      },
      {
        unicode: 'F0C26',
        name: 'mdi-alpha-t-circle-outline'
      },
      {
        unicode: 'F0B02',
        name: 'mdi-alpha-u'
      },
      {
        unicode: 'F0B1C',
        name: 'mdi-alpha-u-box'
      },
      {
        unicode: 'F0C27',
        name: 'mdi-alpha-u-box-outline'
      },
      {
        unicode: 'F0C28',
        name: 'mdi-alpha-u-circle'
      },
      {
        unicode: 'F0C29',
        name: 'mdi-alpha-u-circle-outline'
      },
      {
        unicode: 'F0B03',
        name: 'mdi-alpha-v'
      },
      {
        unicode: 'F0B1D',
        name: 'mdi-alpha-v-box'
      },
      {
        unicode: 'F0C2A',
        name: 'mdi-alpha-v-box-outline'
      },
      {
        unicode: 'F0C2B',
        name: 'mdi-alpha-v-circle'
      },
      {
        unicode: 'F0C2C',
        name: 'mdi-alpha-v-circle-outline'
      },
      {
        unicode: 'F0B04',
        name: 'mdi-alpha-w'
      },
      {
        unicode: 'F0B1E',
        name: 'mdi-alpha-w-box'
      },
      {
        unicode: 'F0C2D',
        name: 'mdi-alpha-w-box-outline'
      },
      {
        unicode: 'F0C2E',
        name: 'mdi-alpha-w-circle'
      },
      {
        unicode: 'F0C2F',
        name: 'mdi-alpha-w-circle-outline'
      },
      {
        unicode: 'F0B05',
        name: 'mdi-alpha-x'
      },
      {
        unicode: 'F0B1F',
        name: 'mdi-alpha-x-box'
      },
      {
        unicode: 'F0C30',
        name: 'mdi-alpha-x-box-outline'
      },
      {
        unicode: 'F0C31',
        name: 'mdi-alpha-x-circle'
      },
      {
        unicode: 'F0C32',
        name: 'mdi-alpha-x-circle-outline'
      },
      {
        unicode: 'F0B06',
        name: 'mdi-alpha-y'
      },
      {
        unicode: 'F0B20',
        name: 'mdi-alpha-y-box'
      },
      {
        unicode: 'F0C33',
        name: 'mdi-alpha-y-box-outline'
      },
      {
        unicode: 'F0C34',
        name: 'mdi-alpha-y-circle'
      },
      {
        unicode: 'F0C35',
        name: 'mdi-alpha-y-circle-outline'
      },
      {
        unicode: 'F0B07',
        name: 'mdi-alpha-z'
      },
      {
        unicode: 'F0B21',
        name: 'mdi-alpha-z-box'
      },
      {
        unicode: 'F0C36',
        name: 'mdi-alpha-z-box-outline'
      },
      {
        unicode: 'F0C37',
        name: 'mdi-alpha-z-circle'
      },
      {
        unicode: 'F0C38',
        name: 'mdi-alpha-z-circle-outline'
      },
      {
        unicode: 'F132C',
        name: 'mdi-alphabet-aurebesh'
      },
      {
        unicode: 'F132D',
        name: 'mdi-alphabet-cyrillic'
      },
      {
        unicode: 'F132E',
        name: 'mdi-alphabet-greek'
      },
      {
        unicode: 'F132F',
        name: 'mdi-alphabet-latin'
      },
      {
        unicode: 'F1330',
        name: 'mdi-alphabet-piqad'
      },
      {
        unicode: 'F1337',
        name: 'mdi-alphabet-tengwar'
      },
      {
        unicode: 'F002C',
        name: 'mdi-alphabetical'
      },
      {
        unicode: 'F100C',
        name: 'mdi-alphabetical-off'
      },
      {
        unicode: 'F100D',
        name: 'mdi-alphabetical-variant'
      },
      {
        unicode: 'F100E',
        name: 'mdi-alphabetical-variant-off'
      },
      {
        unicode: 'F05D7',
        name: 'mdi-altimeter'
      },
      {
        unicode: 'F002D',
        name: 'mdi-amazon'
      },
      {
        unicode: 'F08C6',
        name: 'mdi-amazon-alexa'
      },
      {
        unicode: 'F002F',
        name: 'mdi-ambulance'
      },
      {
        unicode: 'F0CE8',
        name: 'mdi-ammunition'
      },
      {
        unicode: 'F0A8D',
        name: 'mdi-ampersand'
      },
      {
        unicode: 'F0030',
        name: 'mdi-amplifier'
      },
      {
        unicode: 'F11B5',
        name: 'mdi-amplifier-off'
      },
      {
        unicode: 'F0031',
        name: 'mdi-anchor'
      },
      {
        unicode: 'F0032',
        name: 'mdi-android'
      },
      {
        unicode: 'F0A8E',
        name: 'mdi-android-auto'
      },
      {
        unicode: 'F0033',
        name: 'mdi-android-debug-bridge'
      },
      {
        unicode: 'F0D45',
        name: 'mdi-android-messages'
      },
      {
        unicode: 'F0034',
        name: 'mdi-android-studio'
      },
      {
        unicode: 'F0937',
        name: 'mdi-angle-acute'
      },
      {
        unicode: 'F0938',
        name: 'mdi-angle-obtuse'
      },
      {
        unicode: 'F0939',
        name: 'mdi-angle-right'
      },
      {
        unicode: 'F06B2',
        name: 'mdi-angular'
      },
      {
        unicode: 'F06BF',
        name: 'mdi-angularjs'
      },
      {
        unicode: 'F05D8',
        name: 'mdi-animation'
      },
      {
        unicode: 'F0A8F',
        name: 'mdi-animation-outline'
      },
      {
        unicode: 'F093A',
        name: 'mdi-animation-play'
      },
      {
        unicode: 'F0A90',
        name: 'mdi-animation-play-outline'
      },
      {
        unicode: 'F109A',
        name: 'mdi-ansible'
      },
      {
        unicode: 'F1119',
        name: 'mdi-antenna'
      },
      {
        unicode: 'F089B',
        name: 'mdi-anvil'
      },
      {
        unicode: 'F100F',
        name: 'mdi-apache-kafka'
      },
      {
        unicode: 'F109B',
        name: 'mdi-api'
      },
      {
        unicode: 'F1257',
        name: 'mdi-api-off'
      },
      {
        unicode: 'F0035',
        name: 'mdi-apple'
      },
      {
        unicode: 'F001F',
        name: 'mdi-apple-airplay'
      },
      {
        unicode: 'F0036',
        name: 'mdi-apple-finder'
      },
      {
        unicode: 'F0038',
        name: 'mdi-apple-icloud'
      },
      {
        unicode: 'F0037',
        name: 'mdi-apple-ios'
      },
      {
        unicode: 'F0632',
        name: 'mdi-apple-keyboard-caps'
      },
      {
        unicode: 'F0633',
        name: 'mdi-apple-keyboard-command'
      },
      {
        unicode: 'F0634',
        name: 'mdi-apple-keyboard-control'
      },
      {
        unicode: 'F0635',
        name: 'mdi-apple-keyboard-option'
      },
      {
        unicode: 'F0636',
        name: 'mdi-apple-keyboard-shift'
      },
      {
        unicode: 'F0039',
        name: 'mdi-apple-safari'
      },
      {
        unicode: 'F0614',
        name: 'mdi-application'
      },
      {
        unicode: 'F0DAD',
        name: 'mdi-application-export'
      },
      {
        unicode: 'F0DAE',
        name: 'mdi-application-import'
      },
      {
        unicode: 'F0F9E',
        name: 'mdi-approximately-equal'
      },
      {
        unicode: 'F0F9F',
        name: 'mdi-approximately-equal-box'
      },
      {
        unicode: 'F003B',
        name: 'mdi-apps'
      },
      {
        unicode: 'F0D46',
        name: 'mdi-apps-box'
      },
      {
        unicode: 'F08C7',
        name: 'mdi-arch'
      },
      {
        unicode: 'F003C',
        name: 'mdi-archive'
      },
      {
        unicode: 'F1259',
        name: 'mdi-archive-arrow-down'
      },
      {
        unicode: 'F125A',
        name: 'mdi-archive-arrow-down-outline'
      },
      {
        unicode: 'F125B',
        name: 'mdi-archive-arrow-up'
      },
      {
        unicode: 'F125C',
        name: 'mdi-archive-arrow-up-outline'
      },
      {
        unicode: 'F120E',
        name: 'mdi-archive-outline'
      },
      {
        unicode: 'F0FD7',
        name: 'mdi-arm-flex'
      },
      {
        unicode: 'F0FD6',
        name: 'mdi-arm-flex-outline'
      },
      {
        unicode: 'F003D',
        name: 'mdi-arrange-bring-forward'
      },
      {
        unicode: 'F003E',
        name: 'mdi-arrange-bring-to-front'
      },
      {
        unicode: 'F003F',
        name: 'mdi-arrange-send-backward'
      },
      {
        unicode: 'F0040',
        name: 'mdi-arrange-send-to-back'
      },
      {
        unicode: 'F0041',
        name: 'mdi-arrow-all'
      },
      {
        unicode: 'F0042',
        name: 'mdi-arrow-bottom-left'
      },
      {
        unicode: 'F09B7',
        name: 'mdi-arrow-bottom-left-bold-outline'
      },
      {
        unicode: 'F09B8',
        name: 'mdi-arrow-bottom-left-thick'
      },
      {
        unicode: 'F0043',
        name: 'mdi-arrow-bottom-right'
      },
      {
        unicode: 'F09B9',
        name: 'mdi-arrow-bottom-right-bold-outline'
      },
      {
        unicode: 'F09BA',
        name: 'mdi-arrow-bottom-right-thick'
      },
      {
        unicode: 'F0615',
        name: 'mdi-arrow-collapse'
      },
      {
        unicode: 'F0044',
        name: 'mdi-arrow-collapse-all'
      },
      {
        unicode: 'F0792',
        name: 'mdi-arrow-collapse-down'
      },
      {
        unicode: 'F084C',
        name: 'mdi-arrow-collapse-horizontal'
      },
      {
        unicode: 'F0793',
        name: 'mdi-arrow-collapse-left'
      },
      {
        unicode: 'F0794',
        name: 'mdi-arrow-collapse-right'
      },
      {
        unicode: 'F0795',
        name: 'mdi-arrow-collapse-up'
      },
      {
        unicode: 'F084D',
        name: 'mdi-arrow-collapse-vertical'
      },
      {
        unicode: 'F09BB',
        name: 'mdi-arrow-decision'
      },
      {
        unicode: 'F09BC',
        name: 'mdi-arrow-decision-auto'
      },
      {
        unicode: 'F09BD',
        name: 'mdi-arrow-decision-auto-outline'
      },
      {
        unicode: 'F09BE',
        name: 'mdi-arrow-decision-outline'
      },
      {
        unicode: 'F0045',
        name: 'mdi-arrow-down'
      },
      {
        unicode: 'F072E',
        name: 'mdi-arrow-down-bold'
      },
      {
        unicode: 'F072F',
        name: 'mdi-arrow-down-bold-box'
      },
      {
        unicode: 'F0730',
        name: 'mdi-arrow-down-bold-box-outline'
      },
      {
        unicode: 'F0047',
        name: 'mdi-arrow-down-bold-circle'
      },
      {
        unicode: 'F0048',
        name: 'mdi-arrow-down-bold-circle-outline'
      },
      {
        unicode: 'F0049',
        name: 'mdi-arrow-down-bold-hexagon-outline'
      },
      {
        unicode: 'F09BF',
        name: 'mdi-arrow-down-bold-outline'
      },
      {
        unicode: 'F06C0',
        name: 'mdi-arrow-down-box'
      },
      {
        unicode: 'F0CDB',
        name: 'mdi-arrow-down-circle'
      },
      {
        unicode: 'F0CDC',
        name: 'mdi-arrow-down-circle-outline'
      },
      {
        unicode: 'F004A',
        name: 'mdi-arrow-down-drop-circle'
      },
      {
        unicode: 'F004B',
        name: 'mdi-arrow-down-drop-circle-outline'
      },
      {
        unicode: 'F0046',
        name: 'mdi-arrow-down-thick'
      },
      {
        unicode: 'F0616',
        name: 'mdi-arrow-expand'
      },
      {
        unicode: 'F004C',
        name: 'mdi-arrow-expand-all'
      },
      {
        unicode: 'F0796',
        name: 'mdi-arrow-expand-down'
      },
      {
        unicode: 'F084E',
        name: 'mdi-arrow-expand-horizontal'
      },
      {
        unicode: 'F0797',
        name: 'mdi-arrow-expand-left'
      },
      {
        unicode: 'F0798',
        name: 'mdi-arrow-expand-right'
      },
      {
        unicode: 'F0799',
        name: 'mdi-arrow-expand-up'
      },
      {
        unicode: 'F084F',
        name: 'mdi-arrow-expand-vertical'
      },
      {
        unicode: 'F115B',
        name: 'mdi-arrow-horizontal-lock'
      },
      {
        unicode: 'F004D',
        name: 'mdi-arrow-left'
      },
      {
        unicode: 'F0731',
        name: 'mdi-arrow-left-bold'
      },
      {
        unicode: 'F0732',
        name: 'mdi-arrow-left-bold-box'
      },
      {
        unicode: 'F0733',
        name: 'mdi-arrow-left-bold-box-outline'
      },
      {
        unicode: 'F004F',
        name: 'mdi-arrow-left-bold-circle'
      },
      {
        unicode: 'F0050',
        name: 'mdi-arrow-left-bold-circle-outline'
      },
      {
        unicode: 'F0051',
        name: 'mdi-arrow-left-bold-hexagon-outline'
      },
      {
        unicode: 'F09C0',
        name: 'mdi-arrow-left-bold-outline'
      },
      {
        unicode: 'F06C1',
        name: 'mdi-arrow-left-box'
      },
      {
        unicode: 'F0CDD',
        name: 'mdi-arrow-left-circle'
      },
      {
        unicode: 'F0CDE',
        name: 'mdi-arrow-left-circle-outline'
      },
      {
        unicode: 'F0052',
        name: 'mdi-arrow-left-drop-circle'
      },
      {
        unicode: 'F0053',
        name: 'mdi-arrow-left-drop-circle-outline'
      },
      {
        unicode: 'F0E73',
        name: 'mdi-arrow-left-right'
      },
      {
        unicode: 'F0E74',
        name: 'mdi-arrow-left-right-bold'
      },
      {
        unicode: 'F09C1',
        name: 'mdi-arrow-left-right-bold-outline'
      },
      {
        unicode: 'F004E',
        name: 'mdi-arrow-left-thick'
      },
      {
        unicode: 'F0054',
        name: 'mdi-arrow-right'
      },
      {
        unicode: 'F0734',
        name: 'mdi-arrow-right-bold'
      },
      {
        unicode: 'F0735',
        name: 'mdi-arrow-right-bold-box'
      },
      {
        unicode: 'F0736',
        name: 'mdi-arrow-right-bold-box-outline'
      },
      {
        unicode: 'F0056',
        name: 'mdi-arrow-right-bold-circle'
      },
      {
        unicode: 'F0057',
        name: 'mdi-arrow-right-bold-circle-outline'
      },
      {
        unicode: 'F0058',
        name: 'mdi-arrow-right-bold-hexagon-outline'
      },
      {
        unicode: 'F09C2',
        name: 'mdi-arrow-right-bold-outline'
      },
      {
        unicode: 'F06C2',
        name: 'mdi-arrow-right-box'
      },
      {
        unicode: 'F0CDF',
        name: 'mdi-arrow-right-circle'
      },
      {
        unicode: 'F0CE0',
        name: 'mdi-arrow-right-circle-outline'
      },
      {
        unicode: 'F0059',
        name: 'mdi-arrow-right-drop-circle'
      },
      {
        unicode: 'F005A',
        name: 'mdi-arrow-right-drop-circle-outline'
      },
      {
        unicode: 'F0055',
        name: 'mdi-arrow-right-thick'
      },
      {
        unicode: 'F093B',
        name: 'mdi-arrow-split-horizontal'
      },
      {
        unicode: 'F093C',
        name: 'mdi-arrow-split-vertical'
      },
      {
        unicode: 'F005B',
        name: 'mdi-arrow-top-left'
      },
      {
        unicode: 'F09C3',
        name: 'mdi-arrow-top-left-bold-outline'
      },
      {
        unicode: 'F0E75',
        name: 'mdi-arrow-top-left-bottom-right'
      },
      {
        unicode: 'F0E76',
        name: 'mdi-arrow-top-left-bottom-right-bold'
      },
      {
        unicode: 'F09C4',
        name: 'mdi-arrow-top-left-thick'
      },
      {
        unicode: 'F005C',
        name: 'mdi-arrow-top-right'
      },
      {
        unicode: 'F09C5',
        name: 'mdi-arrow-top-right-bold-outline'
      },
      {
        unicode: 'F0E77',
        name: 'mdi-arrow-top-right-bottom-left'
      },
      {
        unicode: 'F0E78',
        name: 'mdi-arrow-top-right-bottom-left-bold'
      },
      {
        unicode: 'F09C6',
        name: 'mdi-arrow-top-right-thick'
      },
      {
        unicode: 'F005D',
        name: 'mdi-arrow-up'
      },
      {
        unicode: 'F0737',
        name: 'mdi-arrow-up-bold'
      },
      {
        unicode: 'F0738',
        name: 'mdi-arrow-up-bold-box'
      },
      {
        unicode: 'F0739',
        name: 'mdi-arrow-up-bold-box-outline'
      },
      {
        unicode: 'F005F',
        name: 'mdi-arrow-up-bold-circle'
      },
      {
        unicode: 'F0060',
        name: 'mdi-arrow-up-bold-circle-outline'
      },
      {
        unicode: 'F0061',
        name: 'mdi-arrow-up-bold-hexagon-outline'
      },
      {
        unicode: 'F09C7',
        name: 'mdi-arrow-up-bold-outline'
      },
      {
        unicode: 'F06C3',
        name: 'mdi-arrow-up-box'
      },
      {
        unicode: 'F0CE1',
        name: 'mdi-arrow-up-circle'
      },
      {
        unicode: 'F0CE2',
        name: 'mdi-arrow-up-circle-outline'
      },
      {
        unicode: 'F0E79',
        name: 'mdi-arrow-up-down'
      },
      {
        unicode: 'F0E7A',
        name: 'mdi-arrow-up-down-bold'
      },
      {
        unicode: 'F09C8',
        name: 'mdi-arrow-up-down-bold-outline'
      },
      {
        unicode: 'F0062',
        name: 'mdi-arrow-up-drop-circle'
      },
      {
        unicode: 'F0063',
        name: 'mdi-arrow-up-drop-circle-outline'
      },
      {
        unicode: 'F005E',
        name: 'mdi-arrow-up-thick'
      },
      {
        unicode: 'F115C',
        name: 'mdi-arrow-vertical-lock'
      },
      {
        unicode: 'F0B5B',
        name: 'mdi-artstation'
      },
      {
        unicode: 'F0A24',
        name: 'mdi-aspect-ratio'
      },
      {
        unicode: 'F0064',
        name: 'mdi-assistant'
      },
      {
        unicode: 'F06C4',
        name: 'mdi-asterisk'
      },
      {
        unicode: 'F0065',
        name: 'mdi-at'
      },
      {
        unicode: 'F0804',
        name: 'mdi-atlassian'
      },
      {
        unicode: 'F0D47',
        name: 'mdi-atm'
      },
      {
        unicode: 'F0768',
        name: 'mdi-atom'
      },
      {
        unicode: 'F0E7B',
        name: 'mdi-atom-variant'
      },
      {
        unicode: 'F0066',
        name: 'mdi-attachment'
      },
      {
        unicode: 'F093D',
        name: 'mdi-audio-video'
      },
      {
        unicode: 'F11B6',
        name: 'mdi-audio-video-off'
      },
      {
        unicode: 'F0850',
        name: 'mdi-augmented-reality'
      },
      {
        unicode: 'F137E',
        name: 'mdi-auto-download'
      },
      {
        unicode: 'F0068',
        name: 'mdi-auto-fix'
      },
      {
        unicode: 'F0069',
        name: 'mdi-auto-upload'
      },
      {
        unicode: 'F006A',
        name: 'mdi-autorenew'
      },
      {
        unicode: 'F006B',
        name: 'mdi-av-timer'
      },
      {
        unicode: 'F0E0F',
        name: 'mdi-aws'
      },
      {
        unicode: 'F08C8',
        name: 'mdi-axe'
      },
      {
        unicode: 'F0D48',
        name: 'mdi-axis'
      },
      {
        unicode: 'F0D49',
        name: 'mdi-axis-arrow'
      },
      {
        unicode: 'F140E',
        name: 'mdi-axis-arrow-info'
      },
      {
        unicode: 'F0D4A',
        name: 'mdi-axis-arrow-lock'
      },
      {
        unicode: 'F0D4B',
        name: 'mdi-axis-lock'
      },
      {
        unicode: 'F0D4C',
        name: 'mdi-axis-x-arrow'
      },
      {
        unicode: 'F0D4D',
        name: 'mdi-axis-x-arrow-lock'
      },
      {
        unicode: 'F0D4E',
        name: 'mdi-axis-x-rotate-clockwise'
      },
      {
        unicode: 'F0D4F',
        name: 'mdi-axis-x-rotate-counterclockwise'
      },
      {
        unicode: 'F0D50',
        name: 'mdi-axis-x-y-arrow-lock'
      },
      {
        unicode: 'F0D51',
        name: 'mdi-axis-y-arrow'
      },
      {
        unicode: 'F0D52',
        name: 'mdi-axis-y-arrow-lock'
      },
      {
        unicode: 'F0D53',
        name: 'mdi-axis-y-rotate-clockwise'
      },
      {
        unicode: 'F0D54',
        name: 'mdi-axis-y-rotate-counterclockwise'
      },
      {
        unicode: 'F0D55',
        name: 'mdi-axis-z-arrow'
      },
      {
        unicode: 'F0D56',
        name: 'mdi-axis-z-arrow-lock'
      },
      {
        unicode: 'F0D57',
        name: 'mdi-axis-z-rotate-clockwise'
      },
      {
        unicode: 'F0D58',
        name: 'mdi-axis-z-rotate-counterclockwise'
      },
      {
        unicode: 'F0A25',
        name: 'mdi-babel'
      },
      {
        unicode: 'F006C',
        name: 'mdi-baby'
      },
      {
        unicode: 'F0F39',
        name: 'mdi-baby-bottle'
      },
      {
        unicode: 'F0F3A',
        name: 'mdi-baby-bottle-outline'
      },
      {
        unicode: 'F13E0',
        name: 'mdi-baby-buggy'
      },
      {
        unicode: 'F068F',
        name: 'mdi-baby-carriage'
      },
      {
        unicode: 'F0FA0',
        name: 'mdi-baby-carriage-off'
      },
      {
        unicode: 'F0E7C',
        name: 'mdi-baby-face'
      },
      {
        unicode: 'F0E7D',
        name: 'mdi-baby-face-outline'
      },
      {
        unicode: 'F006D',
        name: 'mdi-backburger'
      },
      {
        unicode: 'F006E',
        name: 'mdi-backspace'
      },
      {
        unicode: 'F0B5C',
        name: 'mdi-backspace-outline'
      },
      {
        unicode: 'F0E7E',
        name: 'mdi-backspace-reverse'
      },
      {
        unicode: 'F0E7F',
        name: 'mdi-backspace-reverse-outline'
      },
      {
        unicode: 'F006F',
        name: 'mdi-backup-restore'
      },
      {
        unicode: 'F0ED5',
        name: 'mdi-bacteria'
      },
      {
        unicode: 'F0ED6',
        name: 'mdi-bacteria-outline'
      },
      {
        unicode: 'F0DA7',
        name: 'mdi-badge-account'
      },
      {
        unicode: 'F0DA8',
        name: 'mdi-badge-account-alert'
      },
      {
        unicode: 'F0DA9',
        name: 'mdi-badge-account-alert-outline'
      },
      {
        unicode: 'F0E0D',
        name: 'mdi-badge-account-horizontal'
      },
      {
        unicode: 'F0E0E',
        name: 'mdi-badge-account-horizontal-outline'
      },
      {
        unicode: 'F0DAA',
        name: 'mdi-badge-account-outline'
      },
      {
        unicode: 'F0851',
        name: 'mdi-badminton'
      },
      {
        unicode: 'F0F3B',
        name: 'mdi-bag-carry-on'
      },
      {
        unicode: 'F0D65',
        name: 'mdi-bag-carry-on-check'
      },
      {
        unicode: 'F0F3C',
        name: 'mdi-bag-carry-on-off'
      },
      {
        unicode: 'F0F3D',
        name: 'mdi-bag-checked'
      },
      {
        unicode: 'F0E10',
        name: 'mdi-bag-personal'
      },
      {
        unicode: 'F0E11',
        name: 'mdi-bag-personal-off'
      },
      {
        unicode: 'F0E12',
        name: 'mdi-bag-personal-off-outline'
      },
      {
        unicode: 'F0E13',
        name: 'mdi-bag-personal-outline'
      },
      {
        unicode: 'F0F3E',
        name: 'mdi-baguette'
      },
      {
        unicode: 'F0A26',
        name: 'mdi-balloon'
      },
      {
        unicode: 'F09C9',
        name: 'mdi-ballot'
      },
      {
        unicode: 'F09CA',
        name: 'mdi-ballot-outline'
      },
      {
        unicode: 'F0C39',
        name: 'mdi-ballot-recount'
      },
      {
        unicode: 'F0C3A',
        name: 'mdi-ballot-recount-outline'
      },
      {
        unicode: 'F0DAF',
        name: 'mdi-bandage'
      },
      {
        unicode: 'F0675',
        name: 'mdi-bandcamp'
      },
      {
        unicode: 'F0070',
        name: 'mdi-bank'
      },
      {
        unicode: 'F0DB0',
        name: 'mdi-bank-minus'
      },
      {
        unicode: 'F0E80',
        name: 'mdi-bank-outline'
      },
      {
        unicode: 'F0DB1',
        name: 'mdi-bank-plus'
      },
      {
        unicode: 'F0DB2',
        name: 'mdi-bank-remove'
      },
      {
        unicode: 'F0A27',
        name: 'mdi-bank-transfer'
      },
      {
        unicode: 'F0A28',
        name: 'mdi-bank-transfer-in'
      },
      {
        unicode: 'F0A29',
        name: 'mdi-bank-transfer-out'
      },
      {
        unicode: 'F0071',
        name: 'mdi-barcode'
      },
      {
        unicode: 'F1236',
        name: 'mdi-barcode-off'
      },
      {
        unicode: 'F0072',
        name: 'mdi-barcode-scan'
      },
      {
        unicode: 'F0073',
        name: 'mdi-barley'
      },
      {
        unicode: 'F0B5D',
        name: 'mdi-barley-off'
      },
      {
        unicode: 'F0B5E',
        name: 'mdi-barn'
      },
      {
        unicode: 'F0074',
        name: 'mdi-barrel'
      },
      {
        unicode: 'F0852',
        name: 'mdi-baseball'
      },
      {
        unicode: 'F0853',
        name: 'mdi-baseball-bat'
      },
      {
        unicode: 'F1183',
        name: 'mdi-bash'
      },
      {
        unicode: 'F0076',
        name: 'mdi-basket'
      },
      {
        unicode: 'F0077',
        name: 'mdi-basket-fill'
      },
      {
        unicode: 'F1181',
        name: 'mdi-basket-outline'
      },
      {
        unicode: 'F0078',
        name: 'mdi-basket-unfill'
      },
      {
        unicode: 'F0806',
        name: 'mdi-basketball'
      },
      {
        unicode: 'F0C3B',
        name: 'mdi-basketball-hoop'
      },
      {
        unicode: 'F0C3C',
        name: 'mdi-basketball-hoop-outline'
      },
      {
        unicode: 'F0B5F',
        name: 'mdi-bat'
      },
      {
        unicode: 'F0079',
        name: 'mdi-battery'
      },
      {
        unicode: 'F007A',
        name: 'mdi-battery-10'
      },
      {
        unicode: 'F093E',
        name: 'mdi-battery-10-bluetooth'
      },
      {
        unicode: 'F007B',
        name: 'mdi-battery-20'
      },
      {
        unicode: 'F093F',
        name: 'mdi-battery-20-bluetooth'
      },
      {
        unicode: 'F007C',
        name: 'mdi-battery-30'
      },
      {
        unicode: 'F0940',
        name: 'mdi-battery-30-bluetooth'
      },
      {
        unicode: 'F007D',
        name: 'mdi-battery-40'
      },
      {
        unicode: 'F0941',
        name: 'mdi-battery-40-bluetooth'
      },
      {
        unicode: 'F007E',
        name: 'mdi-battery-50'
      },
      {
        unicode: 'F0942',
        name: 'mdi-battery-50-bluetooth'
      },
      {
        unicode: 'F007F',
        name: 'mdi-battery-60'
      },
      {
        unicode: 'F0943',
        name: 'mdi-battery-60-bluetooth'
      },
      {
        unicode: 'F0080',
        name: 'mdi-battery-70'
      },
      {
        unicode: 'F0944',
        name: 'mdi-battery-70-bluetooth'
      },
      {
        unicode: 'F0081',
        name: 'mdi-battery-80'
      },
      {
        unicode: 'F0945',
        name: 'mdi-battery-80-bluetooth'
      },
      {
        unicode: 'F0082',
        name: 'mdi-battery-90'
      },
      {
        unicode: 'F0946',
        name: 'mdi-battery-90-bluetooth'
      },
      {
        unicode: 'F0083',
        name: 'mdi-battery-alert'
      },
      {
        unicode: 'F0947',
        name: 'mdi-battery-alert-bluetooth'
      },
      {
        unicode: 'F10CC',
        name: 'mdi-battery-alert-variant'
      },
      {
        unicode: 'F10CD',
        name: 'mdi-battery-alert-variant-outline'
      },
      {
        unicode: 'F0948',
        name: 'mdi-battery-bluetooth'
      },
      {
        unicode: 'F0949',
        name: 'mdi-battery-bluetooth-variant'
      },
      {
        unicode: 'F0084',
        name: 'mdi-battery-charging'
      },
      {
        unicode: 'F089C',
        name: 'mdi-battery-charging-10'
      },
      {
        unicode: 'F0085',
        name: 'mdi-battery-charging-100'
      },
      {
        unicode: 'F0086',
        name: 'mdi-battery-charging-20'
      },
      {
        unicode: 'F0087',
        name: 'mdi-battery-charging-30'
      },
      {
        unicode: 'F0088',
        name: 'mdi-battery-charging-40'
      },
      {
        unicode: 'F089D',
        name: 'mdi-battery-charging-50'
      },
      {
        unicode: 'F0089',
        name: 'mdi-battery-charging-60'
      },
      {
        unicode: 'F089E',
        name: 'mdi-battery-charging-70'
      },
      {
        unicode: 'F008A',
        name: 'mdi-battery-charging-80'
      },
      {
        unicode: 'F008B',
        name: 'mdi-battery-charging-90'
      },
      {
        unicode: 'F12A6',
        name: 'mdi-battery-charging-high'
      },
      {
        unicode: 'F12A4',
        name: 'mdi-battery-charging-low'
      },
      {
        unicode: 'F12A5',
        name: 'mdi-battery-charging-medium'
      },
      {
        unicode: 'F089F',
        name: 'mdi-battery-charging-outline'
      },
      {
        unicode: 'F0807',
        name: 'mdi-battery-charging-wireless'
      },
      {
        unicode: 'F0808',
        name: 'mdi-battery-charging-wireless-10'
      },
      {
        unicode: 'F0809',
        name: 'mdi-battery-charging-wireless-20'
      },
      {
        unicode: 'F080A',
        name: 'mdi-battery-charging-wireless-30'
      },
      {
        unicode: 'F080B',
        name: 'mdi-battery-charging-wireless-40'
      },
      {
        unicode: 'F080C',
        name: 'mdi-battery-charging-wireless-50'
      },
      {
        unicode: 'F080D',
        name: 'mdi-battery-charging-wireless-60'
      },
      {
        unicode: 'F080E',
        name: 'mdi-battery-charging-wireless-70'
      },
      {
        unicode: 'F080F',
        name: 'mdi-battery-charging-wireless-80'
      },
      {
        unicode: 'F0810',
        name: 'mdi-battery-charging-wireless-90'
      },
      {
        unicode: 'F0811',
        name: 'mdi-battery-charging-wireless-alert'
      },
      {
        unicode: 'F0812',
        name: 'mdi-battery-charging-wireless-outline'
      },
      {
        unicode: 'F120F',
        name: 'mdi-battery-heart'
      },
      {
        unicode: 'F1210',
        name: 'mdi-battery-heart-outline'
      },
      {
        unicode: 'F1211',
        name: 'mdi-battery-heart-variant'
      },
      {
        unicode: 'F12A3',
        name: 'mdi-battery-high'
      },
      {
        unicode: 'F12A1',
        name: 'mdi-battery-low'
      },
      {
        unicode: 'F12A2',
        name: 'mdi-battery-medium'
      },
      {
        unicode: 'F008C',
        name: 'mdi-battery-minus'
      },
      {
        unicode: 'F008D',
        name: 'mdi-battery-negative'
      },
      {
        unicode: 'F125D',
        name: 'mdi-battery-off'
      },
      {
        unicode: 'F125E',
        name: 'mdi-battery-off-outline'
      },
      {
        unicode: 'F008E',
        name: 'mdi-battery-outline'
      },
      {
        unicode: 'F008F',
        name: 'mdi-battery-plus'
      },
      {
        unicode: 'F0090',
        name: 'mdi-battery-positive'
      },
      {
        unicode: 'F0091',
        name: 'mdi-battery-unknown'
      },
      {
        unicode: 'F094A',
        name: 'mdi-battery-unknown-bluetooth'
      },
      {
        unicode: 'F0B60',
        name: 'mdi-battlenet'
      },
      {
        unicode: 'F0092',
        name: 'mdi-beach'
      },
      {
        unicode: 'F0CEA',
        name: 'mdi-beaker'
      },
      {
        unicode: 'F1229',
        name: 'mdi-beaker-alert'
      },
      {
        unicode: 'F122A',
        name: 'mdi-beaker-alert-outline'
      },
      {
        unicode: 'F122B',
        name: 'mdi-beaker-check'
      },
      {
        unicode: 'F122C',
        name: 'mdi-beaker-check-outline'
      },
      {
        unicode: 'F122D',
        name: 'mdi-beaker-minus'
      },
      {
        unicode: 'F122E',
        name: 'mdi-beaker-minus-outline'
      },
      {
        unicode: 'F0690',
        name: 'mdi-beaker-outline'
      },
      {
        unicode: 'F122F',
        name: 'mdi-beaker-plus'
      },
      {
        unicode: 'F1230',
        name: 'mdi-beaker-plus-outline'
      },
      {
        unicode: 'F1231',
        name: 'mdi-beaker-question'
      },
      {
        unicode: 'F1232',
        name: 'mdi-beaker-question-outline'
      },
      {
        unicode: 'F1233',
        name: 'mdi-beaker-remove'
      },
      {
        unicode: 'F1234',
        name: 'mdi-beaker-remove-outline'
      },
      {
        unicode: 'F02E3',
        name: 'mdi-bed'
      },
      {
        unicode: 'F0FD4',
        name: 'mdi-bed-double'
      },
      {
        unicode: 'F0FD3',
        name: 'mdi-bed-double-outline'
      },
      {
        unicode: 'F08A0',
        name: 'mdi-bed-empty'
      },
      {
        unicode: 'F0FD2',
        name: 'mdi-bed-king'
      },
      {
        unicode: 'F0FD1',
        name: 'mdi-bed-king-outline'
      },
      {
        unicode: 'F0099',
        name: 'mdi-bed-outline'
      },
      {
        unicode: 'F0FD0',
        name: 'mdi-bed-queen'
      },
      {
        unicode: 'F0FDB',
        name: 'mdi-bed-queen-outline'
      },
      {
        unicode: 'F106D',
        name: 'mdi-bed-single'
      },
      {
        unicode: 'F106E',
        name: 'mdi-bed-single-outline'
      },
      {
        unicode: 'F0FA1',
        name: 'mdi-bee'
      },
      {
        unicode: 'F0FA2',
        name: 'mdi-bee-flower'
      },
      {
        unicode: 'F13ED',
        name: 'mdi-beehive-off-outline'
      },
      {
        unicode: 'F10CE',
        name: 'mdi-beehive-outline'
      },
      {
        unicode: 'F0098',
        name: 'mdi-beer'
      },
      {
        unicode: 'F130C',
        name: 'mdi-beer-outline'
      },
      {
        unicode: 'F009A',
        name: 'mdi-bell'
      },
      {
        unicode: 'F0D59',
        name: 'mdi-bell-alert'
      },
      {
        unicode: 'F0E81',
        name: 'mdi-bell-alert-outline'
      },
      {
        unicode: 'F13E7',
        name: 'mdi-bell-cancel'
      },
      {
        unicode: 'F13E8',
        name: 'mdi-bell-cancel-outline'
      },
      {
        unicode: 'F11E5',
        name: 'mdi-bell-check'
      },
      {
        unicode: 'F11E6',
        name: 'mdi-bell-check-outline'
      },
      {
        unicode: 'F0D5A',
        name: 'mdi-bell-circle'
      },
      {
        unicode: 'F0D5B',
        name: 'mdi-bell-circle-outline'
      },
      {
        unicode: 'F13E9',
        name: 'mdi-bell-minus'
      },
      {
        unicode: 'F13EA',
        name: 'mdi-bell-minus-outline'
      },
      {
        unicode: 'F009B',
        name: 'mdi-bell-off'
      },
      {
        unicode: 'F0A91',
        name: 'mdi-bell-off-outline'
      },
      {
        unicode: 'F009C',
        name: 'mdi-bell-outline'
      },
      {
        unicode: 'F009D',
        name: 'mdi-bell-plus'
      },
      {
        unicode: 'F0A92',
        name: 'mdi-bell-plus-outline'
      },
      {
        unicode: 'F13EB',
        name: 'mdi-bell-remove'
      },
      {
        unicode: 'F13EC',
        name: 'mdi-bell-remove-outline'
      },
      {
        unicode: 'F009E',
        name: 'mdi-bell-ring'
      },
      {
        unicode: 'F009F',
        name: 'mdi-bell-ring-outline'
      },
      {
        unicode: 'F00A0',
        name: 'mdi-bell-sleep'
      },
      {
        unicode: 'F0A93',
        name: 'mdi-bell-sleep-outline'
      },
      {
        unicode: 'F00A1',
        name: 'mdi-beta'
      },
      {
        unicode: 'F09CB',
        name: 'mdi-betamax'
      },
      {
        unicode: 'F0E14',
        name: 'mdi-biathlon'
      },
      {
        unicode: 'F109C',
        name: 'mdi-bicycle'
      },
      {
        unicode: 'F1235',
        name: 'mdi-bicycle-basket'
      },
      {
        unicode: 'F00A3',
        name: 'mdi-bike'
      },
      {
        unicode: 'F111F',
        name: 'mdi-bike-fast'
      },
      {
        unicode: 'F1010',
        name: 'mdi-billboard'
      },
      {
        unicode: 'F0B61',
        name: 'mdi-billiards'
      },
      {
        unicode: 'F0B62',
        name: 'mdi-billiards-rack'
      },
      {
        unicode: 'F00A5',
        name: 'mdi-binoculars'
      },
      {
        unicode: 'F00A6',
        name: 'mdi-bio'
      },
      {
        unicode: 'F00A7',
        name: 'mdi-biohazard'
      },
      {
        unicode: 'F00A8',
        name: 'mdi-bitbucket'
      },
      {
        unicode: 'F0813',
        name: 'mdi-bitcoin'
      },
      {
        unicode: 'F00A9',
        name: 'mdi-black-mesa'
      },
      {
        unicode: 'F0CEB',
        name: 'mdi-blender'
      },
      {
        unicode: 'F00AB',
        name: 'mdi-blender-software'
      },
      {
        unicode: 'F00AC',
        name: 'mdi-blinds'
      },
      {
        unicode: 'F1011',
        name: 'mdi-blinds-open'
      },
      {
        unicode: 'F00AD',
        name: 'mdi-block-helper'
      },
      {
        unicode: 'F00AE',
        name: 'mdi-blogger'
      },
      {
        unicode: 'F0CEC',
        name: 'mdi-blood-bag'
      },
      {
        unicode: 'F00AF',
        name: 'mdi-bluetooth'
      },
      {
        unicode: 'F00B0',
        name: 'mdi-bluetooth-audio'
      },
      {
        unicode: 'F00B1',
        name: 'mdi-bluetooth-connect'
      },
      {
        unicode: 'F00B2',
        name: 'mdi-bluetooth-off'
      },
      {
        unicode: 'F00B3',
        name: 'mdi-bluetooth-settings'
      },
      {
        unicode: 'F00B4',
        name: 'mdi-bluetooth-transfer'
      },
      {
        unicode: 'F00B5',
        name: 'mdi-blur'
      },
      {
        unicode: 'F00B6',
        name: 'mdi-blur-linear'
      },
      {
        unicode: 'F00B7',
        name: 'mdi-blur-off'
      },
      {
        unicode: 'F00B8',
        name: 'mdi-blur-radial'
      },
      {
        unicode: 'F0CED',
        name: 'mdi-bolnisi-cross'
      },
      {
        unicode: 'F0DB3',
        name: 'mdi-bolt'
      },
      {
        unicode: 'F0691',
        name: 'mdi-bomb'
      },
      {
        unicode: 'F06C5',
        name: 'mdi-bomb-off'
      },
      {
        unicode: 'F00B9',
        name: 'mdi-bone'
      },
      {
        unicode: 'F00BA',
        name: 'mdi-book'
      },
      {
        unicode: 'F13AD',
        name: 'mdi-book-account'
      },
      {
        unicode: 'F13AE',
        name: 'mdi-book-account-outline'
      },
      {
        unicode: 'F061D',
        name: 'mdi-book-alphabet'
      },
      {
        unicode: 'F00A2',
        name: 'mdi-book-cross'
      },
      {
        unicode: 'F106F',
        name: 'mdi-book-information-variant'
      },
      {
        unicode: 'F079A',
        name: 'mdi-book-lock'
      },
      {
        unicode: 'F079B',
        name: 'mdi-book-lock-open'
      },
      {
        unicode: 'F05D9',
        name: 'mdi-book-minus'
      },
      {
        unicode: 'F0A94',
        name: 'mdi-book-minus-multiple'
      },
      {
        unicode: 'F090B',
        name: 'mdi-book-minus-multiple-outline'
      },
      {
        unicode: 'F00BB',
        name: 'mdi-book-multiple'
      },
      {
        unicode: 'F0436',
        name: 'mdi-book-multiple-outline'
      },
      {
        unicode: 'F0067',
        name: 'mdi-book-music'
      },
      {
        unicode: 'F00BD',
        name: 'mdi-book-open'
      },
      {
        unicode: 'F0B63',
        name: 'mdi-book-open-outline'
      },
      {
        unicode: 'F05DA',
        name: 'mdi-book-open-page-variant'
      },
      {
        unicode: 'F00BE',
        name: 'mdi-book-open-variant'
      },
      {
        unicode: 'F0B64',
        name: 'mdi-book-outline'
      },
      {
        unicode: 'F0E82',
        name: 'mdi-book-play'
      },
      {
        unicode: 'F0E83',
        name: 'mdi-book-play-outline'
      },
      {
        unicode: 'F05DB',
        name: 'mdi-book-plus'
      },
      {
        unicode: 'F0A95',
        name: 'mdi-book-plus-multiple'
      },
      {
        unicode: 'F0ADE',
        name: 'mdi-book-plus-multiple-outline'
      },
      {
        unicode: 'F0A97',
        name: 'mdi-book-remove'
      },
      {
        unicode: 'F0A96',
        name: 'mdi-book-remove-multiple'
      },
      {
        unicode: 'F04CA',
        name: 'mdi-book-remove-multiple-outline'
      },
      {
        unicode: 'F0E84',
        name: 'mdi-book-search'
      },
      {
        unicode: 'F0E85',
        name: 'mdi-book-search-outline'
      },
      {
        unicode: 'F00BF',
        name: 'mdi-book-variant'
      },
      {
        unicode: 'F00BC',
        name: 'mdi-book-variant-multiple'
      },
      {
        unicode: 'F00C0',
        name: 'mdi-bookmark'
      },
      {
        unicode: 'F00C1',
        name: 'mdi-bookmark-check'
      },
      {
        unicode: 'F137B',
        name: 'mdi-bookmark-check-outline'
      },
      {
        unicode: 'F09CC',
        name: 'mdi-bookmark-minus'
      },
      {
        unicode: 'F09CD',
        name: 'mdi-bookmark-minus-outline'
      },
      {
        unicode: 'F0E15',
        name: 'mdi-bookmark-multiple'
      },
      {
        unicode: 'F0E16',
        name: 'mdi-bookmark-multiple-outline'
      },
      {
        unicode: 'F00C2',
        name: 'mdi-bookmark-music'
      },
      {
        unicode: 'F1379',
        name: 'mdi-bookmark-music-outline'
      },
      {
        unicode: 'F09CE',
        name: 'mdi-bookmark-off'
      },
      {
        unicode: 'F09CF',
        name: 'mdi-bookmark-off-outline'
      },
      {
        unicode: 'F00C3',
        name: 'mdi-bookmark-outline'
      },
      {
        unicode: 'F00C5',
        name: 'mdi-bookmark-plus'
      },
      {
        unicode: 'F00C4',
        name: 'mdi-bookmark-plus-outline'
      },
      {
        unicode: 'F00C6',
        name: 'mdi-bookmark-remove'
      },
      {
        unicode: 'F137A',
        name: 'mdi-bookmark-remove-outline'
      },
      {
        unicode: 'F125F',
        name: 'mdi-bookshelf'
      },
      {
        unicode: 'F0E86',
        name: 'mdi-boom-gate'
      },
      {
        unicode: 'F0E87',
        name: 'mdi-boom-gate-alert'
      },
      {
        unicode: 'F0E88',
        name: 'mdi-boom-gate-alert-outline'
      },
      {
        unicode: 'F0E89',
        name: 'mdi-boom-gate-down'
      },
      {
        unicode: 'F0E8A',
        name: 'mdi-boom-gate-down-outline'
      },
      {
        unicode: 'F0E8B',
        name: 'mdi-boom-gate-outline'
      },
      {
        unicode: 'F0E8C',
        name: 'mdi-boom-gate-up'
      },
      {
        unicode: 'F0E8D',
        name: 'mdi-boom-gate-up-outline'
      },
      {
        unicode: 'F05DC',
        name: 'mdi-boombox'
      },
      {
        unicode: 'F10CF',
        name: 'mdi-boomerang'
      },
      {
        unicode: 'F06C6',
        name: 'mdi-bootstrap'
      },
      {
        unicode: 'F00C7',
        name: 'mdi-border-all'
      },
      {
        unicode: 'F08A1',
        name: 'mdi-border-all-variant'
      },
      {
        unicode: 'F00C8',
        name: 'mdi-border-bottom'
      },
      {
        unicode: 'F08A2',
        name: 'mdi-border-bottom-variant'
      },
      {
        unicode: 'F00C9',
        name: 'mdi-border-color'
      },
      {
        unicode: 'F00CA',
        name: 'mdi-border-horizontal'
      },
      {
        unicode: 'F00CB',
        name: 'mdi-border-inside'
      },
      {
        unicode: 'F00CC',
        name: 'mdi-border-left'
      },
      {
        unicode: 'F08A3',
        name: 'mdi-border-left-variant'
      },
      {
        unicode: 'F00CD',
        name: 'mdi-border-none'
      },
      {
        unicode: 'F08A4',
        name: 'mdi-border-none-variant'
      },
      {
        unicode: 'F00CE',
        name: 'mdi-border-outside'
      },
      {
        unicode: 'F00CF',
        name: 'mdi-border-right'
      },
      {
        unicode: 'F08A5',
        name: 'mdi-border-right-variant'
      },
      {
        unicode: 'F00D0',
        name: 'mdi-border-style'
      },
      {
        unicode: 'F00D1',
        name: 'mdi-border-top'
      },
      {
        unicode: 'F08A6',
        name: 'mdi-border-top-variant'
      },
      {
        unicode: 'F00D2',
        name: 'mdi-border-vertical'
      },
      {
        unicode: 'F1070',
        name: 'mdi-bottle-soda'
      },
      {
        unicode: 'F1071',
        name: 'mdi-bottle-soda-classic'
      },
      {
        unicode: 'F1363',
        name: 'mdi-bottle-soda-classic-outline'
      },
      {
        unicode: 'F1072',
        name: 'mdi-bottle-soda-outline'
      },
      {
        unicode: 'F112E',
        name: 'mdi-bottle-tonic'
      },
      {
        unicode: 'F112F',
        name: 'mdi-bottle-tonic-outline'
      },
      {
        unicode: 'F1130',
        name: 'mdi-bottle-tonic-plus'
      },
      {
        unicode: 'F1131',
        name: 'mdi-bottle-tonic-plus-outline'
      },
      {
        unicode: 'F1132',
        name: 'mdi-bottle-tonic-skull'
      },
      {
        unicode: 'F1133',
        name: 'mdi-bottle-tonic-skull-outline'
      },
      {
        unicode: 'F0854',
        name: 'mdi-bottle-wine'
      },
      {
        unicode: 'F1310',
        name: 'mdi-bottle-wine-outline'
      },
      {
        unicode: 'F0678',
        name: 'mdi-bow-tie'
      },
      {
        unicode: 'F028E',
        name: 'mdi-bowl'
      },
      {
        unicode: 'F0617',
        name: 'mdi-bowl-mix'
      },
      {
        unicode: 'F02E4',
        name: 'mdi-bowl-mix-outline'
      },
      {
        unicode: 'F02A9',
        name: 'mdi-bowl-outline'
      },
      {
        unicode: 'F00D3',
        name: 'mdi-bowling'
      },
      {
        unicode: 'F00D4',
        name: 'mdi-box'
      },
      {
        unicode: 'F00D5',
        name: 'mdi-box-cutter'
      },
      {
        unicode: 'F0B4A',
        name: 'mdi-box-cutter-off'
      },
      {
        unicode: 'F0637',
        name: 'mdi-box-shadow'
      },
      {
        unicode: 'F0B65',
        name: 'mdi-boxing-glove'
      },
      {
        unicode: 'F09D0',
        name: 'mdi-braille'
      },
      {
        unicode: 'F09D1',
        name: 'mdi-brain'
      },
      {
        unicode: 'F0CEE',
        name: 'mdi-bread-slice'
      },
      {
        unicode: 'F0CEF',
        name: 'mdi-bread-slice-outline'
      },
      {
        unicode: 'F0618',
        name: 'mdi-bridge'
      },
      {
        unicode: 'F00D6',
        name: 'mdi-briefcase'
      },
      {
        unicode: 'F0CF0',
        name: 'mdi-briefcase-account'
      },
      {
        unicode: 'F0CF1',
        name: 'mdi-briefcase-account-outline'
      },
      {
        unicode: 'F00D7',
        name: 'mdi-briefcase-check'
      },
      {
        unicode: 'F131E',
        name: 'mdi-briefcase-check-outline'
      },
      {
        unicode: 'F10D0',
        name: 'mdi-briefcase-clock'
      },
      {
        unicode: 'F10D1',
        name: 'mdi-briefcase-clock-outline'
      },
      {
        unicode: 'F00D8',
        name: 'mdi-briefcase-download'
      },
      {
        unicode: 'F0C3D',
        name: 'mdi-briefcase-download-outline'
      },
      {
        unicode: 'F0A98',
        name: 'mdi-briefcase-edit'
      },
      {
        unicode: 'F0C3E',
        name: 'mdi-briefcase-edit-outline'
      },
      {
        unicode: 'F0A2A',
        name: 'mdi-briefcase-minus'
      },
      {
        unicode: 'F0C3F',
        name: 'mdi-briefcase-minus-outline'
      },
      {
        unicode: 'F0814',
        name: 'mdi-briefcase-outline'
      },
      {
        unicode: 'F0A2B',
        name: 'mdi-briefcase-plus'
      },
      {
        unicode: 'F0C40',
        name: 'mdi-briefcase-plus-outline'
      },
      {
        unicode: 'F0A2C',
        name: 'mdi-briefcase-remove'
      },
      {
        unicode: 'F0C41',
        name: 'mdi-briefcase-remove-outline'
      },
      {
        unicode: 'F0A2D',
        name: 'mdi-briefcase-search'
      },
      {
        unicode: 'F0C42',
        name: 'mdi-briefcase-search-outline'
      },
      {
        unicode: 'F00D9',
        name: 'mdi-briefcase-upload'
      },
      {
        unicode: 'F0C43',
        name: 'mdi-briefcase-upload-outline'
      },
      {
        unicode: 'F1494',
        name: 'mdi-briefcase-variant'
      },
      {
        unicode: 'F1495',
        name: 'mdi-briefcase-variant-outline'
      },
      {
        unicode: 'F00DA',
        name: 'mdi-brightness-1'
      },
      {
        unicode: 'F00DB',
        name: 'mdi-brightness-2'
      },
      {
        unicode: 'F00DC',
        name: 'mdi-brightness-3'
      },
      {
        unicode: 'F00DD',
        name: 'mdi-brightness-4'
      },
      {
        unicode: 'F00DE',
        name: 'mdi-brightness-5'
      },
      {
        unicode: 'F00DF',
        name: 'mdi-brightness-6'
      },
      {
        unicode: 'F00E0',
        name: 'mdi-brightness-7'
      },
      {
        unicode: 'F00E1',
        name: 'mdi-brightness-auto'
      },
      {
        unicode: 'F0CF2',
        name: 'mdi-brightness-percent'
      },
      {
        unicode: 'F00E2',
        name: 'mdi-broom'
      },
      {
        unicode: 'F00E3',
        name: 'mdi-brush'
      },
      {
        unicode: 'F1415',
        name: 'mdi-bucket'
      },
      {
        unicode: 'F1416',
        name: 'mdi-bucket-outline'
      },
      {
        unicode: 'F094B',
        name: 'mdi-buddhism'
      },
      {
        unicode: 'F0619',
        name: 'mdi-buffer'
      },
      {
        unicode: 'F0578',
        name: 'mdi-buffet'
      },
      {
        unicode: 'F00E4',
        name: 'mdi-bug'
      },
      {
        unicode: 'F0A2E',
        name: 'mdi-bug-check'
      },
      {
        unicode: 'F0A2F',
        name: 'mdi-bug-check-outline'
      },
      {
        unicode: 'F0A30',
        name: 'mdi-bug-outline'
      },
      {
        unicode: 'F0DB4',
        name: 'mdi-bugle'
      },
      {
        unicode: 'F0B22',
        name: 'mdi-bulldozer'
      },
      {
        unicode: 'F0CF3',
        name: 'mdi-bullet'
      },
      {
        unicode: 'F00E5',
        name: 'mdi-bulletin-board'
      },
      {
        unicode: 'F00E6',
        name: 'mdi-bullhorn'
      },
      {
        unicode: 'F0B23',
        name: 'mdi-bullhorn-outline'
      },
      {
        unicode: 'F05DD',
        name: 'mdi-bullseye'
      },
      {
        unicode: 'F08C9',
        name: 'mdi-bullseye-arrow'
      },
      {
        unicode: 'F12E7',
        name: 'mdi-bulma'
      },
      {
        unicode: 'F1302',
        name: 'mdi-bunk-bed'
      },
      {
        unicode: 'F0097',
        name: 'mdi-bunk-bed-outline'
      },
      {
        unicode: 'F00E7',
        name: 'mdi-bus'
      },
      {
        unicode: 'F0A99',
        name: 'mdi-bus-alert'
      },
      {
        unicode: 'F079C',
        name: 'mdi-bus-articulated-end'
      },
      {
        unicode: 'F079D',
        name: 'mdi-bus-articulated-front'
      },
      {
        unicode: 'F08CA',
        name: 'mdi-bus-clock'
      },
      {
        unicode: 'F079E',
        name: 'mdi-bus-double-decker'
      },
      {
        unicode: 'F1212',
        name: 'mdi-bus-marker'
      },
      {
        unicode: 'F0F3F',
        name: 'mdi-bus-multiple'
      },
      {
        unicode: 'F079F',
        name: 'mdi-bus-school'
      },
      {
        unicode: 'F07A0',
        name: 'mdi-bus-side'
      },
      {
        unicode: 'F1012',
        name: 'mdi-bus-stop'
      },
      {
        unicode: 'F1013',
        name: 'mdi-bus-stop-covered'
      },
      {
        unicode: 'F1014',
        name: 'mdi-bus-stop-uncovered'
      },
      {
        unicode: 'F1394',
        name: 'mdi-cable-data'
      },
      {
        unicode: 'F00E8',
        name: 'mdi-cached'
      },
      {
        unicode: 'F0DB5',
        name: 'mdi-cactus'
      },
      {
        unicode: 'F00E9',
        name: 'mdi-cake'
      },
      {
        unicode: 'F00EA',
        name: 'mdi-cake-layered'
      },
      {
        unicode: 'F00EB',
        name: 'mdi-cake-variant'
      },
      {
        unicode: 'F00EC',
        name: 'mdi-calculator'
      },
      {
        unicode: 'F0A9A',
        name: 'mdi-calculator-variant'
      },
      {
        unicode: 'F00ED',
        name: 'mdi-calendar'
      },
      {
        unicode: 'F0ED7',
        name: 'mdi-calendar-account'
      },
      {
        unicode: 'F0ED8',
        name: 'mdi-calendar-account-outline'
      },
      {
        unicode: 'F0A31',
        name: 'mdi-calendar-alert'
      },
      {
        unicode: 'F1134',
        name: 'mdi-calendar-arrow-left'
      },
      {
        unicode: 'F1135',
        name: 'mdi-calendar-arrow-right'
      },
      {
        unicode: 'F00EE',
        name: 'mdi-calendar-blank'
      },
      {
        unicode: 'F1073',
        name: 'mdi-calendar-blank-multiple'
      },
      {
        unicode: 'F0B66',
        name: 'mdi-calendar-blank-outline'
      },
      {
        unicode: 'F00EF',
        name: 'mdi-calendar-check'
      },
      {
        unicode: 'F0C44',
        name: 'mdi-calendar-check-outline'
      },
      {
        unicode: 'F00F0',
        name: 'mdi-calendar-clock'
      },
      {
        unicode: 'F08A7',
        name: 'mdi-calendar-edit'
      },
      {
        unicode: 'F0B24',
        name: 'mdi-calendar-export'
      },
      {
        unicode: 'F09D2',
        name: 'mdi-calendar-heart'
      },
      {
        unicode: 'F0B25',
        name: 'mdi-calendar-import'
      },
      {
        unicode: 'F0D5C',
        name: 'mdi-calendar-minus'
      },
      {
        unicode: 'F0E17',
        name: 'mdi-calendar-month'
      },
      {
        unicode: 'F0E18',
        name: 'mdi-calendar-month-outline'
      },
      {
        unicode: 'F00F1',
        name: 'mdi-calendar-multiple'
      },
      {
        unicode: 'F00F2',
        name: 'mdi-calendar-multiple-check'
      },
      {
        unicode: 'F0A32',
        name: 'mdi-calendar-multiselect'
      },
      {
        unicode: 'F0B67',
        name: 'mdi-calendar-outline'
      },
      {
        unicode: 'F00F3',
        name: 'mdi-calendar-plus'
      },
      {
        unicode: 'F0692',
        name: 'mdi-calendar-question'
      },
      {
        unicode: 'F0679',
        name: 'mdi-calendar-range'
      },
      {
        unicode: 'F0B68',
        name: 'mdi-calendar-range-outline'
      },
      {
        unicode: 'F01E1',
        name: 'mdi-calendar-refresh'
      },
      {
        unicode: 'F0203',
        name: 'mdi-calendar-refresh-outline'
      },
      {
        unicode: 'F00F4',
        name: 'mdi-calendar-remove'
      },
      {
        unicode: 'F0C45',
        name: 'mdi-calendar-remove-outline'
      },
      {
        unicode: 'F094C',
        name: 'mdi-calendar-search'
      },
      {
        unicode: 'F09D3',
        name: 'mdi-calendar-star'
      },
      {
        unicode: 'F0E8E',
        name: 'mdi-calendar-sync'
      },
      {
        unicode: 'F0E8F',
        name: 'mdi-calendar-sync-outline'
      },
      {
        unicode: 'F00F5',
        name: 'mdi-calendar-text'
      },
      {
        unicode: 'F0C46',
        name: 'mdi-calendar-text-outline'
      },
      {
        unicode: 'F00F6',
        name: 'mdi-calendar-today'
      },
      {
        unicode: 'F0A33',
        name: 'mdi-calendar-week'
      },
      {
        unicode: 'F0A34',
        name: 'mdi-calendar-week-begin'
      },
      {
        unicode: 'F0ED9',
        name: 'mdi-calendar-weekend'
      },
      {
        unicode: 'F0EDA',
        name: 'mdi-calendar-weekend-outline'
      },
      {
        unicode: 'F00F7',
        name: 'mdi-call-made'
      },
      {
        unicode: 'F00F8',
        name: 'mdi-call-merge'
      },
      {
        unicode: 'F00F9',
        name: 'mdi-call-missed'
      },
      {
        unicode: 'F00FA',
        name: 'mdi-call-received'
      },
      {
        unicode: 'F00FB',
        name: 'mdi-call-split'
      },
      {
        unicode: 'F00FC',
        name: 'mdi-camcorder'
      },
      {
        unicode: 'F00FF',
        name: 'mdi-camcorder-off'
      },
      {
        unicode: 'F0100',
        name: 'mdi-camera'
      },
      {
        unicode: 'F08CB',
        name: 'mdi-camera-account'
      },
      {
        unicode: 'F0693',
        name: 'mdi-camera-burst'
      },
      {
        unicode: 'F0B69',
        name: 'mdi-camera-control'
      },
      {
        unicode: 'F0101',
        name: 'mdi-camera-enhance'
      },
      {
        unicode: 'F0B6A',
        name: 'mdi-camera-enhance-outline'
      },
      {
        unicode: 'F0102',
        name: 'mdi-camera-front'
      },
      {
        unicode: 'F0103',
        name: 'mdi-camera-front-variant'
      },
      {
        unicode: 'F07A1',
        name: 'mdi-camera-gopro'
      },
      {
        unicode: 'F08CC',
        name: 'mdi-camera-image'
      },
      {
        unicode: 'F0104',
        name: 'mdi-camera-iris'
      },
      {
        unicode: 'F07A2',
        name: 'mdi-camera-metering-center'
      },
      {
        unicode: 'F07A3',
        name: 'mdi-camera-metering-matrix'
      },
      {
        unicode: 'F07A4',
        name: 'mdi-camera-metering-partial'
      },
      {
        unicode: 'F07A5',
        name: 'mdi-camera-metering-spot'
      },
      {
        unicode: 'F05DF',
        name: 'mdi-camera-off'
      },
      {
        unicode: 'F0D5D',
        name: 'mdi-camera-outline'
      },
      {
        unicode: 'F0105',
        name: 'mdi-camera-party-mode'
      },
      {
        unicode: 'F0EDB',
        name: 'mdi-camera-plus'
      },
      {
        unicode: 'F0EDC',
        name: 'mdi-camera-plus-outline'
      },
      {
        unicode: 'F0106',
        name: 'mdi-camera-rear'
      },
      {
        unicode: 'F0107',
        name: 'mdi-camera-rear-variant'
      },
      {
        unicode: 'F0E19',
        name: 'mdi-camera-retake'
      },
      {
        unicode: 'F0E1A',
        name: 'mdi-camera-retake-outline'
      },
      {
        unicode: 'F0108',
        name: 'mdi-camera-switch'
      },
      {
        unicode: 'F084A',
        name: 'mdi-camera-switch-outline'
      },
      {
        unicode: 'F0109',
        name: 'mdi-camera-timer'
      },
      {
        unicode: 'F0DB6',
        name: 'mdi-camera-wireless'
      },
      {
        unicode: 'F0DB7',
        name: 'mdi-camera-wireless-outline'
      },
      {
        unicode: 'F0EDD',
        name: 'mdi-campfire'
      },
      {
        unicode: 'F073A',
        name: 'mdi-cancel'
      },
      {
        unicode: 'F05E2',
        name: 'mdi-candle'
      },
      {
        unicode: 'F010A',
        name: 'mdi-candycane'
      },
      {
        unicode: 'F07A6',
        name: 'mdi-cannabis'
      },
      {
        unicode: 'F0A9B',
        name: 'mdi-caps-lock'
      },
      {
        unicode: 'F010B',
        name: 'mdi-car'
      },
      {
        unicode: 'F1015',
        name: 'mdi-car-2-plus'
      },
      {
        unicode: 'F1016',
        name: 'mdi-car-3-plus'
      },
      {
        unicode: 'F13B2',
        name: 'mdi-car-arrow-left'
      },
      {
        unicode: 'F13B3',
        name: 'mdi-car-arrow-right'
      },
      {
        unicode: 'F0E1B',
        name: 'mdi-car-back'
      },
      {
        unicode: 'F010C',
        name: 'mdi-car-battery'
      },
      {
        unicode: 'F0C47',
        name: 'mdi-car-brake-abs'
      },
      {
        unicode: 'F0C48',
        name: 'mdi-car-brake-alert'
      },
      {
        unicode: 'F0D5E',
        name: 'mdi-car-brake-hold'
      },
      {
        unicode: 'F0D5F',
        name: 'mdi-car-brake-parking'
      },
      {
        unicode: 'F1017',
        name: 'mdi-car-brake-retarder'
      },
      {
        unicode: 'F0FA3',
        name: 'mdi-car-child-seat'
      },
      {
        unicode: 'F1018',
        name: 'mdi-car-clutch'
      },
      {
        unicode: 'F13CC',
        name: 'mdi-car-cog'
      },
      {
        unicode: 'F010D',
        name: 'mdi-car-connected'
      },
      {
        unicode: 'F07A7',
        name: 'mdi-car-convertible'
      },
      {
        unicode: 'F1019',
        name: 'mdi-car-coolant-level'
      },
      {
        unicode: 'F0D60',
        name: 'mdi-car-cruise-control'
      },
      {
        unicode: 'F0D61',
        name: 'mdi-car-defrost-front'
      },
      {
        unicode: 'F0D62',
        name: 'mdi-car-defrost-rear'
      },
      {
        unicode: 'F0B6B',
        name: 'mdi-car-door'
      },
      {
        unicode: 'F109D',
        name: 'mdi-car-door-lock'
      },
      {
        unicode: 'F0B6C',
        name: 'mdi-car-electric'
      },
      {
        unicode: 'F0C49',
        name: 'mdi-car-esp'
      },
      {
        unicode: 'F07A8',
        name: 'mdi-car-estate'
      },
      {
        unicode: 'F07A9',
        name: 'mdi-car-hatchback'
      },
      {
        unicode: 'F11BE',
        name: 'mdi-car-info'
      },
      {
        unicode: 'F0B6D',
        name: 'mdi-car-key'
      },
      {
        unicode: 'F0C4A',
        name: 'mdi-car-light-dimmed'
      },
      {
        unicode: 'F0C4B',
        name: 'mdi-car-light-fog'
      },
      {
        unicode: 'F0C4C',
        name: 'mdi-car-light-high'
      },
      {
        unicode: 'F08CD',
        name: 'mdi-car-limousine'
      },
      {
        unicode: 'F0B6E',
        name: 'mdi-car-multiple'
      },
      {
        unicode: 'F0E1C',
        name: 'mdi-car-off'
      },
      {
        unicode: 'F0D63',
        name: 'mdi-car-parking-lights'
      },
      {
        unicode: 'F07AA',
        name: 'mdi-car-pickup'
      },
      {
        unicode: 'F0FA4',
        name: 'mdi-car-seat'
      },
      {
        unicode: 'F0FA5',
        name: 'mdi-car-seat-cooler'
      },
      {
        unicode: 'F0FA6',
        name: 'mdi-car-seat-heater'
      },
      {
        unicode: 'F13CD',
        name: 'mdi-car-settings'
      },
      {
        unicode: 'F0F40',
        name: 'mdi-car-shift-pattern'
      },
      {
        unicode: 'F07AB',
        name: 'mdi-car-side'
      },
      {
        unicode: 'F07AC',
        name: 'mdi-car-sports'
      },
      {
        unicode: 'F0C4D',
        name: 'mdi-car-tire-alert'
      },
      {
        unicode: 'F0D64',
        name: 'mdi-car-traction-control'
      },
      {
        unicode: 'F101A',
        name: 'mdi-car-turbocharger'
      },
      {
        unicode: 'F010E',
        name: 'mdi-car-wash'
      },
      {
        unicode: 'F101B',
        name: 'mdi-car-windshield'
      },
      {
        unicode: 'F101C',
        name: 'mdi-car-windshield-outline'
      },
      {
        unicode: 'F14C0',
        name: 'mdi-carabiner'
      },
      {
        unicode: 'F07AD',
        name: 'mdi-caravan'
      },
      {
        unicode: 'F0B6F',
        name: 'mdi-card'
      },
      {
        unicode: 'F05D2',
        name: 'mdi-card-account-details'
      },
      {
        unicode: 'F0DAB',
        name: 'mdi-card-account-details-outline'
      },
      {
        unicode: 'F02A3',
        name: 'mdi-card-account-details-star'
      },
      {
        unicode: 'F06DB',
        name: 'mdi-card-account-details-star-outline'
      },
      {
        unicode: 'F018E',
        name: 'mdi-card-account-mail'
      },
      {
        unicode: 'F0E98',
        name: 'mdi-card-account-mail-outline'
      },
      {
        unicode: 'F0E99',
        name: 'mdi-card-account-phone'
      },
      {
        unicode: 'F0E9A',
        name: 'mdi-card-account-phone-outline'
      },
      {
        unicode: 'F0B70',
        name: 'mdi-card-bulleted'
      },
      {
        unicode: 'F0B71',
        name: 'mdi-card-bulleted-off'
      },
      {
        unicode: 'F0B72',
        name: 'mdi-card-bulleted-off-outline'
      },
      {
        unicode: 'F0B73',
        name: 'mdi-card-bulleted-outline'
      },
      {
        unicode: 'F0B74',
        name: 'mdi-card-bulleted-settings'
      },
      {
        unicode: 'F0B75',
        name: 'mdi-card-bulleted-settings-outline'
      },
      {
        unicode: 'F0B76',
        name: 'mdi-card-outline'
      },
      {
        unicode: 'F11FF',
        name: 'mdi-card-plus'
      },
      {
        unicode: 'F1200',
        name: 'mdi-card-plus-outline'
      },
      {
        unicode: 'F1074',
        name: 'mdi-card-search'
      },
      {
        unicode: 'F1075',
        name: 'mdi-card-search-outline'
      },
      {
        unicode: 'F0B77',
        name: 'mdi-card-text'
      },
      {
        unicode: 'F0B78',
        name: 'mdi-card-text-outline'
      },
      {
        unicode: 'F0638',
        name: 'mdi-cards'
      },
      {
        unicode: 'F08CE',
        name: 'mdi-cards-club'
      },
      {
        unicode: 'F08CF',
        name: 'mdi-cards-diamond'
      },
      {
        unicode: 'F101D',
        name: 'mdi-cards-diamond-outline'
      },
      {
        unicode: 'F08D0',
        name: 'mdi-cards-heart'
      },
      {
        unicode: 'F0639',
        name: 'mdi-cards-outline'
      },
      {
        unicode: 'F063A',
        name: 'mdi-cards-playing-outline'
      },
      {
        unicode: 'F08D1',
        name: 'mdi-cards-spade'
      },
      {
        unicode: 'F06C7',
        name: 'mdi-cards-variant'
      },
      {
        unicode: 'F010F',
        name: 'mdi-carrot'
      },
      {
        unicode: 'F0110',
        name: 'mdi-cart'
      },
      {
        unicode: 'F0D66',
        name: 'mdi-cart-arrow-down'
      },
      {
        unicode: 'F0C4E',
        name: 'mdi-cart-arrow-right'
      },
      {
        unicode: 'F0D67',
        name: 'mdi-cart-arrow-up'
      },
      {
        unicode: 'F0D68',
        name: 'mdi-cart-minus'
      },
      {
        unicode: 'F066B',
        name: 'mdi-cart-off'
      },
      {
        unicode: 'F0111',
        name: 'mdi-cart-outline'
      },
      {
        unicode: 'F0112',
        name: 'mdi-cart-plus'
      },
      {
        unicode: 'F0D69',
        name: 'mdi-cart-remove'
      },
      {
        unicode: 'F0113',
        name: 'mdi-case-sensitive-alt'
      },
      {
        unicode: 'F0114',
        name: 'mdi-cash'
      },
      {
        unicode: 'F0115',
        name: 'mdi-cash-100'
      },
      {
        unicode: 'F0DB8',
        name: 'mdi-cash-marker'
      },
      {
        unicode: 'F1260',
        name: 'mdi-cash-minus'
      },
      {
        unicode: 'F0116',
        name: 'mdi-cash-multiple'
      },
      {
        unicode: 'F1261',
        name: 'mdi-cash-plus'
      },
      {
        unicode: 'F0A9C',
        name: 'mdi-cash-refund'
      },
      {
        unicode: 'F0CF4',
        name: 'mdi-cash-register'
      },
      {
        unicode: 'F1262',
        name: 'mdi-cash-remove'
      },
      {
        unicode: 'F1176',
        name: 'mdi-cash-usd'
      },
      {
        unicode: 'F0117',
        name: 'mdi-cash-usd-outline'
      },
      {
        unicode: 'F09D4',
        name: 'mdi-cassette'
      },
      {
        unicode: 'F0118',
        name: 'mdi-cast'
      },
      {
        unicode: 'F101E',
        name: 'mdi-cast-audio'
      },
      {
        unicode: 'F0119',
        name: 'mdi-cast-connected'
      },
      {
        unicode: 'F0E1D',
        name: 'mdi-cast-education'
      },
      {
        unicode: 'F078A',
        name: 'mdi-cast-off'
      },
      {
        unicode: 'F011A',
        name: 'mdi-castle'
      },
      {
        unicode: 'F011B',
        name: 'mdi-cat'
      },
      {
        unicode: 'F07AE',
        name: 'mdi-cctv'
      },
      {
        unicode: 'F0769',
        name: 'mdi-ceiling-light'
      },
      {
        unicode: 'F011C',
        name: 'mdi-cellphone'
      },
      {
        unicode: 'F011D',
        name: 'mdi-cellphone-android'
      },
      {
        unicode: 'F09D5',
        name: 'mdi-cellphone-arrow-down'
      },
      {
        unicode: 'F011E',
        name: 'mdi-cellphone-basic'
      },
      {
        unicode: 'F1397',
        name: 'mdi-cellphone-charging'
      },
      {
        unicode: 'F0951',
        name: 'mdi-cellphone-cog'
      },
      {
        unicode: 'F011F',
        name: 'mdi-cellphone-dock'
      },
      {
        unicode: 'F094D',
        name: 'mdi-cellphone-erase'
      },
      {
        unicode: 'F0F41',
        name: 'mdi-cellphone-information'
      },
      {
        unicode: 'F0120',
        name: 'mdi-cellphone-iphone'
      },
      {
        unicode: 'F094E',
        name: 'mdi-cellphone-key'
      },
      {
        unicode: 'F0121',
        name: 'mdi-cellphone-link'
      },
      {
        unicode: 'F0122',
        name: 'mdi-cellphone-link-off'
      },
      {
        unicode: 'F094F',
        name: 'mdi-cellphone-lock'
      },
      {
        unicode: 'F08D3',
        name: 'mdi-cellphone-message'
      },
      {
        unicode: 'F10D2',
        name: 'mdi-cellphone-message-off'
      },
      {
        unicode: 'F0E90',
        name: 'mdi-cellphone-nfc'
      },
      {
        unicode: 'F12D8',
        name: 'mdi-cellphone-nfc-off'
      },
      {
        unicode: 'F0950',
        name: 'mdi-cellphone-off'
      },
      {
        unicode: 'F101F',
        name: 'mdi-cellphone-play'
      },
      {
        unicode: 'F0A35',
        name: 'mdi-cellphone-screenshot'
      },
      {
        unicode: 'F0123',
        name: 'mdi-cellphone-settings'
      },
      {
        unicode: 'F0952',
        name: 'mdi-cellphone-sound'
      },
      {
        unicode: 'F08D2',
        name: 'mdi-cellphone-text'
      },
      {
        unicode: 'F0815',
        name: 'mdi-cellphone-wireless'
      },
      {
        unicode: 'F0CF5',
        name: 'mdi-celtic-cross'
      },
      {
        unicode: 'F111A',
        name: 'mdi-centos'
      },
      {
        unicode: 'F0124',
        name: 'mdi-certificate'
      },
      {
        unicode: 'F1188',
        name: 'mdi-certificate-outline'
      },
      {
        unicode: 'F0F48',
        name: 'mdi-chair-rolling'
      },
      {
        unicode: 'F0125',
        name: 'mdi-chair-school'
      },
      {
        unicode: 'F0C4F',
        name: 'mdi-charity'
      },
      {
        unicode: 'F0126',
        name: 'mdi-chart-arc'
      },
      {
        unicode: 'F0127',
        name: 'mdi-chart-areaspline'
      },
      {
        unicode: 'F0E91',
        name: 'mdi-chart-areaspline-variant'
      },
      {
        unicode: 'F0128',
        name: 'mdi-chart-bar'
      },
      {
        unicode: 'F076A',
        name: 'mdi-chart-bar-stacked'
      },
      {
        unicode: 'F0C50',
        name: 'mdi-chart-bell-curve'
      },
      {
        unicode: 'F0FA7',
        name: 'mdi-chart-bell-curve-cumulative'
      },
      {
        unicode: 'F05E3',
        name: 'mdi-chart-bubble'
      },
      {
        unicode: 'F07AF',
        name: 'mdi-chart-donut'
      },
      {
        unicode: 'F07B0',
        name: 'mdi-chart-donut-variant'
      },
      {
        unicode: 'F066C',
        name: 'mdi-chart-gantt'
      },
      {
        unicode: 'F0129',
        name: 'mdi-chart-histogram'
      },
      {
        unicode: 'F012A',
        name: 'mdi-chart-line'
      },
      {
        unicode: 'F076B',
        name: 'mdi-chart-line-stacked'
      },
      {
        unicode: 'F07B1',
        name: 'mdi-chart-line-variant'
      },
      {
        unicode: 'F08D4',
        name: 'mdi-chart-multiline'
      },
      {
        unicode: 'F1213',
        name: 'mdi-chart-multiple'
      },
      {
        unicode: 'F012B',
        name: 'mdi-chart-pie'
      },
      {
        unicode: 'F1380',
        name: 'mdi-chart-ppf'
      },
      {
        unicode: 'F11DF',
        name: 'mdi-chart-sankey'
      },
      {
        unicode: 'F11E0',
        name: 'mdi-chart-sankey-variant'
      },
      {
        unicode: 'F0E92',
        name: 'mdi-chart-scatter-plot'
      },
      {
        unicode: 'F066D',
        name: 'mdi-chart-scatter-plot-hexbin'
      },
      {
        unicode: 'F066E',
        name: 'mdi-chart-timeline'
      },
      {
        unicode: 'F0E93',
        name: 'mdi-chart-timeline-variant'
      },
      {
        unicode: 'F0E94',
        name: 'mdi-chart-tree'
      },
      {
        unicode: 'F0B79',
        name: 'mdi-chat'
      },
      {
        unicode: 'F0B7A',
        name: 'mdi-chat-alert'
      },
      {
        unicode: 'F12C9',
        name: 'mdi-chat-alert-outline'
      },
      {
        unicode: 'F1410',
        name: 'mdi-chat-minus'
      },
      {
        unicode: 'F1413',
        name: 'mdi-chat-minus-outline'
      },
      {
        unicode: 'F0EDE',
        name: 'mdi-chat-outline'
      },
      {
        unicode: 'F140F',
        name: 'mdi-chat-plus'
      },
      {
        unicode: 'F1412',
        name: 'mdi-chat-plus-outline'
      },
      {
        unicode: 'F0B7B',
        name: 'mdi-chat-processing'
      },
      {
        unicode: 'F12CA',
        name: 'mdi-chat-processing-outline'
      },
      {
        unicode: 'F1411',
        name: 'mdi-chat-remove'
      },
      {
        unicode: 'F1414',
        name: 'mdi-chat-remove-outline'
      },
      {
        unicode: 'F12D1',
        name: 'mdi-chat-sleep'
      },
      {
        unicode: 'F12D2',
        name: 'mdi-chat-sleep-outline'
      },
      {
        unicode: 'F012C',
        name: 'mdi-check'
      },
      {
        unicode: 'F012D',
        name: 'mdi-check-all'
      },
      {
        unicode: 'F0E1E',
        name: 'mdi-check-bold'
      },
      {
        unicode: 'F0C51',
        name: 'mdi-check-box-multiple-outline'
      },
      {
        unicode: 'F0C52',
        name: 'mdi-check-box-outline'
      },
      {
        unicode: 'F05E0',
        name: 'mdi-check-circle'
      },
      {
        unicode: 'F05E1',
        name: 'mdi-check-circle-outline'
      },
      {
        unicode: 'F0791',
        name: 'mdi-check-decagram'
      },
      {
        unicode: 'F0C53',
        name: 'mdi-check-network'
      },
      {
        unicode: 'F0C54',
        name: 'mdi-check-network-outline'
      },
      {
        unicode: 'F0855',
        name: 'mdi-check-outline'
      },
      {
        unicode: 'F0E1F',
        name: 'mdi-check-underline'
      },
      {
        unicode: 'F0E20',
        name: 'mdi-check-underline-circle'
      },
      {
        unicode: 'F0E21',
        name: 'mdi-check-underline-circle-outline'
      },
      {
        unicode: 'F0A9D',
        name: 'mdi-checkbook'
      },
      {
        unicode: 'F012E',
        name: 'mdi-checkbox-blank'
      },
      {
        unicode: 'F012F',
        name: 'mdi-checkbox-blank-circle'
      },
      {
        unicode: 'F0130',
        name: 'mdi-checkbox-blank-circle-outline'
      },
      {
        unicode: 'F12EC',
        name: 'mdi-checkbox-blank-off'
      },
      {
        unicode: 'F12ED',
        name: 'mdi-checkbox-blank-off-outline'
      },
      {
        unicode: 'F0131',
        name: 'mdi-checkbox-blank-outline'
      },
      {
        unicode: 'F0856',
        name: 'mdi-checkbox-intermediate'
      },
      {
        unicode: 'F0132',
        name: 'mdi-checkbox-marked'
      },
      {
        unicode: 'F0133',
        name: 'mdi-checkbox-marked-circle'
      },
      {
        unicode: 'F0134',
        name: 'mdi-checkbox-marked-circle-outline'
      },
      {
        unicode: 'F0135',
        name: 'mdi-checkbox-marked-outline'
      },
      {
        unicode: 'F0136',
        name: 'mdi-checkbox-multiple-blank'
      },
      {
        unicode: 'F063B',
        name: 'mdi-checkbox-multiple-blank-circle'
      },
      {
        unicode: 'F063C',
        name: 'mdi-checkbox-multiple-blank-circle-outline'
      },
      {
        unicode: 'F0137',
        name: 'mdi-checkbox-multiple-blank-outline'
      },
      {
        unicode: 'F0138',
        name: 'mdi-checkbox-multiple-marked'
      },
      {
        unicode: 'F063D',
        name: 'mdi-checkbox-multiple-marked-circle'
      },
      {
        unicode: 'F063E',
        name: 'mdi-checkbox-multiple-marked-circle-outline'
      },
      {
        unicode: 'F0139',
        name: 'mdi-checkbox-multiple-marked-outline'
      },
      {
        unicode: 'F013A',
        name: 'mdi-checkerboard'
      },
      {
        unicode: 'F1202',
        name: 'mdi-checkerboard-minus'
      },
      {
        unicode: 'F1201',
        name: 'mdi-checkerboard-plus'
      },
      {
        unicode: 'F1203',
        name: 'mdi-checkerboard-remove'
      },
      {
        unicode: 'F12B9',
        name: 'mdi-cheese'
      },
      {
        unicode: 'F13EE',
        name: 'mdi-cheese-off'
      },
      {
        unicode: 'F0B7C',
        name: 'mdi-chef-hat'
      },
      {
        unicode: 'F013B',
        name: 'mdi-chemical-weapon'
      },
      {
        unicode: 'F085C',
        name: 'mdi-chess-bishop'
      },
      {
        unicode: 'F0857',
        name: 'mdi-chess-king'
      },
      {
        unicode: 'F0858',
        name: 'mdi-chess-knight'
      },
      {
        unicode: 'F0859',
        name: 'mdi-chess-pawn'
      },
      {
        unicode: 'F085A',
        name: 'mdi-chess-queen'
      },
      {
        unicode: 'F085B',
        name: 'mdi-chess-rook'
      },
      {
        unicode: 'F013C',
        name: 'mdi-chevron-double-down'
      },
      {
        unicode: 'F013D',
        name: 'mdi-chevron-double-left'
      },
      {
        unicode: 'F013E',
        name: 'mdi-chevron-double-right'
      },
      {
        unicode: 'F013F',
        name: 'mdi-chevron-double-up'
      },
      {
        unicode: 'F0140',
        name: 'mdi-chevron-down'
      },
      {
        unicode: 'F09D6',
        name: 'mdi-chevron-down-box'
      },
      {
        unicode: 'F09D7',
        name: 'mdi-chevron-down-box-outline'
      },
      {
        unicode: 'F0B26',
        name: 'mdi-chevron-down-circle'
      },
      {
        unicode: 'F0B27',
        name: 'mdi-chevron-down-circle-outline'
      },
      {
        unicode: 'F0141',
        name: 'mdi-chevron-left'
      },
      {
        unicode: 'F09D8',
        name: 'mdi-chevron-left-box'
      },
      {
        unicode: 'F09D9',
        name: 'mdi-chevron-left-box-outline'
      },
      {
        unicode: 'F0B28',
        name: 'mdi-chevron-left-circle'
      },
      {
        unicode: 'F0B29',
        name: 'mdi-chevron-left-circle-outline'
      },
      {
        unicode: 'F0142',
        name: 'mdi-chevron-right'
      },
      {
        unicode: 'F09DA',
        name: 'mdi-chevron-right-box'
      },
      {
        unicode: 'F09DB',
        name: 'mdi-chevron-right-box-outline'
      },
      {
        unicode: 'F0B2A',
        name: 'mdi-chevron-right-circle'
      },
      {
        unicode: 'F0B2B',
        name: 'mdi-chevron-right-circle-outline'
      },
      {
        unicode: 'F0DB9',
        name: 'mdi-chevron-triple-down'
      },
      {
        unicode: 'F0DBA',
        name: 'mdi-chevron-triple-left'
      },
      {
        unicode: 'F0DBB',
        name: 'mdi-chevron-triple-right'
      },
      {
        unicode: 'F0DBC',
        name: 'mdi-chevron-triple-up'
      },
      {
        unicode: 'F0143',
        name: 'mdi-chevron-up'
      },
      {
        unicode: 'F09DC',
        name: 'mdi-chevron-up-box'
      },
      {
        unicode: 'F09DD',
        name: 'mdi-chevron-up-box-outline'
      },
      {
        unicode: 'F0B2C',
        name: 'mdi-chevron-up-circle'
      },
      {
        unicode: 'F0B2D',
        name: 'mdi-chevron-up-circle-outline'
      },
      {
        unicode: 'F07B2',
        name: 'mdi-chili-hot'
      },
      {
        unicode: 'F07B3',
        name: 'mdi-chili-medium'
      },
      {
        unicode: 'F07B4',
        name: 'mdi-chili-mild'
      },
      {
        unicode: 'F1467',
        name: 'mdi-chili-off'
      },
      {
        unicode: 'F061A',
        name: 'mdi-chip'
      },
      {
        unicode: 'F0953',
        name: 'mdi-christianity'
      },
      {
        unicode: 'F0CF6',
        name: 'mdi-christianity-outline'
      },
      {
        unicode: 'F0144',
        name: 'mdi-church'
      },
      {
        unicode: 'F1189',
        name: 'mdi-cigar'
      },
      {
        unicode: 'F141B',
        name: 'mdi-cigar-off'
      },
      {
        unicode: 'F0765',
        name: 'mdi-circle'
      },
      {
        unicode: 'F0E95',
        name: 'mdi-circle-double'
      },
      {
        unicode: 'F08D5',
        name: 'mdi-circle-edit-outline'
      },
      {
        unicode: 'F0E96',
        name: 'mdi-circle-expand'
      },
      {
        unicode: 'F1395',
        name: 'mdi-circle-half'
      },
      {
        unicode: 'F1396',
        name: 'mdi-circle-half-full'
      },
      {
        unicode: 'F09DE',
        name: 'mdi-circle-medium'
      },
      {
        unicode: 'F0B38',
        name: 'mdi-circle-multiple'
      },
      {
        unicode: 'F0695',
        name: 'mdi-circle-multiple-outline'
      },
      {
        unicode: 'F10D3',
        name: 'mdi-circle-off-outline'
      },
      {
        unicode: 'F0766',
        name: 'mdi-circle-outline'
      },
      {
        unicode: 'F0A9E',
        name: 'mdi-circle-slice-1'
      },
      {
        unicode: 'F0A9F',
        name: 'mdi-circle-slice-2'
      },
      {
        unicode: 'F0AA0',
        name: 'mdi-circle-slice-3'
      },
      {
        unicode: 'F0AA1',
        name: 'mdi-circle-slice-4'
      },
      {
        unicode: 'F0AA2',
        name: 'mdi-circle-slice-5'
      },
      {
        unicode: 'F0AA3',
        name: 'mdi-circle-slice-6'
      },
      {
        unicode: 'F0AA4',
        name: 'mdi-circle-slice-7'
      },
      {
        unicode: 'F0AA5',
        name: 'mdi-circle-slice-8'
      },
      {
        unicode: 'F09DF',
        name: 'mdi-circle-small'
      },
      {
        unicode: 'F0E22',
        name: 'mdi-circular-saw'
      },
      {
        unicode: 'F0146',
        name: 'mdi-city'
      },
      {
        unicode: 'F0A36',
        name: 'mdi-city-variant'
      },
      {
        unicode: 'F0A37',
        name: 'mdi-city-variant-outline'
      },
      {
        unicode: 'F0147',
        name: 'mdi-clipboard'
      },
      {
        unicode: 'F0148',
        name: 'mdi-clipboard-account'
      },
      {
        unicode: 'F0C55',
        name: 'mdi-clipboard-account-outline'
      },
      {
        unicode: 'F0149',
        name: 'mdi-clipboard-alert'
      },
      {
        unicode: 'F0CF7',
        name: 'mdi-clipboard-alert-outline'
      },
      {
        unicode: 'F014A',
        name: 'mdi-clipboard-arrow-down'
      },
      {
        unicode: 'F0C56',
        name: 'mdi-clipboard-arrow-down-outline'
      },
      {
        unicode: 'F014B',
        name: 'mdi-clipboard-arrow-left'
      },
      {
        unicode: 'F0CF8',
        name: 'mdi-clipboard-arrow-left-outline'
      },
      {
        unicode: 'F0CF9',
        name: 'mdi-clipboard-arrow-right'
      },
      {
        unicode: 'F0CFA',
        name: 'mdi-clipboard-arrow-right-outline'
      },
      {
        unicode: 'F0C57',
        name: 'mdi-clipboard-arrow-up'
      },
      {
        unicode: 'F0C58',
        name: 'mdi-clipboard-arrow-up-outline'
      },
      {
        unicode: 'F014E',
        name: 'mdi-clipboard-check'
      },
      {
        unicode: 'F1263',
        name: 'mdi-clipboard-check-multiple'
      },
      {
        unicode: 'F1264',
        name: 'mdi-clipboard-check-multiple-outline'
      },
      {
        unicode: 'F08A8',
        name: 'mdi-clipboard-check-outline'
      },
      {
        unicode: 'F1265',
        name: 'mdi-clipboard-file'
      },
      {
        unicode: 'F1266',
        name: 'mdi-clipboard-file-outline'
      },
      {
        unicode: 'F06C8',
        name: 'mdi-clipboard-flow'
      },
      {
        unicode: 'F1117',
        name: 'mdi-clipboard-flow-outline'
      },
      {
        unicode: 'F10D4',
        name: 'mdi-clipboard-list'
      },
      {
        unicode: 'F10D5',
        name: 'mdi-clipboard-list-outline'
      },
      {
        unicode: 'F1267',
        name: 'mdi-clipboard-multiple'
      },
      {
        unicode: 'F1268',
        name: 'mdi-clipboard-multiple-outline'
      },
      {
        unicode: 'F014C',
        name: 'mdi-clipboard-outline'
      },
      {
        unicode: 'F0C59',
        name: 'mdi-clipboard-play'
      },
      {
        unicode: 'F1269',
        name: 'mdi-clipboard-play-multiple'
      },
      {
        unicode: 'F126A',
        name: 'mdi-clipboard-play-multiple-outline'
      },
      {
        unicode: 'F0C5A',
        name: 'mdi-clipboard-play-outline'
      },
      {
        unicode: 'F0751',
        name: 'mdi-clipboard-plus'
      },
      {
        unicode: 'F131F',
        name: 'mdi-clipboard-plus-outline'
      },
      {
        unicode: 'F085D',
        name: 'mdi-clipboard-pulse'
      },
      {
        unicode: 'F085E',
        name: 'mdi-clipboard-pulse-outline'
      },
      {
        unicode: 'F014D',
        name: 'mdi-clipboard-text'
      },
      {
        unicode: 'F126B',
        name: 'mdi-clipboard-text-multiple'
      },
      {
        unicode: 'F126C',
        name: 'mdi-clipboard-text-multiple-outline'
      },
      {
        unicode: 'F0A38',
        name: 'mdi-clipboard-text-outline'
      },
      {
        unicode: 'F0C5B',
        name: 'mdi-clipboard-text-play'
      },
      {
        unicode: 'F0C5C',
        name: 'mdi-clipboard-text-play-outline'
      },
      {
        unicode: 'F014F',
        name: 'mdi-clippy'
      },
      {
        unicode: 'F0954',
        name: 'mdi-clock'
      },
      {
        unicode: 'F0955',
        name: 'mdi-clock-alert'
      },
      {
        unicode: 'F05CE',
        name: 'mdi-clock-alert-outline'
      },
      {
        unicode: 'F0FA8',
        name: 'mdi-clock-check'
      },
      {
        unicode: 'F0FA9',
        name: 'mdi-clock-check-outline'
      },
      {
        unicode: 'F0E97',
        name: 'mdi-clock-digital'
      },
      {
        unicode: 'F0151',
        name: 'mdi-clock-end'
      },
      {
        unicode: 'F0152',
        name: 'mdi-clock-fast'
      },
      {
        unicode: 'F0153',
        name: 'mdi-clock-in'
      },
      {
        unicode: 'F0154',
        name: 'mdi-clock-out'
      },
      {
        unicode: 'F0150',
        name: 'mdi-clock-outline'
      },
      {
        unicode: 'F0155',
        name: 'mdi-clock-start'
      },
      {
        unicode: 'F1446',
        name: 'mdi-clock-time-eight'
      },
      {
        unicode: 'F1452',
        name: 'mdi-clock-time-eight-outline'
      },
      {
        unicode: 'F1449',
        name: 'mdi-clock-time-eleven'
      },
      {
        unicode: 'F1455',
        name: 'mdi-clock-time-eleven-outline'
      },
      {
        unicode: 'F1443',
        name: 'mdi-clock-time-five'
      },
      {
        unicode: 'F144F',
        name: 'mdi-clock-time-five-outline'
      },
      {
        unicode: 'F1442',
        name: 'mdi-clock-time-four'
      },
      {
        unicode: 'F144E',
        name: 'mdi-clock-time-four-outline'
      },
      {
        unicode: 'F1447',
        name: 'mdi-clock-time-nine'
      },
      {
        unicode: 'F1453',
        name: 'mdi-clock-time-nine-outline'
      },
      {
        unicode: 'F143F',
        name: 'mdi-clock-time-one'
      },
      {
        unicode: 'F144B',
        name: 'mdi-clock-time-one-outline'
      },
      {
        unicode: 'F1445',
        name: 'mdi-clock-time-seven'
      },
      {
        unicode: 'F1451',
        name: 'mdi-clock-time-seven-outline'
      },
      {
        unicode: 'F1444',
        name: 'mdi-clock-time-six'
      },
      {
        unicode: 'F1450',
        name: 'mdi-clock-time-six-outline'
      },
      {
        unicode: 'F1448',
        name: 'mdi-clock-time-ten'
      },
      {
        unicode: 'F1454',
        name: 'mdi-clock-time-ten-outline'
      },
      {
        unicode: 'F1441',
        name: 'mdi-clock-time-three'
      },
      {
        unicode: 'F144D',
        name: 'mdi-clock-time-three-outline'
      },
      {
        unicode: 'F144A',
        name: 'mdi-clock-time-twelve'
      },
      {
        unicode: 'F1456',
        name: 'mdi-clock-time-twelve-outline'
      },
      {
        unicode: 'F1440',
        name: 'mdi-clock-time-two'
      },
      {
        unicode: 'F144C',
        name: 'mdi-clock-time-two-outline'
      },
      {
        unicode: 'F0156',
        name: 'mdi-close'
      },
      {
        unicode: 'F0157',
        name: 'mdi-close-box'
      },
      {
        unicode: 'F0C5D',
        name: 'mdi-close-box-multiple'
      },
      {
        unicode: 'F0C5E',
        name: 'mdi-close-box-multiple-outline'
      },
      {
        unicode: 'F0158',
        name: 'mdi-close-box-outline'
      },
      {
        unicode: 'F0159',
        name: 'mdi-close-circle'
      },
      {
        unicode: 'F062A',
        name: 'mdi-close-circle-multiple'
      },
      {
        unicode: 'F0883',
        name: 'mdi-close-circle-multiple-outline'
      },
      {
        unicode: 'F015A',
        name: 'mdi-close-circle-outline'
      },
      {
        unicode: 'F015B',
        name: 'mdi-close-network'
      },
      {
        unicode: 'F0C5F',
        name: 'mdi-close-network-outline'
      },
      {
        unicode: 'F015C',
        name: 'mdi-close-octagon'
      },
      {
        unicode: 'F015D',
        name: 'mdi-close-octagon-outline'
      },
      {
        unicode: 'F06C9',
        name: 'mdi-close-outline'
      },
      {
        unicode: 'F1398',
        name: 'mdi-close-thick'
      },
      {
        unicode: 'F015E',
        name: 'mdi-closed-caption'
      },
      {
        unicode: 'F0DBD',
        name: 'mdi-closed-caption-outline'
      },
      {
        unicode: 'F015F',
        name: 'mdi-cloud'
      },
      {
        unicode: 'F09E0',
        name: 'mdi-cloud-alert'
      },
      {
        unicode: 'F07B5',
        name: 'mdi-cloud-braces'
      },
      {
        unicode: 'F0160',
        name: 'mdi-cloud-check'
      },
      {
        unicode: 'F12CC',
        name: 'mdi-cloud-check-outline'
      },
      {
        unicode: 'F0161',
        name: 'mdi-cloud-circle'
      },
      {
        unicode: 'F0162',
        name: 'mdi-cloud-download'
      },
      {
        unicode: 'F0B7D',
        name: 'mdi-cloud-download-outline'
      },
      {
        unicode: 'F11F1',
        name: 'mdi-cloud-lock'
      },
      {
        unicode: 'F11F2',
        name: 'mdi-cloud-lock-outline'
      },
      {
        unicode: 'F0164',
        name: 'mdi-cloud-off-outline'
      },
      {
        unicode: 'F0163',
        name: 'mdi-cloud-outline'
      },
      {
        unicode: 'F0165',
        name: 'mdi-cloud-print'
      },
      {
        unicode: 'F0166',
        name: 'mdi-cloud-print-outline'
      },
      {
        unicode: 'F0A39',
        name: 'mdi-cloud-question'
      },
      {
        unicode: 'F052A',
        name: 'mdi-cloud-refresh'
      },
      {
        unicode: 'F0956',
        name: 'mdi-cloud-search'
      },
      {
        unicode: 'F0957',
        name: 'mdi-cloud-search-outline'
      },
      {
        unicode: 'F063F',
        name: 'mdi-cloud-sync'
      },
      {
        unicode: 'F12D6',
        name: 'mdi-cloud-sync-outline'
      },
      {
        unicode: 'F07B6',
        name: 'mdi-cloud-tags'
      },
      {
        unicode: 'F0167',
        name: 'mdi-cloud-upload'
      },
      {
        unicode: 'F0B7E',
        name: 'mdi-cloud-upload-outline'
      },
      {
        unicode: 'F0816',
        name: 'mdi-clover'
      },
      {
        unicode: 'F1020',
        name: 'mdi-coach-lamp'
      },
      {
        unicode: 'F109E',
        name: 'mdi-coat-rack'
      },
      {
        unicode: 'F0168',
        name: 'mdi-code-array'
      },
      {
        unicode: 'F0169',
        name: 'mdi-code-braces'
      },
      {
        unicode: 'F10D6',
        name: 'mdi-code-braces-box'
      },
      {
        unicode: 'F016A',
        name: 'mdi-code-brackets'
      },
      {
        unicode: 'F016B',
        name: 'mdi-code-equal'
      },
      {
        unicode: 'F016C',
        name: 'mdi-code-greater-than'
      },
      {
        unicode: 'F016D',
        name: 'mdi-code-greater-than-or-equal'
      },
      {
        unicode: 'F0626',
        name: 'mdi-code-json'
      },
      {
        unicode: 'F016E',
        name: 'mdi-code-less-than'
      },
      {
        unicode: 'F016F',
        name: 'mdi-code-less-than-or-equal'
      },
      {
        unicode: 'F0170',
        name: 'mdi-code-not-equal'
      },
      {
        unicode: 'F0171',
        name: 'mdi-code-not-equal-variant'
      },
      {
        unicode: 'F0172',
        name: 'mdi-code-parentheses'
      },
      {
        unicode: 'F10D7',
        name: 'mdi-code-parentheses-box'
      },
      {
        unicode: 'F0173',
        name: 'mdi-code-string'
      },
      {
        unicode: 'F0174',
        name: 'mdi-code-tags'
      },
      {
        unicode: 'F0694',
        name: 'mdi-code-tags-check'
      },
      {
        unicode: 'F0175',
        name: 'mdi-codepen'
      },
      {
        unicode: 'F0176',
        name: 'mdi-coffee'
      },
      {
        unicode: 'F109F',
        name: 'mdi-coffee-maker'
      },
      {
        unicode: 'F0FAA',
        name: 'mdi-coffee-off'
      },
      {
        unicode: 'F0FAB',
        name: 'mdi-coffee-off-outline'
      },
      {
        unicode: 'F06CA',
        name: 'mdi-coffee-outline'
      },
      {
        unicode: 'F0177',
        name: 'mdi-coffee-to-go'
      },
      {
        unicode: 'F130E',
        name: 'mdi-coffee-to-go-outline'
      },
      {
        unicode: 'F0B7F',
        name: 'mdi-coffin'
      },
      {
        unicode: 'F0493',
        name: 'mdi-cog'
      },
      {
        unicode: 'F0494',
        name: 'mdi-cog-box'
      },
      {
        unicode: 'F11DD',
        name: 'mdi-cog-clockwise'
      },
      {
        unicode: 'F11DE',
        name: 'mdi-cog-counterclockwise'
      },
      {
        unicode: 'F13CE',
        name: 'mdi-cog-off'
      },
      {
        unicode: 'F13CF',
        name: 'mdi-cog-off-outline'
      },
      {
        unicode: 'F08BB',
        name: 'mdi-cog-outline'
      },
      {
        unicode: 'F145E',
        name: 'mdi-cog-refresh'
      },
      {
        unicode: 'F145F',
        name: 'mdi-cog-refresh-outline'
      },
      {
        unicode: 'F1460',
        name: 'mdi-cog-sync'
      },
      {
        unicode: 'F1461',
        name: 'mdi-cog-sync-outline'
      },
      {
        unicode: 'F105B',
        name: 'mdi-cog-transfer'
      },
      {
        unicode: 'F105C',
        name: 'mdi-cog-transfer-outline'
      },
      {
        unicode: 'F08D6',
        name: 'mdi-cogs'
      },
      {
        unicode: 'F0640',
        name: 'mdi-collage'
      },
      {
        unicode: 'F0AA6',
        name: 'mdi-collapse-all'
      },
      {
        unicode: 'F0AA7',
        name: 'mdi-collapse-all-outline'
      },
      {
        unicode: 'F0179',
        name: 'mdi-color-helper'
      },
      {
        unicode: 'F0E23',
        name: 'mdi-comma'
      },
      {
        unicode: 'F0E2B',
        name: 'mdi-comma-box'
      },
      {
        unicode: 'F0E24',
        name: 'mdi-comma-box-outline'
      },
      {
        unicode: 'F0E25',
        name: 'mdi-comma-circle'
      },
      {
        unicode: 'F0E26',
        name: 'mdi-comma-circle-outline'
      },
      {
        unicode: 'F017A',
        name: 'mdi-comment'
      },
      {
        unicode: 'F017B',
        name: 'mdi-comment-account'
      },
      {
        unicode: 'F017C',
        name: 'mdi-comment-account-outline'
      },
      {
        unicode: 'F017D',
        name: 'mdi-comment-alert'
      },
      {
        unicode: 'F017E',
        name: 'mdi-comment-alert-outline'
      },
      {
        unicode: 'F09E1',
        name: 'mdi-comment-arrow-left'
      },
      {
        unicode: 'F09E2',
        name: 'mdi-comment-arrow-left-outline'
      },
      {
        unicode: 'F09E3',
        name: 'mdi-comment-arrow-right'
      },
      {
        unicode: 'F09E4',
        name: 'mdi-comment-arrow-right-outline'
      },
      {
        unicode: 'F017F',
        name: 'mdi-comment-check'
      },
      {
        unicode: 'F0180',
        name: 'mdi-comment-check-outline'
      },
      {
        unicode: 'F11BF',
        name: 'mdi-comment-edit'
      },
      {
        unicode: 'F12C4',
        name: 'mdi-comment-edit-outline'
      },
      {
        unicode: 'F0A3A',
        name: 'mdi-comment-eye'
      },
      {
        unicode: 'F0A3B',
        name: 'mdi-comment-eye-outline'
      },
      {
        unicode: 'F085F',
        name: 'mdi-comment-multiple'
      },
      {
        unicode: 'F0181',
        name: 'mdi-comment-multiple-outline'
      },
      {
        unicode: 'F0182',
        name: 'mdi-comment-outline'
      },
      {
        unicode: 'F09E5',
        name: 'mdi-comment-plus'
      },
      {
        unicode: 'F0183',
        name: 'mdi-comment-plus-outline'
      },
      {
        unicode: 'F0184',
        name: 'mdi-comment-processing'
      },
      {
        unicode: 'F0185',
        name: 'mdi-comment-processing-outline'
      },
      {
        unicode: 'F0817',
        name: 'mdi-comment-question'
      },
      {
        unicode: 'F0186',
        name: 'mdi-comment-question-outline'
      },
      {
        unicode: 'F1021',
        name: 'mdi-comment-quote'
      },
      {
        unicode: 'F1022',
        name: 'mdi-comment-quote-outline'
      },
      {
        unicode: 'F05DE',
        name: 'mdi-comment-remove'
      },
      {
        unicode: 'F0187',
        name: 'mdi-comment-remove-outline'
      },
      {
        unicode: 'F0A3C',
        name: 'mdi-comment-search'
      },
      {
        unicode: 'F0A3D',
        name: 'mdi-comment-search-outline'
      },
      {
        unicode: 'F0188',
        name: 'mdi-comment-text'
      },
      {
        unicode: 'F0860',
        name: 'mdi-comment-text-multiple'
      },
      {
        unicode: 'F0861',
        name: 'mdi-comment-text-multiple-outline'
      },
      {
        unicode: 'F0189',
        name: 'mdi-comment-text-outline'
      },
      {
        unicode: 'F018A',
        name: 'mdi-compare'
      },
      {
        unicode: 'F1492',
        name: 'mdi-compare-horizontal'
      },
      {
        unicode: 'F1493',
        name: 'mdi-compare-vertical'
      },
      {
        unicode: 'F018B',
        name: 'mdi-compass'
      },
      {
        unicode: 'F0B80',
        name: 'mdi-compass-off'
      },
      {
        unicode: 'F0B81',
        name: 'mdi-compass-off-outline'
      },
      {
        unicode: 'F018C',
        name: 'mdi-compass-outline'
      },
      {
        unicode: 'F1382',
        name: 'mdi-compass-rose'
      },
      {
        unicode: 'F10A0',
        name: 'mdi-concourse-ci'
      },
      {
        unicode: 'F018D',
        name: 'mdi-console'
      },
      {
        unicode: 'F07B7',
        name: 'mdi-console-line'
      },
      {
        unicode: 'F08A9',
        name: 'mdi-console-network'
      },
      {
        unicode: 'F0C60',
        name: 'mdi-console-network-outline'
      },
      {
        unicode: 'F10D8',
        name: 'mdi-consolidate'
      },
      {
        unicode: 'F0D6A',
        name: 'mdi-contactless-payment'
      },
      {
        unicode: 'F0321',
        name: 'mdi-contactless-payment-circle'
      },
      {
        unicode: 'F0408',
        name: 'mdi-contactless-payment-circle-outline'
      },
      {
        unicode: 'F06CB',
        name: 'mdi-contacts'
      },
      {
        unicode: 'F05B8',
        name: 'mdi-contacts-outline'
      },
      {
        unicode: 'F0A3E',
        name: 'mdi-contain'
      },
      {
        unicode: 'F0A3F',
        name: 'mdi-contain-end'
      },
      {
        unicode: 'F0A40',
        name: 'mdi-contain-start'
      },
      {
        unicode: 'F018F',
        name: 'mdi-content-copy'
      },
      {
        unicode: 'F0190',
        name: 'mdi-content-cut'
      },
      {
        unicode: 'F0191',
        name: 'mdi-content-duplicate'
      },
      {
        unicode: 'F0192',
        name: 'mdi-content-paste'
      },
      {
        unicode: 'F0193',
        name: 'mdi-content-save'
      },
      {
        unicode: 'F0F42',
        name: 'mdi-content-save-alert'
      },
      {
        unicode: 'F0F43',
        name: 'mdi-content-save-alert-outline'
      },
      {
        unicode: 'F0194',
        name: 'mdi-content-save-all'
      },
      {
        unicode: 'F0F44',
        name: 'mdi-content-save-all-outline'
      },
      {
        unicode: 'F145B',
        name: 'mdi-content-save-cog'
      },
      {
        unicode: 'F145C',
        name: 'mdi-content-save-cog-outline'
      },
      {
        unicode: 'F0CFB',
        name: 'mdi-content-save-edit'
      },
      {
        unicode: 'F0CFC',
        name: 'mdi-content-save-edit-outline'
      },
      {
        unicode: 'F0E27',
        name: 'mdi-content-save-move'
      },
      {
        unicode: 'F0E28',
        name: 'mdi-content-save-move-outline'
      },
      {
        unicode: 'F0818',
        name: 'mdi-content-save-outline'
      },
      {
        unicode: 'F061B',
        name: 'mdi-content-save-settings'
      },
      {
        unicode: 'F0B2E',
        name: 'mdi-content-save-settings-outline'
      },
      {
        unicode: 'F0195',
        name: 'mdi-contrast'
      },
      {
        unicode: 'F0196',
        name: 'mdi-contrast-box'
      },
      {
        unicode: 'F0197',
        name: 'mdi-contrast-circle'
      },
      {
        unicode: 'F0B82',
        name: 'mdi-controller-classic'
      },
      {
        unicode: 'F0B83',
        name: 'mdi-controller-classic-outline'
      },
      {
        unicode: 'F0198',
        name: 'mdi-cookie'
      },
      {
        unicode: 'F03C8',
        name: 'mdi-coolant-temperature'
      },
      {
        unicode: 'F05E6',
        name: 'mdi-copyright'
      },
      {
        unicode: 'F0958',
        name: 'mdi-cordova'
      },
      {
        unicode: 'F07B8',
        name: 'mdi-corn'
      },
      {
        unicode: 'F13EF',
        name: 'mdi-corn-off'
      },
      {
        unicode: 'F1479',
        name: 'mdi-cosine-wave'
      },
      {
        unicode: 'F0199',
        name: 'mdi-counter'
      },
      {
        unicode: 'F019A',
        name: 'mdi-cow'
      },
      {
        unicode: 'F0EDF',
        name: 'mdi-cpu-32-bit'
      },
      {
        unicode: 'F0EE0',
        name: 'mdi-cpu-64-bit'
      },
      {
        unicode: 'F0862',
        name: 'mdi-crane'
      },
      {
        unicode: 'F0674',
        name: 'mdi-creation'
      },
      {
        unicode: 'F0D6B',
        name: 'mdi-creative-commons'
      },
      {
        unicode: 'F0FEF',
        name: 'mdi-credit-card'
      },
      {
        unicode: 'F13D0',
        name: 'mdi-credit-card-check'
      },
      {
        unicode: 'F13D1',
        name: 'mdi-credit-card-check-outline'
      },
      {
        unicode: 'F0EE1',
        name: 'mdi-credit-card-clock'
      },
      {
        unicode: 'F0EE2',
        name: 'mdi-credit-card-clock-outline'
      },
      {
        unicode: 'F06A8',
        name: 'mdi-credit-card-marker'
      },
      {
        unicode: 'F0DBE',
        name: 'mdi-credit-card-marker-outline'
      },
      {
        unicode: 'F0FAC',
        name: 'mdi-credit-card-minus'
      },
      {
        unicode: 'F0FAD',
        name: 'mdi-credit-card-minus-outline'
      },
      {
        unicode: 'F0FF0',
        name: 'mdi-credit-card-multiple'
      },
      {
        unicode: 'F019C',
        name: 'mdi-credit-card-multiple-outline'
      },
      {
        unicode: 'F0FF1',
        name: 'mdi-credit-card-off'
      },
      {
        unicode: 'F05E4',
        name: 'mdi-credit-card-off-outline'
      },
      {
        unicode: 'F019B',
        name: 'mdi-credit-card-outline'
      },
      {
        unicode: 'F0FF2',
        name: 'mdi-credit-card-plus'
      },
      {
        unicode: 'F0676',
        name: 'mdi-credit-card-plus-outline'
      },
      {
        unicode: 'F0FF3',
        name: 'mdi-credit-card-refund'
      },
      {
        unicode: 'F0AA8',
        name: 'mdi-credit-card-refund-outline'
      },
      {
        unicode: 'F0FAE',
        name: 'mdi-credit-card-remove'
      },
      {
        unicode: 'F0FAF',
        name: 'mdi-credit-card-remove-outline'
      },
      {
        unicode: 'F0FF4',
        name: 'mdi-credit-card-scan'
      },
      {
        unicode: 'F019D',
        name: 'mdi-credit-card-scan-outline'
      },
      {
        unicode: 'F0FF5',
        name: 'mdi-credit-card-settings'
      },
      {
        unicode: 'F08D7',
        name: 'mdi-credit-card-settings-outline'
      },
      {
        unicode: 'F0802',
        name: 'mdi-credit-card-wireless'
      },
      {
        unicode: 'F057A',
        name: 'mdi-credit-card-wireless-off'
      },
      {
        unicode: 'F057B',
        name: 'mdi-credit-card-wireless-off-outline'
      },
      {
        unicode: 'F0D6C',
        name: 'mdi-credit-card-wireless-outline'
      },
      {
        unicode: 'F0D6D',
        name: 'mdi-cricket'
      },
      {
        unicode: 'F019E',
        name: 'mdi-crop'
      },
      {
        unicode: 'F019F',
        name: 'mdi-crop-free'
      },
      {
        unicode: 'F01A0',
        name: 'mdi-crop-landscape'
      },
      {
        unicode: 'F01A1',
        name: 'mdi-crop-portrait'
      },
      {
        unicode: 'F0696',
        name: 'mdi-crop-rotate'
      },
      {
        unicode: 'F01A2',
        name: 'mdi-crop-square'
      },
      {
        unicode: 'F01A3',
        name: 'mdi-crosshairs'
      },
      {
        unicode: 'F01A4',
        name: 'mdi-crosshairs-gps'
      },
      {
        unicode: 'F0F45',
        name: 'mdi-crosshairs-off'
      },
      {
        unicode: 'F1136',
        name: 'mdi-crosshairs-question'
      },
      {
        unicode: 'F01A5',
        name: 'mdi-crown'
      },
      {
        unicode: 'F11D0',
        name: 'mdi-crown-outline'
      },
      {
        unicode: 'F0959',
        name: 'mdi-cryengine'
      },
      {
        unicode: 'F0B2F',
        name: 'mdi-crystal-ball'
      },
      {
        unicode: 'F01A6',
        name: 'mdi-cube'
      },
      {
        unicode: 'F141C',
        name: 'mdi-cube-off'
      },
      {
        unicode: 'F141D',
        name: 'mdi-cube-off-outline'
      },
      {
        unicode: 'F01A7',
        name: 'mdi-cube-outline'
      },
      {
        unicode: 'F0B84',
        name: 'mdi-cube-scan'
      },
      {
        unicode: 'F01A8',
        name: 'mdi-cube-send'
      },
      {
        unicode: 'F01A9',
        name: 'mdi-cube-unfolded'
      },
      {
        unicode: 'F01AA',
        name: 'mdi-cup'
      },
      {
        unicode: 'F05E5',
        name: 'mdi-cup-off'
      },
      {
        unicode: 'F137D',
        name: 'mdi-cup-off-outline'
      },
      {
        unicode: 'F130F',
        name: 'mdi-cup-outline'
      },
      {
        unicode: 'F01AB',
        name: 'mdi-cup-water'
      },
      {
        unicode: 'F0F46',
        name: 'mdi-cupboard'
      },
      {
        unicode: 'F0F47',
        name: 'mdi-cupboard-outline'
      },
      {
        unicode: 'F095A',
        name: 'mdi-cupcake'
      },
      {
        unicode: 'F0863',
        name: 'mdi-curling'
      },
      {
        unicode: 'F0864',
        name: 'mdi-currency-bdt'
      },
      {
        unicode: 'F0B85',
        name: 'mdi-currency-brl'
      },
      {
        unicode: 'F01AC',
        name: 'mdi-currency-btc'
      },
      {
        unicode: 'F07BA',
        name: 'mdi-currency-cny'
      },
      {
        unicode: 'F07BB',
        name: 'mdi-currency-eth'
      },
      {
        unicode: 'F01AD',
        name: 'mdi-currency-eur'
      },
      {
        unicode: 'F1315',
        name: 'mdi-currency-eur-off'
      },
      {
        unicode: 'F01AE',
        name: 'mdi-currency-gbp'
      },
      {
        unicode: 'F0C61',
        name: 'mdi-currency-ils'
      },
      {
        unicode: 'F01AF',
        name: 'mdi-currency-inr'
      },
      {
        unicode: 'F07BC',
        name: 'mdi-currency-jpy'
      },
      {
        unicode: 'F07BD',
        name: 'mdi-currency-krw'
      },
      {
        unicode: 'F0865',
        name: 'mdi-currency-kzt'
      },
      {
        unicode: 'F01B0',
        name: 'mdi-currency-ngn'
      },
      {
        unicode: 'F09E6',
        name: 'mdi-currency-php'
      },
      {
        unicode: 'F0E9C',
        name: 'mdi-currency-rial'
      },
      {
        unicode: 'F01B1',
        name: 'mdi-currency-rub'
      },
      {
        unicode: 'F07BE',
        name: 'mdi-currency-sign'
      },
      {
        unicode: 'F01B2',
        name: 'mdi-currency-try'
      },
      {
        unicode: 'F07BF',
        name: 'mdi-currency-twd'
      },
      {
        unicode: 'F01C1',
        name: 'mdi-currency-usd'
      },
      {
        unicode: 'F116B',
        name: 'mdi-currency-usd-circle'
      },
      {
        unicode: 'F0178',
        name: 'mdi-currency-usd-circle-outline'
      },
      {
        unicode: 'F067A',
        name: 'mdi-currency-usd-off'
      },
      {
        unicode: 'F1480',
        name: 'mdi-current-ac'
      },
      {
        unicode: 'F095C',
        name: 'mdi-current-dc'
      },
      {
        unicode: 'F01C0',
        name: 'mdi-cursor-default'
      },
      {
        unicode: 'F0CFD',
        name: 'mdi-cursor-default-click'
      },
      {
        unicode: 'F0CFE',
        name: 'mdi-cursor-default-click-outline'
      },
      {
        unicode: 'F1127',
        name: 'mdi-cursor-default-gesture'
      },
      {
        unicode: 'F1128',
        name: 'mdi-cursor-default-gesture-outline'
      },
      {
        unicode: 'F01BF',
        name: 'mdi-cursor-default-outline'
      },
      {
        unicode: 'F01BE',
        name: 'mdi-cursor-move'
      },
      {
        unicode: 'F01BD',
        name: 'mdi-cursor-pointer'
      },
      {
        unicode: 'F05E7',
        name: 'mdi-cursor-text'
      },
      {
        unicode: 'F01BC',
        name: 'mdi-database'
      },
      {
        unicode: 'F0AA9',
        name: 'mdi-database-check'
      },
      {
        unicode: 'F0B86',
        name: 'mdi-database-edit'
      },
      {
        unicode: 'F095E',
        name: 'mdi-database-export'
      },
      {
        unicode: 'F095D',
        name: 'mdi-database-import'
      },
      {
        unicode: 'F0AAA',
        name: 'mdi-database-lock'
      },
      {
        unicode: 'F12F6',
        name: 'mdi-database-marker'
      },
      {
        unicode: 'F01BB',
        name: 'mdi-database-minus'
      },
      {
        unicode: 'F01BA',
        name: 'mdi-database-plus'
      },
      {
        unicode: 'F05C2',
        name: 'mdi-database-refresh'
      },
      {
        unicode: 'F0D00',
        name: 'mdi-database-remove'
      },
      {
        unicode: 'F0866',
        name: 'mdi-database-search'
      },
      {
        unicode: 'F0D01',
        name: 'mdi-database-settings'
      },
      {
        unicode: 'F0CFF',
        name: 'mdi-database-sync'
      },
      {
        unicode: 'F08D8',
        name: 'mdi-death-star'
      },
      {
        unicode: 'F08D9',
        name: 'mdi-death-star-variant'
      },
      {
        unicode: 'F0B87',
        name: 'mdi-deathly-hallows'
      },
      {
        unicode: 'F08DA',
        name: 'mdi-debian'
      },
      {
        unicode: 'F01B9',
        name: 'mdi-debug-step-into'
      },
      {
        unicode: 'F01B8',
        name: 'mdi-debug-step-out'
      },
      {
        unicode: 'F01B7',
        name: 'mdi-debug-step-over'
      },
      {
        unicode: 'F076C',
        name: 'mdi-decagram'
      },
      {
        unicode: 'F076D',
        name: 'mdi-decagram-outline'
      },
      {
        unicode: 'F10A1',
        name: 'mdi-decimal'
      },
      {
        unicode: 'F10A2',
        name: 'mdi-decimal-comma'
      },
      {
        unicode: 'F10A3',
        name: 'mdi-decimal-comma-decrease'
      },
      {
        unicode: 'F10A4',
        name: 'mdi-decimal-comma-increase'
      },
      {
        unicode: 'F01B6',
        name: 'mdi-decimal-decrease'
      },
      {
        unicode: 'F01B5',
        name: 'mdi-decimal-increase'
      },
      {
        unicode: 'F01B4',
        name: 'mdi-delete'
      },
      {
        unicode: 'F10A5',
        name: 'mdi-delete-alert'
      },
      {
        unicode: 'F10A6',
        name: 'mdi-delete-alert-outline'
      },
      {
        unicode: 'F0683',
        name: 'mdi-delete-circle'
      },
      {
        unicode: 'F0B88',
        name: 'mdi-delete-circle-outline'
      },
      {
        unicode: 'F06CC',
        name: 'mdi-delete-empty'
      },
      {
        unicode: 'F0E9D',
        name: 'mdi-delete-empty-outline'
      },
      {
        unicode: 'F05E8',
        name: 'mdi-delete-forever'
      },
      {
        unicode: 'F0B89',
        name: 'mdi-delete-forever-outline'
      },
      {
        unicode: 'F10A7',
        name: 'mdi-delete-off'
      },
      {
        unicode: 'F10A8',
        name: 'mdi-delete-off-outline'
      },
      {
        unicode: 'F09E7',
        name: 'mdi-delete-outline'
      },
      {
        unicode: 'F0819',
        name: 'mdi-delete-restore'
      },
      {
        unicode: 'F05E9',
        name: 'mdi-delete-sweep'
      },
      {
        unicode: 'F0C62',
        name: 'mdi-delete-sweep-outline'
      },
      {
        unicode: 'F01B3',
        name: 'mdi-delete-variant'
      },
      {
        unicode: 'F01C2',
        name: 'mdi-delta'
      },
      {
        unicode: 'F1239',
        name: 'mdi-desk'
      },
      {
        unicode: 'F095F',
        name: 'mdi-desk-lamp'
      },
      {
        unicode: 'F01C3',
        name: 'mdi-deskphone'
      },
      {
        unicode: 'F07C0',
        name: 'mdi-desktop-classic'
      },
      {
        unicode: 'F01C4',
        name: 'mdi-desktop-mac'
      },
      {
        unicode: 'F09E8',
        name: 'mdi-desktop-mac-dashboard'
      },
      {
        unicode: 'F01C5',
        name: 'mdi-desktop-tower'
      },
      {
        unicode: 'F0AAB',
        name: 'mdi-desktop-tower-monitor'
      },
      {
        unicode: 'F01C6',
        name: 'mdi-details'
      },
      {
        unicode: 'F0D6E',
        name: 'mdi-dev-to'
      },
      {
        unicode: 'F0697',
        name: 'mdi-developer-board'
      },
      {
        unicode: 'F01C7',
        name: 'mdi-deviantart'
      },
      {
        unicode: 'F0FB0',
        name: 'mdi-devices'
      },
      {
        unicode: 'F1126',
        name: 'mdi-diabetes'
      },
      {
        unicode: 'F061C',
        name: 'mdi-dialpad'
      },
      {
        unicode: 'F0C63',
        name: 'mdi-diameter'
      },
      {
        unicode: 'F0C64',
        name: 'mdi-diameter-outline'
      },
      {
        unicode: 'F0C65',
        name: 'mdi-diameter-variant'
      },
      {
        unicode: 'F0B8A',
        name: 'mdi-diamond'
      },
      {
        unicode: 'F0B8B',
        name: 'mdi-diamond-outline'
      },
      {
        unicode: 'F01C8',
        name: 'mdi-diamond-stone'
      },
      {
        unicode: 'F01CA',
        name: 'mdi-dice-1'
      },
      {
        unicode: 'F114A',
        name: 'mdi-dice-1-outline'
      },
      {
        unicode: 'F01CB',
        name: 'mdi-dice-2'
      },
      {
        unicode: 'F114B',
        name: 'mdi-dice-2-outline'
      },
      {
        unicode: 'F01CC',
        name: 'mdi-dice-3'
      },
      {
        unicode: 'F114C',
        name: 'mdi-dice-3-outline'
      },
      {
        unicode: 'F01CD',
        name: 'mdi-dice-4'
      },
      {
        unicode: 'F114D',
        name: 'mdi-dice-4-outline'
      },
      {
        unicode: 'F01CE',
        name: 'mdi-dice-5'
      },
      {
        unicode: 'F114E',
        name: 'mdi-dice-5-outline'
      },
      {
        unicode: 'F01CF',
        name: 'mdi-dice-6'
      },
      {
        unicode: 'F114F',
        name: 'mdi-dice-6-outline'
      },
      {
        unicode: 'F1153',
        name: 'mdi-dice-d10'
      },
      {
        unicode: 'F076F',
        name: 'mdi-dice-d10-outline'
      },
      {
        unicode: 'F1154',
        name: 'mdi-dice-d12'
      },
      {
        unicode: 'F0867',
        name: 'mdi-dice-d12-outline'
      },
      {
        unicode: 'F1155',
        name: 'mdi-dice-d20'
      },
      {
        unicode: 'F05EA',
        name: 'mdi-dice-d20-outline'
      },
      {
        unicode: 'F1150',
        name: 'mdi-dice-d4'
      },
      {
        unicode: 'F05EB',
        name: 'mdi-dice-d4-outline'
      },
      {
        unicode: 'F1151',
        name: 'mdi-dice-d6'
      },
      {
        unicode: 'F05ED',
        name: 'mdi-dice-d6-outline'
      },
      {
        unicode: 'F1152',
        name: 'mdi-dice-d8'
      },
      {
        unicode: 'F05EC',
        name: 'mdi-dice-d8-outline'
      },
      {
        unicode: 'F076E',
        name: 'mdi-dice-multiple'
      },
      {
        unicode: 'F1156',
        name: 'mdi-dice-multiple-outline'
      },
      {
        unicode: 'F1237',
        name: 'mdi-digital-ocean'
      },
      {
        unicode: 'F07C1',
        name: 'mdi-dip-switch'
      },
      {
        unicode: 'F01D0',
        name: 'mdi-directions'
      },
      {
        unicode: 'F0641',
        name: 'mdi-directions-fork'
      },
      {
        unicode: 'F05EE',
        name: 'mdi-disc'
      },
      {
        unicode: 'F01D1',
        name: 'mdi-disc-alert'
      },
      {
        unicode: 'F0960',
        name: 'mdi-disc-player'
      },
      {
        unicode: 'F066F',
        name: 'mdi-discord'
      },
      {
        unicode: 'F0AAC',
        name: 'mdi-dishwasher'
      },
      {
        unicode: 'F11B8',
        name: 'mdi-dishwasher-alert'
      },
      {
        unicode: 'F11B9',
        name: 'mdi-dishwasher-off'
      },
      {
        unicode: 'F01D2',
        name: 'mdi-disqus'
      },
      {
        unicode: 'F11C9',
        name: 'mdi-distribute-horizontal-center'
      },
      {
        unicode: 'F11C8',
        name: 'mdi-distribute-horizontal-left'
      },
      {
        unicode: 'F11CA',
        name: 'mdi-distribute-horizontal-right'
      },
      {
        unicode: 'F11CB',
        name: 'mdi-distribute-vertical-bottom'
      },
      {
        unicode: 'F11CC',
        name: 'mdi-distribute-vertical-center'
      },
      {
        unicode: 'F11CD',
        name: 'mdi-distribute-vertical-top'
      },
      {
        unicode: 'F0DBF',
        name: 'mdi-diving-flippers'
      },
      {
        unicode: 'F0DC0',
        name: 'mdi-diving-helmet'
      },
      {
        unicode: 'F0DC1',
        name: 'mdi-diving-scuba'
      },
      {
        unicode: 'F0DC2',
        name: 'mdi-diving-scuba-flag'
      },
      {
        unicode: 'F0DC3',
        name: 'mdi-diving-scuba-tank'
      },
      {
        unicode: 'F0DC4',
        name: 'mdi-diving-scuba-tank-multiple'
      },
      {
        unicode: 'F0DC5',
        name: 'mdi-diving-snorkel'
      },
      {
        unicode: 'F01D4',
        name: 'mdi-division'
      },
      {
        unicode: 'F01D5',
        name: 'mdi-division-box'
      },
      {
        unicode: 'F0A41',
        name: 'mdi-dlna'
      },
      {
        unicode: 'F0684',
        name: 'mdi-dna'
      },
      {
        unicode: 'F01D6',
        name: 'mdi-dns'
      },
      {
        unicode: 'F0B8C',
        name: 'mdi-dns-outline'
      },
      {
        unicode: 'F0698',
        name: 'mdi-do-not-disturb'
      },
      {
        unicode: 'F0699',
        name: 'mdi-do-not-disturb-off'
      },
      {
        unicode: 'F10A9',
        name: 'mdi-dock-bottom'
      },
      {
        unicode: 'F10AA',
        name: 'mdi-dock-left'
      },
      {
        unicode: 'F10AB',
        name: 'mdi-dock-right'
      },
      {
        unicode: 'F10AC',
        name: 'mdi-dock-window'
      },
      {
        unicode: 'F0868',
        name: 'mdi-docker'
      },
      {
        unicode: 'F0A42',
        name: 'mdi-doctor'
      },
      {
        unicode: 'F0A43',
        name: 'mdi-dog'
      },
      {
        unicode: 'F0AAD',
        name: 'mdi-dog-service'
      },
      {
        unicode: 'F0A44',
        name: 'mdi-dog-side'
      },
      {
        unicode: 'F06B3',
        name: 'mdi-dolby'
      },
      {
        unicode: 'F0E9E',
        name: 'mdi-dolly'
      },
      {
        unicode: 'F01D7',
        name: 'mdi-domain'
      },
      {
        unicode: 'F0D6F',
        name: 'mdi-domain-off'
      },
      {
        unicode: 'F10AD',
        name: 'mdi-domain-plus'
      },
      {
        unicode: 'F10AE',
        name: 'mdi-domain-remove'
      },
      {
        unicode: 'F141E',
        name: 'mdi-dome-light'
      },
      {
        unicode: 'F1023',
        name: 'mdi-domino-mask'
      },
      {
        unicode: 'F07C2',
        name: 'mdi-donkey'
      },
      {
        unicode: 'F081A',
        name: 'mdi-door'
      },
      {
        unicode: 'F081B',
        name: 'mdi-door-closed'
      },
      {
        unicode: 'F10AF',
        name: 'mdi-door-closed-lock'
      },
      {
        unicode: 'F081C',
        name: 'mdi-door-open'
      },
      {
        unicode: 'F12E6',
        name: 'mdi-doorbell'
      },
      {
        unicode: 'F0869',
        name: 'mdi-doorbell-video'
      },
      {
        unicode: 'F0AAE',
        name: 'mdi-dot-net'
      },
      {
        unicode: 'F01D8',
        name: 'mdi-dots-horizontal'
      },
      {
        unicode: 'F07C3',
        name: 'mdi-dots-horizontal-circle'
      },
      {
        unicode: 'F0B8D',
        name: 'mdi-dots-horizontal-circle-outline'
      },
      {
        unicode: 'F01D9',
        name: 'mdi-dots-vertical'
      },
      {
        unicode: 'F07C4',
        name: 'mdi-dots-vertical-circle'
      },
      {
        unicode: 'F0B8E',
        name: 'mdi-dots-vertical-circle-outline'
      },
      {
        unicode: 'F069A',
        name: 'mdi-douban'
      },
      {
        unicode: 'F01DA',
        name: 'mdi-download'
      },
      {
        unicode: 'F1462',
        name: 'mdi-download-box'
      },
      {
        unicode: 'F1463',
        name: 'mdi-download-box-outline'
      },
      {
        unicode: 'F1464',
        name: 'mdi-download-circle'
      },
      {
        unicode: 'F1465',
        name: 'mdi-download-circle-outline'
      },
      {
        unicode: 'F1320',
        name: 'mdi-download-lock'
      },
      {
        unicode: 'F1321',
        name: 'mdi-download-lock-outline'
      },
      {
        unicode: 'F09E9',
        name: 'mdi-download-multiple'
      },
      {
        unicode: 'F06F4',
        name: 'mdi-download-network'
      },
      {
        unicode: 'F0C66',
        name: 'mdi-download-network-outline'
      },
      {
        unicode: 'F10B0',
        name: 'mdi-download-off'
      },
      {
        unicode: 'F10B1',
        name: 'mdi-download-off-outline'
      },
      {
        unicode: 'F0B8F',
        name: 'mdi-download-outline'
      },
      {
        unicode: 'F01DB',
        name: 'mdi-drag'
      },
      {
        unicode: 'F01DC',
        name: 'mdi-drag-horizontal'
      },
      {
        unicode: 'F12F0',
        name: 'mdi-drag-horizontal-variant'
      },
      {
        unicode: 'F0B90',
        name: 'mdi-drag-variant'
      },
      {
        unicode: 'F01DD',
        name: 'mdi-drag-vertical'
      },
      {
        unicode: 'F12F1',
        name: 'mdi-drag-vertical-variant'
      },
      {
        unicode: 'F0D02',
        name: 'mdi-drama-masks'
      },
      {
        unicode: 'F0F49',
        name: 'mdi-draw'
      },
      {
        unicode: 'F01DE',
        name: 'mdi-drawing'
      },
      {
        unicode: 'F01DF',
        name: 'mdi-drawing-box'
      },
      {
        unicode: 'F0F4A',
        name: 'mdi-dresser'
      },
      {
        unicode: 'F0F4B',
        name: 'mdi-dresser-outline'
      },
      {
        unicode: 'F01E2',
        name: 'mdi-drone'
      },
      {
        unicode: 'F01E3',
        name: 'mdi-dropbox'
      },
      {
        unicode: 'F01E4',
        name: 'mdi-drupal'
      },
      {
        unicode: 'F01E5',
        name: 'mdi-duck'
      },
      {
        unicode: 'F01E6',
        name: 'mdi-dumbbell'
      },
      {
        unicode: 'F0C67',
        name: 'mdi-dump-truck'
      },
      {
        unicode: 'F07C5',
        name: 'mdi-ear-hearing'
      },
      {
        unicode: 'F0A45',
        name: 'mdi-ear-hearing-off'
      },
      {
        unicode: 'F01E7',
        name: 'mdi-earth'
      },
      {
        unicode: 'F1311',
        name: 'mdi-earth-arrow-right'
      },
      {
        unicode: 'F06CD',
        name: 'mdi-earth-box'
      },
      {
        unicode: 'F1407',
        name: 'mdi-earth-box-minus'
      },
      {
        unicode: 'F06CE',
        name: 'mdi-earth-box-off'
      },
      {
        unicode: 'F1406',
        name: 'mdi-earth-box-plus'
      },
      {
        unicode: 'F1408',
        name: 'mdi-earth-box-remove'
      },
      {
        unicode: 'F1404',
        name: 'mdi-earth-minus'
      },
      {
        unicode: 'F01E8',
        name: 'mdi-earth-off'
      },
      {
        unicode: 'F1403',
        name: 'mdi-earth-plus'
      },
      {
        unicode: 'F1405',
        name: 'mdi-earth-remove'
      },
      {
        unicode: 'F0AAF',
        name: 'mdi-egg'
      },
      {
        unicode: 'F0AB0',
        name: 'mdi-egg-easter'
      },
      {
        unicode: 'F13F0',
        name: 'mdi-egg-off'
      },
      {
        unicode: 'F13F1',
        name: 'mdi-egg-off-outline'
      },
      {
        unicode: 'F13F2',
        name: 'mdi-egg-outline'
      },
      {
        unicode: 'F09EA',
        name: 'mdi-eight-track'
      },
      {
        unicode: 'F01EA',
        name: 'mdi-eject'
      },
      {
        unicode: 'F0B91',
        name: 'mdi-eject-outline'
      },
      {
        unicode: 'F0E9F',
        name: 'mdi-electric-switch'
      },
      {
        unicode: 'F10D9',
        name: 'mdi-electric-switch-closed'
      },
      {
        unicode: 'F1024',
        name: 'mdi-electron-framework'
      },
      {
        unicode: 'F07C6',
        name: 'mdi-elephant'
      },
      {
        unicode: 'F01EB',
        name: 'mdi-elevation-decline'
      },
      {
        unicode: 'F01EC',
        name: 'mdi-elevation-rise'
      },
      {
        unicode: 'F01ED',
        name: 'mdi-elevator'
      },
      {
        unicode: 'F12C2',
        name: 'mdi-elevator-down'
      },
      {
        unicode: 'F1381',
        name: 'mdi-elevator-passenger'
      },
      {
        unicode: 'F12C1',
        name: 'mdi-elevator-up'
      },
      {
        unicode: 'F0EA0',
        name: 'mdi-ellipse'
      },
      {
        unicode: 'F0EA1',
        name: 'mdi-ellipse-outline'
      },
      {
        unicode: 'F01EE',
        name: 'mdi-email'
      },
      {
        unicode: 'F06CF',
        name: 'mdi-email-alert'
      },
      {
        unicode: 'F0D42',
        name: 'mdi-email-alert-outline'
      },
      {
        unicode: 'F0D03',
        name: 'mdi-email-box'
      },
      {
        unicode: 'F0AB1',
        name: 'mdi-email-check'
      },
      {
        unicode: 'F0AB2',
        name: 'mdi-email-check-outline'
      },
      {
        unicode: 'F0EE3',
        name: 'mdi-email-edit'
      },
      {
        unicode: 'F0EE4',
        name: 'mdi-email-edit-outline'
      },
      {
        unicode: 'F01F1',
        name: 'mdi-email-lock'
      },
      {
        unicode: 'F0B92',
        name: 'mdi-email-mark-as-unread'
      },
      {
        unicode: 'F0EE5',
        name: 'mdi-email-minus'
      },
      {
        unicode: 'F0EE6',
        name: 'mdi-email-minus-outline'
      },
      {
        unicode: 'F0EE7',
        name: 'mdi-email-multiple'
      },
      {
        unicode: 'F0EE8',
        name: 'mdi-email-multiple-outline'
      },
      {
        unicode: 'F0FB1',
        name: 'mdi-email-newsletter'
      },
      {
        unicode: 'F13E3',
        name: 'mdi-email-off'
      },
      {
        unicode: 'F13E4',
        name: 'mdi-email-off-outline'
      },
      {
        unicode: 'F01EF',
        name: 'mdi-email-open'
      },
      {
        unicode: 'F0EE9',
        name: 'mdi-email-open-multiple'
      },
      {
        unicode: 'F0EEA',
        name: 'mdi-email-open-multiple-outline'
      },
      {
        unicode: 'F05EF',
        name: 'mdi-email-open-outline'
      },
      {
        unicode: 'F01F0',
        name: 'mdi-email-outline'
      },
      {
        unicode: 'F09EB',
        name: 'mdi-email-plus'
      },
      {
        unicode: 'F09EC',
        name: 'mdi-email-plus-outline'
      },
      {
        unicode: 'F10DA',
        name: 'mdi-email-receive'
      },
      {
        unicode: 'F10DB',
        name: 'mdi-email-receive-outline'
      },
      {
        unicode: 'F0961',
        name: 'mdi-email-search'
      },
      {
        unicode: 'F0962',
        name: 'mdi-email-search-outline'
      },
      {
        unicode: 'F10DC',
        name: 'mdi-email-send'
      },
      {
        unicode: 'F10DD',
        name: 'mdi-email-send-outline'
      },
      {
        unicode: 'F12C7',
        name: 'mdi-email-sync'
      },
      {
        unicode: 'F12C8',
        name: 'mdi-email-sync-outline'
      },
      {
        unicode: 'F05F0',
        name: 'mdi-email-variant'
      },
      {
        unicode: 'F0B30',
        name: 'mdi-ember'
      },
      {
        unicode: 'F06B4',
        name: 'mdi-emby'
      },
      {
        unicode: 'F0C68',
        name: 'mdi-emoticon'
      },
      {
        unicode: 'F0C69',
        name: 'mdi-emoticon-angry'
      },
      {
        unicode: 'F0C6A',
        name: 'mdi-emoticon-angry-outline'
      },
      {
        unicode: 'F10DE',
        name: 'mdi-emoticon-confused'
      },
      {
        unicode: 'F10DF',
        name: 'mdi-emoticon-confused-outline'
      },
      {
        unicode: 'F0C6B',
        name: 'mdi-emoticon-cool'
      },
      {
        unicode: 'F01F3',
        name: 'mdi-emoticon-cool-outline'
      },
      {
        unicode: 'F0C6C',
        name: 'mdi-emoticon-cry'
      },
      {
        unicode: 'F0C6D',
        name: 'mdi-emoticon-cry-outline'
      },
      {
        unicode: 'F0C6E',
        name: 'mdi-emoticon-dead'
      },
      {
        unicode: 'F069B',
        name: 'mdi-emoticon-dead-outline'
      },
      {
        unicode: 'F0C6F',
        name: 'mdi-emoticon-devil'
      },
      {
        unicode: 'F01F4',
        name: 'mdi-emoticon-devil-outline'
      },
      {
        unicode: 'F0C70',
        name: 'mdi-emoticon-excited'
      },
      {
        unicode: 'F069C',
        name: 'mdi-emoticon-excited-outline'
      },
      {
        unicode: 'F0F4C',
        name: 'mdi-emoticon-frown'
      },
      {
        unicode: 'F0F4D',
        name: 'mdi-emoticon-frown-outline'
      },
      {
        unicode: 'F0C71',
        name: 'mdi-emoticon-happy'
      },
      {
        unicode: 'F01F5',
        name: 'mdi-emoticon-happy-outline'
      },
      {
        unicode: 'F0C72',
        name: 'mdi-emoticon-kiss'
      },
      {
        unicode: 'F0C73',
        name: 'mdi-emoticon-kiss-outline'
      },
      {
        unicode: 'F1214',
        name: 'mdi-emoticon-lol'
      },
      {
        unicode: 'F1215',
        name: 'mdi-emoticon-lol-outline'
      },
      {
        unicode: 'F0C74',
        name: 'mdi-emoticon-neutral'
      },
      {
        unicode: 'F01F6',
        name: 'mdi-emoticon-neutral-outline'
      },
      {
        unicode: 'F01F2',
        name: 'mdi-emoticon-outline'
      },
      {
        unicode: 'F01F7',
        name: 'mdi-emoticon-poop'
      },
      {
        unicode: 'F0C75',
        name: 'mdi-emoticon-poop-outline'
      },
      {
        unicode: 'F0C76',
        name: 'mdi-emoticon-sad'
      },
      {
        unicode: 'F01F8',
        name: 'mdi-emoticon-sad-outline'
      },
      {
        unicode: 'F01F9',
        name: 'mdi-emoticon-tongue'
      },
      {
        unicode: 'F0C77',
        name: 'mdi-emoticon-tongue-outline'
      },
      {
        unicode: 'F0C78',
        name: 'mdi-emoticon-wink'
      },
      {
        unicode: 'F0C79',
        name: 'mdi-emoticon-wink-outline'
      },
      {
        unicode: 'F01FA',
        name: 'mdi-engine'
      },
      {
        unicode: 'F0A46',
        name: 'mdi-engine-off'
      },
      {
        unicode: 'F0A47',
        name: 'mdi-engine-off-outline'
      },
      {
        unicode: 'F01FB',
        name: 'mdi-engine-outline'
      },
      {
        unicode: 'F10E0',
        name: 'mdi-epsilon'
      },
      {
        unicode: 'F01FC',
        name: 'mdi-equal'
      },
      {
        unicode: 'F01FD',
        name: 'mdi-equal-box'
      },
      {
        unicode: 'F0EA2',
        name: 'mdi-equalizer'
      },
      {
        unicode: 'F0EA3',
        name: 'mdi-equalizer-outline'
      },
      {
        unicode: 'F01FE',
        name: 'mdi-eraser'
      },
      {
        unicode: 'F0642',
        name: 'mdi-eraser-variant'
      },
      {
        unicode: 'F01FF',
        name: 'mdi-escalator'
      },
      {
        unicode: 'F1399',
        name: 'mdi-escalator-box'
      },
      {
        unicode: 'F12C0',
        name: 'mdi-escalator-down'
      },
      {
        unicode: 'F12BF',
        name: 'mdi-escalator-up'
      },
      {
        unicode: 'F0C7A',
        name: 'mdi-eslint'
      },
      {
        unicode: 'F0AB3',
        name: 'mdi-et'
      },
      {
        unicode: 'F086A',
        name: 'mdi-ethereum'
      },
      {
        unicode: 'F0200',
        name: 'mdi-ethernet'
      },
      {
        unicode: 'F0201',
        name: 'mdi-ethernet-cable'
      },
      {
        unicode: 'F0202',
        name: 'mdi-ethernet-cable-off'
      },
      {
        unicode: 'F05F1',
        name: 'mdi-ev-station'
      },
      {
        unicode: 'F0204',
        name: 'mdi-evernote'
      },
      {
        unicode: 'F1025',
        name: 'mdi-excavator'
      },
      {
        unicode: 'F0205',
        name: 'mdi-exclamation'
      },
      {
        unicode: 'F1238',
        name: 'mdi-exclamation-thick'
      },
      {
        unicode: 'F0A48',
        name: 'mdi-exit-run'
      },
      {
        unicode: 'F0206',
        name: 'mdi-exit-to-app'
      },
      {
        unicode: 'F0AB4',
        name: 'mdi-expand-all'
      },
      {
        unicode: 'F0AB5',
        name: 'mdi-expand-all-outline'
      },
      {
        unicode: 'F08AE',
        name: 'mdi-expansion-card'
      },
      {
        unicode: 'F0FB2',
        name: 'mdi-expansion-card-variant'
      },
      {
        unicode: 'F0963',
        name: 'mdi-exponent'
      },
      {
        unicode: 'F0964',
        name: 'mdi-exponent-box'
      },
      {
        unicode: 'F0207',
        name: 'mdi-export'
      },
      {
        unicode: 'F0B93',
        name: 'mdi-export-variant'
      },
      {
        unicode: 'F0208',
        name: 'mdi-eye'
      },
      {
        unicode: 'F0D04',
        name: 'mdi-eye-check'
      },
      {
        unicode: 'F0D05',
        name: 'mdi-eye-check-outline'
      },
      {
        unicode: 'F0B94',
        name: 'mdi-eye-circle'
      },
      {
        unicode: 'F0B95',
        name: 'mdi-eye-circle-outline'
      },
      {
        unicode: 'F1026',
        name: 'mdi-eye-minus'
      },
      {
        unicode: 'F1027',
        name: 'mdi-eye-minus-outline'
      },
      {
        unicode: 'F0209',
        name: 'mdi-eye-off'
      },
      {
        unicode: 'F06D1',
        name: 'mdi-eye-off-outline'
      },
      {
        unicode: 'F06D0',
        name: 'mdi-eye-outline'
      },
      {
        unicode: 'F086B',
        name: 'mdi-eye-plus'
      },
      {
        unicode: 'F086C',
        name: 'mdi-eye-plus-outline'
      },
      {
        unicode: 'F086D',
        name: 'mdi-eye-settings'
      },
      {
        unicode: 'F086E',
        name: 'mdi-eye-settings-outline'
      },
      {
        unicode: 'F020A',
        name: 'mdi-eyedropper'
      },
      {
        unicode: 'F13DD',
        name: 'mdi-eyedropper-minus'
      },
      {
        unicode: 'F13DF',
        name: 'mdi-eyedropper-off'
      },
      {
        unicode: 'F13DC',
        name: 'mdi-eyedropper-plus'
      },
      {
        unicode: 'F13DE',
        name: 'mdi-eyedropper-remove'
      },
      {
        unicode: 'F020B',
        name: 'mdi-eyedropper-variant'
      },
      {
        unicode: 'F0643',
        name: 'mdi-face'
      },
      {
        unicode: 'F0D70',
        name: 'mdi-face-agent'
      },
      {
        unicode: 'F0B96',
        name: 'mdi-face-outline'
      },
      {
        unicode: 'F0644',
        name: 'mdi-face-profile'
      },
      {
        unicode: 'F1076',
        name: 'mdi-face-profile-woman'
      },
      {
        unicode: 'F0C7B',
        name: 'mdi-face-recognition'
      },
      {
        unicode: 'F1077',
        name: 'mdi-face-woman'
      },
      {
        unicode: 'F1078',
        name: 'mdi-face-woman-outline'
      },
      {
        unicode: 'F020C',
        name: 'mdi-facebook'
      },
      {
        unicode: 'F020E',
        name: 'mdi-facebook-messenger'
      },
      {
        unicode: 'F0B31',
        name: 'mdi-facebook-workplace'
      },
      {
        unicode: 'F020F',
        name: 'mdi-factory'
      },
      {
        unicode: 'F0210',
        name: 'mdi-fan'
      },
      {
        unicode: 'F146C',
        name: 'mdi-fan-alert'
      },
      {
        unicode: 'F146D',
        name: 'mdi-fan-chevron-down'
      },
      {
        unicode: 'F146E',
        name: 'mdi-fan-chevron-up'
      },
      {
        unicode: 'F1470',
        name: 'mdi-fan-minus'
      },
      {
        unicode: 'F081D',
        name: 'mdi-fan-off'
      },
      {
        unicode: 'F146F',
        name: 'mdi-fan-plus'
      },
      {
        unicode: 'F1471',
        name: 'mdi-fan-remove'
      },
      {
        unicode: 'F1472',
        name: 'mdi-fan-speed-1'
      },
      {
        unicode: 'F1473',
        name: 'mdi-fan-speed-2'
      },
      {
        unicode: 'F1474',
        name: 'mdi-fan-speed-3'
      },
      {
        unicode: 'F0211',
        name: 'mdi-fast-forward'
      },
      {
        unicode: 'F0D71',
        name: 'mdi-fast-forward-10'
      },
      {
        unicode: 'F0D06',
        name: 'mdi-fast-forward-30'
      },
      {
        unicode: 'F11F8',
        name: 'mdi-fast-forward-5'
      },
      {
        unicode: 'F06D2',
        name: 'mdi-fast-forward-outline'
      },
      {
        unicode: 'F0212',
        name: 'mdi-fax'
      },
      {
        unicode: 'F06D3',
        name: 'mdi-feather'
      },
      {
        unicode: 'F0A49',
        name: 'mdi-feature-search'
      },
      {
        unicode: 'F0A4A',
        name: 'mdi-feature-search-outline'
      },
      {
        unicode: 'F08DB',
        name: 'mdi-fedora'
      },
      {
        unicode: 'F14C1',
        name: 'mdi-fencing'
      },
      {
        unicode: 'F0EA4',
        name: 'mdi-ferris-wheel'
      },
      {
        unicode: 'F0213',
        name: 'mdi-ferry'
      },
      {
        unicode: 'F0214',
        name: 'mdi-file'
      },
      {
        unicode: 'F073B',
        name: 'mdi-file-account'
      },
      {
        unicode: 'F1028',
        name: 'mdi-file-account-outline'
      },
      {
        unicode: 'F0A4B',
        name: 'mdi-file-alert'
      },
      {
        unicode: 'F0A4C',
        name: 'mdi-file-alert-outline'
      },
      {
        unicode: 'F0AB6',
        name: 'mdi-file-cabinet'
      },
      {
        unicode: 'F0EEB',
        name: 'mdi-file-cad'
      },
      {
        unicode: 'F0EEC',
        name: 'mdi-file-cad-box'
      },
      {
        unicode: 'F0DC6',
        name: 'mdi-file-cancel'
      },
      {
        unicode: 'F0DC7',
        name: 'mdi-file-cancel-outline'
      },
      {
        unicode: 'F1186',
        name: 'mdi-file-certificate'
      },
      {
        unicode: 'F1187',
        name: 'mdi-file-certificate-outline'
      },
      {
        unicode: 'F0215',
        name: 'mdi-file-chart'
      },
      {
        unicode: 'F1029',
        name: 'mdi-file-chart-outline'
      },
      {
        unicode: 'F0216',
        name: 'mdi-file-check'
      },
      {
        unicode: 'F0E29',
        name: 'mdi-file-check-outline'
      },
      {
        unicode: 'F12E1',
        name: 'mdi-file-clock'
      },
      {
        unicode: 'F12E2',
        name: 'mdi-file-clock-outline'
      },
      {
        unicode: 'F0217',
        name: 'mdi-file-cloud'
      },
      {
        unicode: 'F102A',
        name: 'mdi-file-cloud-outline'
      },
      {
        unicode: 'F022E',
        name: 'mdi-file-code'
      },
      {
        unicode: 'F102B',
        name: 'mdi-file-code-outline'
      },
      {
        unicode: 'F107B',
        name: 'mdi-file-cog'
      },
      {
        unicode: 'F107C',
        name: 'mdi-file-cog-outline'
      },
      {
        unicode: 'F08AA',
        name: 'mdi-file-compare'
      },
      {
        unicode: 'F0218',
        name: 'mdi-file-delimited'
      },
      {
        unicode: 'F0EA5',
        name: 'mdi-file-delimited-outline'
      },
      {
        unicode: 'F0219',
        name: 'mdi-file-document'
      },
      {
        unicode: 'F0DC8',
        name: 'mdi-file-document-edit'
      },
      {
        unicode: 'F0DC9',
        name: 'mdi-file-document-edit-outline'
      },
      {
        unicode: 'F09EE',
        name: 'mdi-file-document-outline'
      },
      {
        unicode: 'F0965',
        name: 'mdi-file-download'
      },
      {
        unicode: 'F0966',
        name: 'mdi-file-download-outline'
      },
      {
        unicode: 'F11E7',
        name: 'mdi-file-edit'
      },
      {
        unicode: 'F11E8',
        name: 'mdi-file-edit-outline'
      },
      {
        unicode: 'F021B',
        name: 'mdi-file-excel'
      },
      {
        unicode: 'F021C',
        name: 'mdi-file-excel-box'
      },
      {
        unicode: 'F102C',
        name: 'mdi-file-excel-box-outline'
      },
      {
        unicode: 'F102D',
        name: 'mdi-file-excel-outline'
      },
      {
        unicode: 'F021D',
        name: 'mdi-file-export'
      },
      {
        unicode: 'F102E',
        name: 'mdi-file-export-outline'
      },
      {
        unicode: 'F0DCA',
        name: 'mdi-file-eye'
      },
      {
        unicode: 'F0DCB',
        name: 'mdi-file-eye-outline'
      },
      {
        unicode: 'F021E',
        name: 'mdi-file-find'
      },
      {
        unicode: 'F0B97',
        name: 'mdi-file-find-outline'
      },
      {
        unicode: 'F0613',
        name: 'mdi-file-hidden'
      },
      {
        unicode: 'F021F',
        name: 'mdi-file-image'
      },
      {
        unicode: 'F0EB0',
        name: 'mdi-file-image-outline'
      },
      {
        unicode: 'F0220',
        name: 'mdi-file-import'
      },
      {
        unicode: 'F102F',
        name: 'mdi-file-import-outline'
      },
      {
        unicode: 'F1184',
        name: 'mdi-file-key'
      },
      {
        unicode: 'F1185',
        name: 'mdi-file-key-outline'
      },
      {
        unicode: 'F1177',
        name: 'mdi-file-link'
      },
      {
        unicode: 'F1178',
        name: 'mdi-file-link-outline'
      },
      {
        unicode: 'F0221',
        name: 'mdi-file-lock'
      },
      {
        unicode: 'F1030',
        name: 'mdi-file-lock-outline'
      },
      {
        unicode: 'F0AB9',
        name: 'mdi-file-move'
      },
      {
        unicode: 'F1031',
        name: 'mdi-file-move-outline'
      },
      {
        unicode: 'F0222',
        name: 'mdi-file-multiple'
      },
      {
        unicode: 'F1032',
        name: 'mdi-file-multiple-outline'
      },
      {
        unicode: 'F0223',
        name: 'mdi-file-music'
      },
      {
        unicode: 'F0E2A',
        name: 'mdi-file-music-outline'
      },
      {
        unicode: 'F0224',
        name: 'mdi-file-outline'
      },
      {
        unicode: 'F0225',
        name: 'mdi-file-pdf'
      },
      {
        unicode: 'F0226',
        name: 'mdi-file-pdf-box'
      },
      {
        unicode: 'F0FB3',
        name: 'mdi-file-pdf-box-outline'
      },
      {
        unicode: 'F0E2D',
        name: 'mdi-file-pdf-outline'
      },
      {
        unicode: 'F081E',
        name: 'mdi-file-percent'
      },
      {
        unicode: 'F1033',
        name: 'mdi-file-percent-outline'
      },
      {
        unicode: 'F1179',
        name: 'mdi-file-phone'
      },
      {
        unicode: 'F117A',
        name: 'mdi-file-phone-outline'
      },
      {
        unicode: 'F0752',
        name: 'mdi-file-plus'
      },
      {
        unicode: 'F0EED',
        name: 'mdi-file-plus-outline'
      },
      {
        unicode: 'F0227',
        name: 'mdi-file-powerpoint'
      },
      {
        unicode: 'F0228',
        name: 'mdi-file-powerpoint-box'
      },
      {
        unicode: 'F1034',
        name: 'mdi-file-powerpoint-box-outline'
      },
      {
        unicode: 'F1035',
        name: 'mdi-file-powerpoint-outline'
      },
      {
        unicode: 'F0229',
        name: 'mdi-file-presentation-box'
      },
      {
        unicode: 'F086F',
        name: 'mdi-file-question'
      },
      {
        unicode: 'F1036',
        name: 'mdi-file-question-outline'
      },
      {
        unicode: 'F0918',
        name: 'mdi-file-refresh'
      },
      {
        unicode: 'F0541',
        name: 'mdi-file-refresh-outline'
      },
      {
        unicode: 'F0B98',
        name: 'mdi-file-remove'
      },
      {
        unicode: 'F1037',
        name: 'mdi-file-remove-outline'
      },
      {
        unicode: 'F0B32',
        name: 'mdi-file-replace'
      },
      {
        unicode: 'F0B33',
        name: 'mdi-file-replace-outline'
      },
      {
        unicode: 'F0670',
        name: 'mdi-file-restore'
      },
      {
        unicode: 'F1038',
        name: 'mdi-file-restore-outline'
      },
      {
        unicode: 'F0C7C',
        name: 'mdi-file-search'
      },
      {
        unicode: 'F0C7D',
        name: 'mdi-file-search-outline'
      },
      {
        unicode: 'F022A',
        name: 'mdi-file-send'
      },
      {
        unicode: 'F1039',
        name: 'mdi-file-send-outline'
      },
      {
        unicode: 'F1079',
        name: 'mdi-file-settings'
      },
      {
        unicode: 'F107A',
        name: 'mdi-file-settings-outline'
      },
      {
        unicode: 'F103A',
        name: 'mdi-file-star'
      },
      {
        unicode: 'F103B',
        name: 'mdi-file-star-outline'
      },
      {
        unicode: 'F0FB4',
        name: 'mdi-file-swap'
      },
      {
        unicode: 'F0FB5',
        name: 'mdi-file-swap-outline'
      },
      {
        unicode: 'F1216',
        name: 'mdi-file-sync'
      },
      {
        unicode: 'F1217',
        name: 'mdi-file-sync-outline'
      },
      {
        unicode: 'F0C7E',
        name: 'mdi-file-table'
      },
      {
        unicode: 'F10E1',
        name: 'mdi-file-table-box'
      },
      {
        unicode: 'F10E2',
        name: 'mdi-file-table-box-multiple'
      },
      {
        unicode: 'F10E3',
        name: 'mdi-file-table-box-multiple-outline'
      },
      {
        unicode: 'F10E4',
        name: 'mdi-file-table-box-outline'
      },
      {
        unicode: 'F0C7F',
        name: 'mdi-file-table-outline'
      },
      {
        unicode: 'F0645',
        name: 'mdi-file-tree'
      },
      {
        unicode: 'F13D2',
        name: 'mdi-file-tree-outline'
      },
      {
        unicode: 'F08DC',
        name: 'mdi-file-undo'
      },
      {
        unicode: 'F103C',
        name: 'mdi-file-undo-outline'
      },
      {
        unicode: 'F0A4D',
        name: 'mdi-file-upload'
      },
      {
        unicode: 'F0A4E',
        name: 'mdi-file-upload-outline'
      },
      {
        unicode: 'F022B',
        name: 'mdi-file-video'
      },
      {
        unicode: 'F0E2C',
        name: 'mdi-file-video-outline'
      },
      {
        unicode: 'F022C',
        name: 'mdi-file-word'
      },
      {
        unicode: 'F022D',
        name: 'mdi-file-word-box'
      },
      {
        unicode: 'F103D',
        name: 'mdi-file-word-box-outline'
      },
      {
        unicode: 'F103E',
        name: 'mdi-file-word-outline'
      },
      {
        unicode: 'F022F',
        name: 'mdi-film'
      },
      {
        unicode: 'F0230',
        name: 'mdi-filmstrip'
      },
      {
        unicode: 'F0332',
        name: 'mdi-filmstrip-box'
      },
      {
        unicode: 'F0D18',
        name: 'mdi-filmstrip-box-multiple'
      },
      {
        unicode: 'F0231',
        name: 'mdi-filmstrip-off'
      },
      {
        unicode: 'F0232',
        name: 'mdi-filter'
      },
      {
        unicode: 'F10E5',
        name: 'mdi-filter-menu'
      },
      {
        unicode: 'F10E6',
        name: 'mdi-filter-menu-outline'
      },
      {
        unicode: 'F0EEE',
        name: 'mdi-filter-minus'
      },
      {
        unicode: 'F0EEF',
        name: 'mdi-filter-minus-outline'
      },
      {
        unicode: 'F0233',
        name: 'mdi-filter-outline'
      },
      {
        unicode: 'F0EF0',
        name: 'mdi-filter-plus'
      },
      {
        unicode: 'F0EF1',
        name: 'mdi-filter-plus-outline'
      },
      {
        unicode: 'F0234',
        name: 'mdi-filter-remove'
      },
      {
        unicode: 'F0235',
        name: 'mdi-filter-remove-outline'
      },
      {
        unicode: 'F0236',
        name: 'mdi-filter-variant'
      },
      {
        unicode: 'F1112',
        name: 'mdi-filter-variant-minus'
      },
      {
        unicode: 'F1113',
        name: 'mdi-filter-variant-plus'
      },
      {
        unicode: 'F103F',
        name: 'mdi-filter-variant-remove'
      },
      {
        unicode: 'F081F',
        name: 'mdi-finance'
      },
      {
        unicode: 'F06D4',
        name: 'mdi-find-replace'
      },
      {
        unicode: 'F0237',
        name: 'mdi-fingerprint'
      },
      {
        unicode: 'F0EB1',
        name: 'mdi-fingerprint-off'
      },
      {
        unicode: 'F0238',
        name: 'mdi-fire'
      },
      {
        unicode: 'F0EF2',
        name: 'mdi-fire-extinguisher'
      },
      {
        unicode: 'F1137',
        name: 'mdi-fire-hydrant'
      },
      {
        unicode: 'F1138',
        name: 'mdi-fire-hydrant-alert'
      },
      {
        unicode: 'F1139',
        name: 'mdi-fire-hydrant-off'
      },
      {
        unicode: 'F08AB',
        name: 'mdi-fire-truck'
      },
      {
        unicode: 'F0967',
        name: 'mdi-firebase'
      },
      {
        unicode: 'F0239',
        name: 'mdi-firefox'
      },
      {
        unicode: 'F0E2E',
        name: 'mdi-fireplace'
      },
      {
        unicode: 'F0E2F',
        name: 'mdi-fireplace-off'
      },
      {
        unicode: 'F0E30',
        name: 'mdi-firework'
      },
      {
        unicode: 'F023A',
        name: 'mdi-fish'
      },
      {
        unicode: 'F13F3',
        name: 'mdi-fish-off'
      },
      {
        unicode: 'F0EF3',
        name: 'mdi-fishbowl'
      },
      {
        unicode: 'F0EF4',
        name: 'mdi-fishbowl-outline'
      },
      {
        unicode: 'F0EF5',
        name: 'mdi-fit-to-page'
      },
      {
        unicode: 'F0EF6',
        name: 'mdi-fit-to-page-outline'
      },
      {
        unicode: 'F023B',
        name: 'mdi-flag'
      },
      {
        unicode: 'F023C',
        name: 'mdi-flag-checkered'
      },
      {
        unicode: 'F0B99',
        name: 'mdi-flag-minus'
      },
      {
        unicode: 'F10B2',
        name: 'mdi-flag-minus-outline'
      },
      {
        unicode: 'F023D',
        name: 'mdi-flag-outline'
      },
      {
        unicode: 'F0B9A',
        name: 'mdi-flag-plus'
      },
      {
        unicode: 'F10B3',
        name: 'mdi-flag-plus-outline'
      },
      {
        unicode: 'F0B9B',
        name: 'mdi-flag-remove'
      },
      {
        unicode: 'F10B4',
        name: 'mdi-flag-remove-outline'
      },
      {
        unicode: 'F023F',
        name: 'mdi-flag-triangle'
      },
      {
        unicode: 'F0240',
        name: 'mdi-flag-variant'
      },
      {
        unicode: 'F023E',
        name: 'mdi-flag-variant-outline'
      },
      {
        unicode: 'F0D72',
        name: 'mdi-flare'
      },
      {
        unicode: 'F0241',
        name: 'mdi-flash'
      },
      {
        unicode: 'F0EF7',
        name: 'mdi-flash-alert'
      },
      {
        unicode: 'F0EF8',
        name: 'mdi-flash-alert-outline'
      },
      {
        unicode: 'F0242',
        name: 'mdi-flash-auto'
      },
      {
        unicode: 'F0820',
        name: 'mdi-flash-circle'
      },
      {
        unicode: 'F0243',
        name: 'mdi-flash-off'
      },
      {
        unicode: 'F06D5',
        name: 'mdi-flash-outline'
      },
      {
        unicode: 'F067B',
        name: 'mdi-flash-red-eye'
      },
      {
        unicode: 'F0244',
        name: 'mdi-flashlight'
      },
      {
        unicode: 'F0245',
        name: 'mdi-flashlight-off'
      },
      {
        unicode: 'F0093',
        name: 'mdi-flask'
      },
      {
        unicode: 'F0094',
        name: 'mdi-flask-empty'
      },
      {
        unicode: 'F123A',
        name: 'mdi-flask-empty-minus'
      },
      {
        unicode: 'F123B',
        name: 'mdi-flask-empty-minus-outline'
      },
      {
        unicode: 'F13F4',
        name: 'mdi-flask-empty-off'
      },
      {
        unicode: 'F13F5',
        name: 'mdi-flask-empty-off-outline'
      },
      {
        unicode: 'F0095',
        name: 'mdi-flask-empty-outline'
      },
      {
        unicode: 'F123C',
        name: 'mdi-flask-empty-plus'
      },
      {
        unicode: 'F123D',
        name: 'mdi-flask-empty-plus-outline'
      },
      {
        unicode: 'F123E',
        name: 'mdi-flask-empty-remove'
      },
      {
        unicode: 'F123F',
        name: 'mdi-flask-empty-remove-outline'
      },
      {
        unicode: 'F1240',
        name: 'mdi-flask-minus'
      },
      {
        unicode: 'F1241',
        name: 'mdi-flask-minus-outline'
      },
      {
        unicode: 'F13F6',
        name: 'mdi-flask-off'
      },
      {
        unicode: 'F13F7',
        name: 'mdi-flask-off-outline'
      },
      {
        unicode: 'F0096',
        name: 'mdi-flask-outline'
      },
      {
        unicode: 'F1242',
        name: 'mdi-flask-plus'
      },
      {
        unicode: 'F1243',
        name: 'mdi-flask-plus-outline'
      },
      {
        unicode: 'F1244',
        name: 'mdi-flask-remove'
      },
      {
        unicode: 'F1245',
        name: 'mdi-flask-remove-outline'
      },
      {
        unicode: 'F124B',
        name: 'mdi-flask-round-bottom'
      },
      {
        unicode: 'F124C',
        name: 'mdi-flask-round-bottom-empty'
      },
      {
        unicode: 'F124D',
        name: 'mdi-flask-round-bottom-empty-outline'
      },
      {
        unicode: 'F124E',
        name: 'mdi-flask-round-bottom-outline'
      },
      {
        unicode: 'F1303',
        name: 'mdi-fleur-de-lis'
      },
      {
        unicode: 'F10E7',
        name: 'mdi-flip-horizontal'
      },
      {
        unicode: 'F0247',
        name: 'mdi-flip-to-back'
      },
      {
        unicode: 'F0248',
        name: 'mdi-flip-to-front'
      },
      {
        unicode: 'F10E8',
        name: 'mdi-flip-vertical'
      },
      {
        unicode: 'F08DD',
        name: 'mdi-floor-lamp'
      },
      {
        unicode: 'F1040',
        name: 'mdi-floor-lamp-dual'
      },
      {
        unicode: 'F1041',
        name: 'mdi-floor-lamp-variant'
      },
      {
        unicode: 'F0821',
        name: 'mdi-floor-plan'
      },
      {
        unicode: 'F0249',
        name: 'mdi-floppy'
      },
      {
        unicode: 'F09EF',
        name: 'mdi-floppy-variant'
      },
      {
        unicode: 'F024A',
        name: 'mdi-flower'
      },
      {
        unicode: 'F09F0',
        name: 'mdi-flower-outline'
      },
      {
        unicode: 'F0D08',
        name: 'mdi-flower-poppy'
      },
      {
        unicode: 'F09F1',
        name: 'mdi-flower-tulip'
      },
      {
        unicode: 'F09F2',
        name: 'mdi-flower-tulip-outline'
      },
      {
        unicode: 'F0F4E',
        name: 'mdi-focus-auto'
      },
      {
        unicode: 'F0F4F',
        name: 'mdi-focus-field'
      },
      {
        unicode: 'F0F50',
        name: 'mdi-focus-field-horizontal'
      },
      {
        unicode: 'F0F51',
        name: 'mdi-focus-field-vertical'
      },
      {
        unicode: 'F024B',
        name: 'mdi-folder'
      },
      {
        unicode: 'F024C',
        name: 'mdi-folder-account'
      },
      {
        unicode: 'F0B9C',
        name: 'mdi-folder-account-outline'
      },
      {
        unicode: 'F0DCC',
        name: 'mdi-folder-alert'
      },
      {
        unicode: 'F0DCD',
        name: 'mdi-folder-alert-outline'
      },
      {
        unicode: 'F0ABA',
        name: 'mdi-folder-clock'
      },
      {
        unicode: 'F0ABB',
        name: 'mdi-folder-clock-outline'
      },
      {
        unicode: 'F107F',
        name: 'mdi-folder-cog'
      },
      {
        unicode: 'F1080',
        name: 'mdi-folder-cog-outline'
      },
      {
        unicode: 'F024D',
        name: 'mdi-folder-download'
      },
      {
        unicode: 'F10E9',
        name: 'mdi-folder-download-outline'
      },
      {
        unicode: 'F08DE',
        name: 'mdi-folder-edit'
      },
      {
        unicode: 'F0DCE',
        name: 'mdi-folder-edit-outline'
      },
      {
        unicode: 'F024E',
        name: 'mdi-folder-google-drive'
      },
      {
        unicode: 'F10EA',
        name: 'mdi-folder-heart'
      },
      {
        unicode: 'F10EB',
        name: 'mdi-folder-heart-outline'
      },
      {
        unicode: 'F10B5',
        name: 'mdi-folder-home'
      },
      {
        unicode: 'F10B6',
        name: 'mdi-folder-home-outline'
      },
      {
        unicode: 'F024F',
        name: 'mdi-folder-image'
      },
      {
        unicode: 'F10B7',
        name: 'mdi-folder-information'
      },
      {
        unicode: 'F10B8',
        name: 'mdi-folder-information-outline'
      },
      {
        unicode: 'F08AC',
        name: 'mdi-folder-key'
      },
      {
        unicode: 'F08AD',
        name: 'mdi-folder-key-network'
      },
      {
        unicode: 'F0C80',
        name: 'mdi-folder-key-network-outline'
      },
      {
        unicode: 'F10EC',
        name: 'mdi-folder-key-outline'
      },
      {
        unicode: 'F0250',
        name: 'mdi-folder-lock'
      },
      {
        unicode: 'F0251',
        name: 'mdi-folder-lock-open'
      },
      {
        unicode: 'F126D',
        name: 'mdi-folder-marker'
      },
      {
        unicode: 'F126E',
        name: 'mdi-folder-marker-outline'
      },
      {
        unicode: 'F0252',
        name: 'mdi-folder-move'
      },
      {
        unicode: 'F1246',
        name: 'mdi-folder-move-outline'
      },
      {
        unicode: 'F0253',
        name: 'mdi-folder-multiple'
      },
      {
        unicode: 'F0254',
        name: 'mdi-folder-multiple-image'
      },
      {
        unicode: 'F0255',
        name: 'mdi-folder-multiple-outline'
      },
      {
        unicode: 'F147E',
        name: 'mdi-folder-multiple-plus'
      },
      {
        unicode: 'F147F',
        name: 'mdi-folder-multiple-plus-outline'
      },
      {
        unicode: 'F1359',
        name: 'mdi-folder-music'
      },
      {
        unicode: 'F135A',
        name: 'mdi-folder-music-outline'
      },
      {
        unicode: 'F0870',
        name: 'mdi-folder-network'
      },
      {
        unicode: 'F0C81',
        name: 'mdi-folder-network-outline'
      },
      {
        unicode: 'F0770',
        name: 'mdi-folder-open'
      },
      {
        unicode: 'F0DCF',
        name: 'mdi-folder-open-outline'
      },
      {
        unicode: 'F0256',
        name: 'mdi-folder-outline'
      },
      {
        unicode: 'F0257',
        name: 'mdi-folder-plus'
      },
      {
        unicode: 'F0B9D',
        name: 'mdi-folder-plus-outline'
      },
      {
        unicode: 'F0D09',
        name: 'mdi-folder-pound'
      },
      {
        unicode: 'F0D0A',
        name: 'mdi-folder-pound-outline'
      },
      {
        unicode: 'F0749',
        name: 'mdi-folder-refresh'
      },
      {
        unicode: 'F0542',
        name: 'mdi-folder-refresh-outline'
      },
      {
        unicode: 'F0258',
        name: 'mdi-folder-remove'
      },
      {
        unicode: 'F0B9E',
        name: 'mdi-folder-remove-outline'
      },
      {
        unicode: 'F0968',
        name: 'mdi-folder-search'
      },
      {
        unicode: 'F0969',
        name: 'mdi-folder-search-outline'
      },
      {
        unicode: 'F107D',
        name: 'mdi-folder-settings'
      },
      {
        unicode: 'F107E',
        name: 'mdi-folder-settings-outline'
      },
      {
        unicode: 'F069D',
        name: 'mdi-folder-star'
      },
      {
        unicode: 'F13D3',
        name: 'mdi-folder-star-multiple'
      },
      {
        unicode: 'F13D4',
        name: 'mdi-folder-star-multiple-outline'
      },
      {
        unicode: 'F0B9F',
        name: 'mdi-folder-star-outline'
      },
      {
        unicode: 'F0FB6',
        name: 'mdi-folder-swap'
      },
      {
        unicode: 'F0FB7',
        name: 'mdi-folder-swap-outline'
      },
      {
        unicode: 'F0D0B',
        name: 'mdi-folder-sync'
      },
      {
        unicode: 'F0D0C',
        name: 'mdi-folder-sync-outline'
      },
      {
        unicode: 'F12E3',
        name: 'mdi-folder-table'
      },
      {
        unicode: 'F12E4',
        name: 'mdi-folder-table-outline'
      },
      {
        unicode: 'F0C82',
        name: 'mdi-folder-text'
      },
      {
        unicode: 'F0C83',
        name: 'mdi-folder-text-outline'
      },
      {
        unicode: 'F0259',
        name: 'mdi-folder-upload'
      },
      {
        unicode: 'F10ED',
        name: 'mdi-folder-upload-outline'
      },
      {
        unicode: 'F06EB',
        name: 'mdi-folder-zip'
      },
      {
        unicode: 'F07B9',
        name: 'mdi-folder-zip-outline'
      },
      {
        unicode: 'F003A',
        name: 'mdi-font-awesome'
      },
      {
        unicode: 'F025A',
        name: 'mdi-food'
      },
      {
        unicode: 'F025B',
        name: 'mdi-food-apple'
      },
      {
        unicode: 'F0C84',
        name: 'mdi-food-apple-outline'
      },
      {
        unicode: 'F07C8',
        name: 'mdi-food-croissant'
      },
      {
        unicode: 'F141F',
        name: 'mdi-food-drumstick'
      },
      {
        unicode: 'F1468',
        name: 'mdi-food-drumstick-off'
      },
      {
        unicode: 'F1469',
        name: 'mdi-food-drumstick-off-outline'
      },
      {
        unicode: 'F1420',
        name: 'mdi-food-drumstick-outline'
      },
      {
        unicode: 'F05F2',
        name: 'mdi-food-fork-drink'
      },
      {
        unicode: 'F05F3',
        name: 'mdi-food-off'
      },
      {
        unicode: 'F146A',
        name: 'mdi-food-steak'
      },
      {
        unicode: 'F146B',
        name: 'mdi-food-steak-off'
      },
      {
        unicode: 'F025C',
        name: 'mdi-food-variant'
      },
      {
        unicode: 'F13E5',
        name: 'mdi-food-variant-off'
      },
      {
        unicode: 'F0F52',
        name: 'mdi-foot-print'
      },
      {
        unicode: 'F025D',
        name: 'mdi-football'
      },
      {
        unicode: 'F025E',
        name: 'mdi-football-australian'
      },
      {
        unicode: 'F025F',
        name: 'mdi-football-helmet'
      },
      {
        unicode: 'F07C9',
        name: 'mdi-forklift'
      },
      {
        unicode: 'F1400',
        name: 'mdi-form-dropdown'
      },
      {
        unicode: 'F1401',
        name: 'mdi-form-select'
      },
      {
        unicode: 'F1095',
        name: 'mdi-form-textarea'
      },
      {
        unicode: 'F060E',
        name: 'mdi-form-textbox'
      },
      {
        unicode: 'F135D',
        name: 'mdi-form-textbox-lock'
      },
      {
        unicode: 'F07F5',
        name: 'mdi-form-textbox-password'
      },
      {
        unicode: 'F0753',
        name: 'mdi-format-align-bottom'
      },
      {
        unicode: 'F0260',
        name: 'mdi-format-align-center'
      },
      {
        unicode: 'F0261',
        name: 'mdi-format-align-justify'
      },
      {
        unicode: 'F0262',
        name: 'mdi-format-align-left'
      },
      {
        unicode: 'F0754',
        name: 'mdi-format-align-middle'
      },
      {
        unicode: 'F0263',
        name: 'mdi-format-align-right'
      },
      {
        unicode: 'F0755',
        name: 'mdi-format-align-top'
      },
      {
        unicode: 'F0ABC',
        name: 'mdi-format-annotation-minus'
      },
      {
        unicode: 'F0646',
        name: 'mdi-format-annotation-plus'
      },
      {
        unicode: 'F0264',
        name: 'mdi-format-bold'
      },
      {
        unicode: 'F0265',
        name: 'mdi-format-clear'
      },
      {
        unicode: 'F0266',
        name: 'mdi-format-color-fill'
      },
      {
        unicode: 'F0E31',
        name: 'mdi-format-color-highlight'
      },
      {
        unicode: 'F1313',
        name: 'mdi-format-color-marker-cancel'
      },
      {
        unicode: 'F069E',
        name: 'mdi-format-color-text'
      },
      {
        unicode: 'F08DF',
        name: 'mdi-format-columns'
      },
      {
        unicode: 'F0267',
        name: 'mdi-format-float-center'
      },
      {
        unicode: 'F0268',
        name: 'mdi-format-float-left'
      },
      {
        unicode: 'F0269',
        name: 'mdi-format-float-none'
      },
      {
        unicode: 'F026A',
        name: 'mdi-format-float-right'
      },
      {
        unicode: 'F06D6',
        name: 'mdi-format-font'
      },
      {
        unicode: 'F09F3',
        name: 'mdi-format-font-size-decrease'
      },
      {
        unicode: 'F09F4',
        name: 'mdi-format-font-size-increase'
      },
      {
        unicode: 'F026B',
        name: 'mdi-format-header-1'
      },
      {
        unicode: 'F026C',
        name: 'mdi-format-header-2'
      },
      {
        unicode: 'F026D',
        name: 'mdi-format-header-3'
      },
      {
        unicode: 'F026E',
        name: 'mdi-format-header-4'
      },
      {
        unicode: 'F026F',
        name: 'mdi-format-header-5'
      },
      {
        unicode: 'F0270',
        name: 'mdi-format-header-6'
      },
      {
        unicode: 'F0271',
        name: 'mdi-format-header-decrease'
      },
      {
        unicode: 'F0272',
        name: 'mdi-format-header-equal'
      },
      {
        unicode: 'F0273',
        name: 'mdi-format-header-increase'
      },
      {
        unicode: 'F0274',
        name: 'mdi-format-header-pound'
      },
      {
        unicode: 'F061E',
        name: 'mdi-format-horizontal-align-center'
      },
      {
        unicode: 'F061F',
        name: 'mdi-format-horizontal-align-left'
      },
      {
        unicode: 'F0620',
        name: 'mdi-format-horizontal-align-right'
      },
      {
        unicode: 'F0275',
        name: 'mdi-format-indent-decrease'
      },
      {
        unicode: 'F0276',
        name: 'mdi-format-indent-increase'
      },
      {
        unicode: 'F0277',
        name: 'mdi-format-italic'
      },
      {
        unicode: 'F0B34',
        name: 'mdi-format-letter-case'
      },
      {
        unicode: 'F0B35',
        name: 'mdi-format-letter-case-lower'
      },
      {
        unicode: 'F0B36',
        name: 'mdi-format-letter-case-upper'
      },
      {
        unicode: 'F0FB8',
        name: 'mdi-format-letter-ends-with'
      },
      {
        unicode: 'F0FB9',
        name: 'mdi-format-letter-matches'
      },
      {
        unicode: 'F0FBA',
        name: 'mdi-format-letter-starts-with'
      },
      {
        unicode: 'F0278',
        name: 'mdi-format-line-spacing'
      },
      {
        unicode: 'F05C8',
        name: 'mdi-format-line-style'
      },
      {
        unicode: 'F05C9',
        name: 'mdi-format-line-weight'
      },
      {
        unicode: 'F0279',
        name: 'mdi-format-list-bulleted'
      },
      {
        unicode: 'F0DD0',
        name: 'mdi-format-list-bulleted-square'
      },
      {
        unicode: 'F0EB2',
        name: 'mdi-format-list-bulleted-triangle'
      },
      {
        unicode: 'F027A',
        name: 'mdi-format-list-bulleted-type'
      },
      {
        unicode: 'F096A',
        name: 'mdi-format-list-checkbox'
      },
      {
        unicode: 'F0756',
        name: 'mdi-format-list-checks'
      },
      {
        unicode: 'F027B',
        name: 'mdi-format-list-numbered'
      },
      {
        unicode: 'F0D0D',
        name: 'mdi-format-list-numbered-rtl'
      },
      {
        unicode: 'F126F',
        name: 'mdi-format-list-text'
      },
      {
        unicode: 'F0EB3',
        name: 'mdi-format-overline'
      },
      {
        unicode: 'F06D7',
        name: 'mdi-format-page-break'
      },
      {
        unicode: 'F027C',
        name: 'mdi-format-paint'
      },
      {
        unicode: 'F027D',
        name: 'mdi-format-paragraph'
      },
      {
        unicode: 'F06D8',
        name: 'mdi-format-pilcrow'
      },
      {
        unicode: 'F027E',
        name: 'mdi-format-quote-close'
      },
      {
        unicode: 'F11A8',
        name: 'mdi-format-quote-close-outline'
      },
      {
        unicode: 'F0757',
        name: 'mdi-format-quote-open'
      },
      {
        unicode: 'F11A7',
        name: 'mdi-format-quote-open-outline'
      },
      {
        unicode: 'F06AA',
        name: 'mdi-format-rotate-90'
      },
      {
        unicode: 'F069F',
        name: 'mdi-format-section'
      },
      {
        unicode: 'F027F',
        name: 'mdi-format-size'
      },
      {
        unicode: 'F0280',
        name: 'mdi-format-strikethrough'
      },
      {
        unicode: 'F0281',
        name: 'mdi-format-strikethrough-variant'
      },
      {
        unicode: 'F0282',
        name: 'mdi-format-subscript'
      },
      {
        unicode: 'F0283',
        name: 'mdi-format-superscript'
      },
      {
        unicode: 'F0284',
        name: 'mdi-format-text'
      },
      {
        unicode: 'F0FBB',
        name: 'mdi-format-text-rotation-angle-down'
      },
      {
        unicode: 'F0FBC',
        name: 'mdi-format-text-rotation-angle-up'
      },
      {
        unicode: 'F0D73',
        name: 'mdi-format-text-rotation-down'
      },
      {
        unicode: 'F0FBD',
        name: 'mdi-format-text-rotation-down-vertical'
      },
      {
        unicode: 'F0D74',
        name: 'mdi-format-text-rotation-none'
      },
      {
        unicode: 'F0FBE',
        name: 'mdi-format-text-rotation-up'
      },
      {
        unicode: 'F0FBF',
        name: 'mdi-format-text-rotation-vertical'
      },
      {
        unicode: 'F0E32',
        name: 'mdi-format-text-variant'
      },
      {
        unicode: 'F0D0E',
        name: 'mdi-format-text-wrapping-clip'
      },
      {
        unicode: 'F0D0F',
        name: 'mdi-format-text-wrapping-overflow'
      },
      {
        unicode: 'F0D10',
        name: 'mdi-format-text-wrapping-wrap'
      },
      {
        unicode: 'F0D11',
        name: 'mdi-format-textbox'
      },
      {
        unicode: 'F0285',
        name: 'mdi-format-textdirection-l-to-r'
      },
      {
        unicode: 'F0286',
        name: 'mdi-format-textdirection-r-to-l'
      },
      {
        unicode: 'F05F4',
        name: 'mdi-format-title'
      },
      {
        unicode: 'F0287',
        name: 'mdi-format-underline'
      },
      {
        unicode: 'F0621',
        name: 'mdi-format-vertical-align-bottom'
      },
      {
        unicode: 'F0622',
        name: 'mdi-format-vertical-align-center'
      },
      {
        unicode: 'F0623',
        name: 'mdi-format-vertical-align-top'
      },
      {
        unicode: 'F0288',
        name: 'mdi-format-wrap-inline'
      },
      {
        unicode: 'F0289',
        name: 'mdi-format-wrap-square'
      },
      {
        unicode: 'F028A',
        name: 'mdi-format-wrap-tight'
      },
      {
        unicode: 'F028B',
        name: 'mdi-format-wrap-top-bottom'
      },
      {
        unicode: 'F028C',
        name: 'mdi-forum'
      },
      {
        unicode: 'F0822',
        name: 'mdi-forum-outline'
      },
      {
        unicode: 'F028D',
        name: 'mdi-forward'
      },
      {
        unicode: 'F0D75',
        name: 'mdi-forwardburger'
      },
      {
        unicode: 'F096B',
        name: 'mdi-fountain'
      },
      {
        unicode: 'F0D12',
        name: 'mdi-fountain-pen'
      },
      {
        unicode: 'F0D13',
        name: 'mdi-fountain-pen-tip'
      },
      {
        unicode: 'F08E0',
        name: 'mdi-freebsd'
      },
      {
        unicode: 'F0EB4',
        name: 'mdi-frequently-asked-questions'
      },
      {
        unicode: 'F0290',
        name: 'mdi-fridge'
      },
      {
        unicode: 'F11B1',
        name: 'mdi-fridge-alert'
      },
      {
        unicode: 'F11B2',
        name: 'mdi-fridge-alert-outline'
      },
      {
        unicode: 'F0292',
        name: 'mdi-fridge-bottom'
      },
      {
        unicode: 'F11AF',
        name: 'mdi-fridge-off'
      },
      {
        unicode: 'F11B0',
        name: 'mdi-fridge-off-outline'
      },
      {
        unicode: 'F028F',
        name: 'mdi-fridge-outline'
      },
      {
        unicode: 'F0291',
        name: 'mdi-fridge-top'
      },
      {
        unicode: 'F1042',
        name: 'mdi-fruit-cherries'
      },
      {
        unicode: 'F13F8',
        name: 'mdi-fruit-cherries-off'
      },
      {
        unicode: 'F1043',
        name: 'mdi-fruit-citrus'
      },
      {
        unicode: 'F13F9',
        name: 'mdi-fruit-citrus-off'
      },
      {
        unicode: 'F1044',
        name: 'mdi-fruit-grapes'
      },
      {
        unicode: 'F1045',
        name: 'mdi-fruit-grapes-outline'
      },
      {
        unicode: 'F1046',
        name: 'mdi-fruit-pineapple'
      },
      {
        unicode: 'F1047',
        name: 'mdi-fruit-watermelon'
      },
      {
        unicode: 'F07CA',
        name: 'mdi-fuel'
      },
      {
        unicode: 'F0293',
        name: 'mdi-fullscreen'
      },
      {
        unicode: 'F0294',
        name: 'mdi-fullscreen-exit'
      },
      {
        unicode: 'F0295',
        name: 'mdi-function'
      },
      {
        unicode: 'F0871',
        name: 'mdi-function-variant'
      },
      {
        unicode: 'F1081',
        name: 'mdi-furigana-horizontal'
      },
      {
        unicode: 'F1082',
        name: 'mdi-furigana-vertical'
      },
      {
        unicode: 'F0C85',
        name: 'mdi-fuse'
      },
      {
        unicode: 'F142D',
        name: 'mdi-fuse-alert'
      },
      {
        unicode: 'F0C86',
        name: 'mdi-fuse-blade'
      },
      {
        unicode: 'F142C',
        name: 'mdi-fuse-off'
      },
      {
        unicode: 'F0296',
        name: 'mdi-gamepad'
      },
      {
        unicode: 'F0E33',
        name: 'mdi-gamepad-circle'
      },
      {
        unicode: 'F0E34',
        name: 'mdi-gamepad-circle-down'
      },
      {
        unicode: 'F0E35',
        name: 'mdi-gamepad-circle-left'
      },
      {
        unicode: 'F0E36',
        name: 'mdi-gamepad-circle-outline'
      },
      {
        unicode: 'F0E37',
        name: 'mdi-gamepad-circle-right'
      },
      {
        unicode: 'F0E38',
        name: 'mdi-gamepad-circle-up'
      },
      {
        unicode: 'F0E39',
        name: 'mdi-gamepad-down'
      },
      {
        unicode: 'F0E3A',
        name: 'mdi-gamepad-left'
      },
      {
        unicode: 'F0E3B',
        name: 'mdi-gamepad-right'
      },
      {
        unicode: 'F0E3C',
        name: 'mdi-gamepad-round'
      },
      {
        unicode: 'F0E3D',
        name: 'mdi-gamepad-round-down'
      },
      {
        unicode: 'F0E3E',
        name: 'mdi-gamepad-round-left'
      },
      {
        unicode: 'F0E3F',
        name: 'mdi-gamepad-round-outline'
      },
      {
        unicode: 'F0E40',
        name: 'mdi-gamepad-round-right'
      },
      {
        unicode: 'F0E41',
        name: 'mdi-gamepad-round-up'
      },
      {
        unicode: 'F0EB5',
        name: 'mdi-gamepad-square'
      },
      {
        unicode: 'F0EB6',
        name: 'mdi-gamepad-square-outline'
      },
      {
        unicode: 'F0E42',
        name: 'mdi-gamepad-up'
      },
      {
        unicode: 'F0297',
        name: 'mdi-gamepad-variant'
      },
      {
        unicode: 'F0EB7',
        name: 'mdi-gamepad-variant-outline'
      },
      {
        unicode: 'F10EE',
        name: 'mdi-gamma'
      },
      {
        unicode: 'F0DD1',
        name: 'mdi-gantry-crane'
      },
      {
        unicode: 'F06D9',
        name: 'mdi-garage'
      },
      {
        unicode: 'F0872',
        name: 'mdi-garage-alert'
      },
      {
        unicode: 'F12D5',
        name: 'mdi-garage-alert-variant'
      },
      {
        unicode: 'F06DA',
        name: 'mdi-garage-open'
      },
      {
        unicode: 'F12D4',
        name: 'mdi-garage-open-variant'
      },
      {
        unicode: 'F12D3',
        name: 'mdi-garage-variant'
      },
      {
        unicode: 'F0647',
        name: 'mdi-gas-cylinder'
      },
      {
        unicode: 'F0298',
        name: 'mdi-gas-station'
      },
      {
        unicode: 'F1409',
        name: 'mdi-gas-station-off'
      },
      {
        unicode: 'F140A',
        name: 'mdi-gas-station-off-outline'
      },
      {
        unicode: 'F0EB8',
        name: 'mdi-gas-station-outline'
      },
      {
        unicode: 'F0299',
        name: 'mdi-gate'
      },
      {
        unicode: 'F08E1',
        name: 'mdi-gate-and'
      },
      {
        unicode: 'F1169',
        name: 'mdi-gate-arrow-right'
      },
      {
        unicode: 'F08E2',
        name: 'mdi-gate-nand'
      },
      {
        unicode: 'F08E3',
        name: 'mdi-gate-nor'
      },
      {
        unicode: 'F08E4',
        name: 'mdi-gate-not'
      },
      {
        unicode: 'F116A',
        name: 'mdi-gate-open'
      },
      {
        unicode: 'F08E5',
        name: 'mdi-gate-or'
      },
      {
        unicode: 'F08E6',
        name: 'mdi-gate-xnor'
      },
      {
        unicode: 'F08E7',
        name: 'mdi-gate-xor'
      },
      {
        unicode: 'F0E43',
        name: 'mdi-gatsby'
      },
      {
        unicode: 'F029A',
        name: 'mdi-gauge'
      },
      {
        unicode: 'F0873',
        name: 'mdi-gauge-empty'
      },
      {
        unicode: 'F0874',
        name: 'mdi-gauge-full'
      },
      {
        unicode: 'F0875',
        name: 'mdi-gauge-low'
      },
      {
        unicode: 'F029B',
        name: 'mdi-gavel'
      },
      {
        unicode: 'F029C',
        name: 'mdi-gender-female'
      },
      {
        unicode: 'F029D',
        name: 'mdi-gender-male'
      },
      {
        unicode: 'F029E',
        name: 'mdi-gender-male-female'
      },
      {
        unicode: 'F113F',
        name: 'mdi-gender-male-female-variant'
      },
      {
        unicode: 'F1140',
        name: 'mdi-gender-non-binary'
      },
      {
        unicode: 'F029F',
        name: 'mdi-gender-transgender'
      },
      {
        unicode: 'F08E8',
        name: 'mdi-gentoo'
      },
      {
        unicode: 'F07CB',
        name: 'mdi-gesture'
      },
      {
        unicode: 'F073C',
        name: 'mdi-gesture-double-tap'
      },
      {
        unicode: 'F0ABD',
        name: 'mdi-gesture-pinch'
      },
      {
        unicode: 'F0ABE',
        name: 'mdi-gesture-spread'
      },
      {
        unicode: 'F0D76',
        name: 'mdi-gesture-swipe'
      },
      {
        unicode: 'F073D',
        name: 'mdi-gesture-swipe-down'
      },
      {
        unicode: 'F0ABF',
        name: 'mdi-gesture-swipe-horizontal'
      },
      {
        unicode: 'F073E',
        name: 'mdi-gesture-swipe-left'
      },
      {
        unicode: 'F073F',
        name: 'mdi-gesture-swipe-right'
      },
      {
        unicode: 'F0740',
        name: 'mdi-gesture-swipe-up'
      },
      {
        unicode: 'F0AC0',
        name: 'mdi-gesture-swipe-vertical'
      },
      {
        unicode: 'F0741',
        name: 'mdi-gesture-tap'
      },
      {
        unicode: 'F12A9',
        name: 'mdi-gesture-tap-box'
      },
      {
        unicode: 'F12A8',
        name: 'mdi-gesture-tap-button'
      },
      {
        unicode: 'F0D77',
        name: 'mdi-gesture-tap-hold'
      },
      {
        unicode: 'F0742',
        name: 'mdi-gesture-two-double-tap'
      },
      {
        unicode: 'F0743',
        name: 'mdi-gesture-two-tap'
      },
      {
        unicode: 'F02A0',
        name: 'mdi-ghost'
      },
      {
        unicode: 'F09F5',
        name: 'mdi-ghost-off'
      },
      {
        unicode: 'F0D78',
        name: 'mdi-gif'
      },
      {
        unicode: 'F0E44',
        name: 'mdi-gift'
      },
      {
        unicode: 'F02A1',
        name: 'mdi-gift-outline'
      },
      {
        unicode: 'F02A2',
        name: 'mdi-git'
      },
      {
        unicode: 'F02A4',
        name: 'mdi-github'
      },
      {
        unicode: 'F0BA0',
        name: 'mdi-gitlab'
      },
      {
        unicode: 'F0356',
        name: 'mdi-glass-cocktail'
      },
      {
        unicode: 'F02A5',
        name: 'mdi-glass-flute'
      },
      {
        unicode: 'F02A6',
        name: 'mdi-glass-mug'
      },
      {
        unicode: 'F1116',
        name: 'mdi-glass-mug-variant'
      },
      {
        unicode: 'F130D',
        name: 'mdi-glass-pint-outline'
      },
      {
        unicode: 'F02A7',
        name: 'mdi-glass-stange'
      },
      {
        unicode: 'F02A8',
        name: 'mdi-glass-tulip'
      },
      {
        unicode: 'F0876',
        name: 'mdi-glass-wine'
      },
      {
        unicode: 'F02AA',
        name: 'mdi-glasses'
      },
      {
        unicode: 'F12D7',
        name: 'mdi-globe-light'
      },
      {
        unicode: 'F08E9',
        name: 'mdi-globe-model'
      },
      {
        unicode: 'F02AB',
        name: 'mdi-gmail'
      },
      {
        unicode: 'F02AC',
        name: 'mdi-gnome'
      },
      {
        unicode: 'F0D79',
        name: 'mdi-go-kart'
      },
      {
        unicode: 'F0D7A',
        name: 'mdi-go-kart-track'
      },
      {
        unicode: 'F0BA1',
        name: 'mdi-gog'
      },
      {
        unicode: 'F124F',
        name: 'mdi-gold'
      },
      {
        unicode: 'F0823',
        name: 'mdi-golf'
      },
      {
        unicode: 'F11A4',
        name: 'mdi-golf-cart'
      },
      {
        unicode: 'F1083',
        name: 'mdi-golf-tee'
      },
      {
        unicode: 'F0686',
        name: 'mdi-gondola'
      },
      {
        unicode: 'F0D7B',
        name: 'mdi-goodreads'
      },
      {
        unicode: 'F02AD',
        name: 'mdi-google'
      },
      {
        unicode: 'F0C87',
        name: 'mdi-google-ads'
      },
      {
        unicode: 'F07CC',
        name: 'mdi-google-analytics'
      },
      {
        unicode: 'F07CD',
        name: 'mdi-google-assistant'
      },
      {
        unicode: 'F02AE',
        name: 'mdi-google-cardboard'
      },
      {
        unicode: 'F02AF',
        name: 'mdi-google-chrome'
      },
      {
        unicode: 'F02B0',
        name: 'mdi-google-circles'
      },
      {
        unicode: 'F02B1',
        name: 'mdi-google-circles-communities'
      },
      {
        unicode: 'F02B2',
        name: 'mdi-google-circles-extended'
      },
      {
        unicode: 'F02B3',
        name: 'mdi-google-circles-group'
      },
      {
        unicode: 'F02C0',
        name: 'mdi-google-classroom'
      },
      {
        unicode: 'F11F6',
        name: 'mdi-google-cloud'
      },
      {
        unicode: 'F02B4',
        name: 'mdi-google-controller'
      },
      {
        unicode: 'F02B5',
        name: 'mdi-google-controller-off'
      },
      {
        unicode: 'F1362',
        name: 'mdi-google-downasaur'
      },
      {
        unicode: 'F02B6',
        name: 'mdi-google-drive'
      },
      {
        unicode: 'F02B7',
        name: 'mdi-google-earth'
      },
      {
        unicode: 'F096C',
        name: 'mdi-google-fit'
      },
      {
        unicode: 'F02B8',
        name: 'mdi-google-glass'
      },
      {
        unicode: 'F02C9',
        name: 'mdi-google-hangouts'
      },
      {
        unicode: 'F0824',
        name: 'mdi-google-home'
      },
      {
        unicode: 'F06DC',
        name: 'mdi-google-keep'
      },
      {
        unicode: 'F09F6',
        name: 'mdi-google-lens'
      },
      {
        unicode: 'F05F5',
        name: 'mdi-google-maps'
      },
      {
        unicode: 'F1048',
        name: 'mdi-google-my-business'
      },
      {
        unicode: 'F02B9',
        name: 'mdi-google-nearby'
      },
      {
        unicode: 'F06DD',
        name: 'mdi-google-photos'
      },
      {
        unicode: 'F02BC',
        name: 'mdi-google-play'
      },
      {
        unicode: 'F02BD',
        name: 'mdi-google-plus'
      },
      {
        unicode: 'F0EB9',
        name: 'mdi-google-podcast'
      },
      {
        unicode: 'F09F7',
        name: 'mdi-google-spreadsheet'
      },
      {
        unicode: 'F0C88',
        name: 'mdi-google-street-view'
      },
      {
        unicode: 'F02BF',
        name: 'mdi-google-translate'
      },
      {
        unicode: 'F06A0',
        name: 'mdi-gradient'
      },
      {
        unicode: 'F0D7C',
        name: 'mdi-grain'
      },
      {
        unicode: 'F1049',
        name: 'mdi-graph'
      },
      {
        unicode: 'F104A',
        name: 'mdi-graph-outline'
      },
      {
        unicode: 'F0877',
        name: 'mdi-graphql'
      },
      {
        unicode: 'F0BA2',
        name: 'mdi-grave-stone'
      },
      {
        unicode: 'F0648',
        name: 'mdi-grease-pencil'
      },
      {
        unicode: 'F096D',
        name: 'mdi-greater-than'
      },
      {
        unicode: 'F096E',
        name: 'mdi-greater-than-or-equal'
      },
      {
        unicode: 'F02C1',
        name: 'mdi-grid'
      },
      {
        unicode: 'F0758',
        name: 'mdi-grid-large'
      },
      {
        unicode: 'F02C2',
        name: 'mdi-grid-off'
      },
      {
        unicode: 'F0E45',
        name: 'mdi-grill'
      },
      {
        unicode: 'F118A',
        name: 'mdi-grill-outline'
      },
      {
        unicode: 'F02C3',
        name: 'mdi-group'
      },
      {
        unicode: 'F0771',
        name: 'mdi-guitar-acoustic'
      },
      {
        unicode: 'F02C4',
        name: 'mdi-guitar-electric'
      },
      {
        unicode: 'F02C5',
        name: 'mdi-guitar-pick'
      },
      {
        unicode: 'F02C6',
        name: 'mdi-guitar-pick-outline'
      },
      {
        unicode: 'F0825',
        name: 'mdi-guy-fawkes-mask'
      },
      {
        unicode: 'F0AC1',
        name: 'mdi-hail'
      },
      {
        unicode: 'F10EF',
        name: 'mdi-hair-dryer'
      },
      {
        unicode: 'F10F0',
        name: 'mdi-hair-dryer-outline'
      },
      {
        unicode: 'F0BA3',
        name: 'mdi-halloween'
      },
      {
        unicode: 'F0685',
        name: 'mdi-hamburger'
      },
      {
        unicode: 'F08EA',
        name: 'mdi-hammer'
      },
      {
        unicode: 'F1322',
        name: 'mdi-hammer-screwdriver'
      },
      {
        unicode: 'F1323',
        name: 'mdi-hammer-wrench'
      },
      {
        unicode: 'F0A4F',
        name: 'mdi-hand'
      },
      {
        unicode: 'F10F1',
        name: 'mdi-hand-heart'
      },
      {
        unicode: 'F0E46',
        name: 'mdi-hand-left'
      },
      {
        unicode: 'F0A50',
        name: 'mdi-hand-okay'
      },
      {
        unicode: 'F0A51',
        name: 'mdi-hand-peace'
      },
      {
        unicode: 'F0A52',
        name: 'mdi-hand-peace-variant'
      },
      {
        unicode: 'F0A53',
        name: 'mdi-hand-pointing-down'
      },
      {
        unicode: 'F0A54',
        name: 'mdi-hand-pointing-left'
      },
      {
        unicode: 'F02C7',
        name: 'mdi-hand-pointing-right'
      },
      {
        unicode: 'F0A55',
        name: 'mdi-hand-pointing-up'
      },
      {
        unicode: 'F0E47',
        name: 'mdi-hand-right'
      },
      {
        unicode: 'F0E48',
        name: 'mdi-hand-saw'
      },
      {
        unicode: 'F139F',
        name: 'mdi-hand-water'
      },
      {
        unicode: 'F0F53',
        name: 'mdi-handball'
      },
      {
        unicode: 'F113E',
        name: 'mdi-handcuffs'
      },
      {
        unicode: 'F1218',
        name: 'mdi-handshake'
      },
      {
        unicode: 'F02C8',
        name: 'mdi-hanger'
      },
      {
        unicode: 'F096F',
        name: 'mdi-hard-hat'
      },
      {
        unicode: 'F02CA',
        name: 'mdi-harddisk'
      },
      {
        unicode: 'F104B',
        name: 'mdi-harddisk-plus'
      },
      {
        unicode: 'F104C',
        name: 'mdi-harddisk-remove'
      },
      {
        unicode: 'F0BA4',
        name: 'mdi-hat-fedora'
      },
      {
        unicode: 'F0C89',
        name: 'mdi-hazard-lights'
      },
      {
        unicode: 'F0D7D',
        name: 'mdi-hdr'
      },
      {
        unicode: 'F0D7E',
        name: 'mdi-hdr-off'
      },
      {
        unicode: 'F135E',
        name: 'mdi-head'
      },
      {
        unicode: 'F1338',
        name: 'mdi-head-alert'
      },
      {
        unicode: 'F1339',
        name: 'mdi-head-alert-outline'
      },
      {
        unicode: 'F133A',
        name: 'mdi-head-check'
      },
      {
        unicode: 'F133B',
        name: 'mdi-head-check-outline'
      },
      {
        unicode: 'F133C',
        name: 'mdi-head-cog'
      },
      {
        unicode: 'F133D',
        name: 'mdi-head-cog-outline'
      },
      {
        unicode: 'F133E',
        name: 'mdi-head-dots-horizontal'
      },
      {
        unicode: 'F133F',
        name: 'mdi-head-dots-horizontal-outline'
      },
      {
        unicode: 'F1340',
        name: 'mdi-head-flash'
      },
      {
        unicode: 'F1341',
        name: 'mdi-head-flash-outline'
      },
      {
        unicode: 'F1342',
        name: 'mdi-head-heart'
      },
      {
        unicode: 'F1343',
        name: 'mdi-head-heart-outline'
      },
      {
        unicode: 'F1344',
        name: 'mdi-head-lightbulb'
      },
      {
        unicode: 'F1345',
        name: 'mdi-head-lightbulb-outline'
      },
      {
        unicode: 'F1346',
        name: 'mdi-head-minus'
      },
      {
        unicode: 'F1347',
        name: 'mdi-head-minus-outline'
      },
      {
        unicode: 'F135F',
        name: 'mdi-head-outline'
      },
      {
        unicode: 'F1348',
        name: 'mdi-head-plus'
      },
      {
        unicode: 'F1349',
        name: 'mdi-head-plus-outline'
      },
      {
        unicode: 'F134A',
        name: 'mdi-head-question'
      },
      {
        unicode: 'F134B',
        name: 'mdi-head-question-outline'
      },
      {
        unicode: 'F134C',
        name: 'mdi-head-remove'
      },
      {
        unicode: 'F134D',
        name: 'mdi-head-remove-outline'
      },
      {
        unicode: 'F134E',
        name: 'mdi-head-snowflake'
      },
      {
        unicode: 'F134F',
        name: 'mdi-head-snowflake-outline'
      },
      {
        unicode: 'F1350',
        name: 'mdi-head-sync'
      },
      {
        unicode: 'F1351',
        name: 'mdi-head-sync-outline'
      },
      {
        unicode: 'F02CB',
        name: 'mdi-headphones'
      },
      {
        unicode: 'F0970',
        name: 'mdi-headphones-bluetooth'
      },
      {
        unicode: 'F02CC',
        name: 'mdi-headphones-box'
      },
      {
        unicode: 'F07CE',
        name: 'mdi-headphones-off'
      },
      {
        unicode: 'F02CD',
        name: 'mdi-headphones-settings'
      },
      {
        unicode: 'F02CE',
        name: 'mdi-headset'
      },
      {
        unicode: 'F02CF',
        name: 'mdi-headset-dock'
      },
      {
        unicode: 'F02D0',
        name: 'mdi-headset-off'
      },
      {
        unicode: 'F02D1',
        name: 'mdi-heart'
      },
      {
        unicode: 'F02D2',
        name: 'mdi-heart-box'
      },
      {
        unicode: 'F02D3',
        name: 'mdi-heart-box-outline'
      },
      {
        unicode: 'F02D4',
        name: 'mdi-heart-broken'
      },
      {
        unicode: 'F0D14',
        name: 'mdi-heart-broken-outline'
      },
      {
        unicode: 'F0971',
        name: 'mdi-heart-circle'
      },
      {
        unicode: 'F0972',
        name: 'mdi-heart-circle-outline'
      },
      {
        unicode: 'F0EF9',
        name: 'mdi-heart-flash'
      },
      {
        unicode: 'F06DF',
        name: 'mdi-heart-half'
      },
      {
        unicode: 'F06DE',
        name: 'mdi-heart-half-full'
      },
      {
        unicode: 'F06E0',
        name: 'mdi-heart-half-outline'
      },
      {
        unicode: 'F142F',
        name: 'mdi-heart-minus'
      },
      {
        unicode: 'F1432',
        name: 'mdi-heart-minus-outline'
      },
      {
        unicode: 'F0A56',
        name: 'mdi-heart-multiple'
      },
      {
        unicode: 'F0A57',
        name: 'mdi-heart-multiple-outline'
      },
      {
        unicode: 'F0759',
        name: 'mdi-heart-off'
      },
      {
        unicode: 'F1434',
        name: 'mdi-heart-off-outline'
      },
      {
        unicode: 'F02D5',
        name: 'mdi-heart-outline'
      },
      {
        unicode: 'F142E',
        name: 'mdi-heart-plus'
      },
      {
        unicode: 'F1431',
        name: 'mdi-heart-plus-outline'
      },
      {
        unicode: 'F05F6',
        name: 'mdi-heart-pulse'
      },
      {
        unicode: 'F1430',
        name: 'mdi-heart-remove'
      },
      {
        unicode: 'F1433',
        name: 'mdi-heart-remove-outline'
      },
      {
        unicode: 'F0AC2',
        name: 'mdi-helicopter'
      },
      {
        unicode: 'F02D6',
        name: 'mdi-help'
      },
      {
        unicode: 'F078B',
        name: 'mdi-help-box'
      },
      {
        unicode: 'F02D7',
        name: 'mdi-help-circle'
      },
      {
        unicode: 'F0625',
        name: 'mdi-help-circle-outline'
      },
      {
        unicode: 'F06F5',
        name: 'mdi-help-network'
      },
      {
        unicode: 'F0C8A',
        name: 'mdi-help-network-outline'
      },
      {
        unicode: 'F0BA5',
        name: 'mdi-help-rhombus'
      },
      {
        unicode: 'F0BA6',
        name: 'mdi-help-rhombus-outline'
      },
      {
        unicode: 'F12A7',
        name: 'mdi-hexadecimal'
      },
      {
        unicode: 'F02D8',
        name: 'mdi-hexagon'
      },
      {
        unicode: 'F06E1',
        name: 'mdi-hexagon-multiple'
      },
      {
        unicode: 'F10F2',
        name: 'mdi-hexagon-multiple-outline'
      },
      {
        unicode: 'F02D9',
        name: 'mdi-hexagon-outline'
      },
      {
        unicode: 'F0AC3',
        name: 'mdi-hexagon-slice-1'
      },
      {
        unicode: 'F0AC4',
        name: 'mdi-hexagon-slice-2'
      },
      {
        unicode: 'F0AC5',
        name: 'mdi-hexagon-slice-3'
      },
      {
        unicode: 'F0AC6',
        name: 'mdi-hexagon-slice-4'
      },
      {
        unicode: 'F0AC7',
        name: 'mdi-hexagon-slice-5'
      },
      {
        unicode: 'F0AC8',
        name: 'mdi-hexagon-slice-6'
      },
      {
        unicode: 'F0AC9',
        name: 'mdi-hexagram'
      },
      {
        unicode: 'F0ACA',
        name: 'mdi-hexagram-outline'
      },
      {
        unicode: 'F07CF',
        name: 'mdi-high-definition'
      },
      {
        unicode: 'F0878',
        name: 'mdi-high-definition-box'
      },
      {
        unicode: 'F05F7',
        name: 'mdi-highway'
      },
      {
        unicode: 'F0D7F',
        name: 'mdi-hiking'
      },
      {
        unicode: 'F0973',
        name: 'mdi-hinduism'
      },
      {
        unicode: 'F02DA',
        name: 'mdi-history'
      },
      {
        unicode: 'F0879',
        name: 'mdi-hockey-puck'
      },
      {
        unicode: 'F087A',
        name: 'mdi-hockey-sticks'
      },
      {
        unicode: 'F02DB',
        name: 'mdi-hololens'
      },
      {
        unicode: 'F02DC',
        name: 'mdi-home'
      },
      {
        unicode: 'F0826',
        name: 'mdi-home-account'
      },
      {
        unicode: 'F087B',
        name: 'mdi-home-alert'
      },
      {
        unicode: 'F0EBA',
        name: 'mdi-home-analytics'
      },
      {
        unicode: 'F07D0',
        name: 'mdi-home-assistant'
      },
      {
        unicode: 'F07D1',
        name: 'mdi-home-automation'
      },
      {
        unicode: 'F07D2',
        name: 'mdi-home-circle'
      },
      {
        unicode: 'F104D',
        name: 'mdi-home-circle-outline'
      },
      {
        unicode: 'F0D15',
        name: 'mdi-home-city'
      },
      {
        unicode: 'F0D16',
        name: 'mdi-home-city-outline'
      },
      {
        unicode: 'F08AF',
        name: 'mdi-home-currency-usd'
      },
      {
        unicode: 'F1159',
        name: 'mdi-home-edit'
      },
      {
        unicode: 'F115A',
        name: 'mdi-home-edit-outline'
      },
      {
        unicode: 'F0F9B',
        name: 'mdi-home-export-outline'
      },
      {
        unicode: 'F0EFA',
        name: 'mdi-home-flood'
      },
      {
        unicode: 'F0DD2',
        name: 'mdi-home-floor-0'
      },
      {
        unicode: 'F0D80',
        name: 'mdi-home-floor-1'
      },
      {
        unicode: 'F0D81',
        name: 'mdi-home-floor-2'
      },
      {
        unicode: 'F0D82',
        name: 'mdi-home-floor-3'
      },
      {
        unicode: 'F0D83',
        name: 'mdi-home-floor-a'
      },
      {
        unicode: 'F0D84',
        name: 'mdi-home-floor-b'
      },
      {
        unicode: 'F0D85',
        name: 'mdi-home-floor-g'
      },
      {
        unicode: 'F0D86',
        name: 'mdi-home-floor-l'
      },
      {
        unicode: 'F0DD3',
        name: 'mdi-home-floor-negative-1'
      },
      {
        unicode: 'F0DD4',
        name: 'mdi-home-group'
      },
      {
        unicode: 'F0827',
        name: 'mdi-home-heart'
      },
      {
        unicode: 'F0F9C',
        name: 'mdi-home-import-outline'
      },
      {
        unicode: 'F1251',
        name: 'mdi-home-lightbulb'
      },
      {
        unicode: 'F1252',
        name: 'mdi-home-lightbulb-outline'
      },
      {
        unicode: 'F08EB',
        name: 'mdi-home-lock'
      },
      {
        unicode: 'F08EC',
        name: 'mdi-home-lock-open'
      },
      {
        unicode: 'F05F8',
        name: 'mdi-home-map-marker'
      },
      {
        unicode: 'F0974',
        name: 'mdi-home-minus'
      },
      {
        unicode: 'F13D5',
        name: 'mdi-home-minus-outline'
      },
      {
        unicode: 'F02DD',
        name: 'mdi-home-modern'
      },
      {
        unicode: 'F06A1',
        name: 'mdi-home-outline'
      },
      {
        unicode: 'F0975',
        name: 'mdi-home-plus'
      },
      {
        unicode: 'F13D6',
        name: 'mdi-home-plus-outline'
      },
      {
        unicode: 'F1247',
        name: 'mdi-home-remove'
      },
      {
        unicode: 'F13D7',
        name: 'mdi-home-remove-outline'
      },
      {
        unicode: 'F112B',
        name: 'mdi-home-roof'
      },
      {
        unicode: 'F13B0',
        name: 'mdi-home-search'
      },
      {
        unicode: 'F13B1',
        name: 'mdi-home-search-outline'
      },
      {
        unicode: 'F0F54',
        name: 'mdi-home-thermometer'
      },
      {
        unicode: 'F0F55',
        name: 'mdi-home-thermometer-outline'
      },
      {
        unicode: 'F02DE',
        name: 'mdi-home-variant'
      },
      {
        unicode: 'F0BA7',
        name: 'mdi-home-variant-outline'
      },
      {
        unicode: 'F06E2',
        name: 'mdi-hook'
      },
      {
        unicode: 'F06E3',
        name: 'mdi-hook-off'
      },
      {
        unicode: 'F02DF',
        name: 'mdi-hops'
      },
      {
        unicode: 'F10F3',
        name: 'mdi-horizontal-rotate-clockwise'
      },
      {
        unicode: 'F10F4',
        name: 'mdi-horizontal-rotate-counterclockwise'
      },
      {
        unicode: 'F0A58',
        name: 'mdi-horseshoe'
      },
      {
        unicode: 'F0FF6',
        name: 'mdi-hospital'
      },
      {
        unicode: 'F02E0',
        name: 'mdi-hospital-box'
      },
      {
        unicode: 'F0FF7',
        name: 'mdi-hospital-box-outline'
      },
      {
        unicode: 'F02E1',
        name: 'mdi-hospital-building'
      },
      {
        unicode: 'F02E2',
        name: 'mdi-hospital-marker'
      },
      {
        unicode: 'F0828',
        name: 'mdi-hot-tub'
      },
      {
        unicode: 'F1478',
        name: 'mdi-hours-24'
      },
      {
        unicode: 'F0D17',
        name: 'mdi-hubspot'
      },
      {
        unicode: 'F0829',
        name: 'mdi-hulu'
      },
      {
        unicode: 'F02E6',
        name: 'mdi-human'
      },
      {
        unicode: 'F138B',
        name: 'mdi-human-baby-changing-table'
      },
      {
        unicode: 'F02E7',
        name: 'mdi-human-child'
      },
      {
        unicode: 'F0649',
        name: 'mdi-human-female'
      },
      {
        unicode: 'F0A59',
        name: 'mdi-human-female-boy'
      },
      {
        unicode: 'F0A5A',
        name: 'mdi-human-female-female'
      },
      {
        unicode: 'F0A5B',
        name: 'mdi-human-female-girl'
      },
      {
        unicode: 'F064A',
        name: 'mdi-human-greeting'
      },
      {
        unicode: 'F064B',
        name: 'mdi-human-handsdown'
      },
      {
        unicode: 'F064C',
        name: 'mdi-human-handsup'
      },
      {
        unicode: 'F064D',
        name: 'mdi-human-male'
      },
      {
        unicode: 'F0A5C',
        name: 'mdi-human-male-boy'
      },
      {
        unicode: 'F138C',
        name: 'mdi-human-male-child'
      },
      {
        unicode: 'F02E8',
        name: 'mdi-human-male-female'
      },
      {
        unicode: 'F0A5D',
        name: 'mdi-human-male-girl'
      },
      {
        unicode: 'F0EFB',
        name: 'mdi-human-male-height'
      },
      {
        unicode: 'F0EFC',
        name: 'mdi-human-male-height-variant'
      },
      {
        unicode: 'F0A5E',
        name: 'mdi-human-male-male'
      },
      {
        unicode: 'F05CF',
        name: 'mdi-human-pregnant'
      },
      {
        unicode: 'F138D',
        name: 'mdi-human-wheelchair'
      },
      {
        unicode: 'F0744',
        name: 'mdi-humble-bundle'
      },
      {
        unicode: 'F1352',
        name: 'mdi-hvac'
      },
      {
        unicode: 'F1324',
        name: 'mdi-hydraulic-oil-level'
      },
      {
        unicode: 'F1325',
        name: 'mdi-hydraulic-oil-temperature'
      },
      {
        unicode: 'F12E5',
        name: 'mdi-hydro-power'
      },
      {
        unicode: 'F082A',
        name: 'mdi-ice-cream'
      },
      {
        unicode: 'F0E52',
        name: 'mdi-ice-cream-off'
      },
      {
        unicode: 'F0EFD',
        name: 'mdi-ice-pop'
      },
      {
        unicode: 'F0FC0',
        name: 'mdi-id-card'
      },
      {
        unicode: 'F0EFE',
        name: 'mdi-identifier'
      },
      {
        unicode: 'F1331',
        name: 'mdi-ideogram-cjk'
      },
      {
        unicode: 'F1332',
        name: 'mdi-ideogram-cjk-variant'
      },
      {
        unicode: 'F0C8B',
        name: 'mdi-iframe'
      },
      {
        unicode: 'F10F5',
        name: 'mdi-iframe-array'
      },
      {
        unicode: 'F10F6',
        name: 'mdi-iframe-array-outline'
      },
      {
        unicode: 'F10F7',
        name: 'mdi-iframe-braces'
      },
      {
        unicode: 'F10F8',
        name: 'mdi-iframe-braces-outline'
      },
      {
        unicode: 'F0C8C',
        name: 'mdi-iframe-outline'
      },
      {
        unicode: 'F10F9',
        name: 'mdi-iframe-parentheses'
      },
      {
        unicode: 'F10FA',
        name: 'mdi-iframe-parentheses-outline'
      },
      {
        unicode: 'F10FB',
        name: 'mdi-iframe-variable'
      },
      {
        unicode: 'F10FC',
        name: 'mdi-iframe-variable-outline'
      },
      {
        unicode: 'F02E9',
        name: 'mdi-image'
      },
      {
        unicode: 'F02EA',
        name: 'mdi-image-album'
      },
      {
        unicode: 'F02EB',
        name: 'mdi-image-area'
      },
      {
        unicode: 'F02EC',
        name: 'mdi-image-area-close'
      },
      {
        unicode: 'F0FC1',
        name: 'mdi-image-auto-adjust'
      },
      {
        unicode: 'F02ED',
        name: 'mdi-image-broken'
      },
      {
        unicode: 'F02EE',
        name: 'mdi-image-broken-variant'
      },
      {
        unicode: 'F11E3',
        name: 'mdi-image-edit'
      },
      {
        unicode: 'F11E4',
        name: 'mdi-image-edit-outline'
      },
      {
        unicode: 'F02F0',
        name: 'mdi-image-filter-black-white'
      },
      {
        unicode: 'F02F1',
        name: 'mdi-image-filter-center-focus'
      },
      {
        unicode: 'F0EFF',
        name: 'mdi-image-filter-center-focus-strong'
      },
      {
        unicode: 'F0F00',
        name: 'mdi-image-filter-center-focus-strong-outline'
      },
      {
        unicode: 'F02F2',
        name: 'mdi-image-filter-center-focus-weak'
      },
      {
        unicode: 'F02F3',
        name: 'mdi-image-filter-drama'
      },
      {
        unicode: 'F02F4',
        name: 'mdi-image-filter-frames'
      },
      {
        unicode: 'F02F5',
        name: 'mdi-image-filter-hdr'
      },
      {
        unicode: 'F02F6',
        name: 'mdi-image-filter-none'
      },
      {
        unicode: 'F02F7',
        name: 'mdi-image-filter-tilt-shift'
      },
      {
        unicode: 'F02F8',
        name: 'mdi-image-filter-vintage'
      },
      {
        unicode: 'F0E49',
        name: 'mdi-image-frame'
      },
      {
        unicode: 'F1419',
        name: 'mdi-image-minus'
      },
      {
        unicode: 'F09F8',
        name: 'mdi-image-move'
      },
      {
        unicode: 'F02F9',
        name: 'mdi-image-multiple'
      },
      {
        unicode: 'F02EF',
        name: 'mdi-image-multiple-outline'
      },
      {
        unicode: 'F082B',
        name: 'mdi-image-off'
      },
      {
        unicode: 'F11D1',
        name: 'mdi-image-off-outline'
      },
      {
        unicode: 'F0976',
        name: 'mdi-image-outline'
      },
      {
        unicode: 'F087C',
        name: 'mdi-image-plus'
      },
      {
        unicode: 'F1418',
        name: 'mdi-image-remove'
      },
      {
        unicode: 'F0977',
        name: 'mdi-image-search'
      },
      {
        unicode: 'F0978',
        name: 'mdi-image-search-outline'
      },
      {
        unicode: 'F0C8D',
        name: 'mdi-image-size-select-actual'
      },
      {
        unicode: 'F0C8E',
        name: 'mdi-image-size-select-large'
      },
      {
        unicode: 'F0C8F',
        name: 'mdi-image-size-select-small'
      },
      {
        unicode: 'F02FA',
        name: 'mdi-import'
      },
      {
        unicode: 'F0687',
        name: 'mdi-inbox'
      },
      {
        unicode: 'F02FB',
        name: 'mdi-inbox-arrow-down'
      },
      {
        unicode: 'F1270',
        name: 'mdi-inbox-arrow-down-outline'
      },
      {
        unicode: 'F03D1',
        name: 'mdi-inbox-arrow-up'
      },
      {
        unicode: 'F1271',
        name: 'mdi-inbox-arrow-up-outline'
      },
      {
        unicode: 'F1272',
        name: 'mdi-inbox-full'
      },
      {
        unicode: 'F1273',
        name: 'mdi-inbox-full-outline'
      },
      {
        unicode: 'F08B0',
        name: 'mdi-inbox-multiple'
      },
      {
        unicode: 'F0BA8',
        name: 'mdi-inbox-multiple-outline'
      },
      {
        unicode: 'F1274',
        name: 'mdi-inbox-outline'
      },
      {
        unicode: 'F05F9',
        name: 'mdi-incognito'
      },
      {
        unicode: 'F1421',
        name: 'mdi-incognito-circle'
      },
      {
        unicode: 'F1422',
        name: 'mdi-incognito-circle-off'
      },
      {
        unicode: 'F0075',
        name: 'mdi-incognito-off'
      },
      {
        unicode: 'F06E4',
        name: 'mdi-infinity'
      },
      {
        unicode: 'F02FC',
        name: 'mdi-information'
      },
      {
        unicode: 'F02FD',
        name: 'mdi-information-outline'
      },
      {
        unicode: 'F064E',
        name: 'mdi-information-variant'
      },
      {
        unicode: 'F02FE',
        name: 'mdi-instagram'
      },
      {
        unicode: 'F104E',
        name: 'mdi-instrument-triangle'
      },
      {
        unicode: 'F0301',
        name: 'mdi-invert-colors'
      },
      {
        unicode: 'F0E4A',
        name: 'mdi-invert-colors-off'
      },
      {
        unicode: 'F12E8',
        name: 'mdi-iobroker'
      },
      {
        unicode: 'F0A5F',
        name: 'mdi-ip'
      },
      {
        unicode: 'F0A60',
        name: 'mdi-ip-network'
      },
      {
        unicode: 'F0C90',
        name: 'mdi-ip-network-outline'
      },
      {
        unicode: 'F0C91',
        name: 'mdi-ipod'
      },
      {
        unicode: 'F0979',
        name: 'mdi-islam'
      },
      {
        unicode: 'F104F',
        name: 'mdi-island'
      },
      {
        unicode: 'F10B9',
        name: 'mdi-iv-bag'
      },
      {
        unicode: 'F0DD5',
        name: 'mdi-jabber'
      },
      {
        unicode: 'F0302',
        name: 'mdi-jeepney'
      },
      {
        unicode: 'F0F01',
        name: 'mdi-jellyfish'
      },
      {
        unicode: 'F0F02',
        name: 'mdi-jellyfish-outline'
      },
      {
        unicode: 'F0303',
        name: 'mdi-jira'
      },
      {
        unicode: 'F087D',
        name: 'mdi-jquery'
      },
      {
        unicode: 'F0304',
        name: 'mdi-jsfiddle'
      },
      {
        unicode: 'F097A',
        name: 'mdi-judaism'
      },
      {
        unicode: 'F12FF',
        name: 'mdi-jump-rope'
      },
      {
        unicode: 'F0D87',
        name: 'mdi-kabaddi'
      },
      {
        unicode: 'F082C',
        name: 'mdi-karate'
      },
      {
        unicode: 'F0305',
        name: 'mdi-keg'
      },
      {
        unicode: 'F05FA',
        name: 'mdi-kettle'
      },
      {
        unicode: 'F1317',
        name: 'mdi-kettle-alert'
      },
      {
        unicode: 'F1318',
        name: 'mdi-kettle-alert-outline'
      },
      {
        unicode: 'F131B',
        name: 'mdi-kettle-off'
      },
      {
        unicode: 'F131C',
        name: 'mdi-kettle-off-outline'
      },
      {
        unicode: 'F0F56',
        name: 'mdi-kettle-outline'
      },
      {
        unicode: 'F1319',
        name: 'mdi-kettle-steam'
      },
      {
        unicode: 'F131A',
        name: 'mdi-kettle-steam-outline'
      },
      {
        unicode: 'F1300',
        name: 'mdi-kettlebell'
      },
      {
        unicode: 'F0306',
        name: 'mdi-key'
      },
      {
        unicode: 'F1312',
        name: 'mdi-key-arrow-right'
      },
      {
        unicode: 'F0307',
        name: 'mdi-key-change'
      },
      {
        unicode: 'F119F',
        name: 'mdi-key-link'
      },
      {
        unicode: 'F0308',
        name: 'mdi-key-minus'
      },
      {
        unicode: 'F0DD6',
        name: 'mdi-key-outline'
      },
      {
        unicode: 'F0309',
        name: 'mdi-key-plus'
      },
      {
        unicode: 'F030A',
        name: 'mdi-key-remove'
      },
      {
        unicode: 'F119E',
        name: 'mdi-key-star'
      },
      {
        unicode: 'F030B',
        name: 'mdi-key-variant'
      },
      {
        unicode: 'F0FC2',
        name: 'mdi-key-wireless'
      },
      {
        unicode: 'F030C',
        name: 'mdi-keyboard'
      },
      {
        unicode: 'F030D',
        name: 'mdi-keyboard-backspace'
      },
      {
        unicode: 'F030E',
        name: 'mdi-keyboard-caps'
      },
      {
        unicode: 'F030F',
        name: 'mdi-keyboard-close'
      },
      {
        unicode: 'F12B7',
        name: 'mdi-keyboard-esc'
      },
      {
        unicode: 'F12AB',
        name: 'mdi-keyboard-f1'
      },
      {
        unicode: 'F12B4',
        name: 'mdi-keyboard-f10'
      },
      {
        unicode: 'F12B5',
        name: 'mdi-keyboard-f11'
      },
      {
        unicode: 'F12B6',
        name: 'mdi-keyboard-f12'
      },
      {
        unicode: 'F12AC',
        name: 'mdi-keyboard-f2'
      },
      {
        unicode: 'F12AD',
        name: 'mdi-keyboard-f3'
      },
      {
        unicode: 'F12AE',
        name: 'mdi-keyboard-f4'
      },
      {
        unicode: 'F12AF',
        name: 'mdi-keyboard-f5'
      },
      {
        unicode: 'F12B0',
        name: 'mdi-keyboard-f6'
      },
      {
        unicode: 'F12B1',
        name: 'mdi-keyboard-f7'
      },
      {
        unicode: 'F12B2',
        name: 'mdi-keyboard-f8'
      },
      {
        unicode: 'F12B3',
        name: 'mdi-keyboard-f9'
      },
      {
        unicode: 'F0310',
        name: 'mdi-keyboard-off'
      },
      {
        unicode: 'F0E4B',
        name: 'mdi-keyboard-off-outline'
      },
      {
        unicode: 'F097B',
        name: 'mdi-keyboard-outline'
      },
      {
        unicode: 'F0311',
        name: 'mdi-keyboard-return'
      },
      {
        unicode: 'F09F9',
        name: 'mdi-keyboard-settings'
      },
      {
        unicode: 'F09FA',
        name: 'mdi-keyboard-settings-outline'
      },
      {
        unicode: 'F1050',
        name: 'mdi-keyboard-space'
      },
      {
        unicode: 'F0312',
        name: 'mdi-keyboard-tab'
      },
      {
        unicode: 'F0313',
        name: 'mdi-keyboard-variant'
      },
      {
        unicode: 'F10FD',
        name: 'mdi-khanda'
      },
      {
        unicode: 'F0745',
        name: 'mdi-kickstarter'
      },
      {
        unicode: 'F135B',
        name: 'mdi-klingon'
      },
      {
        unicode: 'F09FB',
        name: 'mdi-knife'
      },
      {
        unicode: 'F09FC',
        name: 'mdi-knife-military'
      },
      {
        unicode: 'F0314',
        name: 'mdi-kodi'
      },
      {
        unicode: 'F10FE',
        name: 'mdi-kubernetes'
      },
      {
        unicode: 'F0315',
        name: 'mdi-label'
      },
      {
        unicode: 'F1375',
        name: 'mdi-label-multiple'
      },
      {
        unicode: 'F1376',
        name: 'mdi-label-multiple-outline'
      },
      {
        unicode: 'F0ACB',
        name: 'mdi-label-off'
      },
      {
        unicode: 'F0ACC',
        name: 'mdi-label-off-outline'
      },
      {
        unicode: 'F0316',
        name: 'mdi-label-outline'
      },
      {
        unicode: 'F12EA',
        name: 'mdi-label-percent'
      },
      {
        unicode: 'F12EB',
        name: 'mdi-label-percent-outline'
      },
      {
        unicode: 'F0ACD',
        name: 'mdi-label-variant'
      },
      {
        unicode: 'F0ACE',
        name: 'mdi-label-variant-outline'
      },
      {
        unicode: 'F082D',
        name: 'mdi-ladybug'
      },
      {
        unicode: 'F0627',
        name: 'mdi-lambda'
      },
      {
        unicode: 'F06B5',
        name: 'mdi-lamp'
      },
      {
        unicode: 'F0317',
        name: 'mdi-lan'
      },
      {
        unicode: 'F12AA',
        name: 'mdi-lan-check'
      },
      {
        unicode: 'F0318',
        name: 'mdi-lan-connect'
      },
      {
        unicode: 'F0319',
        name: 'mdi-lan-disconnect'
      },
      {
        unicode: 'F031A',
        name: 'mdi-lan-pending'
      },
      {
        unicode: 'F0671',
        name: 'mdi-language-c'
      },
      {
        unicode: 'F0672',
        name: 'mdi-language-cpp'
      },
      {
        unicode: 'F031B',
        name: 'mdi-language-csharp'
      },
      {
        unicode: 'F031C',
        name: 'mdi-language-css3'
      },
      {
        unicode: 'F121A',
        name: 'mdi-language-fortran'
      },
      {
        unicode: 'F07D3',
        name: 'mdi-language-go'
      },
      {
        unicode: 'F0C92',
        name: 'mdi-language-haskell'
      },
      {
        unicode: 'F031D',
        name: 'mdi-language-html5'
      },
      {
        unicode: 'F0B37',
        name: 'mdi-language-java'
      },
      {
        unicode: 'F031E',
        name: 'mdi-language-javascript'
      },
      {
        unicode: 'F1219',
        name: 'mdi-language-kotlin'
      },
      {
        unicode: 'F08B1',
        name: 'mdi-language-lua'
      },
      {
        unicode: 'F0354',
        name: 'mdi-language-markdown'
      },
      {
        unicode: 'F0F5B',
        name: 'mdi-language-markdown-outline'
      },
      {
        unicode: 'F031F',
        name: 'mdi-language-php'
      },
      {
        unicode: 'F0320',
        name: 'mdi-language-python'
      },
      {
        unicode: 'F07D4',
        name: 'mdi-language-r'
      },
      {
        unicode: 'F0D2D',
        name: 'mdi-language-ruby'
      },
      {
        unicode: 'F0ACF',
        name: 'mdi-language-ruby-on-rails'
      },
      {
        unicode: 'F06E5',
        name: 'mdi-language-swift'
      },
      {
        unicode: 'F06E6',
        name: 'mdi-language-typescript'
      },
      {
        unicode: 'F0673',
        name: 'mdi-language-xaml'
      },
      {
        unicode: 'F0322',
        name: 'mdi-laptop'
      },
      {
        unicode: 'F0323',
        name: 'mdi-laptop-chromebook'
      },
      {
        unicode: 'F0324',
        name: 'mdi-laptop-mac'
      },
      {
        unicode: 'F06E7',
        name: 'mdi-laptop-off'
      },
      {
        unicode: 'F0325',
        name: 'mdi-laptop-windows'
      },
      {
        unicode: 'F0AD0',
        name: 'mdi-laravel'
      },
      {
        unicode: 'F1484',
        name: 'mdi-laser-pointer'
      },
      {
        unicode: 'F0F03',
        name: 'mdi-lasso'
      },
      {
        unicode: 'F0446',
        name: 'mdi-lastpass'
      },
      {
        unicode: 'F0F57',
        name: 'mdi-latitude'
      },
      {
        unicode: 'F0327',
        name: 'mdi-launch'
      },
      {
        unicode: 'F07D5',
        name: 'mdi-lava-lamp'
      },
      {
        unicode: 'F0328',
        name: 'mdi-layers'
      },
      {
        unicode: 'F0E4C',
        name: 'mdi-layers-minus'
      },
      {
        unicode: 'F0329',
        name: 'mdi-layers-off'
      },
      {
        unicode: 'F09FD',
        name: 'mdi-layers-off-outline'
      },
      {
        unicode: 'F09FE',
        name: 'mdi-layers-outline'
      },
      {
        unicode: 'F0E4D',
        name: 'mdi-layers-plus'
      },
      {
        unicode: 'F0E4E',
        name: 'mdi-layers-remove'
      },
      {
        unicode: 'F1206',
        name: 'mdi-layers-search'
      },
      {
        unicode: 'F1207',
        name: 'mdi-layers-search-outline'
      },
      {
        unicode: 'F0F58',
        name: 'mdi-layers-triple'
      },
      {
        unicode: 'F0F59',
        name: 'mdi-layers-triple-outline'
      },
      {
        unicode: 'F064F',
        name: 'mdi-lead-pencil'
      },
      {
        unicode: 'F032A',
        name: 'mdi-leaf'
      },
      {
        unicode: 'F0C93',
        name: 'mdi-leaf-maple'
      },
      {
        unicode: 'F12DA',
        name: 'mdi-leaf-maple-off'
      },
      {
        unicode: 'F12D9',
        name: 'mdi-leaf-off'
      },
      {
        unicode: 'F0DD7',
        name: 'mdi-leak'
      },
      {
        unicode: 'F0DD8',
        name: 'mdi-leak-off'
      },
      {
        unicode: 'F032B',
        name: 'mdi-led-off'
      },
      {
        unicode: 'F032C',
        name: 'mdi-led-on'
      },
      {
        unicode: 'F032D',
        name: 'mdi-led-outline'
      },
      {
        unicode: 'F07D6',
        name: 'mdi-led-strip'
      },
      {
        unicode: 'F1051',
        name: 'mdi-led-strip-variant'
      },
      {
        unicode: 'F032E',
        name: 'mdi-led-variant-off'
      },
      {
        unicode: 'F032F',
        name: 'mdi-led-variant-on'
      },
      {
        unicode: 'F0330',
        name: 'mdi-led-variant-outline'
      },
      {
        unicode: 'F117D',
        name: 'mdi-leek'
      },
      {
        unicode: 'F097C',
        name: 'mdi-less-than'
      },
      {
        unicode: 'F097D',
        name: 'mdi-less-than-or-equal'
      },
      {
        unicode: 'F0331',
        name: 'mdi-library'
      },
      {
        unicode: 'F0BA9',
        name: 'mdi-library-shelves'
      },
      {
        unicode: 'F0FC3',
        name: 'mdi-license'
      },
      {
        unicode: 'F087E',
        name: 'mdi-lifebuoy'
      },
      {
        unicode: 'F097E',
        name: 'mdi-light-switch'
      },
      {
        unicode: 'F0335',
        name: 'mdi-lightbulb'
      },
      {
        unicode: 'F1208',
        name: 'mdi-lightbulb-cfl'
      },
      {
        unicode: 'F1209',
        name: 'mdi-lightbulb-cfl-off'
      },
      {
        unicode: 'F1275',
        name: 'mdi-lightbulb-cfl-spiral'
      },
      {
        unicode: 'F12C3',
        name: 'mdi-lightbulb-cfl-spiral-off'
      },
      {
        unicode: 'F1253',
        name: 'mdi-lightbulb-group'
      },
      {
        unicode: 'F12CD',
        name: 'mdi-lightbulb-group-off'
      },
      {
        unicode: 'F12CE',
        name: 'mdi-lightbulb-group-off-outline'
      },
      {
        unicode: 'F1254',
        name: 'mdi-lightbulb-group-outline'
      },
      {
        unicode: 'F1255',
        name: 'mdi-lightbulb-multiple'
      },
      {
        unicode: 'F12CF',
        name: 'mdi-lightbulb-multiple-off'
      },
      {
        unicode: 'F12D0',
        name: 'mdi-lightbulb-multiple-off-outline'
      },
      {
        unicode: 'F1256',
        name: 'mdi-lightbulb-multiple-outline'
      },
      {
        unicode: 'F0E4F',
        name: 'mdi-lightbulb-off'
      },
      {
        unicode: 'F0E50',
        name: 'mdi-lightbulb-off-outline'
      },
      {
        unicode: 'F06E8',
        name: 'mdi-lightbulb-on'
      },
      {
        unicode: 'F06E9',
        name: 'mdi-lightbulb-on-outline'
      },
      {
        unicode: 'F0336',
        name: 'mdi-lightbulb-outline'
      },
      {
        unicode: 'F09FF',
        name: 'mdi-lighthouse'
      },
      {
        unicode: 'F0A00',
        name: 'mdi-lighthouse-on'
      },
      {
        unicode: 'F140B',
        name: 'mdi-lightning-bolt'
      },
      {
        unicode: 'F140C',
        name: 'mdi-lightning-bolt-outline'
      },
      {
        unicode: 'F1476',
        name: 'mdi-lingerie'
      },
      {
        unicode: 'F0337',
        name: 'mdi-link'
      },
      {
        unicode: 'F0D1A',
        name: 'mdi-link-box'
      },
      {
        unicode: 'F0D1B',
        name: 'mdi-link-box-outline'
      },
      {
        unicode: 'F0D1C',
        name: 'mdi-link-box-variant'
      },
      {
        unicode: 'F0D1D',
        name: 'mdi-link-box-variant-outline'
      },
      {
        unicode: 'F10BA',
        name: 'mdi-link-lock'
      },
      {
        unicode: 'F0338',
        name: 'mdi-link-off'
      },
      {
        unicode: 'F0C94',
        name: 'mdi-link-plus'
      },
      {
        unicode: 'F0339',
        name: 'mdi-link-variant'
      },
      {
        unicode: 'F10FF',
        name: 'mdi-link-variant-minus'
      },
      {
        unicode: 'F033A',
        name: 'mdi-link-variant-off'
      },
      {
        unicode: 'F1100',
        name: 'mdi-link-variant-plus'
      },
      {
        unicode: 'F1101',
        name: 'mdi-link-variant-remove'
      },
      {
        unicode: 'F033B',
        name: 'mdi-linkedin'
      },
      {
        unicode: 'F033D',
        name: 'mdi-linux'
      },
      {
        unicode: 'F08ED',
        name: 'mdi-linux-mint'
      },
      {
        unicode: 'F13B5',
        name: 'mdi-lipstick'
      },
      {
        unicode: 'F0A61',
        name: 'mdi-litecoin'
      },
      {
        unicode: 'F0772',
        name: 'mdi-loading'
      },
      {
        unicode: 'F0FC4',
        name: 'mdi-location-enter'
      },
      {
        unicode: 'F0FC5',
        name: 'mdi-location-exit'
      },
      {
        unicode: 'F033E',
        name: 'mdi-lock'
      },
      {
        unicode: 'F08EE',
        name: 'mdi-lock-alert'
      },
      {
        unicode: 'F139A',
        name: 'mdi-lock-check'
      },
      {
        unicode: 'F097F',
        name: 'mdi-lock-clock'
      },
      {
        unicode: 'F033F',
        name: 'mdi-lock-open'
      },
      {
        unicode: 'F139B',
        name: 'mdi-lock-open-alert'
      },
      {
        unicode: 'F139C',
        name: 'mdi-lock-open-check'
      },
      {
        unicode: 'F0340',
        name: 'mdi-lock-open-outline'
      },
      {
        unicode: 'F0FC6',
        name: 'mdi-lock-open-variant'
      },
      {
        unicode: 'F0FC7',
        name: 'mdi-lock-open-variant-outline'
      },
      {
        unicode: 'F0341',
        name: 'mdi-lock-outline'
      },
      {
        unicode: 'F06EA',
        name: 'mdi-lock-pattern'
      },
      {
        unicode: 'F05FB',
        name: 'mdi-lock-plus'
      },
      {
        unicode: 'F08EF',
        name: 'mdi-lock-question'
      },
      {
        unicode: 'F0773',
        name: 'mdi-lock-reset'
      },
      {
        unicode: 'F08B2',
        name: 'mdi-lock-smart'
      },
      {
        unicode: 'F07D7',
        name: 'mdi-locker'
      },
      {
        unicode: 'F07D8',
        name: 'mdi-locker-multiple'
      },
      {
        unicode: 'F0342',
        name: 'mdi-login'
      },
      {
        unicode: 'F05FC',
        name: 'mdi-login-variant'
      },
      {
        unicode: 'F0343',
        name: 'mdi-logout'
      },
      {
        unicode: 'F05FD',
        name: 'mdi-logout-variant'
      },
      {
        unicode: 'F0F5A',
        name: 'mdi-longitude'
      },
      {
        unicode: 'F0344',
        name: 'mdi-looks'
      },
      {
        unicode: 'F0345',
        name: 'mdi-loupe'
      },
      {
        unicode: 'F0346',
        name: 'mdi-lumx'
      },
      {
        unicode: 'F1084',
        name: 'mdi-lungs'
      },
      {
        unicode: 'F0347',
        name: 'mdi-magnet'
      },
      {
        unicode: 'F0348',
        name: 'mdi-magnet-on'
      },
      {
        unicode: 'F0349',
        name: 'mdi-magnify'
      },
      {
        unicode: 'F0980',
        name: 'mdi-magnify-close'
      },
      {
        unicode: 'F034A',
        name: 'mdi-magnify-minus'
      },
      {
        unicode: 'F0A62',
        name: 'mdi-magnify-minus-cursor'
      },
      {
        unicode: 'F06EC',
        name: 'mdi-magnify-minus-outline'
      },
      {
        unicode: 'F034B',
        name: 'mdi-magnify-plus'
      },
      {
        unicode: 'F0A63',
        name: 'mdi-magnify-plus-cursor'
      },
      {
        unicode: 'F06ED',
        name: 'mdi-magnify-plus-outline'
      },
      {
        unicode: 'F120C',
        name: 'mdi-magnify-remove-cursor'
      },
      {
        unicode: 'F120D',
        name: 'mdi-magnify-remove-outline'
      },
      {
        unicode: 'F1276',
        name: 'mdi-magnify-scan'
      },
      {
        unicode: 'F0EBB',
        name: 'mdi-mail'
      },
      {
        unicode: 'F06EE',
        name: 'mdi-mailbox'
      },
      {
        unicode: 'F0D88',
        name: 'mdi-mailbox-open'
      },
      {
        unicode: 'F0D89',
        name: 'mdi-mailbox-open-outline'
      },
      {
        unicode: 'F0D8A',
        name: 'mdi-mailbox-open-up'
      },
      {
        unicode: 'F0D8B',
        name: 'mdi-mailbox-open-up-outline'
      },
      {
        unicode: 'F0D8C',
        name: 'mdi-mailbox-outline'
      },
      {
        unicode: 'F0D8D',
        name: 'mdi-mailbox-up'
      },
      {
        unicode: 'F0D8E',
        name: 'mdi-mailbox-up-outline'
      },
      {
        unicode: 'F034D',
        name: 'mdi-map'
      },
      {
        unicode: 'F0EBC',
        name: 'mdi-map-check'
      },
      {
        unicode: 'F0EBD',
        name: 'mdi-map-check-outline'
      },
      {
        unicode: 'F0D1E',
        name: 'mdi-map-clock'
      },
      {
        unicode: 'F0D1F',
        name: 'mdi-map-clock-outline'
      },
      {
        unicode: 'F0A01',
        name: 'mdi-map-legend'
      },
      {
        unicode: 'F034E',
        name: 'mdi-map-marker'
      },
      {
        unicode: 'F0F05',
        name: 'mdi-map-marker-alert'
      },
      {
        unicode: 'F0F06',
        name: 'mdi-map-marker-alert-outline'
      },
      {
        unicode: 'F0C95',
        name: 'mdi-map-marker-check'
      },
      {
        unicode: 'F12FB',
        name: 'mdi-map-marker-check-outline'
      },
      {
        unicode: 'F034F',
        name: 'mdi-map-marker-circle'
      },
      {
        unicode: 'F08F0',
        name: 'mdi-map-marker-distance'
      },
      {
        unicode: 'F1102',
        name: 'mdi-map-marker-down'
      },
      {
        unicode: 'F12DB',
        name: 'mdi-map-marker-left'
      },
      {
        unicode: 'F12DD',
        name: 'mdi-map-marker-left-outline'
      },
      {
        unicode: 'F0650',
        name: 'mdi-map-marker-minus'
      },
      {
        unicode: 'F12F9',
        name: 'mdi-map-marker-minus-outline'
      },
      {
        unicode: 'F0350',
        name: 'mdi-map-marker-multiple'
      },
      {
        unicode: 'F1277',
        name: 'mdi-map-marker-multiple-outline'
      },
      {
        unicode: 'F0351',
        name: 'mdi-map-marker-off'
      },
      {
        unicode: 'F12FD',
        name: 'mdi-map-marker-off-outline'
      },
      {
        unicode: 'F07D9',
        name: 'mdi-map-marker-outline'
      },
      {
        unicode: 'F0D20',
        name: 'mdi-map-marker-path'
      },
      {
        unicode: 'F0651',
        name: 'mdi-map-marker-plus'
      },
      {
        unicode: 'F12F8',
        name: 'mdi-map-marker-plus-outline'
      },
      {
        unicode: 'F0F07',
        name: 'mdi-map-marker-question'
      },
      {
        unicode: 'F0F08',
        name: 'mdi-map-marker-question-outline'
      },
      {
        unicode: 'F0352',
        name: 'mdi-map-marker-radius'
      },
      {
        unicode: 'F12FC',
        name: 'mdi-map-marker-radius-outline'
      },
      {
        unicode: 'F0F09',
        name: 'mdi-map-marker-remove'
      },
      {
        unicode: 'F12FA',
        name: 'mdi-map-marker-remove-outline'
      },
      {
        unicode: 'F0F0A',
        name: 'mdi-map-marker-remove-variant'
      },
      {
        unicode: 'F12DC',
        name: 'mdi-map-marker-right'
      },
      {
        unicode: 'F12DE',
        name: 'mdi-map-marker-right-outline'
      },
      {
        unicode: 'F1103',
        name: 'mdi-map-marker-up'
      },
      {
        unicode: 'F0981',
        name: 'mdi-map-minus'
      },
      {
        unicode: 'F0982',
        name: 'mdi-map-outline'
      },
      {
        unicode: 'F0983',
        name: 'mdi-map-plus'
      },
      {
        unicode: 'F0984',
        name: 'mdi-map-search'
      },
      {
        unicode: 'F0985',
        name: 'mdi-map-search-outline'
      },
      {
        unicode: 'F0BAA',
        name: 'mdi-mapbox'
      },
      {
        unicode: 'F0353',
        name: 'mdi-margin'
      },
      {
        unicode: 'F0652',
        name: 'mdi-marker'
      },
      {
        unicode: 'F0DD9',
        name: 'mdi-marker-cancel'
      },
      {
        unicode: 'F0355',
        name: 'mdi-marker-check'
      },
      {
        unicode: 'F0AD1',
        name: 'mdi-mastodon'
      },
      {
        unicode: 'F0986',
        name: 'mdi-material-design'
      },
      {
        unicode: 'F0357',
        name: 'mdi-material-ui'
      },
      {
        unicode: 'F0358',
        name: 'mdi-math-compass'
      },
      {
        unicode: 'F0C96',
        name: 'mdi-math-cos'
      },
      {
        unicode: 'F0FC8',
        name: 'mdi-math-integral'
      },
      {
        unicode: 'F0FC9',
        name: 'mdi-math-integral-box'
      },
      {
        unicode: 'F1085',
        name: 'mdi-math-log'
      },
      {
        unicode: 'F0FCA',
        name: 'mdi-math-norm'
      },
      {
        unicode: 'F0FCB',
        name: 'mdi-math-norm-box'
      },
      {
        unicode: 'F0C97',
        name: 'mdi-math-sin'
      },
      {
        unicode: 'F0C98',
        name: 'mdi-math-tan'
      },
      {
        unicode: 'F0628',
        name: 'mdi-matrix'
      },
      {
        unicode: 'F0987',
        name: 'mdi-medal'
      },
      {
        unicode: 'F1326',
        name: 'mdi-medal-outline'
      },
      {
        unicode: 'F06EF',
        name: 'mdi-medical-bag'
      },
      {
        unicode: 'F117B',
        name: 'mdi-meditation'
      },
      {
        unicode: 'F035B',
        name: 'mdi-memory'
      },
      {
        unicode: 'F035C',
        name: 'mdi-menu'
      },
      {
        unicode: 'F035D',
        name: 'mdi-menu-down'
      },
      {
        unicode: 'F06B6',
        name: 'mdi-menu-down-outline'
      },
      {
        unicode: 'F035E',
        name: 'mdi-menu-left'
      },
      {
        unicode: 'F0A02',
        name: 'mdi-menu-left-outline'
      },
      {
        unicode: 'F0BAB',
        name: 'mdi-menu-open'
      },
      {
        unicode: 'F035F',
        name: 'mdi-menu-right'
      },
      {
        unicode: 'F0A03',
        name: 'mdi-menu-right-outline'
      },
      {
        unicode: 'F0A64',
        name: 'mdi-menu-swap'
      },
      {
        unicode: 'F0A65',
        name: 'mdi-menu-swap-outline'
      },
      {
        unicode: 'F0360',
        name: 'mdi-menu-up'
      },
      {
        unicode: 'F06B7',
        name: 'mdi-menu-up-outline'
      },
      {
        unicode: 'F0F5C',
        name: 'mdi-merge'
      },
      {
        unicode: 'F0361',
        name: 'mdi-message'
      },
      {
        unicode: 'F0362',
        name: 'mdi-message-alert'
      },
      {
        unicode: 'F0A04',
        name: 'mdi-message-alert-outline'
      },
      {
        unicode: 'F12F2',
        name: 'mdi-message-arrow-left'
      },
      {
        unicode: 'F12F3',
        name: 'mdi-message-arrow-left-outline'
      },
      {
        unicode: 'F12F4',
        name: 'mdi-message-arrow-right'
      },
      {
        unicode: 'F12F5',
        name: 'mdi-message-arrow-right-outline'
      },
      {
        unicode: 'F06A2',
        name: 'mdi-message-bulleted'
      },
      {
        unicode: 'F06A3',
        name: 'mdi-message-bulleted-off'
      },
      {
        unicode: 'F06F1',
        name: 'mdi-message-cog'
      },
      {
        unicode: 'F1172',
        name: 'mdi-message-cog-outline'
      },
      {
        unicode: 'F0363',
        name: 'mdi-message-draw'
      },
      {
        unicode: 'F0364',
        name: 'mdi-message-image'
      },
      {
        unicode: 'F116C',
        name: 'mdi-message-image-outline'
      },
      {
        unicode: 'F0FCC',
        name: 'mdi-message-lock'
      },
      {
        unicode: 'F116D',
        name: 'mdi-message-lock-outline'
      },
      {
        unicode: 'F116E',
        name: 'mdi-message-minus'
      },
      {
        unicode: 'F116F',
        name: 'mdi-message-minus-outline'
      },
      {
        unicode: 'F0365',
        name: 'mdi-message-outline'
      },
      {
        unicode: 'F0653',
        name: 'mdi-message-plus'
      },
      {
        unicode: 'F10BB',
        name: 'mdi-message-plus-outline'
      },
      {
        unicode: 'F0366',
        name: 'mdi-message-processing'
      },
      {
        unicode: 'F1170',
        name: 'mdi-message-processing-outline'
      },
      {
        unicode: 'F0367',
        name: 'mdi-message-reply'
      },
      {
        unicode: 'F0368',
        name: 'mdi-message-reply-text'
      },
      {
        unicode: 'F06F0',
        name: 'mdi-message-settings'
      },
      {
        unicode: 'F1171',
        name: 'mdi-message-settings-outline'
      },
      {
        unicode: 'F0369',
        name: 'mdi-message-text'
      },
      {
        unicode: 'F1173',
        name: 'mdi-message-text-clock'
      },
      {
        unicode: 'F1174',
        name: 'mdi-message-text-clock-outline'
      },
      {
        unicode: 'F0FCD',
        name: 'mdi-message-text-lock'
      },
      {
        unicode: 'F1175',
        name: 'mdi-message-text-lock-outline'
      },
      {
        unicode: 'F036A',
        name: 'mdi-message-text-outline'
      },
      {
        unicode: 'F036B',
        name: 'mdi-message-video'
      },
      {
        unicode: 'F0629',
        name: 'mdi-meteor'
      },
      {
        unicode: 'F07DA',
        name: 'mdi-metronome'
      },
      {
        unicode: 'F07DB',
        name: 'mdi-metronome-tick'
      },
      {
        unicode: 'F07DC',
        name: 'mdi-micro-sd'
      },
      {
        unicode: 'F036C',
        name: 'mdi-microphone'
      },
      {
        unicode: 'F08B3',
        name: 'mdi-microphone-minus'
      },
      {
        unicode: 'F036D',
        name: 'mdi-microphone-off'
      },
      {
        unicode: 'F036E',
        name: 'mdi-microphone-outline'
      },
      {
        unicode: 'F08B4',
        name: 'mdi-microphone-plus'
      },
      {
        unicode: 'F036F',
        name: 'mdi-microphone-settings'
      },
      {
        unicode: 'F0370',
        name: 'mdi-microphone-variant'
      },
      {
        unicode: 'F0371',
        name: 'mdi-microphone-variant-off'
      },
      {
        unicode: 'F0654',
        name: 'mdi-microscope'
      },
      {
        unicode: 'F0372',
        name: 'mdi-microsoft'
      },
      {
        unicode: 'F138E',
        name: 'mdi-microsoft-access'
      },
      {
        unicode: 'F0805',
        name: 'mdi-microsoft-azure'
      },
      {
        unicode: 'F0FD5',
        name: 'mdi-microsoft-azure-devops'
      },
      {
        unicode: 'F00A4',
        name: 'mdi-microsoft-bing'
      },
      {
        unicode: 'F0988',
        name: 'mdi-microsoft-dynamics-365'
      },
      {
        unicode: 'F01E9',
        name: 'mdi-microsoft-edge'
      },
      {
        unicode: 'F1250',
        name: 'mdi-microsoft-edge-legacy'
      },
      {
        unicode: 'F138F',
        name: 'mdi-microsoft-excel'
      },
      {
        unicode: 'F0300',
        name: 'mdi-microsoft-internet-explorer'
      },
      {
        unicode: 'F03C6',
        name: 'mdi-microsoft-office'
      },
      {
        unicode: 'F03CA',
        name: 'mdi-microsoft-onedrive'
      },
      {
        unicode: 'F0747',
        name: 'mdi-microsoft-onenote'
      },
      {
        unicode: 'F0D22',
        name: 'mdi-microsoft-outlook'
      },
      {
        unicode: 'F1390',
        name: 'mdi-microsoft-powerpoint'
      },
      {
        unicode: 'F1391',
        name: 'mdi-microsoft-sharepoint'
      },
      {
        unicode: 'F02BB',
        name: 'mdi-microsoft-teams'
      },
      {
        unicode: 'F0610',
        name: 'mdi-microsoft-visual-studio'
      },
      {
        unicode: 'F0A1E',
        name: 'mdi-microsoft-visual-studio-code'
      },
      {
        unicode: 'F05B3',
        name: 'mdi-microsoft-windows'
      },
      {
        unicode: 'F0A21',
        name: 'mdi-microsoft-windows-classic'
      },
      {
        unicode: 'F1392',
        name: 'mdi-microsoft-word'
      },
      {
        unicode: 'F05B9',
        name: 'mdi-microsoft-xbox'
      },
      {
        unicode: 'F05BA',
        name: 'mdi-microsoft-xbox-controller'
      },
      {
        unicode: 'F074B',
        name: 'mdi-microsoft-xbox-controller-battery-alert'
      },
      {
        unicode: 'F0A22',
        name: 'mdi-microsoft-xbox-controller-battery-charging'
      },
      {
        unicode: 'F074C',
        name: 'mdi-microsoft-xbox-controller-battery-empty'
      },
      {
        unicode: 'F074D',
        name: 'mdi-microsoft-xbox-controller-battery-full'
      },
      {
        unicode: 'F074E',
        name: 'mdi-microsoft-xbox-controller-battery-low'
      },
      {
        unicode: 'F074F',
        name: 'mdi-microsoft-xbox-controller-battery-medium'
      },
      {
        unicode: 'F0750',
        name: 'mdi-microsoft-xbox-controller-battery-unknown'
      },
      {
        unicode: 'F0E6F',
        name: 'mdi-microsoft-xbox-controller-menu'
      },
      {
        unicode: 'F05BB',
        name: 'mdi-microsoft-xbox-controller-off'
      },
      {
        unicode: 'F0E70',
        name: 'mdi-microsoft-xbox-controller-view'
      },
      {
        unicode: 'F0789',
        name: 'mdi-microsoft-yammer'
      },
      {
        unicode: 'F0C99',
        name: 'mdi-microwave'
      },
      {
        unicode: 'F1423',
        name: 'mdi-microwave-off'
      },
      {
        unicode: 'F0F5D',
        name: 'mdi-middleware'
      },
      {
        unicode: 'F0F5E',
        name: 'mdi-middleware-outline'
      },
      {
        unicode: 'F08F1',
        name: 'mdi-midi'
      },
      {
        unicode: 'F08F2',
        name: 'mdi-midi-port'
      },
      {
        unicode: 'F0DDA',
        name: 'mdi-mine'
      },
      {
        unicode: 'F0373',
        name: 'mdi-minecraft'
      },
      {
        unicode: 'F0A05',
        name: 'mdi-mini-sd'
      },
      {
        unicode: 'F0A06',
        name: 'mdi-minidisc'
      },
      {
        unicode: 'F0374',
        name: 'mdi-minus'
      },
      {
        unicode: 'F0375',
        name: 'mdi-minus-box'
      },
      {
        unicode: 'F1141',
        name: 'mdi-minus-box-multiple'
      },
      {
        unicode: 'F1142',
        name: 'mdi-minus-box-multiple-outline'
      },
      {
        unicode: 'F06F2',
        name: 'mdi-minus-box-outline'
      },
      {
        unicode: 'F0376',
        name: 'mdi-minus-circle'
      },
      {
        unicode: 'F035A',
        name: 'mdi-minus-circle-multiple'
      },
      {
        unicode: 'F0AD3',
        name: 'mdi-minus-circle-multiple-outline'
      },
      {
        unicode: 'F1459',
        name: 'mdi-minus-circle-off'
      },
      {
        unicode: 'F145A',
        name: 'mdi-minus-circle-off-outline'
      },
      {
        unicode: 'F0377',
        name: 'mdi-minus-circle-outline'
      },
      {
        unicode: 'F0378',
        name: 'mdi-minus-network'
      },
      {
        unicode: 'F0C9A',
        name: 'mdi-minus-network-outline'
      },
      {
        unicode: 'F11FD',
        name: 'mdi-mirror'
      },
      {
        unicode: 'F0D8F',
        name: 'mdi-mixed-martial-arts'
      },
      {
        unicode: 'F087F',
        name: 'mdi-mixed-reality'
      },
      {
        unicode: 'F07DD',
        name: 'mdi-mixer'
      },
      {
        unicode: 'F0BAC',
        name: 'mdi-molecule'
      },
      {
        unicode: 'F12FE',
        name: 'mdi-molecule-co'
      },
      {
        unicode: 'F07E4',
        name: 'mdi-molecule-co2'
      },
      {
        unicode: 'F0379',
        name: 'mdi-monitor'
      },
      {
        unicode: 'F0989',
        name: 'mdi-monitor-cellphone'
      },
      {
        unicode: 'F098A',
        name: 'mdi-monitor-cellphone-star'
      },
      {
        unicode: 'F1104',
        name: 'mdi-monitor-clean'
      },
      {
        unicode: 'F0A07',
        name: 'mdi-monitor-dashboard'
      },
      {
        unicode: 'F12C6',
        name: 'mdi-monitor-edit'
      },
      {
        unicode: 'F13B4',
        name: 'mdi-monitor-eye'
      },
      {
        unicode: 'F0DDB',
        name: 'mdi-monitor-lock'
      },
      {
        unicode: 'F037A',
        name: 'mdi-monitor-multiple'
      },
      {
        unicode: 'F0D90',
        name: 'mdi-monitor-off'
      },
      {
        unicode: 'F0E51',
        name: 'mdi-monitor-screenshot'
      },
      {
        unicode: 'F1483',
        name: 'mdi-monitor-share'
      },
      {
        unicode: 'F0F5F',
        name: 'mdi-monitor-speaker'
      },
      {
        unicode: 'F0F60',
        name: 'mdi-monitor-speaker-off'
      },
      {
        unicode: 'F0DDC',
        name: 'mdi-monitor-star'
      },
      {
        unicode: 'F0F61',
        name: 'mdi-moon-first-quarter'
      },
      {
        unicode: 'F0F62',
        name: 'mdi-moon-full'
      },
      {
        unicode: 'F0F63',
        name: 'mdi-moon-last-quarter'
      },
      {
        unicode: 'F0F64',
        name: 'mdi-moon-new'
      },
      {
        unicode: 'F0F65',
        name: 'mdi-moon-waning-crescent'
      },
      {
        unicode: 'F0F66',
        name: 'mdi-moon-waning-gibbous'
      },
      {
        unicode: 'F0F67',
        name: 'mdi-moon-waxing-crescent'
      },
      {
        unicode: 'F0F68',
        name: 'mdi-moon-waxing-gibbous'
      },
      {
        unicode: 'F1086',
        name: 'mdi-moped'
      },
      {
        unicode: 'F037B',
        name: 'mdi-more'
      },
      {
        unicode: 'F1314',
        name: 'mdi-mother-heart'
      },
      {
        unicode: 'F0D21',
        name: 'mdi-mother-nurse'
      },
      {
        unicode: 'F0D91',
        name: 'mdi-motion-sensor'
      },
      {
        unicode: 'F1435',
        name: 'mdi-motion-sensor-off'
      },
      {
        unicode: 'F037C',
        name: 'mdi-motorbike'
      },
      {
        unicode: 'F037D',
        name: 'mdi-mouse'
      },
      {
        unicode: 'F098B',
        name: 'mdi-mouse-bluetooth'
      },
      {
        unicode: 'F037E',
        name: 'mdi-mouse-off'
      },
      {
        unicode: 'F037F',
        name: 'mdi-mouse-variant'
      },
      {
        unicode: 'F0380',
        name: 'mdi-mouse-variant-off'
      },
      {
        unicode: 'F0655',
        name: 'mdi-move-resize'
      },
      {
        unicode: 'F0656',
        name: 'mdi-move-resize-variant'
      },
      {
        unicode: 'F0381',
        name: 'mdi-movie'
      },
      {
        unicode: 'F1122',
        name: 'mdi-movie-edit'
      },
      {
        unicode: 'F1123',
        name: 'mdi-movie-edit-outline'
      },
      {
        unicode: 'F1124',
        name: 'mdi-movie-filter'
      },
      {
        unicode: 'F1125',
        name: 'mdi-movie-filter-outline'
      },
      {
        unicode: 'F0FCE',
        name: 'mdi-movie-open'
      },
      {
        unicode: 'F0FCF',
        name: 'mdi-movie-open-outline'
      },
      {
        unicode: 'F0DDD',
        name: 'mdi-movie-outline'
      },
      {
        unicode: 'F07DE',
        name: 'mdi-movie-roll'
      },
      {
        unicode: 'F11D2',
        name: 'mdi-movie-search'
      },
      {
        unicode: 'F11D3',
        name: 'mdi-movie-search-outline'
      },
      {
        unicode: 'F098C',
        name: 'mdi-muffin'
      },
      {
        unicode: 'F0382',
        name: 'mdi-multiplication'
      },
      {
        unicode: 'F0383',
        name: 'mdi-multiplication-box'
      },
      {
        unicode: 'F07DF',
        name: 'mdi-mushroom'
      },
      {
        unicode: 'F13FA',
        name: 'mdi-mushroom-off'
      },
      {
        unicode: 'F13FB',
        name: 'mdi-mushroom-off-outline'
      },
      {
        unicode: 'F07E0',
        name: 'mdi-mushroom-outline'
      },
      {
        unicode: 'F075A',
        name: 'mdi-music'
      },
      {
        unicode: 'F0F69',
        name: 'mdi-music-accidental-double-flat'
      },
      {
        unicode: 'F0F6A',
        name: 'mdi-music-accidental-double-sharp'
      },
      {
        unicode: 'F0F6B',
        name: 'mdi-music-accidental-flat'
      },
      {
        unicode: 'F0F6C',
        name: 'mdi-music-accidental-natural'
      },
      {
        unicode: 'F0F6D',
        name: 'mdi-music-accidental-sharp'
      },
      {
        unicode: 'F0384',
        name: 'mdi-music-box'
      },
      {
        unicode: 'F0333',
        name: 'mdi-music-box-multiple'
      },
      {
        unicode: 'F0F04',
        name: 'mdi-music-box-multiple-outline'
      },
      {
        unicode: 'F0385',
        name: 'mdi-music-box-outline'
      },
      {
        unicode: 'F0386',
        name: 'mdi-music-circle'
      },
      {
        unicode: 'F0AD4',
        name: 'mdi-music-circle-outline'
      },
      {
        unicode: 'F0F6E',
        name: 'mdi-music-clef-alto'
      },
      {
        unicode: 'F0F6F',
        name: 'mdi-music-clef-bass'
      },
      {
        unicode: 'F0F70',
        name: 'mdi-music-clef-treble'
      },
      {
        unicode: 'F0387',
        name: 'mdi-music-note'
      },
      {
        unicode: 'F05FE',
        name: 'mdi-music-note-bluetooth'
      },
      {
        unicode: 'F05FF',
        name: 'mdi-music-note-bluetooth-off'
      },
      {
        unicode: 'F0388',
        name: 'mdi-music-note-eighth'
      },
      {
        unicode: 'F0F71',
        name: 'mdi-music-note-eighth-dotted'
      },
      {
        unicode: 'F0389',
        name: 'mdi-music-note-half'
      },
      {
        unicode: 'F0F72',
        name: 'mdi-music-note-half-dotted'
      },
      {
        unicode: 'F038A',
        name: 'mdi-music-note-off'
      },
      {
        unicode: 'F0F73',
        name: 'mdi-music-note-off-outline'
      },
      {
        unicode: 'F0F74',
        name: 'mdi-music-note-outline'
      },
      {
        unicode: 'F0DDE',
        name: 'mdi-music-note-plus'
      },
      {
        unicode: 'F038B',
        name: 'mdi-music-note-quarter'
      },
      {
        unicode: 'F0F75',
        name: 'mdi-music-note-quarter-dotted'
      },
      {
        unicode: 'F038C',
        name: 'mdi-music-note-sixteenth'
      },
      {
        unicode: 'F0F76',
        name: 'mdi-music-note-sixteenth-dotted'
      },
      {
        unicode: 'F038D',
        name: 'mdi-music-note-whole'
      },
      {
        unicode: 'F0F77',
        name: 'mdi-music-note-whole-dotted'
      },
      {
        unicode: 'F075B',
        name: 'mdi-music-off'
      },
      {
        unicode: 'F0F78',
        name: 'mdi-music-rest-eighth'
      },
      {
        unicode: 'F0F79',
        name: 'mdi-music-rest-half'
      },
      {
        unicode: 'F0F7A',
        name: 'mdi-music-rest-quarter'
      },
      {
        unicode: 'F0F7B',
        name: 'mdi-music-rest-sixteenth'
      },
      {
        unicode: 'F0F7C',
        name: 'mdi-music-rest-whole'
      },
      {
        unicode: 'F0DDF',
        name: 'mdi-nail'
      },
      {
        unicode: 'F08F3',
        name: 'mdi-nas'
      },
      {
        unicode: 'F0880',
        name: 'mdi-nativescript'
      },
      {
        unicode: 'F038E',
        name: 'mdi-nature'
      },
      {
        unicode: 'F038F',
        name: 'mdi-nature-people'
      },
      {
        unicode: 'F0390',
        name: 'mdi-navigation'
      },
      {
        unicode: 'F05CD',
        name: 'mdi-near-me'
      },
      {
        unicode: 'F0F0B',
        name: 'mdi-necklace'
      },
      {
        unicode: 'F0391',
        name: 'mdi-needle'
      },
      {
        unicode: 'F0746',
        name: 'mdi-netflix'
      },
      {
        unicode: 'F06F3',
        name: 'mdi-network'
      },
      {
        unicode: 'F0C9B',
        name: 'mdi-network-off'
      },
      {
        unicode: 'F0C9C',
        name: 'mdi-network-off-outline'
      },
      {
        unicode: 'F0C9D',
        name: 'mdi-network-outline'
      },
      {
        unicode: 'F08F4',
        name: 'mdi-network-strength-1'
      },
      {
        unicode: 'F08F5',
        name: 'mdi-network-strength-1-alert'
      },
      {
        unicode: 'F08F6',
        name: 'mdi-network-strength-2'
      },
      {
        unicode: 'F08F7',
        name: 'mdi-network-strength-2-alert'
      },
      {
        unicode: 'F08F8',
        name: 'mdi-network-strength-3'
      },
      {
        unicode: 'F08F9',
        name: 'mdi-network-strength-3-alert'
      },
      {
        unicode: 'F08FA',
        name: 'mdi-network-strength-4'
      },
      {
        unicode: 'F08FB',
        name: 'mdi-network-strength-4-alert'
      },
      {
        unicode: 'F08FC',
        name: 'mdi-network-strength-off'
      },
      {
        unicode: 'F08FD',
        name: 'mdi-network-strength-off-outline'
      },
      {
        unicode: 'F08FE',
        name: 'mdi-network-strength-outline'
      },
      {
        unicode: 'F0394',
        name: 'mdi-new-box'
      },
      {
        unicode: 'F0395',
        name: 'mdi-newspaper'
      },
      {
        unicode: 'F0F0C',
        name: 'mdi-newspaper-minus'
      },
      {
        unicode: 'F0F0D',
        name: 'mdi-newspaper-plus'
      },
      {
        unicode: 'F1001',
        name: 'mdi-newspaper-variant'
      },
      {
        unicode: 'F1002',
        name: 'mdi-newspaper-variant-multiple'
      },
      {
        unicode: 'F1003',
        name: 'mdi-newspaper-variant-multiple-outline'
      },
      {
        unicode: 'F1004',
        name: 'mdi-newspaper-variant-outline'
      },
      {
        unicode: 'F0396',
        name: 'mdi-nfc'
      },
      {
        unicode: 'F0E53',
        name: 'mdi-nfc-search-variant'
      },
      {
        unicode: 'F0397',
        name: 'mdi-nfc-tap'
      },
      {
        unicode: 'F0398',
        name: 'mdi-nfc-variant'
      },
      {
        unicode: 'F0E54',
        name: 'mdi-nfc-variant-off'
      },
      {
        unicode: 'F0774',
        name: 'mdi-ninja'
      },
      {
        unicode: 'F1393',
        name: 'mdi-nintendo-game-boy'
      },
      {
        unicode: 'F07E1',
        name: 'mdi-nintendo-switch'
      },
      {
        unicode: 'F05AB',
        name: 'mdi-nintendo-wii'
      },
      {
        unicode: 'F072D',
        name: 'mdi-nintendo-wiiu'
      },
      {
        unicode: 'F1105',
        name: 'mdi-nix'
      },
      {
        unicode: 'F0399',
        name: 'mdi-nodejs'
      },
      {
        unicode: 'F117E',
        name: 'mdi-noodles'
      },
      {
        unicode: 'F098D',
        name: 'mdi-not-equal'
      },
      {
        unicode: 'F098E',
        name: 'mdi-not-equal-variant'
      },
      {
        unicode: 'F039A',
        name: 'mdi-note'
      },
      {
        unicode: 'F06B8',
        name: 'mdi-note-multiple'
      },
      {
        unicode: 'F06B9',
        name: 'mdi-note-multiple-outline'
      },
      {
        unicode: 'F039B',
        name: 'mdi-note-outline'
      },
      {
        unicode: 'F039C',
        name: 'mdi-note-plus'
      },
      {
        unicode: 'F039D',
        name: 'mdi-note-plus-outline'
      },
      {
        unicode: 'F039E',
        name: 'mdi-note-text'
      },
      {
        unicode: 'F11D7',
        name: 'mdi-note-text-outline'
      },
      {
        unicode: 'F082E',
        name: 'mdi-notebook'
      },
      {
        unicode: 'F0E55',
        name: 'mdi-notebook-multiple'
      },
      {
        unicode: 'F0EBF',
        name: 'mdi-notebook-outline'
      },
      {
        unicode: 'F039F',
        name: 'mdi-notification-clear-all'
      },
      {
        unicode: 'F06F7',
        name: 'mdi-npm'
      },
      {
        unicode: 'F06A4',
        name: 'mdi-nuke'
      },
      {
        unicode: 'F07E2',
        name: 'mdi-null'
      },
      {
        unicode: 'F03A0',
        name: 'mdi-numeric'
      },
      {
        unicode: 'F0B39',
        name: 'mdi-numeric-0'
      },
      {
        unicode: 'F03A1',
        name: 'mdi-numeric-0-box'
      },
      {
        unicode: 'F0F0E',
        name: 'mdi-numeric-0-box-multiple'
      },
      {
        unicode: 'F03A2',
        name: 'mdi-numeric-0-box-multiple-outline'
      },
      {
        unicode: 'F03A3',
        name: 'mdi-numeric-0-box-outline'
      },
      {
        unicode: 'F0C9E',
        name: 'mdi-numeric-0-circle'
      },
      {
        unicode: 'F0C9F',
        name: 'mdi-numeric-0-circle-outline'
      },
      {
        unicode: 'F0B3A',
        name: 'mdi-numeric-1'
      },
      {
        unicode: 'F03A4',
        name: 'mdi-numeric-1-box'
      },
      {
        unicode: 'F0F0F',
        name: 'mdi-numeric-1-box-multiple'
      },
      {
        unicode: 'F03A5',
        name: 'mdi-numeric-1-box-multiple-outline'
      },
      {
        unicode: 'F03A6',
        name: 'mdi-numeric-1-box-outline'
      },
      {
        unicode: 'F0CA0',
        name: 'mdi-numeric-1-circle'
      },
      {
        unicode: 'F0CA1',
        name: 'mdi-numeric-1-circle-outline'
      },
      {
        unicode: 'F0FE9',
        name: 'mdi-numeric-10'
      },
      {
        unicode: 'F0F7D',
        name: 'mdi-numeric-10-box'
      },
      {
        unicode: 'F0FEA',
        name: 'mdi-numeric-10-box-multiple'
      },
      {
        unicode: 'F0FEB',
        name: 'mdi-numeric-10-box-multiple-outline'
      },
      {
        unicode: 'F0F7E',
        name: 'mdi-numeric-10-box-outline'
      },
      {
        unicode: 'F0FEC',
        name: 'mdi-numeric-10-circle'
      },
      {
        unicode: 'F0FED',
        name: 'mdi-numeric-10-circle-outline'
      },
      {
        unicode: 'F0B3B',
        name: 'mdi-numeric-2'
      },
      {
        unicode: 'F03A7',
        name: 'mdi-numeric-2-box'
      },
      {
        unicode: 'F0F10',
        name: 'mdi-numeric-2-box-multiple'
      },
      {
        unicode: 'F03A8',
        name: 'mdi-numeric-2-box-multiple-outline'
      },
      {
        unicode: 'F03A9',
        name: 'mdi-numeric-2-box-outline'
      },
      {
        unicode: 'F0CA2',
        name: 'mdi-numeric-2-circle'
      },
      {
        unicode: 'F0CA3',
        name: 'mdi-numeric-2-circle-outline'
      },
      {
        unicode: 'F0B3C',
        name: 'mdi-numeric-3'
      },
      {
        unicode: 'F03AA',
        name: 'mdi-numeric-3-box'
      },
      {
        unicode: 'F0F11',
        name: 'mdi-numeric-3-box-multiple'
      },
      {
        unicode: 'F03AB',
        name: 'mdi-numeric-3-box-multiple-outline'
      },
      {
        unicode: 'F03AC',
        name: 'mdi-numeric-3-box-outline'
      },
      {
        unicode: 'F0CA4',
        name: 'mdi-numeric-3-circle'
      },
      {
        unicode: 'F0CA5',
        name: 'mdi-numeric-3-circle-outline'
      },
      {
        unicode: 'F0B3D',
        name: 'mdi-numeric-4'
      },
      {
        unicode: 'F03AD',
        name: 'mdi-numeric-4-box'
      },
      {
        unicode: 'F0F12',
        name: 'mdi-numeric-4-box-multiple'
      },
      {
        unicode: 'F03B2',
        name: 'mdi-numeric-4-box-multiple-outline'
      },
      {
        unicode: 'F03AE',
        name: 'mdi-numeric-4-box-outline'
      },
      {
        unicode: 'F0CA6',
        name: 'mdi-numeric-4-circle'
      },
      {
        unicode: 'F0CA7',
        name: 'mdi-numeric-4-circle-outline'
      },
      {
        unicode: 'F0B3E',
        name: 'mdi-numeric-5'
      },
      {
        unicode: 'F03B1',
        name: 'mdi-numeric-5-box'
      },
      {
        unicode: 'F0F13',
        name: 'mdi-numeric-5-box-multiple'
      },
      {
        unicode: 'F03AF',
        name: 'mdi-numeric-5-box-multiple-outline'
      },
      {
        unicode: 'F03B0',
        name: 'mdi-numeric-5-box-outline'
      },
      {
        unicode: 'F0CA8',
        name: 'mdi-numeric-5-circle'
      },
      {
        unicode: 'F0CA9',
        name: 'mdi-numeric-5-circle-outline'
      },
      {
        unicode: 'F0B3F',
        name: 'mdi-numeric-6'
      },
      {
        unicode: 'F03B3',
        name: 'mdi-numeric-6-box'
      },
      {
        unicode: 'F0F14',
        name: 'mdi-numeric-6-box-multiple'
      },
      {
        unicode: 'F03B4',
        name: 'mdi-numeric-6-box-multiple-outline'
      },
      {
        unicode: 'F03B5',
        name: 'mdi-numeric-6-box-outline'
      },
      {
        unicode: 'F0CAA',
        name: 'mdi-numeric-6-circle'
      },
      {
        unicode: 'F0CAB',
        name: 'mdi-numeric-6-circle-outline'
      },
      {
        unicode: 'F0B40',
        name: 'mdi-numeric-7'
      },
      {
        unicode: 'F03B6',
        name: 'mdi-numeric-7-box'
      },
      {
        unicode: 'F0F15',
        name: 'mdi-numeric-7-box-multiple'
      },
      {
        unicode: 'F03B7',
        name: 'mdi-numeric-7-box-multiple-outline'
      },
      {
        unicode: 'F03B8',
        name: 'mdi-numeric-7-box-outline'
      },
      {
        unicode: 'F0CAC',
        name: 'mdi-numeric-7-circle'
      },
      {
        unicode: 'F0CAD',
        name: 'mdi-numeric-7-circle-outline'
      },
      {
        unicode: 'F0B41',
        name: 'mdi-numeric-8'
      },
      {
        unicode: 'F03B9',
        name: 'mdi-numeric-8-box'
      },
      {
        unicode: 'F0F16',
        name: 'mdi-numeric-8-box-multiple'
      },
      {
        unicode: 'F03BA',
        name: 'mdi-numeric-8-box-multiple-outline'
      },
      {
        unicode: 'F03BB',
        name: 'mdi-numeric-8-box-outline'
      },
      {
        unicode: 'F0CAE',
        name: 'mdi-numeric-8-circle'
      },
      {
        unicode: 'F0CAF',
        name: 'mdi-numeric-8-circle-outline'
      },
      {
        unicode: 'F0B42',
        name: 'mdi-numeric-9'
      },
      {
        unicode: 'F03BC',
        name: 'mdi-numeric-9-box'
      },
      {
        unicode: 'F0F17',
        name: 'mdi-numeric-9-box-multiple'
      },
      {
        unicode: 'F03BD',
        name: 'mdi-numeric-9-box-multiple-outline'
      },
      {
        unicode: 'F03BE',
        name: 'mdi-numeric-9-box-outline'
      },
      {
        unicode: 'F0CB0',
        name: 'mdi-numeric-9-circle'
      },
      {
        unicode: 'F0CB1',
        name: 'mdi-numeric-9-circle-outline'
      },
      {
        unicode: 'F0FEE',
        name: 'mdi-numeric-9-plus'
      },
      {
        unicode: 'F03BF',
        name: 'mdi-numeric-9-plus-box'
      },
      {
        unicode: 'F0F18',
        name: 'mdi-numeric-9-plus-box-multiple'
      },
      {
        unicode: 'F03C0',
        name: 'mdi-numeric-9-plus-box-multiple-outline'
      },
      {
        unicode: 'F03C1',
        name: 'mdi-numeric-9-plus-box-outline'
      },
      {
        unicode: 'F0CB2',
        name: 'mdi-numeric-9-plus-circle'
      },
      {
        unicode: 'F0CB3',
        name: 'mdi-numeric-9-plus-circle-outline'
      },
      {
        unicode: 'F1052',
        name: 'mdi-numeric-negative-1'
      },
      {
        unicode: 'F06F8',
        name: 'mdi-nut'
      },
      {
        unicode: 'F03C2',
        name: 'mdi-nutrition'
      },
      {
        unicode: 'F1106',
        name: 'mdi-nuxt'
      },
      {
        unicode: 'F067C',
        name: 'mdi-oar'
      },
      {
        unicode: 'F0DE0',
        name: 'mdi-ocarina'
      },
      {
        unicode: 'F12E9',
        name: 'mdi-oci'
      },
      {
        unicode: 'F113A',
        name: 'mdi-ocr'
      },
      {
        unicode: 'F03C3',
        name: 'mdi-octagon'
      },
      {
        unicode: 'F03C4',
        name: 'mdi-octagon-outline'
      },
      {
        unicode: 'F06F9',
        name: 'mdi-octagram'
      },
      {
        unicode: 'F0775',
        name: 'mdi-octagram-outline'
      },
      {
        unicode: 'F03C5',
        name: 'mdi-odnoklassniki'
      },
      {
        unicode: 'F121B',
        name: 'mdi-offer'
      },
      {
        unicode: 'F0991',
        name: 'mdi-office-building'
      },
      {
        unicode: 'F03C7',
        name: 'mdi-oil'
      },
      {
        unicode: 'F0F19',
        name: 'mdi-oil-lamp'
      },
      {
        unicode: 'F1053',
        name: 'mdi-oil-level'
      },
      {
        unicode: 'F0FF8',
        name: 'mdi-oil-temperature'
      },
      {
        unicode: 'F03C9',
        name: 'mdi-omega'
      },
      {
        unicode: 'F0BAD',
        name: 'mdi-one-up'
      },
      {
        unicode: 'F0881',
        name: 'mdi-onepassword'
      },
      {
        unicode: 'F05CC',
        name: 'mdi-opacity'
      },
      {
        unicode: 'F03CB',
        name: 'mdi-open-in-app'
      },
      {
        unicode: 'F03CC',
        name: 'mdi-open-in-new'
      },
      {
        unicode: 'F0BAE',
        name: 'mdi-open-source-initiative'
      },
      {
        unicode: 'F03CD',
        name: 'mdi-openid'
      },
      {
        unicode: 'F03CE',
        name: 'mdi-opera'
      },
      {
        unicode: 'F0018',
        name: 'mdi-orbit'
      },
      {
        unicode: 'F020D',
        name: 'mdi-order-alphabetical-ascending'
      },
      {
        unicode: 'F0D07',
        name: 'mdi-order-alphabetical-descending'
      },
      {
        unicode: 'F02BE',
        name: 'mdi-order-bool-ascending'
      },
      {
        unicode: 'F098F',
        name: 'mdi-order-bool-ascending-variant'
      },
      {
        unicode: 'F1384',
        name: 'mdi-order-bool-descending'
      },
      {
        unicode: 'F0990',
        name: 'mdi-order-bool-descending-variant'
      },
      {
        unicode: 'F0545',
        name: 'mdi-order-numeric-ascending'
      },
      {
        unicode: 'F0546',
        name: 'mdi-order-numeric-descending'
      },
      {
        unicode: 'F0B43',
        name: 'mdi-origin'
      },
      {
        unicode: 'F03CF',
        name: 'mdi-ornament'
      },
      {
        unicode: 'F03D0',
        name: 'mdi-ornament-variant'
      },
      {
        unicode: 'F1054',
        name: 'mdi-outdoor-lamp'
      },
      {
        unicode: 'F1005',
        name: 'mdi-overscan'
      },
      {
        unicode: 'F03D2',
        name: 'mdi-owl'
      },
      {
        unicode: 'F0BAF',
        name: 'mdi-pac-man'
      },
      {
        unicode: 'F03D3',
        name: 'mdi-package'
      },
      {
        unicode: 'F03D4',
        name: 'mdi-package-down'
      },
      {
        unicode: 'F03D5',
        name: 'mdi-package-up'
      },
      {
        unicode: 'F03D6',
        name: 'mdi-package-variant'
      },
      {
        unicode: 'F03D7',
        name: 'mdi-package-variant-closed'
      },
      {
        unicode: 'F0600',
        name: 'mdi-page-first'
      },
      {
        unicode: 'F0601',
        name: 'mdi-page-last'
      },
      {
        unicode: 'F06FA',
        name: 'mdi-page-layout-body'
      },
      {
        unicode: 'F06FB',
        name: 'mdi-page-layout-footer'
      },
      {
        unicode: 'F06FC',
        name: 'mdi-page-layout-header'
      },
      {
        unicode: 'F0F7F',
        name: 'mdi-page-layout-header-footer'
      },
      {
        unicode: 'F06FD',
        name: 'mdi-page-layout-sidebar-left'
      },
      {
        unicode: 'F06FE',
        name: 'mdi-page-layout-sidebar-right'
      },
      {
        unicode: 'F0BB0',
        name: 'mdi-page-next'
      },
      {
        unicode: 'F0BB1',
        name: 'mdi-page-next-outline'
      },
      {
        unicode: 'F0BB2',
        name: 'mdi-page-previous'
      },
      {
        unicode: 'F0BB3',
        name: 'mdi-page-previous-outline'
      },
      {
        unicode: 'F1417',
        name: 'mdi-pail'
      },
      {
        unicode: 'F1437',
        name: 'mdi-pail-minus'
      },
      {
        unicode: 'F143C',
        name: 'mdi-pail-minus-outline'
      },
      {
        unicode: 'F1439',
        name: 'mdi-pail-off'
      },
      {
        unicode: 'F143E',
        name: 'mdi-pail-off-outline'
      },
      {
        unicode: 'F143A',
        name: 'mdi-pail-outline'
      },
      {
        unicode: 'F1436',
        name: 'mdi-pail-plus'
      },
      {
        unicode: 'F143B',
        name: 'mdi-pail-plus-outline'
      },
      {
        unicode: 'F1438',
        name: 'mdi-pail-remove'
      },
      {
        unicode: 'F143D',
        name: 'mdi-pail-remove-outline'
      },
      {
        unicode: 'F03D8',
        name: 'mdi-palette'
      },
      {
        unicode: 'F03D9',
        name: 'mdi-palette-advanced'
      },
      {
        unicode: 'F0E0C',
        name: 'mdi-palette-outline'
      },
      {
        unicode: 'F08B5',
        name: 'mdi-palette-swatch'
      },
      {
        unicode: 'F135C',
        name: 'mdi-palette-swatch-outline'
      },
      {
        unicode: 'F1055',
        name: 'mdi-palm-tree'
      },
      {
        unicode: 'F0BB4',
        name: 'mdi-pan'
      },
      {
        unicode: 'F0BB5',
        name: 'mdi-pan-bottom-left'
      },
      {
        unicode: 'F0BB6',
        name: 'mdi-pan-bottom-right'
      },
      {
        unicode: 'F0BB7',
        name: 'mdi-pan-down'
      },
      {
        unicode: 'F0BB8',
        name: 'mdi-pan-horizontal'
      },
      {
        unicode: 'F0BB9',
        name: 'mdi-pan-left'
      },
      {
        unicode: 'F0BBA',
        name: 'mdi-pan-right'
      },
      {
        unicode: 'F0BBB',
        name: 'mdi-pan-top-left'
      },
      {
        unicode: 'F0BBC',
        name: 'mdi-pan-top-right'
      },
      {
        unicode: 'F0BBD',
        name: 'mdi-pan-up'
      },
      {
        unicode: 'F0BBE',
        name: 'mdi-pan-vertical'
      },
      {
        unicode: 'F03DA',
        name: 'mdi-panda'
      },
      {
        unicode: 'F03DB',
        name: 'mdi-pandora'
      },
      {
        unicode: 'F03DC',
        name: 'mdi-panorama'
      },
      {
        unicode: 'F03DD',
        name: 'mdi-panorama-fisheye'
      },
      {
        unicode: 'F03DE',
        name: 'mdi-panorama-horizontal'
      },
      {
        unicode: 'F03DF',
        name: 'mdi-panorama-vertical'
      },
      {
        unicode: 'F03E0',
        name: 'mdi-panorama-wide-angle'
      },
      {
        unicode: 'F03E1',
        name: 'mdi-paper-cut-vertical'
      },
      {
        unicode: 'F1157',
        name: 'mdi-paper-roll'
      },
      {
        unicode: 'F1158',
        name: 'mdi-paper-roll-outline'
      },
      {
        unicode: 'F03E2',
        name: 'mdi-paperclip'
      },
      {
        unicode: 'F0CB4',
        name: 'mdi-parachute'
      },
      {
        unicode: 'F0CB5',
        name: 'mdi-parachute-outline'
      },
      {
        unicode: 'F03E3',
        name: 'mdi-parking'
      },
      {
        unicode: 'F1056',
        name: 'mdi-party-popper'
      },
      {
        unicode: 'F07E3',
        name: 'mdi-passport'
      },
      {
        unicode: 'F0DE1',
        name: 'mdi-passport-biometric'
      },
      {
        unicode: 'F1160',
        name: 'mdi-pasta'
      },
      {
        unicode: 'F0F80',
        name: 'mdi-patio-heater'
      },
      {
        unicode: 'F0882',
        name: 'mdi-patreon'
      },
      {
        unicode: 'F03E4',
        name: 'mdi-pause'
      },
      {
        unicode: 'F03E5',
        name: 'mdi-pause-circle'
      },
      {
        unicode: 'F03E6',
        name: 'mdi-pause-circle-outline'
      },
      {
        unicode: 'F03E7',
        name: 'mdi-pause-octagon'
      },
      {
        unicode: 'F03E8',
        name: 'mdi-pause-octagon-outline'
      },
      {
        unicode: 'F03E9',
        name: 'mdi-paw'
      },
      {
        unicode: 'F0657',
        name: 'mdi-paw-off'
      },
      {
        unicode: 'F0E56',
        name: 'mdi-pdf-box'
      },
      {
        unicode: 'F0884',
        name: 'mdi-peace'
      },
      {
        unicode: 'F0FFC',
        name: 'mdi-peanut'
      },
      {
        unicode: 'F0FFD',
        name: 'mdi-peanut-off'
      },
      {
        unicode: 'F0FFF',
        name: 'mdi-peanut-off-outline'
      },
      {
        unicode: 'F0FFE',
        name: 'mdi-peanut-outline'
      },
      {
        unicode: 'F03EA',
        name: 'mdi-pen'
      },
      {
        unicode: 'F0DE2',
        name: 'mdi-pen-lock'
      },
      {
        unicode: 'F0DE3',
        name: 'mdi-pen-minus'
      },
      {
        unicode: 'F0DE4',
        name: 'mdi-pen-off'
      },
      {
        unicode: 'F0DE5',
        name: 'mdi-pen-plus'
      },
      {
        unicode: 'F0DE6',
        name: 'mdi-pen-remove'
      },
      {
        unicode: 'F03EB',
        name: 'mdi-pencil'
      },
      {
        unicode: 'F03EC',
        name: 'mdi-pencil-box'
      },
      {
        unicode: 'F1144',
        name: 'mdi-pencil-box-multiple'
      },
      {
        unicode: 'F1145',
        name: 'mdi-pencil-box-multiple-outline'
      },
      {
        unicode: 'F03ED',
        name: 'mdi-pencil-box-outline'
      },
      {
        unicode: 'F06FF',
        name: 'mdi-pencil-circle'
      },
      {
        unicode: 'F0776',
        name: 'mdi-pencil-circle-outline'
      },
      {
        unicode: 'F03EE',
        name: 'mdi-pencil-lock'
      },
      {
        unicode: 'F0DE7',
        name: 'mdi-pencil-lock-outline'
      },
      {
        unicode: 'F0DE8',
        name: 'mdi-pencil-minus'
      },
      {
        unicode: 'F0DE9',
        name: 'mdi-pencil-minus-outline'
      },
      {
        unicode: 'F03EF',
        name: 'mdi-pencil-off'
      },
      {
        unicode: 'F0DEA',
        name: 'mdi-pencil-off-outline'
      },
      {
        unicode: 'F0CB6',
        name: 'mdi-pencil-outline'
      },
      {
        unicode: 'F0DEB',
        name: 'mdi-pencil-plus'
      },
      {
        unicode: 'F0DEC',
        name: 'mdi-pencil-plus-outline'
      },
      {
        unicode: 'F0DED',
        name: 'mdi-pencil-remove'
      },
      {
        unicode: 'F0DEE',
        name: 'mdi-pencil-remove-outline'
      },
      {
        unicode: 'F1353',
        name: 'mdi-pencil-ruler'
      },
      {
        unicode: 'F0EC0',
        name: 'mdi-penguin'
      },
      {
        unicode: 'F0701',
        name: 'mdi-pentagon'
      },
      {
        unicode: 'F0700',
        name: 'mdi-pentagon-outline'
      },
      {
        unicode: 'F03F0',
        name: 'mdi-percent'
      },
      {
        unicode: 'F1278',
        name: 'mdi-percent-outline'
      },
      {
        unicode: 'F08B6',
        name: 'mdi-periodic-table'
      },
      {
        unicode: 'F0D23',
        name: 'mdi-perspective-less'
      },
      {
        unicode: 'F0D24',
        name: 'mdi-perspective-more'
      },
      {
        unicode: 'F03F1',
        name: 'mdi-pharmacy'
      },
      {
        unicode: 'F03F2',
        name: 'mdi-phone'
      },
      {
        unicode: 'F0F1A',
        name: 'mdi-phone-alert'
      },
      {
        unicode: 'F118E',
        name: 'mdi-phone-alert-outline'
      },
      {
        unicode: 'F03F3',
        name: 'mdi-phone-bluetooth'
      },
      {
        unicode: 'F118F',
        name: 'mdi-phone-bluetooth-outline'
      },
      {
        unicode: 'F10BC',
        name: 'mdi-phone-cancel'
      },
      {
        unicode: 'F1190',
        name: 'mdi-phone-cancel-outline'
      },
      {
        unicode: 'F11A9',
        name: 'mdi-phone-check'
      },
      {
        unicode: 'F11AA',
        name: 'mdi-phone-check-outline'
      },
      {
        unicode: 'F0602',
        name: 'mdi-phone-classic'
      },
      {
        unicode: 'F1279',
        name: 'mdi-phone-classic-off'
      },
      {
        unicode: 'F03F4',
        name: 'mdi-phone-forward'
      },
      {
        unicode: 'F1191',
        name: 'mdi-phone-forward-outline'
      },
      {
        unicode: 'F03F5',
        name: 'mdi-phone-hangup'
      },
      {
        unicode: 'F1192',
        name: 'mdi-phone-hangup-outline'
      },
      {
        unicode: 'F03F6',
        name: 'mdi-phone-in-talk'
      },
      {
        unicode: 'F1182',
        name: 'mdi-phone-in-talk-outline'
      },
      {
        unicode: 'F03F7',
        name: 'mdi-phone-incoming'
      },
      {
        unicode: 'F1193',
        name: 'mdi-phone-incoming-outline'
      },
      {
        unicode: 'F03F8',
        name: 'mdi-phone-lock'
      },
      {
        unicode: 'F1194',
        name: 'mdi-phone-lock-outline'
      },
      {
        unicode: 'F03F9',
        name: 'mdi-phone-log'
      },
      {
        unicode: 'F1195',
        name: 'mdi-phone-log-outline'
      },
      {
        unicode: 'F1196',
        name: 'mdi-phone-message'
      },
      {
        unicode: 'F1197',
        name: 'mdi-phone-message-outline'
      },
      {
        unicode: 'F0658',
        name: 'mdi-phone-minus'
      },
      {
        unicode: 'F1198',
        name: 'mdi-phone-minus-outline'
      },
      {
        unicode: 'F03FA',
        name: 'mdi-phone-missed'
      },
      {
        unicode: 'F11A5',
        name: 'mdi-phone-missed-outline'
      },
      {
        unicode: 'F0DEF',
        name: 'mdi-phone-off'
      },
      {
        unicode: 'F11A6',
        name: 'mdi-phone-off-outline'
      },
      {
        unicode: 'F03FB',
        name: 'mdi-phone-outgoing'
      },
      {
        unicode: 'F1199',
        name: 'mdi-phone-outgoing-outline'
      },
      {
        unicode: 'F0DF0',
        name: 'mdi-phone-outline'
      },
      {
        unicode: 'F03FC',
        name: 'mdi-phone-paused'
      },
      {
        unicode: 'F119A',
        name: 'mdi-phone-paused-outline'
      },
      {
        unicode: 'F0659',
        name: 'mdi-phone-plus'
      },
      {
        unicode: 'F119B',
        name: 'mdi-phone-plus-outline'
      },
      {
        unicode: 'F082F',
        name: 'mdi-phone-return'
      },
      {
        unicode: 'F119C',
        name: 'mdi-phone-return-outline'
      },
      {
        unicode: 'F11AB',
        name: 'mdi-phone-ring'
      },
      {
        unicode: 'F11AC',
        name: 'mdi-phone-ring-outline'
      },
      {
        unicode: 'F0885',
        name: 'mdi-phone-rotate-landscape'
      },
      {
        unicode: 'F0886',
        name: 'mdi-phone-rotate-portrait'
      },
      {
        unicode: 'F03FD',
        name: 'mdi-phone-settings'
      },
      {
        unicode: 'F119D',
        name: 'mdi-phone-settings-outline'
      },
      {
        unicode: 'F03FE',
        name: 'mdi-phone-voip'
      },
      {
        unicode: 'F03FF',
        name: 'mdi-pi'
      },
      {
        unicode: 'F0400',
        name: 'mdi-pi-box'
      },
      {
        unicode: 'F0DF1',
        name: 'mdi-pi-hole'
      },
      {
        unicode: 'F067D',
        name: 'mdi-piano'
      },
      {
        unicode: 'F08B7',
        name: 'mdi-pickaxe'
      },
      {
        unicode: 'F0E57',
        name: 'mdi-picture-in-picture-bottom-right'
      },
      {
        unicode: 'F0E58',
        name: 'mdi-picture-in-picture-bottom-right-outline'
      },
      {
        unicode: 'F0E59',
        name: 'mdi-picture-in-picture-top-right'
      },
      {
        unicode: 'F0E5A',
        name: 'mdi-picture-in-picture-top-right-outline'
      },
      {
        unicode: 'F0887',
        name: 'mdi-pier'
      },
      {
        unicode: 'F0888',
        name: 'mdi-pier-crane'
      },
      {
        unicode: 'F0401',
        name: 'mdi-pig'
      },
      {
        unicode: 'F1006',
        name: 'mdi-pig-variant'
      },
      {
        unicode: 'F1007',
        name: 'mdi-piggy-bank'
      },
      {
        unicode: 'F0402',
        name: 'mdi-pill'
      },
      {
        unicode: 'F0702',
        name: 'mdi-pillar'
      },
      {
        unicode: 'F0403',
        name: 'mdi-pin'
      },
      {
        unicode: 'F0404',
        name: 'mdi-pin-off'
      },
      {
        unicode: 'F0930',
        name: 'mdi-pin-off-outline'
      },
      {
        unicode: 'F0931',
        name: 'mdi-pin-outline'
      },
      {
        unicode: 'F0405',
        name: 'mdi-pine-tree'
      },
      {
        unicode: 'F0406',
        name: 'mdi-pine-tree-box'
      },
      {
        unicode: 'F141A',
        name: 'mdi-pine-tree-fire'
      },
      {
        unicode: 'F0407',
        name: 'mdi-pinterest'
      },
      {
        unicode: 'F0AD5',
        name: 'mdi-pinwheel'
      },
      {
        unicode: 'F0AD6',
        name: 'mdi-pinwheel-outline'
      },
      {
        unicode: 'F07E5',
        name: 'mdi-pipe'
      },
      {
        unicode: 'F07E6',
        name: 'mdi-pipe-disconnected'
      },
      {
        unicode: 'F0889',
        name: 'mdi-pipe-leak'
      },
      {
        unicode: 'F1354',
        name: 'mdi-pipe-wrench'
      },
      {
        unicode: 'F0A08',
        name: 'mdi-pirate'
      },
      {
        unicode: 'F0703',
        name: 'mdi-pistol'
      },
      {
        unicode: 'F088A',
        name: 'mdi-piston'
      },
      {
        unicode: 'F0409',
        name: 'mdi-pizza'
      },
      {
        unicode: 'F040A',
        name: 'mdi-play'
      },
      {
        unicode: 'F127A',
        name: 'mdi-play-box'
      },
      {
        unicode: 'F0D19',
        name: 'mdi-play-box-multiple'
      },
      {
        unicode: 'F13E6',
        name: 'mdi-play-box-multiple-outline'
      },
      {
        unicode: 'F040B',
        name: 'mdi-play-box-outline'
      },
      {
        unicode: 'F040C',
        name: 'mdi-play-circle'
      },
      {
        unicode: 'F040D',
        name: 'mdi-play-circle-outline'
      },
      {
        unicode: 'F088B',
        name: 'mdi-play-network'
      },
      {
        unicode: 'F0CB7',
        name: 'mdi-play-network-outline'
      },
      {
        unicode: 'F0F1B',
        name: 'mdi-play-outline'
      },
      {
        unicode: 'F040E',
        name: 'mdi-play-pause'
      },
      {
        unicode: 'F040F',
        name: 'mdi-play-protected-content'
      },
      {
        unicode: 'F08FF',
        name: 'mdi-play-speed'
      },
      {
        unicode: 'F05C7',
        name: 'mdi-playlist-check'
      },
      {
        unicode: 'F0900',
        name: 'mdi-playlist-edit'
      },
      {
        unicode: 'F0410',
        name: 'mdi-playlist-minus'
      },
      {
        unicode: 'F0CB8',
        name: 'mdi-playlist-music'
      },
      {
        unicode: 'F0CB9',
        name: 'mdi-playlist-music-outline'
      },
      {
        unicode: 'F0411',
        name: 'mdi-playlist-play'
      },
      {
        unicode: 'F0412',
        name: 'mdi-playlist-plus'
      },
      {
        unicode: 'F0413',
        name: 'mdi-playlist-remove'
      },
      {
        unicode: 'F0DF2',
        name: 'mdi-playlist-star'
      },
      {
        unicode: 'F06BA',
        name: 'mdi-plex'
      },
      {
        unicode: 'F0415',
        name: 'mdi-plus'
      },
      {
        unicode: 'F0416',
        name: 'mdi-plus-box'
      },
      {
        unicode: 'F0334',
        name: 'mdi-plus-box-multiple'
      },
      {
        unicode: 'F1143',
        name: 'mdi-plus-box-multiple-outline'
      },
      {
        unicode: 'F0704',
        name: 'mdi-plus-box-outline'
      },
      {
        unicode: 'F0417',
        name: 'mdi-plus-circle'
      },
      {
        unicode: 'F034C',
        name: 'mdi-plus-circle-multiple'
      },
      {
        unicode: 'F0418',
        name: 'mdi-plus-circle-multiple-outline'
      },
      {
        unicode: 'F0419',
        name: 'mdi-plus-circle-outline'
      },
      {
        unicode: 'F0992',
        name: 'mdi-plus-minus'
      },
      {
        unicode: 'F0993',
        name: 'mdi-plus-minus-box'
      },
      {
        unicode: 'F14C9',
        name: 'mdi-plus-minus-variant'
      },
      {
        unicode: 'F041A',
        name: 'mdi-plus-network'
      },
      {
        unicode: 'F0CBA',
        name: 'mdi-plus-network-outline'
      },
      {
        unicode: 'F041B',
        name: 'mdi-plus-one'
      },
      {
        unicode: 'F0705',
        name: 'mdi-plus-outline'
      },
      {
        unicode: 'F11EC',
        name: 'mdi-plus-thick'
      },
      {
        unicode: 'F0994',
        name: 'mdi-podcast'
      },
      {
        unicode: 'F0D25',
        name: 'mdi-podium'
      },
      {
        unicode: 'F0D26',
        name: 'mdi-podium-bronze'
      },
      {
        unicode: 'F0D27',
        name: 'mdi-podium-gold'
      },
      {
        unicode: 'F0D28',
        name: 'mdi-podium-silver'
      },
      {
        unicode: 'F0D92',
        name: 'mdi-point-of-sale'
      },
      {
        unicode: 'F041D',
        name: 'mdi-pokeball'
      },
      {
        unicode: 'F0A09',
        name: 'mdi-pokemon-go'
      },
      {
        unicode: 'F0830',
        name: 'mdi-poker-chip'
      },
      {
        unicode: 'F041E',
        name: 'mdi-polaroid'
      },
      {
        unicode: 'F1167',
        name: 'mdi-police-badge'
      },
      {
        unicode: 'F1168',
        name: 'mdi-police-badge-outline'
      },
      {
        unicode: 'F041F',
        name: 'mdi-poll'
      },
      {
        unicode: 'F0420',
        name: 'mdi-poll-box'
      },
      {
        unicode: 'F127B',
        name: 'mdi-poll-box-outline'
      },
      {
        unicode: 'F14C3',
        name: 'mdi-polo'
      },
      {
        unicode: 'F0421',
        name: 'mdi-polymer'
      },
      {
        unicode: 'F0606',
        name: 'mdi-pool'
      },
      {
        unicode: 'F0422',
        name: 'mdi-popcorn'
      },
      {
        unicode: 'F1008',
        name: 'mdi-post'
      },
      {
        unicode: 'F1009',
        name: 'mdi-post-outline'
      },
      {
        unicode: 'F0CBB',
        name: 'mdi-postage-stamp'
      },
      {
        unicode: 'F02E5',
        name: 'mdi-pot'
      },
      {
        unicode: 'F065B',
        name: 'mdi-pot-mix'
      },
      {
        unicode: 'F0677',
        name: 'mdi-pot-mix-outline'
      },
      {
        unicode: 'F02FF',
        name: 'mdi-pot-outline'
      },
      {
        unicode: 'F065A',
        name: 'mdi-pot-steam'
      },
      {
        unicode: 'F0326',
        name: 'mdi-pot-steam-outline'
      },
      {
        unicode: 'F0423',
        name: 'mdi-pound'
      },
      {
        unicode: 'F0424',
        name: 'mdi-pound-box'
      },
      {
        unicode: 'F117F',
        name: 'mdi-pound-box-outline'
      },
      {
        unicode: 'F0425',
        name: 'mdi-power'
      },
      {
        unicode: 'F0901',
        name: 'mdi-power-cycle'
      },
      {
        unicode: 'F0902',
        name: 'mdi-power-off'
      },
      {
        unicode: 'F0903',
        name: 'mdi-power-on'
      },
      {
        unicode: 'F06A5',
        name: 'mdi-power-plug'
      },
      {
        unicode: 'F06A6',
        name: 'mdi-power-plug-off'
      },
      {
        unicode: 'F1424',
        name: 'mdi-power-plug-off-outline'
      },
      {
        unicode: 'F1425',
        name: 'mdi-power-plug-outline'
      },
      {
        unicode: 'F0426',
        name: 'mdi-power-settings'
      },
      {
        unicode: 'F0904',
        name: 'mdi-power-sleep'
      },
      {
        unicode: 'F0427',
        name: 'mdi-power-socket'
      },
      {
        unicode: 'F0905',
        name: 'mdi-power-socket-au'
      },
      {
        unicode: 'F1107',
        name: 'mdi-power-socket-de'
      },
      {
        unicode: 'F07E7',
        name: 'mdi-power-socket-eu'
      },
      {
        unicode: 'F1108',
        name: 'mdi-power-socket-fr'
      },
      {
        unicode: 'F1109',
        name: 'mdi-power-socket-jp'
      },
      {
        unicode: 'F07E8',
        name: 'mdi-power-socket-uk'
      },
      {
        unicode: 'F07E9',
        name: 'mdi-power-socket-us'
      },
      {
        unicode: 'F0906',
        name: 'mdi-power-standby'
      },
      {
        unicode: 'F0A0A',
        name: 'mdi-powershell'
      },
      {
        unicode: 'F0706',
        name: 'mdi-prescription'
      },
      {
        unicode: 'F0428',
        name: 'mdi-presentation'
      },
      {
        unicode: 'F0429',
        name: 'mdi-presentation-play'
      },
      {
        unicode: 'F042A',
        name: 'mdi-printer'
      },
      {
        unicode: 'F042B',
        name: 'mdi-printer-3d'
      },
      {
        unicode: 'F0E5B',
        name: 'mdi-printer-3d-nozzle'
      },
      {
        unicode: 'F11C0',
        name: 'mdi-printer-3d-nozzle-alert'
      },
      {
        unicode: 'F11C1',
        name: 'mdi-printer-3d-nozzle-alert-outline'
      },
      {
        unicode: 'F0E5C',
        name: 'mdi-printer-3d-nozzle-outline'
      },
      {
        unicode: 'F042C',
        name: 'mdi-printer-alert'
      },
      {
        unicode: 'F1146',
        name: 'mdi-printer-check'
      },
      {
        unicode: 'F1458',
        name: 'mdi-printer-eye'
      },
      {
        unicode: 'F0E5D',
        name: 'mdi-printer-off'
      },
      {
        unicode: 'F1057',
        name: 'mdi-printer-pos'
      },
      {
        unicode: 'F1457',
        name: 'mdi-printer-search'
      },
      {
        unicode: 'F0707',
        name: 'mdi-printer-settings'
      },
      {
        unicode: 'F0A0B',
        name: 'mdi-printer-wireless'
      },
      {
        unicode: 'F0603',
        name: 'mdi-priority-high'
      },
      {
        unicode: 'F0604',
        name: 'mdi-priority-low'
      },
      {
        unicode: 'F042D',
        name: 'mdi-professional-hexagon'
      },
      {
        unicode: 'F0CBC',
        name: 'mdi-progress-alert'
      },
      {
        unicode: 'F0995',
        name: 'mdi-progress-check'
      },
      {
        unicode: 'F0996',
        name: 'mdi-progress-clock'
      },
      {
        unicode: 'F110A',
        name: 'mdi-progress-close'
      },
      {
        unicode: 'F0997',
        name: 'mdi-progress-download'
      },
      {
        unicode: 'F0998',
        name: 'mdi-progress-upload'
      },
      {
        unicode: 'F0CBD',
        name: 'mdi-progress-wrench'
      },
      {
        unicode: 'F042E',
        name: 'mdi-projector'
      },
      {
        unicode: 'F042F',
        name: 'mdi-projector-screen'
      },
      {
        unicode: 'F1357',
        name: 'mdi-propane-tank'
      },
      {
        unicode: 'F1358',
        name: 'mdi-propane-tank-outline'
      },
      {
        unicode: 'F0FD8',
        name: 'mdi-protocol'
      },
      {
        unicode: 'F06A7',
        name: 'mdi-publish'
      },
      {
        unicode: 'F0430',
        name: 'mdi-pulse'
      },
      {
        unicode: 'F1402',
        name: 'mdi-pump'
      },
      {
        unicode: 'F0BBF',
        name: 'mdi-pumpkin'
      },
      {
        unicode: 'F0F1C',
        name: 'mdi-purse'
      },
      {
        unicode: 'F0F1D',
        name: 'mdi-purse-outline'
      },
      {
        unicode: 'F0431',
        name: 'mdi-puzzle'
      },
      {
        unicode: 'F1426',
        name: 'mdi-puzzle-check'
      },
      {
        unicode: 'F1427',
        name: 'mdi-puzzle-check-outline'
      },
      {
        unicode: 'F14D3',
        name: 'mdi-puzzle-edit'
      },
      {
        unicode: 'F14D9',
        name: 'mdi-puzzle-edit-outline'
      },
      {
        unicode: 'F14D4',
        name: 'mdi-puzzle-heart'
      },
      {
        unicode: 'F14DA',
        name: 'mdi-puzzle-heart-outline'
      },
      {
        unicode: 'F14D1',
        name: 'mdi-puzzle-minus'
      },
      {
        unicode: 'F14D7',
        name: 'mdi-puzzle-minus-outline'
      },
      {
        unicode: 'F0A66',
        name: 'mdi-puzzle-outline'
      },
      {
        unicode: 'F14D0',
        name: 'mdi-puzzle-plus'
      },
      {
        unicode: 'F14D6',
        name: 'mdi-puzzle-plus-outline'
      },
      {
        unicode: 'F14D2',
        name: 'mdi-puzzle-remove'
      },
      {
        unicode: 'F14D8',
        name: 'mdi-puzzle-remove-outline'
      },
      {
        unicode: 'F14D5',
        name: 'mdi-puzzle-star'
      },
      {
        unicode: 'F14DB',
        name: 'mdi-puzzle-star-outline'
      },
      {
        unicode: 'F0999',
        name: 'mdi-qi'
      },
      {
        unicode: 'F0605',
        name: 'mdi-qqchat'
      },
      {
        unicode: 'F0432',
        name: 'mdi-qrcode'
      },
      {
        unicode: 'F08B8',
        name: 'mdi-qrcode-edit'
      },
      {
        unicode: 'F118C',
        name: 'mdi-qrcode-minus'
      },
      {
        unicode: 'F118B',
        name: 'mdi-qrcode-plus'
      },
      {
        unicode: 'F118D',
        name: 'mdi-qrcode-remove'
      },
      {
        unicode: 'F0433',
        name: 'mdi-qrcode-scan'
      },
      {
        unicode: 'F0434',
        name: 'mdi-quadcopter'
      },
      {
        unicode: 'F0435',
        name: 'mdi-quality-high'
      },
      {
        unicode: 'F0A0C',
        name: 'mdi-quality-low'
      },
      {
        unicode: 'F0A0D',
        name: 'mdi-quality-medium'
      },
      {
        unicode: 'F0D29',
        name: 'mdi-quora'
      },
      {
        unicode: 'F0907',
        name: 'mdi-rabbit'
      },
      {
        unicode: 'F0D93',
        name: 'mdi-racing-helmet'
      },
      {
        unicode: 'F0D94',
        name: 'mdi-racquetball'
      },
      {
        unicode: 'F0437',
        name: 'mdi-radar'
      },
      {
        unicode: 'F0438',
        name: 'mdi-radiator'
      },
      {
        unicode: 'F0AD7',
        name: 'mdi-radiator-disabled'
      },
      {
        unicode: 'F0AD8',
        name: 'mdi-radiator-off'
      },
      {
        unicode: 'F0439',
        name: 'mdi-radio'
      },
      {
        unicode: 'F0CBE',
        name: 'mdi-radio-am'
      },
      {
        unicode: 'F0CBF',
        name: 'mdi-radio-fm'
      },
      {
        unicode: 'F043A',
        name: 'mdi-radio-handheld'
      },
      {
        unicode: 'F121C',
        name: 'mdi-radio-off'
      },
      {
        unicode: 'F043B',
        name: 'mdi-radio-tower'
      },
      {
        unicode: 'F043C',
        name: 'mdi-radioactive'
      },
      {
        unicode: 'F0EC1',
        name: 'mdi-radioactive-off'
      },
      {
        unicode: 'F043D',
        name: 'mdi-radiobox-blank'
      },
      {
        unicode: 'F043E',
        name: 'mdi-radiobox-marked'
      },
      {
        unicode: 'F14C5',
        name: 'mdi-radiology-box'
      },
      {
        unicode: 'F14C6',
        name: 'mdi-radiology-box-outline'
      },
      {
        unicode: 'F0CC0',
        name: 'mdi-radius'
      },
      {
        unicode: 'F0CC1',
        name: 'mdi-radius-outline'
      },
      {
        unicode: 'F0F1E',
        name: 'mdi-railroad-light'
      },
      {
        unicode: 'F043F',
        name: 'mdi-raspberry-pi'
      },
      {
        unicode: 'F0440',
        name: 'mdi-ray-end'
      },
      {
        unicode: 'F0441',
        name: 'mdi-ray-end-arrow'
      },
      {
        unicode: 'F0442',
        name: 'mdi-ray-start'
      },
      {
        unicode: 'F0443',
        name: 'mdi-ray-start-arrow'
      },
      {
        unicode: 'F0444',
        name: 'mdi-ray-start-end'
      },
      {
        unicode: 'F0445',
        name: 'mdi-ray-vertex'
      },
      {
        unicode: 'F0708',
        name: 'mdi-react'
      },
      {
        unicode: 'F0447',
        name: 'mdi-read'
      },
      {
        unicode: 'F0449',
        name: 'mdi-receipt'
      },
      {
        unicode: 'F044A',
        name: 'mdi-record'
      },
      {
        unicode: 'F0EC2',
        name: 'mdi-record-circle'
      },
      {
        unicode: 'F0EC3',
        name: 'mdi-record-circle-outline'
      },
      {
        unicode: 'F099A',
        name: 'mdi-record-player'
      },
      {
        unicode: 'F044B',
        name: 'mdi-record-rec'
      },
      {
        unicode: 'F0E5E',
        name: 'mdi-rectangle'
      },
      {
        unicode: 'F0E5F',
        name: 'mdi-rectangle-outline'
      },
      {
        unicode: 'F044C',
        name: 'mdi-recycle'
      },
      {
        unicode: 'F139D',
        name: 'mdi-recycle-variant'
      },
      {
        unicode: 'F044D',
        name: 'mdi-reddit'
      },
      {
        unicode: 'F111B',
        name: 'mdi-redhat'
      },
      {
        unicode: 'F044E',
        name: 'mdi-redo'
      },
      {
        unicode: 'F044F',
        name: 'mdi-redo-variant'
      },
      {
        unicode: 'F0A0E',
        name: 'mdi-reflect-horizontal'
      },
      {
        unicode: 'F0A0F',
        name: 'mdi-reflect-vertical'
      },
      {
        unicode: 'F0450',
        name: 'mdi-refresh'
      },
      {
        unicode: 'F1377',
        name: 'mdi-refresh-circle'
      },
      {
        unicode: 'F0451',
        name: 'mdi-regex'
      },
      {
        unicode: 'F0A67',
        name: 'mdi-registered-trademark'
      },
      {
        unicode: 'F1496',
        name: 'mdi-relation-many-to-many'
      },
      {
        unicode: 'F1497',
        name: 'mdi-relation-many-to-one'
      },
      {
        unicode: 'F1498',
        name: 'mdi-relation-many-to-one-or-many'
      },
      {
        unicode: 'F1499',
        name: 'mdi-relation-many-to-only-one'
      },
      {
        unicode: 'F149A',
        name: 'mdi-relation-many-to-zero-or-many'
      },
      {
        unicode: 'F149B',
        name: 'mdi-relation-many-to-zero-or-one'
      },
      {
        unicode: 'F149C',
        name: 'mdi-relation-one-or-many-to-many'
      },
      {
        unicode: 'F149D',
        name: 'mdi-relation-one-or-many-to-one'
      },
      {
        unicode: 'F149E',
        name: 'mdi-relation-one-or-many-to-one-or-many'
      },
      {
        unicode: 'F149F',
        name: 'mdi-relation-one-or-many-to-only-one'
      },
      {
        unicode: 'F14A0',
        name: 'mdi-relation-one-or-many-to-zero-or-many'
      },
      {
        unicode: 'F14A1',
        name: 'mdi-relation-one-or-many-to-zero-or-one'
      },
      {
        unicode: 'F14A2',
        name: 'mdi-relation-one-to-many'
      },
      {
        unicode: 'F14A3',
        name: 'mdi-relation-one-to-one'
      },
      {
        unicode: 'F14A4',
        name: 'mdi-relation-one-to-one-or-many'
      },
      {
        unicode: 'F14A5',
        name: 'mdi-relation-one-to-only-one'
      },
      {
        unicode: 'F14A6',
        name: 'mdi-relation-one-to-zero-or-many'
      },
      {
        unicode: 'F14A7',
        name: 'mdi-relation-one-to-zero-or-one'
      },
      {
        unicode: 'F14A8',
        name: 'mdi-relation-only-one-to-many'
      },
      {
        unicode: 'F14A9',
        name: 'mdi-relation-only-one-to-one'
      },
      {
        unicode: 'F14AA',
        name: 'mdi-relation-only-one-to-one-or-many'
      },
      {
        unicode: 'F14AB',
        name: 'mdi-relation-only-one-to-only-one'
      },
      {
        unicode: 'F14AC',
        name: 'mdi-relation-only-one-to-zero-or-many'
      },
      {
        unicode: 'F14AD',
        name: 'mdi-relation-only-one-to-zero-or-one'
      },
      {
        unicode: 'F14AE',
        name: 'mdi-relation-zero-or-many-to-many'
      },
      {
        unicode: 'F14AF',
        name: 'mdi-relation-zero-or-many-to-one'
      },
      {
        unicode: 'F14B0',
        name: 'mdi-relation-zero-or-many-to-one-or-many'
      },
      {
        unicode: 'F14B1',
        name: 'mdi-relation-zero-or-many-to-only-one'
      },
      {
        unicode: 'F14B2',
        name: 'mdi-relation-zero-or-many-to-zero-or-many'
      },
      {
        unicode: 'F14B3',
        name: 'mdi-relation-zero-or-many-to-zero-or-one'
      },
      {
        unicode: 'F14B4',
        name: 'mdi-relation-zero-or-one-to-many'
      },
      {
        unicode: 'F14B5',
        name: 'mdi-relation-zero-or-one-to-one'
      },
      {
        unicode: 'F14B6',
        name: 'mdi-relation-zero-or-one-to-one-or-many'
      },
      {
        unicode: 'F14B7',
        name: 'mdi-relation-zero-or-one-to-only-one'
      },
      {
        unicode: 'F14B8',
        name: 'mdi-relation-zero-or-one-to-zero-or-many'
      },
      {
        unicode: 'F14B9',
        name: 'mdi-relation-zero-or-one-to-zero-or-one'
      },
      {
        unicode: 'F0452',
        name: 'mdi-relative-scale'
      },
      {
        unicode: 'F0453',
        name: 'mdi-reload'
      },
      {
        unicode: 'F110B',
        name: 'mdi-reload-alert'
      },
      {
        unicode: 'F088C',
        name: 'mdi-reminder'
      },
      {
        unicode: 'F0454',
        name: 'mdi-remote'
      },
      {
        unicode: 'F08B9',
        name: 'mdi-remote-desktop'
      },
      {
        unicode: 'F0EC4',
        name: 'mdi-remote-off'
      },
      {
        unicode: 'F0EC5',
        name: 'mdi-remote-tv'
      },
      {
        unicode: 'F0EC6',
        name: 'mdi-remote-tv-off'
      },
      {
        unicode: 'F0455',
        name: 'mdi-rename-box'
      },
      {
        unicode: 'F0688',
        name: 'mdi-reorder-horizontal'
      },
      {
        unicode: 'F0689',
        name: 'mdi-reorder-vertical'
      },
      {
        unicode: 'F0456',
        name: 'mdi-repeat'
      },
      {
        unicode: 'F0457',
        name: 'mdi-repeat-off'
      },
      {
        unicode: 'F0458',
        name: 'mdi-repeat-once'
      },
      {
        unicode: 'F0459',
        name: 'mdi-replay'
      },
      {
        unicode: 'F045A',
        name: 'mdi-reply'
      },
      {
        unicode: 'F045B',
        name: 'mdi-reply-all'
      },
      {
        unicode: 'F0F1F',
        name: 'mdi-reply-all-outline'
      },
      {
        unicode: 'F11AE',
        name: 'mdi-reply-circle'
      },
      {
        unicode: 'F0F20',
        name: 'mdi-reply-outline'
      },
      {
        unicode: 'F045C',
        name: 'mdi-reproduction'
      },
      {
        unicode: 'F0B44',
        name: 'mdi-resistor'
      },
      {
        unicode: 'F0B45',
        name: 'mdi-resistor-nodes'
      },
      {
        unicode: 'F0A68',
        name: 'mdi-resize'
      },
      {
        unicode: 'F045D',
        name: 'mdi-resize-bottom-right'
      },
      {
        unicode: 'F045E',
        name: 'mdi-responsive'
      },
      {
        unicode: 'F0709',
        name: 'mdi-restart'
      },
      {
        unicode: 'F110C',
        name: 'mdi-restart-alert'
      },
      {
        unicode: 'F0D95',
        name: 'mdi-restart-off'
      },
      {
        unicode: 'F099B',
        name: 'mdi-restore'
      },
      {
        unicode: 'F110D',
        name: 'mdi-restore-alert'
      },
      {
        unicode: 'F045F',
        name: 'mdi-rewind'
      },
      {
        unicode: 'F0D2A',
        name: 'mdi-rewind-10'
      },
      {
        unicode: 'F0D96',
        name: 'mdi-rewind-30'
      },
      {
        unicode: 'F11F9',
        name: 'mdi-rewind-5'
      },
      {
        unicode: 'F070A',
        name: 'mdi-rewind-outline'
      },
      {
        unicode: 'F070B',
        name: 'mdi-rhombus'
      },
      {
        unicode: 'F0A10',
        name: 'mdi-rhombus-medium'
      },
      {
        unicode: 'F14DC',
        name: 'mdi-rhombus-medium-outline'
      },
      {
        unicode: 'F070C',
        name: 'mdi-rhombus-outline'
      },
      {
        unicode: 'F0A11',
        name: 'mdi-rhombus-split'
      },
      {
        unicode: 'F14DD',
        name: 'mdi-rhombus-split-outline'
      },
      {
        unicode: 'F0460',
        name: 'mdi-ribbon'
      },
      {
        unicode: 'F07EA',
        name: 'mdi-rice'
      },
      {
        unicode: 'F07EB',
        name: 'mdi-ring'
      },
      {
        unicode: 'F0E60',
        name: 'mdi-rivet'
      },
      {
        unicode: 'F0461',
        name: 'mdi-road'
      },
      {
        unicode: 'F0462',
        name: 'mdi-road-variant'
      },
      {
        unicode: 'F1058',
        name: 'mdi-robber'
      },
      {
        unicode: 'F06A9',
        name: 'mdi-robot'
      },
      {
        unicode: 'F0B46',
        name: 'mdi-robot-industrial'
      },
      {
        unicode: 'F11F7',
        name: 'mdi-robot-mower'
      },
      {
        unicode: 'F11F3',
        name: 'mdi-robot-mower-outline'
      },
      {
        unicode: 'F070D',
        name: 'mdi-robot-vacuum'
      },
      {
        unicode: 'F0908',
        name: 'mdi-robot-vacuum-variant'
      },
      {
        unicode: 'F0463',
        name: 'mdi-rocket'
      },
      {
        unicode: 'F14DE',
        name: 'mdi-rocket-launch'
      },
      {
        unicode: 'F14DF',
        name: 'mdi-rocket-launch-outline'
      },
      {
        unicode: 'F13AF',
        name: 'mdi-rocket-outline'
      },
      {
        unicode: 'F1327',
        name: 'mdi-rodent'
      },
      {
        unicode: 'F0D2B',
        name: 'mdi-roller-skate'
      },
      {
        unicode: 'F0145',
        name: 'mdi-roller-skate-off'
      },
      {
        unicode: 'F0D2C',
        name: 'mdi-rollerblade'
      },
      {
        unicode: 'F002E',
        name: 'mdi-rollerblade-off'
      },
      {
        unicode: 'F0BC0',
        name: 'mdi-rollupjs'
      },
      {
        unicode: 'F1088',
        name: 'mdi-roman-numeral-1'
      },
      {
        unicode: 'F1091',
        name: 'mdi-roman-numeral-10'
      },
      {
        unicode: 'F1089',
        name: 'mdi-roman-numeral-2'
      },
      {
        unicode: 'F108A',
        name: 'mdi-roman-numeral-3'
      },
      {
        unicode: 'F108B',
        name: 'mdi-roman-numeral-4'
      },
      {
        unicode: 'F108C',
        name: 'mdi-roman-numeral-5'
      },
      {
        unicode: 'F108D',
        name: 'mdi-roman-numeral-6'
      },
      {
        unicode: 'F108E',
        name: 'mdi-roman-numeral-7'
      },
      {
        unicode: 'F108F',
        name: 'mdi-roman-numeral-8'
      },
      {
        unicode: 'F1090',
        name: 'mdi-roman-numeral-9'
      },
      {
        unicode: 'F088D',
        name: 'mdi-room-service'
      },
      {
        unicode: 'F0D97',
        name: 'mdi-room-service-outline'
      },
      {
        unicode: 'F0EC7',
        name: 'mdi-rotate-3d'
      },
      {
        unicode: 'F0464',
        name: 'mdi-rotate-3d-variant'
      },
      {
        unicode: 'F0465',
        name: 'mdi-rotate-left'
      },
      {
        unicode: 'F0466',
        name: 'mdi-rotate-left-variant'
      },
      {
        unicode: 'F0D98',
        name: 'mdi-rotate-orbit'
      },
      {
        unicode: 'F0467',
        name: 'mdi-rotate-right'
      },
      {
        unicode: 'F0468',
        name: 'mdi-rotate-right-variant'
      },
      {
        unicode: 'F0607',
        name: 'mdi-rounded-corner'
      },
      {
        unicode: 'F11E2',
        name: 'mdi-router'
      },
      {
        unicode: 'F1087',
        name: 'mdi-router-network'
      },
      {
        unicode: 'F0469',
        name: 'mdi-router-wireless'
      },
      {
        unicode: 'F0A69',
        name: 'mdi-router-wireless-settings'
      },
      {
        unicode: 'F046A',
        name: 'mdi-routes'
      },
      {
        unicode: 'F1059',
        name: 'mdi-routes-clock'
      },
      {
        unicode: 'F0608',
        name: 'mdi-rowing'
      },
      {
        unicode: 'F046B',
        name: 'mdi-rss'
      },
      {
        unicode: 'F046C',
        name: 'mdi-rss-box'
      },
      {
        unicode: 'F0F21',
        name: 'mdi-rss-off'
      },
      {
        unicode: 'F1475',
        name: 'mdi-rug'
      },
      {
        unicode: 'F0D99',
        name: 'mdi-rugby'
      },
      {
        unicode: 'F046D',
        name: 'mdi-ruler'
      },
      {
        unicode: 'F0CC2',
        name: 'mdi-ruler-square'
      },
      {
        unicode: 'F0EBE',
        name: 'mdi-ruler-square-compass'
      },
      {
        unicode: 'F070E',
        name: 'mdi-run'
      },
      {
        unicode: 'F046E',
        name: 'mdi-run-fast'
      },
      {
        unicode: 'F11D4',
        name: 'mdi-rv-truck'
      },
      {
        unicode: 'F0D2E',
        name: 'mdi-sack'
      },
      {
        unicode: 'F0D2F',
        name: 'mdi-sack-percent'
      },
      {
        unicode: 'F0A6A',
        name: 'mdi-safe'
      },
      {
        unicode: 'F127C',
        name: 'mdi-safe-square'
      },
      {
        unicode: 'F127D',
        name: 'mdi-safe-square-outline'
      },
      {
        unicode: 'F0D30',
        name: 'mdi-safety-goggles'
      },
      {
        unicode: 'F0EC8',
        name: 'mdi-sail-boat'
      },
      {
        unicode: 'F046F',
        name: 'mdi-sale'
      },
      {
        unicode: 'F088E',
        name: 'mdi-salesforce'
      },
      {
        unicode: 'F07EC',
        name: 'mdi-sass'
      },
      {
        unicode: 'F0470',
        name: 'mdi-satellite'
      },
      {
        unicode: 'F0909',
        name: 'mdi-satellite-uplink'
      },
      {
        unicode: 'F0471',
        name: 'mdi-satellite-variant'
      },
      {
        unicode: 'F08BA',
        name: 'mdi-sausage'
      },
      {
        unicode: 'F0E61',
        name: 'mdi-saw-blade'
      },
      {
        unicode: 'F147A',
        name: 'mdi-sawtooth-wave'
      },
      {
        unicode: 'F0609',
        name: 'mdi-saxophone'
      },
      {
        unicode: 'F0472',
        name: 'mdi-scale'
      },
      {
        unicode: 'F05D1',
        name: 'mdi-scale-balance'
      },
      {
        unicode: 'F0473',
        name: 'mdi-scale-bathroom'
      },
      {
        unicode: 'F105A',
        name: 'mdi-scale-off'
      },
      {
        unicode: 'F13D8',
        name: 'mdi-scan-helper'
      },
      {
        unicode: 'F06AB',
        name: 'mdi-scanner'
      },
      {
        unicode: 'F090A',
        name: 'mdi-scanner-off'
      },
      {
        unicode: 'F0EC9',
        name: 'mdi-scatter-plot'
      },
      {
        unicode: 'F0ECA',
        name: 'mdi-scatter-plot-outline'
      },
      {
        unicode: 'F0474',
        name: 'mdi-school'
      },
      {
        unicode: 'F1180',
        name: 'mdi-school-outline'
      },
      {
        unicode: 'F0A6B',
        name: 'mdi-scissors-cutting'
      },
      {
        unicode: 'F11E9',
        name: 'mdi-scooter'
      },
      {
        unicode: 'F127E',
        name: 'mdi-scoreboard'
      },
      {
        unicode: 'F127F',
        name: 'mdi-scoreboard-outline'
      },
      {
        unicode: 'F0475',
        name: 'mdi-screen-rotation'
      },
      {
        unicode: 'F0478',
        name: 'mdi-screen-rotation-lock'
      },
      {
        unicode: 'F0DF3',
        name: 'mdi-screw-flat-top'
      },
      {
        unicode: 'F0DF4',
        name: 'mdi-screw-lag'
      },
      {
        unicode: 'F0DF5',
        name: 'mdi-screw-machine-flat-top'
      },
      {
        unicode: 'F0DF6',
        name: 'mdi-screw-machine-round-top'
      },
      {
        unicode: 'F0DF7',
        name: 'mdi-screw-round-top'
      },
      {
        unicode: 'F0476',
        name: 'mdi-screwdriver'
      },
      {
        unicode: 'F0BC1',
        name: 'mdi-script'
      },
      {
        unicode: 'F0477',
        name: 'mdi-script-outline'
      },
      {
        unicode: 'F0BC2',
        name: 'mdi-script-text'
      },
      {
        unicode: 'F0BC3',
        name: 'mdi-script-text-outline'
      },
      {
        unicode: 'F0479',
        name: 'mdi-sd'
      },
      {
        unicode: 'F047A',
        name: 'mdi-seal'
      },
      {
        unicode: 'F0FD9',
        name: 'mdi-seal-variant'
      },
      {
        unicode: 'F070F',
        name: 'mdi-search-web'
      },
      {
        unicode: 'F0CC3',
        name: 'mdi-seat'
      },
      {
        unicode: 'F047B',
        name: 'mdi-seat-flat'
      },
      {
        unicode: 'F047C',
        name: 'mdi-seat-flat-angled'
      },
      {
        unicode: 'F047D',
        name: 'mdi-seat-individual-suite'
      },
      {
        unicode: 'F047E',
        name: 'mdi-seat-legroom-extra'
      },
      {
        unicode: 'F047F',
        name: 'mdi-seat-legroom-normal'
      },
      {
        unicode: 'F0480',
        name: 'mdi-seat-legroom-reduced'
      },
      {
        unicode: 'F0CC4',
        name: 'mdi-seat-outline'
      },
      {
        unicode: 'F1249',
        name: 'mdi-seat-passenger'
      },
      {
        unicode: 'F0481',
        name: 'mdi-seat-recline-extra'
      },
      {
        unicode: 'F0482',
        name: 'mdi-seat-recline-normal'
      },
      {
        unicode: 'F0CC5',
        name: 'mdi-seatbelt'
      },
      {
        unicode: 'F0483',
        name: 'mdi-security'
      },
      {
        unicode: 'F0484',
        name: 'mdi-security-network'
      },
      {
        unicode: 'F0E62',
        name: 'mdi-seed'
      },
      {
        unicode: 'F13FD',
        name: 'mdi-seed-off'
      },
      {
        unicode: 'F13FE',
        name: 'mdi-seed-off-outline'
      },
      {
        unicode: 'F0E63',
        name: 'mdi-seed-outline'
      },
      {
        unicode: 'F0ECB',
        name: 'mdi-segment'
      },
      {
        unicode: 'F0485',
        name: 'mdi-select'
      },
      {
        unicode: 'F0486',
        name: 'mdi-select-all'
      },
      {
        unicode: 'F0D31',
        name: 'mdi-select-color'
      },
      {
        unicode: 'F0AD9',
        name: 'mdi-select-compare'
      },
      {
        unicode: 'F0A6C',
        name: 'mdi-select-drag'
      },
      {
        unicode: 'F0F82',
        name: 'mdi-select-group'
      },
      {
        unicode: 'F0487',
        name: 'mdi-select-inverse'
      },
      {
        unicode: 'F1280',
        name: 'mdi-select-marker'
      },
      {
        unicode: 'F1281',
        name: 'mdi-select-multiple'
      },
      {
        unicode: 'F1282',
        name: 'mdi-select-multiple-marker'
      },
      {
        unicode: 'F0488',
        name: 'mdi-select-off'
      },
      {
        unicode: 'F0FDA',
        name: 'mdi-select-place'
      },
      {
        unicode: 'F1204',
        name: 'mdi-select-search'
      },
      {
        unicode: 'F0489',
        name: 'mdi-selection'
      },
      {
        unicode: 'F0A6D',
        name: 'mdi-selection-drag'
      },
      {
        unicode: 'F0D32',
        name: 'mdi-selection-ellipse'
      },
      {
        unicode: 'F0F22',
        name: 'mdi-selection-ellipse-arrow-inside'
      },
      {
        unicode: 'F1283',
        name: 'mdi-selection-marker'
      },
      {
        unicode: 'F1285',
        name: 'mdi-selection-multiple'
      },
      {
        unicode: 'F1284',
        name: 'mdi-selection-multiple-marker'
      },
      {
        unicode: 'F0777',
        name: 'mdi-selection-off'
      },
      {
        unicode: 'F1205',
        name: 'mdi-selection-search'
      },
      {
        unicode: 'F1316',
        name: 'mdi-semantic-web'
      },
      {
        unicode: 'F048A',
        name: 'mdi-send'
      },
      {
        unicode: 'F1161',
        name: 'mdi-send-check'
      },
      {
        unicode: 'F1162',
        name: 'mdi-send-check-outline'
      },
      {
        unicode: 'F0DF8',
        name: 'mdi-send-circle'
      },
      {
        unicode: 'F0DF9',
        name: 'mdi-send-circle-outline'
      },
      {
        unicode: 'F1163',
        name: 'mdi-send-clock'
      },
      {
        unicode: 'F1164',
        name: 'mdi-send-clock-outline'
      },
      {
        unicode: 'F07ED',
        name: 'mdi-send-lock'
      },
      {
        unicode: 'F1166',
        name: 'mdi-send-lock-outline'
      },
      {
        unicode: 'F1165',
        name: 'mdi-send-outline'
      },
      {
        unicode: 'F065C',
        name: 'mdi-serial-port'
      },
      {
        unicode: 'F048B',
        name: 'mdi-server'
      },
      {
        unicode: 'F048C',
        name: 'mdi-server-minus'
      },
      {
        unicode: 'F048D',
        name: 'mdi-server-network'
      },
      {
        unicode: 'F048E',
        name: 'mdi-server-network-off'
      },
      {
        unicode: 'F048F',
        name: 'mdi-server-off'
      },
      {
        unicode: 'F0490',
        name: 'mdi-server-plus'
      },
      {
        unicode: 'F0491',
        name: 'mdi-server-remove'
      },
      {
        unicode: 'F0492',
        name: 'mdi-server-security'
      },
      {
        unicode: 'F0778',
        name: 'mdi-set-all'
      },
      {
        unicode: 'F0779',
        name: 'mdi-set-center'
      },
      {
        unicode: 'F077A',
        name: 'mdi-set-center-right'
      },
      {
        unicode: 'F077B',
        name: 'mdi-set-left'
      },
      {
        unicode: 'F077C',
        name: 'mdi-set-left-center'
      },
      {
        unicode: 'F077D',
        name: 'mdi-set-left-right'
      },
      {
        unicode: 'F14E0',
        name: 'mdi-set-merge'
      },
      {
        unicode: 'F077E',
        name: 'mdi-set-none'
      },
      {
        unicode: 'F077F',
        name: 'mdi-set-right'
      },
      {
        unicode: 'F14E1',
        name: 'mdi-set-split'
      },
      {
        unicode: 'F145D',
        name: 'mdi-set-square'
      },
      {
        unicode: 'F099F',
        name: 'mdi-set-top-box'
      },
      {
        unicode: 'F0A6E',
        name: 'mdi-settings-helper'
      },
      {
        unicode: 'F110E',
        name: 'mdi-shaker'
      },
      {
        unicode: 'F110F',
        name: 'mdi-shaker-outline'
      },
      {
        unicode: 'F0831',
        name: 'mdi-shape'
      },
      {
        unicode: 'F065D',
        name: 'mdi-shape-circle-plus'
      },
      {
        unicode: 'F0832',
        name: 'mdi-shape-outline'
      },
      {
        unicode: 'F11FA',
        name: 'mdi-shape-oval-plus'
      },
      {
        unicode: 'F0495',
        name: 'mdi-shape-plus'
      },
      {
        unicode: 'F065E',
        name: 'mdi-shape-polygon-plus'
      },
      {
        unicode: 'F065F',
        name: 'mdi-shape-rectangle-plus'
      },
      {
        unicode: 'F0660',
        name: 'mdi-shape-square-plus'
      },
      {
        unicode: 'F0496',
        name: 'mdi-share'
      },
      {
        unicode: 'F11F4',
        name: 'mdi-share-all'
      },
      {
        unicode: 'F11F5',
        name: 'mdi-share-all-outline'
      },
      {
        unicode: 'F11AD',
        name: 'mdi-share-circle'
      },
      {
        unicode: 'F0F23',
        name: 'mdi-share-off'
      },
      {
        unicode: 'F0F24',
        name: 'mdi-share-off-outline'
      },
      {
        unicode: 'F0932',
        name: 'mdi-share-outline'
      },
      {
        unicode: 'F0497',
        name: 'mdi-share-variant'
      },
      {
        unicode: 'F0CC6',
        name: 'mdi-sheep'
      },
      {
        unicode: 'F0498',
        name: 'mdi-shield'
      },
      {
        unicode: 'F088F',
        name: 'mdi-shield-account'
      },
      {
        unicode: 'F0A12',
        name: 'mdi-shield-account-outline'
      },
      {
        unicode: 'F06BB',
        name: 'mdi-shield-airplane'
      },
      {
        unicode: 'F0CC7',
        name: 'mdi-shield-airplane-outline'
      },
      {
        unicode: 'F0ECC',
        name: 'mdi-shield-alert'
      },
      {
        unicode: 'F0ECD',
        name: 'mdi-shield-alert-outline'
      },
      {
        unicode: 'F13DA',
        name: 'mdi-shield-bug'
      },
      {
        unicode: 'F13DB',
        name: 'mdi-shield-bug-outline'
      },
      {
        unicode: 'F0F83',
        name: 'mdi-shield-car'
      },
      {
        unicode: 'F0565',
        name: 'mdi-shield-check'
      },
      {
        unicode: 'F0CC8',
        name: 'mdi-shield-check-outline'
      },
      {
        unicode: 'F0CC9',
        name: 'mdi-shield-cross'
      },
      {
        unicode: 'F0CCA',
        name: 'mdi-shield-cross-outline'
      },
      {
        unicode: 'F11A0',
        name: 'mdi-shield-edit'
      },
      {
        unicode: 'F11A1',
        name: 'mdi-shield-edit-outline'
      },
      {
        unicode: 'F1360',
        name: 'mdi-shield-half'
      },
      {
        unicode: 'F0780',
        name: 'mdi-shield-half-full'
      },
      {
        unicode: 'F068A',
        name: 'mdi-shield-home'
      },
      {
        unicode: 'F0CCB',
        name: 'mdi-shield-home-outline'
      },
      {
        unicode: 'F0BC4',
        name: 'mdi-shield-key'
      },
      {
        unicode: 'F0BC5',
        name: 'mdi-shield-key-outline'
      },
      {
        unicode: 'F0D33',
        name: 'mdi-shield-link-variant'
      },
      {
        unicode: 'F0D34',
        name: 'mdi-shield-link-variant-outline'
      },
      {
        unicode: 'F099D',
        name: 'mdi-shield-lock'
      },
      {
        unicode: 'F0CCC',
        name: 'mdi-shield-lock-outline'
      },
      {
        unicode: 'F099E',
        name: 'mdi-shield-off'
      },
      {
        unicode: 'F099C',
        name: 'mdi-shield-off-outline'
      },
      {
        unicode: 'F0499',
        name: 'mdi-shield-outline'
      },
      {
        unicode: 'F0ADA',
        name: 'mdi-shield-plus'
      },
      {
        unicode: 'F0ADB',
        name: 'mdi-shield-plus-outline'
      },
      {
        unicode: 'F00AA',
        name: 'mdi-shield-refresh'
      },
      {
        unicode: 'F01E0',
        name: 'mdi-shield-refresh-outline'
      },
      {
        unicode: 'F0ADC',
        name: 'mdi-shield-remove'
      },
      {
        unicode: 'F0ADD',
        name: 'mdi-shield-remove-outline'
      },
      {
        unicode: 'F0D9A',
        name: 'mdi-shield-search'
      },
      {
        unicode: 'F113B',
        name: 'mdi-shield-star'
      },
      {
        unicode: 'F113C',
        name: 'mdi-shield-star-outline'
      },
      {
        unicode: 'F105D',
        name: 'mdi-shield-sun'
      },
      {
        unicode: 'F105E',
        name: 'mdi-shield-sun-outline'
      },
      {
        unicode: 'F11A2',
        name: 'mdi-shield-sync'
      },
      {
        unicode: 'F11A3',
        name: 'mdi-shield-sync-outline'
      },
      {
        unicode: 'F0833',
        name: 'mdi-ship-wheel'
      },
      {
        unicode: 'F0B47',
        name: 'mdi-shoe-formal'
      },
      {
        unicode: 'F0B48',
        name: 'mdi-shoe-heel'
      },
      {
        unicode: 'F0DFA',
        name: 'mdi-shoe-print'
      },
      {
        unicode: 'F049A',
        name: 'mdi-shopping'
      },
      {
        unicode: 'F049B',
        name: 'mdi-shopping-music'
      },
      {
        unicode: 'F11D5',
        name: 'mdi-shopping-outline'
      },
      {
        unicode: 'F0F84',
        name: 'mdi-shopping-search'
      },
      {
        unicode: 'F0710',
        name: 'mdi-shovel'
      },
      {
        unicode: 'F0711',
        name: 'mdi-shovel-off'
      },
      {
        unicode: 'F09A0',
        name: 'mdi-shower'
      },
      {
        unicode: 'F09A1',
        name: 'mdi-shower-head'
      },
      {
        unicode: 'F049C',
        name: 'mdi-shredder'
      },
      {
        unicode: 'F049D',
        name: 'mdi-shuffle'
      },
      {
        unicode: 'F049E',
        name: 'mdi-shuffle-disabled'
      },
      {
        unicode: 'F049F',
        name: 'mdi-shuffle-variant'
      },
      {
        unicode: 'F137F',
        name: 'mdi-shuriken'
      },
      {
        unicode: 'F04A0',
        name: 'mdi-sigma'
      },
      {
        unicode: 'F062B',
        name: 'mdi-sigma-lower'
      },
      {
        unicode: 'F04A1',
        name: 'mdi-sign-caution'
      },
      {
        unicode: 'F0781',
        name: 'mdi-sign-direction'
      },
      {
        unicode: 'F1000',
        name: 'mdi-sign-direction-minus'
      },
      {
        unicode: 'F0FDC',
        name: 'mdi-sign-direction-plus'
      },
      {
        unicode: 'F0FDD',
        name: 'mdi-sign-direction-remove'
      },
      {
        unicode: 'F1118',
        name: 'mdi-sign-real-estate'
      },
      {
        unicode: 'F0782',
        name: 'mdi-sign-text'
      },
      {
        unicode: 'F04A2',
        name: 'mdi-signal'
      },
      {
        unicode: 'F0712',
        name: 'mdi-signal-2g'
      },
      {
        unicode: 'F0713',
        name: 'mdi-signal-3g'
      },
      {
        unicode: 'F0714',
        name: 'mdi-signal-4g'
      },
      {
        unicode: 'F0A6F',
        name: 'mdi-signal-5g'
      },
      {
        unicode: 'F08BC',
        name: 'mdi-signal-cellular-1'
      },
      {
        unicode: 'F08BD',
        name: 'mdi-signal-cellular-2'
      },
      {
        unicode: 'F08BE',
        name: 'mdi-signal-cellular-3'
      },
      {
        unicode: 'F08BF',
        name: 'mdi-signal-cellular-outline'
      },
      {
        unicode: 'F0E64',
        name: 'mdi-signal-distance-variant'
      },
      {
        unicode: 'F0715',
        name: 'mdi-signal-hspa'
      },
      {
        unicode: 'F0716',
        name: 'mdi-signal-hspa-plus'
      },
      {
        unicode: 'F0783',
        name: 'mdi-signal-off'
      },
      {
        unicode: 'F060A',
        name: 'mdi-signal-variant'
      },
      {
        unicode: 'F0DFB',
        name: 'mdi-signature'
      },
      {
        unicode: 'F0DFC',
        name: 'mdi-signature-freehand'
      },
      {
        unicode: 'F0DFD',
        name: 'mdi-signature-image'
      },
      {
        unicode: 'F0DFE',
        name: 'mdi-signature-text'
      },
      {
        unicode: 'F0B49',
        name: 'mdi-silo'
      },
      {
        unicode: 'F04A3',
        name: 'mdi-silverware'
      },
      {
        unicode: 'F0FDE',
        name: 'mdi-silverware-clean'
      },
      {
        unicode: 'F04A4',
        name: 'mdi-silverware-fork'
      },
      {
        unicode: 'F0A70',
        name: 'mdi-silverware-fork-knife'
      },
      {
        unicode: 'F04A5',
        name: 'mdi-silverware-spoon'
      },
      {
        unicode: 'F04A6',
        name: 'mdi-silverware-variant'
      },
      {
        unicode: 'F04A7',
        name: 'mdi-sim'
      },
      {
        unicode: 'F04A8',
        name: 'mdi-sim-alert'
      },
      {
        unicode: 'F04A9',
        name: 'mdi-sim-off'
      },
      {
        unicode: 'F131D',
        name: 'mdi-simple-icons'
      },
      {
        unicode: 'F0ADF',
        name: 'mdi-sina-weibo'
      },
      {
        unicode: 'F095B',
        name: 'mdi-sine-wave'
      },
      {
        unicode: 'F04AA',
        name: 'mdi-sitemap'
      },
      {
        unicode: 'F13A6',
        name: 'mdi-size-l'
      },
      {
        unicode: 'F13A5',
        name: 'mdi-size-m'
      },
      {
        unicode: 'F13A4',
        name: 'mdi-size-s'
      },
      {
        unicode: 'F13A7',
        name: 'mdi-size-xl'
      },
      {
        unicode: 'F13A3',
        name: 'mdi-size-xs'
      },
      {
        unicode: 'F13A8',
        name: 'mdi-size-xxl'
      },
      {
        unicode: 'F13A2',
        name: 'mdi-size-xxs'
      },
      {
        unicode: 'F13A9',
        name: 'mdi-size-xxxl'
      },
      {
        unicode: 'F0D35',
        name: 'mdi-skate'
      },
      {
        unicode: 'F14C2',
        name: 'mdi-skateboard'
      },
      {
        unicode: 'F0D36',
        name: 'mdi-skew-less'
      },
      {
        unicode: 'F0D37',
        name: 'mdi-skew-more'
      },
      {
        unicode: 'F1304',
        name: 'mdi-ski'
      },
      {
        unicode: 'F1305',
        name: 'mdi-ski-cross-country'
      },
      {
        unicode: 'F1306',
        name: 'mdi-ski-water'
      },
      {
        unicode: 'F04AB',
        name: 'mdi-skip-backward'
      },
      {
        unicode: 'F0F25',
        name: 'mdi-skip-backward-outline'
      },
      {
        unicode: 'F04AC',
        name: 'mdi-skip-forward'
      },
      {
        unicode: 'F0F26',
        name: 'mdi-skip-forward-outline'
      },
      {
        unicode: 'F04AD',
        name: 'mdi-skip-next'
      },
      {
        unicode: 'F0661',
        name: 'mdi-skip-next-circle'
      },
      {
        unicode: 'F0662',
        name: 'mdi-skip-next-circle-outline'
      },
      {
        unicode: 'F0F27',
        name: 'mdi-skip-next-outline'
      },
      {
        unicode: 'F04AE',
        name: 'mdi-skip-previous'
      },
      {
        unicode: 'F0663',
        name: 'mdi-skip-previous-circle'
      },
      {
        unicode: 'F0664',
        name: 'mdi-skip-previous-circle-outline'
      },
      {
        unicode: 'F0F28',
        name: 'mdi-skip-previous-outline'
      },
      {
        unicode: 'F068C',
        name: 'mdi-skull'
      },
      {
        unicode: 'F0BC6',
        name: 'mdi-skull-crossbones'
      },
      {
        unicode: 'F0BC7',
        name: 'mdi-skull-crossbones-outline'
      },
      {
        unicode: 'F0BC8',
        name: 'mdi-skull-outline'
      },
      {
        unicode: 'F14C7',
        name: 'mdi-skull-scan'
      },
      {
        unicode: 'F14C8',
        name: 'mdi-skull-scan-outline'
      },
      {
        unicode: 'F04AF',
        name: 'mdi-skype'
      },
      {
        unicode: 'F04B0',
        name: 'mdi-skype-business'
      },
      {
        unicode: 'F04B1',
        name: 'mdi-slack'
      },
      {
        unicode: 'F0FDF',
        name: 'mdi-slash-forward'
      },
      {
        unicode: 'F0FE0',
        name: 'mdi-slash-forward-box'
      },
      {
        unicode: 'F04B2',
        name: 'mdi-sleep'
      },
      {
        unicode: 'F04B3',
        name: 'mdi-sleep-off'
      },
      {
        unicode: 'F0DFF',
        name: 'mdi-slope-downhill'
      },
      {
        unicode: 'F0E00',
        name: 'mdi-slope-uphill'
      },
      {
        unicode: 'F1114',
        name: 'mdi-slot-machine'
      },
      {
        unicode: 'F1115',
        name: 'mdi-slot-machine-outline'
      },
      {
        unicode: 'F10BD',
        name: 'mdi-smart-card'
      },
      {
        unicode: 'F10BE',
        name: 'mdi-smart-card-outline'
      },
      {
        unicode: 'F10BF',
        name: 'mdi-smart-card-reader'
      },
      {
        unicode: 'F10C0',
        name: 'mdi-smart-card-reader-outline'
      },
      {
        unicode: 'F0A71',
        name: 'mdi-smog'
      },
      {
        unicode: 'F0392',
        name: 'mdi-smoke-detector'
      },
      {
        unicode: 'F04B4',
        name: 'mdi-smoking'
      },
      {
        unicode: 'F04B5',
        name: 'mdi-smoking-off'
      },
      {
        unicode: 'F140D',
        name: 'mdi-smoking-pipe'
      },
      {
        unicode: 'F1428',
        name: 'mdi-smoking-pipe-off'
      },
      {
        unicode: 'F04B6',
        name: 'mdi-snapchat'
      },
      {
        unicode: 'F1307',
        name: 'mdi-snowboard'
      },
      {
        unicode: 'F0717',
        name: 'mdi-snowflake'
      },
      {
        unicode: 'F0F29',
        name: 'mdi-snowflake-alert'
      },
      {
        unicode: 'F12CB',
        name: 'mdi-snowflake-melt'
      },
      {
        unicode: 'F0F2A',
        name: 'mdi-snowflake-variant'
      },
      {
        unicode: 'F04B7',
        name: 'mdi-snowman'
      },
      {
        unicode: 'F04B8',
        name: 'mdi-soccer'
      },
      {
        unicode: 'F0834',
        name: 'mdi-soccer-field'
      },
      {
        unicode: 'F04B9',
        name: 'mdi-sofa'
      },
      {
        unicode: 'F0D9B',
        name: 'mdi-solar-panel'
      },
      {
        unicode: 'F0D9C',
        name: 'mdi-solar-panel-large'
      },
      {
        unicode: 'F0A72',
        name: 'mdi-solar-power'
      },
      {
        unicode: 'F1092',
        name: 'mdi-soldering-iron'
      },
      {
        unicode: 'F068D',
        name: 'mdi-solid'
      },
      {
        unicode: 'F0414',
        name: 'mdi-sony-playstation'
      },
      {
        unicode: 'F04BA',
        name: 'mdi-sort'
      },
      {
        unicode: 'F05BD',
        name: 'mdi-sort-alphabetical-ascending'
      },
      {
        unicode: 'F1148',
        name: 'mdi-sort-alphabetical-ascending-variant'
      },
      {
        unicode: 'F05BF',
        name: 'mdi-sort-alphabetical-descending'
      },
      {
        unicode: 'F1149',
        name: 'mdi-sort-alphabetical-descending-variant'
      },
      {
        unicode: 'F04BB',
        name: 'mdi-sort-alphabetical-variant'
      },
      {
        unicode: 'F04BC',
        name: 'mdi-sort-ascending'
      },
      {
        unicode: 'F1385',
        name: 'mdi-sort-bool-ascending'
      },
      {
        unicode: 'F1386',
        name: 'mdi-sort-bool-ascending-variant'
      },
      {
        unicode: 'F1387',
        name: 'mdi-sort-bool-descending'
      },
      {
        unicode: 'F1388',
        name: 'mdi-sort-bool-descending-variant'
      },
      {
        unicode: 'F04BD',
        name: 'mdi-sort-descending'
      },
      {
        unicode: 'F1389',
        name: 'mdi-sort-numeric-ascending'
      },
      {
        unicode: 'F090D',
        name: 'mdi-sort-numeric-ascending-variant'
      },
      {
        unicode: 'F138A',
        name: 'mdi-sort-numeric-descending'
      },
      {
        unicode: 'F0AD2',
        name: 'mdi-sort-numeric-descending-variant'
      },
      {
        unicode: 'F04BE',
        name: 'mdi-sort-numeric-variant'
      },
      {
        unicode: 'F033C',
        name: 'mdi-sort-reverse-variant'
      },
      {
        unicode: 'F04BF',
        name: 'mdi-sort-variant'
      },
      {
        unicode: 'F0CCD',
        name: 'mdi-sort-variant-lock'
      },
      {
        unicode: 'F0CCE',
        name: 'mdi-sort-variant-lock-open'
      },
      {
        unicode: 'F1147',
        name: 'mdi-sort-variant-remove'
      },
      {
        unicode: 'F04C0',
        name: 'mdi-soundcloud'
      },
      {
        unicode: 'F062C',
        name: 'mdi-source-branch'
      },
      {
        unicode: 'F14CF',
        name: 'mdi-source-branch-check'
      },
      {
        unicode: 'F14CB',
        name: 'mdi-source-branch-minus'
      },
      {
        unicode: 'F14CA',
        name: 'mdi-source-branch-plus'
      },
      {
        unicode: 'F14CD',
        name: 'mdi-source-branch-refresh'
      },
      {
        unicode: 'F14CC',
        name: 'mdi-source-branch-remove'
      },
      {
        unicode: 'F14CE',
        name: 'mdi-source-branch-sync'
      },
      {
        unicode: 'F0718',
        name: 'mdi-source-commit'
      },
      {
        unicode: 'F0719',
        name: 'mdi-source-commit-end'
      },
      {
        unicode: 'F071A',
        name: 'mdi-source-commit-end-local'
      },
      {
        unicode: 'F071B',
        name: 'mdi-source-commit-local'
      },
      {
        unicode: 'F071C',
        name: 'mdi-source-commit-next-local'
      },
      {
        unicode: 'F071D',
        name: 'mdi-source-commit-start'
      },
      {
        unicode: 'F071E',
        name: 'mdi-source-commit-start-next-local'
      },
      {
        unicode: 'F04C1',
        name: 'mdi-source-fork'
      },
      {
        unicode: 'F062D',
        name: 'mdi-source-merge'
      },
      {
        unicode: 'F04C2',
        name: 'mdi-source-pull'
      },
      {
        unicode: 'F0CCF',
        name: 'mdi-source-repository'
      },
      {
        unicode: 'F0CD0',
        name: 'mdi-source-repository-multiple'
      },
      {
        unicode: 'F07EE',
        name: 'mdi-soy-sauce'
      },
      {
        unicode: 'F13FC',
        name: 'mdi-soy-sauce-off'
      },
      {
        unicode: 'F0CD1',
        name: 'mdi-spa'
      },
      {
        unicode: 'F0CD2',
        name: 'mdi-spa-outline'
      },
      {
        unicode: 'F0BC9',
        name: 'mdi-space-invaders'
      },
      {
        unicode: 'F1383',
        name: 'mdi-space-station'
      },
      {
        unicode: 'F0E65',
        name: 'mdi-spade'
      },
      {
        unicode: 'F04C3',
        name: 'mdi-speaker'
      },
      {
        unicode: 'F09A2',
        name: 'mdi-speaker-bluetooth'
      },
      {
        unicode: 'F0D38',
        name: 'mdi-speaker-multiple'
      },
      {
        unicode: 'F04C4',
        name: 'mdi-speaker-off'
      },
      {
        unicode: 'F071F',
        name: 'mdi-speaker-wireless'
      },
      {
        unicode: 'F04C5',
        name: 'mdi-speedometer'
      },
      {
        unicode: 'F0F85',
        name: 'mdi-speedometer-medium'
      },
      {
        unicode: 'F0F86',
        name: 'mdi-speedometer-slow'
      },
      {
        unicode: 'F04C6',
        name: 'mdi-spellcheck'
      },
      {
        unicode: 'F11EA',
        name: 'mdi-spider'
      },
      {
        unicode: 'F11EB',
        name: 'mdi-spider-thread'
      },
      {
        unicode: 'F0BCA',
        name: 'mdi-spider-web'
      },
      {
        unicode: 'F1429',
        name: 'mdi-spoon-sugar'
      },
      {
        unicode: 'F04C7',
        name: 'mdi-spotify'
      },
      {
        unicode: 'F04C8',
        name: 'mdi-spotlight'
      },
      {
        unicode: 'F04C9',
        name: 'mdi-spotlight-beam'
      },
      {
        unicode: 'F0665',
        name: 'mdi-spray'
      },
      {
        unicode: 'F0AE0',
        name: 'mdi-spray-bottle'
      },
      {
        unicode: 'F105F',
        name: 'mdi-sprinkler'
      },
      {
        unicode: 'F1060',
        name: 'mdi-sprinkler-variant'
      },
      {
        unicode: 'F0E66',
        name: 'mdi-sprout'
      },
      {
        unicode: 'F0E67',
        name: 'mdi-sprout-outline'
      },
      {
        unicode: 'F0764',
        name: 'mdi-square'
      },
      {
        unicode: 'F090C',
        name: 'mdi-square-edit-outline'
      },
      {
        unicode: 'F0A13',
        name: 'mdi-square-medium'
      },
      {
        unicode: 'F0A14',
        name: 'mdi-square-medium-outline'
      },
      {
        unicode: 'F12EE',
        name: 'mdi-square-off'
      },
      {
        unicode: 'F12EF',
        name: 'mdi-square-off-outline'
      },
      {
        unicode: 'F0763',
        name: 'mdi-square-outline'
      },
      {
        unicode: 'F0784',
        name: 'mdi-square-root'
      },
      {
        unicode: 'F09A3',
        name: 'mdi-square-root-box'
      },
      {
        unicode: 'F0A15',
        name: 'mdi-square-small'
      },
      {
        unicode: 'F147B',
        name: 'mdi-square-wave'
      },
      {
        unicode: 'F0AE1',
        name: 'mdi-squeegee'
      },
      {
        unicode: 'F08C0',
        name: 'mdi-ssh'
      },
      {
        unicode: 'F060B',
        name: 'mdi-stack-exchange'
      },
      {
        unicode: 'F04CC',
        name: 'mdi-stack-overflow'
      },
      {
        unicode: 'F0359',
        name: 'mdi-stackpath'
      },
      {
        unicode: 'F0FF9',
        name: 'mdi-stadium'
      },
      {
        unicode: 'F0720',
        name: 'mdi-stadium-variant'
      },
      {
        unicode: 'F04CD',
        name: 'mdi-stairs'
      },
      {
        unicode: 'F139E',
        name: 'mdi-stairs-box'
      },
      {
        unicode: 'F12BE',
        name: 'mdi-stairs-down'
      },
      {
        unicode: 'F12BD',
        name: 'mdi-stairs-up'
      },
      {
        unicode: 'F0D39',
        name: 'mdi-stamper'
      },
      {
        unicode: 'F07EF',
        name: 'mdi-standard-definition'
      },
      {
        unicode: 'F04CE',
        name: 'mdi-star'
      },
      {
        unicode: 'F0A73',
        name: 'mdi-star-box'
      },
      {
        unicode: 'F1286',
        name: 'mdi-star-box-multiple'
      },
      {
        unicode: 'F1287',
        name: 'mdi-star-box-multiple-outline'
      },
      {
        unicode: 'F0A74',
        name: 'mdi-star-box-outline'
      },
      {
        unicode: 'F04CF',
        name: 'mdi-star-circle'
      },
      {
        unicode: 'F09A4',
        name: 'mdi-star-circle-outline'
      },
      {
        unicode: 'F09A5',
        name: 'mdi-star-face'
      },
      {
        unicode: 'F0AE2',
        name: 'mdi-star-four-points'
      },
      {
        unicode: 'F0AE3',
        name: 'mdi-star-four-points-outline'
      },
      {
        unicode: 'F0246',
        name: 'mdi-star-half'
      },
      {
        unicode: 'F04D0',
        name: 'mdi-star-half-full'
      },
      {
        unicode: 'F04D1',
        name: 'mdi-star-off'
      },
      {
        unicode: 'F04D2',
        name: 'mdi-star-outline'
      },
      {
        unicode: 'F0AE4',
        name: 'mdi-star-three-points'
      },
      {
        unicode: 'F0AE5',
        name: 'mdi-star-three-points-outline'
      },
      {
        unicode: 'F11EF',
        name: 'mdi-state-machine'
      },
      {
        unicode: 'F04D3',
        name: 'mdi-steam'
      },
      {
        unicode: 'F04D4',
        name: 'mdi-steering'
      },
      {
        unicode: 'F090E',
        name: 'mdi-steering-off'
      },
      {
        unicode: 'F04D5',
        name: 'mdi-step-backward'
      },
      {
        unicode: 'F04D6',
        name: 'mdi-step-backward-2'
      },
      {
        unicode: 'F04D7',
        name: 'mdi-step-forward'
      },
      {
        unicode: 'F04D8',
        name: 'mdi-step-forward-2'
      },
      {
        unicode: 'F04D9',
        name: 'mdi-stethoscope'
      },
      {
        unicode: 'F1364',
        name: 'mdi-sticker'
      },
      {
        unicode: 'F1365',
        name: 'mdi-sticker-alert'
      },
      {
        unicode: 'F1366',
        name: 'mdi-sticker-alert-outline'
      },
      {
        unicode: 'F1367',
        name: 'mdi-sticker-check'
      },
      {
        unicode: 'F1368',
        name: 'mdi-sticker-check-outline'
      },
      {
        unicode: 'F05D0',
        name: 'mdi-sticker-circle-outline'
      },
      {
        unicode: 'F0785',
        name: 'mdi-sticker-emoji'
      },
      {
        unicode: 'F1369',
        name: 'mdi-sticker-minus'
      },
      {
        unicode: 'F136A',
        name: 'mdi-sticker-minus-outline'
      },
      {
        unicode: 'F136B',
        name: 'mdi-sticker-outline'
      },
      {
        unicode: 'F136C',
        name: 'mdi-sticker-plus'
      },
      {
        unicode: 'F136D',
        name: 'mdi-sticker-plus-outline'
      },
      {
        unicode: 'F136E',
        name: 'mdi-sticker-remove'
      },
      {
        unicode: 'F136F',
        name: 'mdi-sticker-remove-outline'
      },
      {
        unicode: 'F04DA',
        name: 'mdi-stocking'
      },
      {
        unicode: 'F1093',
        name: 'mdi-stomach'
      },
      {
        unicode: 'F04DB',
        name: 'mdi-stop'
      },
      {
        unicode: 'F0666',
        name: 'mdi-stop-circle'
      },
      {
        unicode: 'F0667',
        name: 'mdi-stop-circle-outline'
      },
      {
        unicode: 'F04DC',
        name: 'mdi-store'
      },
      {
        unicode: 'F04DD',
        name: 'mdi-store-24-hour'
      },
      {
        unicode: 'F1361',
        name: 'mdi-store-outline'
      },
      {
        unicode: 'F07C7',
        name: 'mdi-storefront'
      },
      {
        unicode: 'F10C1',
        name: 'mdi-storefront-outline'
      },
      {
        unicode: 'F04DE',
        name: 'mdi-stove'
      },
      {
        unicode: 'F11D6',
        name: 'mdi-strategy'
      },
      {
        unicode: 'F0F2B',
        name: 'mdi-stretch-to-page'
      },
      {
        unicode: 'F0F2C',
        name: 'mdi-stretch-to-page-outline'
      },
      {
        unicode: 'F12BA',
        name: 'mdi-string-lights'
      },
      {
        unicode: 'F12BB',
        name: 'mdi-string-lights-off'
      },
      {
        unicode: 'F060C',
        name: 'mdi-subdirectory-arrow-left'
      },
      {
        unicode: 'F060D',
        name: 'mdi-subdirectory-arrow-right'
      },
      {
        unicode: 'F0A16',
        name: 'mdi-subtitles'
      },
      {
        unicode: 'F0A17',
        name: 'mdi-subtitles-outline'
      },
      {
        unicode: 'F06AC',
        name: 'mdi-subway'
      },
      {
        unicode: 'F0D9D',
        name: 'mdi-subway-alert-variant'
      },
      {
        unicode: 'F04DF',
        name: 'mdi-subway-variant'
      },
      {
        unicode: 'F0786',
        name: 'mdi-summit'
      },
      {
        unicode: 'F04E0',
        name: 'mdi-sunglasses'
      },
      {
        unicode: 'F05C5',
        name: 'mdi-surround-sound'
      },
      {
        unicode: 'F07F0',
        name: 'mdi-surround-sound-2-0'
      },
      {
        unicode: 'F07F1',
        name: 'mdi-surround-sound-3-1'
      },
      {
        unicode: 'F07F2',
        name: 'mdi-surround-sound-5-1'
      },
      {
        unicode: 'F07F3',
        name: 'mdi-surround-sound-7-1'
      },
      {
        unicode: 'F0721',
        name: 'mdi-svg'
      },
      {
        unicode: 'F04E1',
        name: 'mdi-swap-horizontal'
      },
      {
        unicode: 'F0BCD',
        name: 'mdi-swap-horizontal-bold'
      },
      {
        unicode: 'F0FE1',
        name: 'mdi-swap-horizontal-circle'
      },
      {
        unicode: 'F0FE2',
        name: 'mdi-swap-horizontal-circle-outline'
      },
      {
        unicode: 'F08C1',
        name: 'mdi-swap-horizontal-variant'
      },
      {
        unicode: 'F04E2',
        name: 'mdi-swap-vertical'
      },
      {
        unicode: 'F0BCE',
        name: 'mdi-swap-vertical-bold'
      },
      {
        unicode: 'F0FE3',
        name: 'mdi-swap-vertical-circle'
      },
      {
        unicode: 'F0FE4',
        name: 'mdi-swap-vertical-circle-outline'
      },
      {
        unicode: 'F08C2',
        name: 'mdi-swap-vertical-variant'
      },
      {
        unicode: 'F04E3',
        name: 'mdi-swim'
      },
      {
        unicode: 'F04E4',
        name: 'mdi-switch'
      },
      {
        unicode: 'F04E5',
        name: 'mdi-sword'
      },
      {
        unicode: 'F0787',
        name: 'mdi-sword-cross'
      },
      {
        unicode: 'F1333',
        name: 'mdi-syllabary-hangul'
      },
      {
        unicode: 'F1334',
        name: 'mdi-syllabary-hiragana'
      },
      {
        unicode: 'F1335',
        name: 'mdi-syllabary-katakana'
      },
      {
        unicode: 'F1336',
        name: 'mdi-syllabary-katakana-halfwidth'
      },
      {
        unicode: 'F0AE6',
        name: 'mdi-symfony'
      },
      {
        unicode: 'F04E6',
        name: 'mdi-sync'
      },
      {
        unicode: 'F04E7',
        name: 'mdi-sync-alert'
      },
      {
        unicode: 'F1378',
        name: 'mdi-sync-circle'
      },
      {
        unicode: 'F04E8',
        name: 'mdi-sync-off'
      },
      {
        unicode: 'F04E9',
        name: 'mdi-tab'
      },
      {
        unicode: 'F0B4B',
        name: 'mdi-tab-minus'
      },
      {
        unicode: 'F075C',
        name: 'mdi-tab-plus'
      },
      {
        unicode: 'F0B4C',
        name: 'mdi-tab-remove'
      },
      {
        unicode: 'F04EA',
        name: 'mdi-tab-unselected'
      },
      {
        unicode: 'F04EB',
        name: 'mdi-table'
      },
      {
        unicode: 'F13B9',
        name: 'mdi-table-account'
      },
      {
        unicode: 'F13BA',
        name: 'mdi-table-alert'
      },
      {
        unicode: 'F13BB',
        name: 'mdi-table-arrow-down'
      },
      {
        unicode: 'F13BC',
        name: 'mdi-table-arrow-left'
      },
      {
        unicode: 'F13BD',
        name: 'mdi-table-arrow-right'
      },
      {
        unicode: 'F13BE',
        name: 'mdi-table-arrow-up'
      },
      {
        unicode: 'F0A18',
        name: 'mdi-table-border'
      },
      {
        unicode: 'F13BF',
        name: 'mdi-table-cancel'
      },
      {
        unicode: 'F1061',
        name: 'mdi-table-chair'
      },
      {
        unicode: 'F13C0',
        name: 'mdi-table-check'
      },
      {
        unicode: 'F13C1',
        name: 'mdi-table-clock'
      },
      {
        unicode: 'F13C2',
        name: 'mdi-table-cog'
      },
      {
        unicode: 'F0835',
        name: 'mdi-table-column'
      },
      {
        unicode: 'F04EC',
        name: 'mdi-table-column-plus-after'
      },
      {
        unicode: 'F04ED',
        name: 'mdi-table-column-plus-before'
      },
      {
        unicode: 'F04EE',
        name: 'mdi-table-column-remove'
      },
      {
        unicode: 'F04EF',
        name: 'mdi-table-column-width'
      },
      {
        unicode: 'F04F0',
        name: 'mdi-table-edit'
      },
      {
        unicode: 'F1094',
        name: 'mdi-table-eye'
      },
      {
        unicode: 'F13C3',
        name: 'mdi-table-eye-off'
      },
      {
        unicode: 'F05BC',
        name: 'mdi-table-furniture'
      },
      {
        unicode: 'F121D',
        name: 'mdi-table-headers-eye'
      },
      {
        unicode: 'F121E',
        name: 'mdi-table-headers-eye-off'
      },
      {
        unicode: 'F13C4',
        name: 'mdi-table-heart'
      },
      {
        unicode: 'F13C5',
        name: 'mdi-table-key'
      },
      {
        unicode: 'F04F1',
        name: 'mdi-table-large'
      },
      {
        unicode: 'F0F87',
        name: 'mdi-table-large-plus'
      },
      {
        unicode: 'F0F88',
        name: 'mdi-table-large-remove'
      },
      {
        unicode: 'F13C6',
        name: 'mdi-table-lock'
      },
      {
        unicode: 'F09A6',
        name: 'mdi-table-merge-cells'
      },
      {
        unicode: 'F13C7',
        name: 'mdi-table-minus'
      },
      {
        unicode: 'F13C8',
        name: 'mdi-table-multiple'
      },
      {
        unicode: 'F13C9',
        name: 'mdi-table-network'
      },
      {
        unicode: 'F0836',
        name: 'mdi-table-of-contents'
      },
      {
        unicode: 'F13CA',
        name: 'mdi-table-off'
      },
      {
        unicode: 'F0A75',
        name: 'mdi-table-plus'
      },
      {
        unicode: 'F13A0',
        name: 'mdi-table-refresh'
      },
      {
        unicode: 'F0A76',
        name: 'mdi-table-remove'
      },
      {
        unicode: 'F0837',
        name: 'mdi-table-row'
      },
      {
        unicode: 'F04F2',
        name: 'mdi-table-row-height'
      },
      {
        unicode: 'F04F3',
        name: 'mdi-table-row-plus-after'
      },
      {
        unicode: 'F04F4',
        name: 'mdi-table-row-plus-before'
      },
      {
        unicode: 'F04F5',
        name: 'mdi-table-row-remove'
      },
      {
        unicode: 'F090F',
        name: 'mdi-table-search'
      },
      {
        unicode: 'F0838',
        name: 'mdi-table-settings'
      },
      {
        unicode: 'F142A',
        name: 'mdi-table-split-cell'
      },
      {
        unicode: 'F13CB',
        name: 'mdi-table-star'
      },
      {
        unicode: 'F13A1',
        name: 'mdi-table-sync'
      },
      {
        unicode: 'F0E68',
        name: 'mdi-table-tennis'
      },
      {
        unicode: 'F04F6',
        name: 'mdi-tablet'
      },
      {
        unicode: 'F04F7',
        name: 'mdi-tablet-android'
      },
      {
        unicode: 'F09A7',
        name: 'mdi-tablet-cellphone'
      },
      {
        unicode: 'F0ECE',
        name: 'mdi-tablet-dashboard'
      },
      {
        unicode: 'F04F8',
        name: 'mdi-tablet-ipad'
      },
      {
        unicode: 'F0762',
        name: 'mdi-taco'
      },
      {
        unicode: 'F04F9',
        name: 'mdi-tag'
      },
      {
        unicode: 'F04FA',
        name: 'mdi-tag-faces'
      },
      {
        unicode: 'F068B',
        name: 'mdi-tag-heart'
      },
      {
        unicode: 'F0BCF',
        name: 'mdi-tag-heart-outline'
      },
      {
        unicode: 'F0910',
        name: 'mdi-tag-minus'
      },
      {
        unicode: 'F121F',
        name: 'mdi-tag-minus-outline'
      },
      {
        unicode: 'F04FB',
        name: 'mdi-tag-multiple'
      },
      {
        unicode: 'F12F7',
        name: 'mdi-tag-multiple-outline'
      },
      {
        unicode: 'F1220',
        name: 'mdi-tag-off'
      },
      {
        unicode: 'F1221',
        name: 'mdi-tag-off-outline'
      },
      {
        unicode: 'F04FC',
        name: 'mdi-tag-outline'
      },
      {
        unicode: 'F0722',
        name: 'mdi-tag-plus'
      },
      {
        unicode: 'F1222',
        name: 'mdi-tag-plus-outline'
      },
      {
        unicode: 'F0723',
        name: 'mdi-tag-remove'
      },
      {
        unicode: 'F1223',
        name: 'mdi-tag-remove-outline'
      },
      {
        unicode: 'F1224',
        name: 'mdi-tag-text'
      },
      {
        unicode: 'F04FD',
        name: 'mdi-tag-text-outline'
      },
      {
        unicode: 'F13FF',
        name: 'mdi-tailwind'
      },
      {
        unicode: 'F0D3A',
        name: 'mdi-tank'
      },
      {
        unicode: 'F0FE5',
        name: 'mdi-tanker-truck'
      },
      {
        unicode: 'F0B4D',
        name: 'mdi-tape-measure'
      },
      {
        unicode: 'F04FE',
        name: 'mdi-target'
      },
      {
        unicode: 'F0BD0',
        name: 'mdi-target-account'
      },
      {
        unicode: 'F0A77',
        name: 'mdi-target-variant'
      },
      {
        unicode: 'F04FF',
        name: 'mdi-taxi'
      },
      {
        unicode: 'F0D9E',
        name: 'mdi-tea'
      },
      {
        unicode: 'F0D9F',
        name: 'mdi-tea-outline'
      },
      {
        unicode: 'F0890',
        name: 'mdi-teach'
      },
      {
        unicode: 'F0500',
        name: 'mdi-teamviewer'
      },
      {
        unicode: 'F0501',
        name: 'mdi-telegram'
      },
      {
        unicode: 'F0B4E',
        name: 'mdi-telescope'
      },
      {
        unicode: 'F0502',
        name: 'mdi-television'
      },
      {
        unicode: 'F1356',
        name: 'mdi-television-ambient-light'
      },
      {
        unicode: 'F0839',
        name: 'mdi-television-box'
      },
      {
        unicode: 'F07F4',
        name: 'mdi-television-classic'
      },
      {
        unicode: 'F083A',
        name: 'mdi-television-classic-off'
      },
      {
        unicode: 'F1110',
        name: 'mdi-television-clean'
      },
      {
        unicode: 'F0503',
        name: 'mdi-television-guide'
      },
      {
        unicode: 'F083B',
        name: 'mdi-television-off'
      },
      {
        unicode: 'F0F89',
        name: 'mdi-television-pause'
      },
      {
        unicode: 'F0ECF',
        name: 'mdi-television-play'
      },
      {
        unicode: 'F0F8A',
        name: 'mdi-television-stop'
      },
      {
        unicode: 'F0504',
        name: 'mdi-temperature-celsius'
      },
      {
        unicode: 'F0505',
        name: 'mdi-temperature-fahrenheit'
      },
      {
        unicode: 'F0506',
        name: 'mdi-temperature-kelvin'
      },
      {
        unicode: 'F0DA0',
        name: 'mdi-tennis'
      },
      {
        unicode: 'F0507',
        name: 'mdi-tennis-ball'
      },
      {
        unicode: 'F0508',
        name: 'mdi-tent'
      },
      {
        unicode: 'F1062',
        name: 'mdi-terraform'
      },
      {
        unicode: 'F0509',
        name: 'mdi-terrain'
      },
      {
        unicode: 'F0668',
        name: 'mdi-test-tube'
      },
      {
        unicode: 'F0911',
        name: 'mdi-test-tube-empty'
      },
      {
        unicode: 'F0912',
        name: 'mdi-test-tube-off'
      },
      {
        unicode: 'F09A8',
        name: 'mdi-text'
      },
      {
        unicode: 'F021A',
        name: 'mdi-text-box'
      },
      {
        unicode: 'F0EA6',
        name: 'mdi-text-box-check'
      },
      {
        unicode: 'F0EA7',
        name: 'mdi-text-box-check-outline'
      },
      {
        unicode: 'F0EA8',
        name: 'mdi-text-box-minus'
      },
      {
        unicode: 'F0EA9',
        name: 'mdi-text-box-minus-outline'
      },
      {
        unicode: 'F0AB7',
        name: 'mdi-text-box-multiple'
      },
      {
        unicode: 'F0AB8',
        name: 'mdi-text-box-multiple-outline'
      },
      {
        unicode: 'F09ED',
        name: 'mdi-text-box-outline'
      },
      {
        unicode: 'F0EAA',
        name: 'mdi-text-box-plus'
      },
      {
        unicode: 'F0EAB',
        name: 'mdi-text-box-plus-outline'
      },
      {
        unicode: 'F0EAC',
        name: 'mdi-text-box-remove'
      },
      {
        unicode: 'F0EAD',
        name: 'mdi-text-box-remove-outline'
      },
      {
        unicode: 'F0EAE',
        name: 'mdi-text-box-search'
      },
      {
        unicode: 'F0EAF',
        name: 'mdi-text-box-search-outline'
      },
      {
        unicode: 'F113D',
        name: 'mdi-text-recognition'
      },
      {
        unicode: 'F13B8',
        name: 'mdi-text-search'
      },
      {
        unicode: 'F0669',
        name: 'mdi-text-shadow'
      },
      {
        unicode: 'F09A9',
        name: 'mdi-text-short'
      },
      {
        unicode: 'F09AA',
        name: 'mdi-text-subject'
      },
      {
        unicode: 'F050A',
        name: 'mdi-text-to-speech'
      },
      {
        unicode: 'F050B',
        name: 'mdi-text-to-speech-off'
      },
      {
        unicode: 'F050C',
        name: 'mdi-texture'
      },
      {
        unicode: 'F0FE6',
        name: 'mdi-texture-box'
      },
      {
        unicode: 'F050D',
        name: 'mdi-theater'
      },
      {
        unicode: 'F050E',
        name: 'mdi-theme-light-dark'
      },
      {
        unicode: 'F050F',
        name: 'mdi-thermometer'
      },
      {
        unicode: 'F0E01',
        name: 'mdi-thermometer-alert'
      },
      {
        unicode: 'F0E02',
        name: 'mdi-thermometer-chevron-down'
      },
      {
        unicode: 'F0E03',
        name: 'mdi-thermometer-chevron-up'
      },
      {
        unicode: 'F10C2',
        name: 'mdi-thermometer-high'
      },
      {
        unicode: 'F0510',
        name: 'mdi-thermometer-lines'
      },
      {
        unicode: 'F10C3',
        name: 'mdi-thermometer-low'
      },
      {
        unicode: 'F0E04',
        name: 'mdi-thermometer-minus'
      },
      {
        unicode: 'F0E05',
        name: 'mdi-thermometer-plus'
      },
      {
        unicode: 'F0393',
        name: 'mdi-thermostat'
      },
      {
        unicode: 'F0891',
        name: 'mdi-thermostat-box'
      },
      {
        unicode: 'F07F6',
        name: 'mdi-thought-bubble'
      },
      {
        unicode: 'F07F7',
        name: 'mdi-thought-bubble-outline'
      },
      {
        unicode: 'F0511',
        name: 'mdi-thumb-down'
      },
      {
        unicode: 'F0512',
        name: 'mdi-thumb-down-outline'
      },
      {
        unicode: 'F0513',
        name: 'mdi-thumb-up'
      },
      {
        unicode: 'F0514',
        name: 'mdi-thumb-up-outline'
      },
      {
        unicode: 'F0515',
        name: 'mdi-thumbs-up-down'
      },
      {
        unicode: 'F0516',
        name: 'mdi-ticket'
      },
      {
        unicode: 'F0517',
        name: 'mdi-ticket-account'
      },
      {
        unicode: 'F0518',
        name: 'mdi-ticket-confirmation'
      },
      {
        unicode: 'F13AA',
        name: 'mdi-ticket-confirmation-outline'
      },
      {
        unicode: 'F0913',
        name: 'mdi-ticket-outline'
      },
      {
        unicode: 'F0724',
        name: 'mdi-ticket-percent'
      },
      {
        unicode: 'F142B',
        name: 'mdi-ticket-percent-outline'
      },
      {
        unicode: 'F0519',
        name: 'mdi-tie'
      },
      {
        unicode: 'F0725',
        name: 'mdi-tilde'
      },
      {
        unicode: 'F051A',
        name: 'mdi-timelapse'
      },
      {
        unicode: 'F0BD1',
        name: 'mdi-timeline'
      },
      {
        unicode: 'F0F95',
        name: 'mdi-timeline-alert'
      },
      {
        unicode: 'F0F98',
        name: 'mdi-timeline-alert-outline'
      },
      {
        unicode: 'F11FB',
        name: 'mdi-timeline-clock'
      },
      {
        unicode: 'F11FC',
        name: 'mdi-timeline-clock-outline'
      },
      {
        unicode: 'F0F99',
        name: 'mdi-timeline-help'
      },
      {
        unicode: 'F0F9A',
        name: 'mdi-timeline-help-outline'
      },
      {
        unicode: 'F0BD2',
        name: 'mdi-timeline-outline'
      },
      {
        unicode: 'F0F96',
        name: 'mdi-timeline-plus'
      },
      {
        unicode: 'F0F97',
        name: 'mdi-timeline-plus-outline'
      },
      {
        unicode: 'F0BD3',
        name: 'mdi-timeline-text'
      },
      {
        unicode: 'F0BD4',
        name: 'mdi-timeline-text-outline'
      },
      {
        unicode: 'F13AB',
        name: 'mdi-timer'
      },
      {
        unicode: 'F051C',
        name: 'mdi-timer-10'
      },
      {
        unicode: 'F051D',
        name: 'mdi-timer-3'
      },
      {
        unicode: 'F13AC',
        name: 'mdi-timer-off'
      },
      {
        unicode: 'F051E',
        name: 'mdi-timer-off-outline'
      },
      {
        unicode: 'F051B',
        name: 'mdi-timer-outline'
      },
      {
        unicode: 'F051F',
        name: 'mdi-timer-sand'
      },
      {
        unicode: 'F06AD',
        name: 'mdi-timer-sand-empty'
      },
      {
        unicode: 'F078C',
        name: 'mdi-timer-sand-full'
      },
      {
        unicode: 'F0520',
        name: 'mdi-timetable'
      },
      {
        unicode: 'F1063',
        name: 'mdi-toaster'
      },
      {
        unicode: 'F11B7',
        name: 'mdi-toaster-off'
      },
      {
        unicode: 'F0CD3',
        name: 'mdi-toaster-oven'
      },
      {
        unicode: 'F0521',
        name: 'mdi-toggle-switch'
      },
      {
        unicode: 'F0522',
        name: 'mdi-toggle-switch-off'
      },
      {
        unicode: 'F0A19',
        name: 'mdi-toggle-switch-off-outline'
      },
      {
        unicode: 'F0A1A',
        name: 'mdi-toggle-switch-outline'
      },
      {
        unicode: 'F09AB',
        name: 'mdi-toilet'
      },
      {
        unicode: 'F09AC',
        name: 'mdi-toolbox'
      },
      {
        unicode: 'F09AD',
        name: 'mdi-toolbox-outline'
      },
      {
        unicode: 'F1064',
        name: 'mdi-tools'
      },
      {
        unicode: 'F0523',
        name: 'mdi-tooltip'
      },
      {
        unicode: 'F000C',
        name: 'mdi-tooltip-account'
      },
      {
        unicode: 'F0524',
        name: 'mdi-tooltip-edit'
      },
      {
        unicode: 'F12C5',
        name: 'mdi-tooltip-edit-outline'
      },
      {
        unicode: 'F0525',
        name: 'mdi-tooltip-image'
      },
      {
        unicode: 'F0BD5',
        name: 'mdi-tooltip-image-outline'
      },
      {
        unicode: 'F0526',
        name: 'mdi-tooltip-outline'
      },
      {
        unicode: 'F0BD6',
        name: 'mdi-tooltip-plus'
      },
      {
        unicode: 'F0527',
        name: 'mdi-tooltip-plus-outline'
      },
      {
        unicode: 'F0528',
        name: 'mdi-tooltip-text'
      },
      {
        unicode: 'F0BD7',
        name: 'mdi-tooltip-text-outline'
      },
      {
        unicode: 'F08C3',
        name: 'mdi-tooth'
      },
      {
        unicode: 'F0529',
        name: 'mdi-tooth-outline'
      },
      {
        unicode: 'F1129',
        name: 'mdi-toothbrush'
      },
      {
        unicode: 'F112C',
        name: 'mdi-toothbrush-electric'
      },
      {
        unicode: 'F112A',
        name: 'mdi-toothbrush-paste'
      },
      {
        unicode: 'F0D3B',
        name: 'mdi-tortoise'
      },
      {
        unicode: 'F12B8',
        name: 'mdi-toslink'
      },
      {
        unicode: 'F09AE',
        name: 'mdi-tournament'
      },
      {
        unicode: 'F083C',
        name: 'mdi-tow-truck'
      },
      {
        unicode: 'F0681',
        name: 'mdi-tower-beach'
      },
      {
        unicode: 'F0682',
        name: 'mdi-tower-fire'
      },
      {
        unicode: 'F1288',
        name: 'mdi-toy-brick'
      },
      {
        unicode: 'F1289',
        name: 'mdi-toy-brick-marker'
      },
      {
        unicode: 'F128A',
        name: 'mdi-toy-brick-marker-outline'
      },
      {
        unicode: 'F128B',
        name: 'mdi-toy-brick-minus'
      },
      {
        unicode: 'F128C',
        name: 'mdi-toy-brick-minus-outline'
      },
      {
        unicode: 'F128D',
        name: 'mdi-toy-brick-outline'
      },
      {
        unicode: 'F128E',
        name: 'mdi-toy-brick-plus'
      },
      {
        unicode: 'F128F',
        name: 'mdi-toy-brick-plus-outline'
      },
      {
        unicode: 'F1290',
        name: 'mdi-toy-brick-remove'
      },
      {
        unicode: 'F1291',
        name: 'mdi-toy-brick-remove-outline'
      },
      {
        unicode: 'F1292',
        name: 'mdi-toy-brick-search'
      },
      {
        unicode: 'F1293',
        name: 'mdi-toy-brick-search-outline'
      },
      {
        unicode: 'F0914',
        name: 'mdi-track-light'
      },
      {
        unicode: 'F07F8',
        name: 'mdi-trackpad'
      },
      {
        unicode: 'F0933',
        name: 'mdi-trackpad-lock'
      },
      {
        unicode: 'F0892',
        name: 'mdi-tractor'
      },
      {
        unicode: 'F14C4',
        name: 'mdi-tractor-variant'
      },
      {
        unicode: 'F0A78',
        name: 'mdi-trademark'
      },
      {
        unicode: 'F137C',
        name: 'mdi-traffic-cone'
      },
      {
        unicode: 'F052B',
        name: 'mdi-traffic-light'
      },
      {
        unicode: 'F052C',
        name: 'mdi-train'
      },
      {
        unicode: 'F0BD8',
        name: 'mdi-train-car'
      },
      {
        unicode: 'F08C4',
        name: 'mdi-train-variant'
      },
      {
        unicode: 'F052D',
        name: 'mdi-tram'
      },
      {
        unicode: 'F0FE7',
        name: 'mdi-tram-side'
      },
      {
        unicode: 'F052E',
        name: 'mdi-transcribe'
      },
      {
        unicode: 'F052F',
        name: 'mdi-transcribe-close'
      },
      {
        unicode: 'F1065',
        name: 'mdi-transfer'
      },
      {
        unicode: 'F0DA1',
        name: 'mdi-transfer-down'
      },
      {
        unicode: 'F0DA2',
        name: 'mdi-transfer-left'
      },
      {
        unicode: 'F0530',
        name: 'mdi-transfer-right'
      },
      {
        unicode: 'F0DA3',
        name: 'mdi-transfer-up'
      },
      {
        unicode: 'F0D3C',
        name: 'mdi-transit-connection'
      },
      {
        unicode: 'F0D3D',
        name: 'mdi-transit-connection-variant'
      },
      {
        unicode: 'F0F8B',
        name: 'mdi-transit-detour'
      },
      {
        unicode: 'F06AE',
        name: 'mdi-transit-transfer'
      },
      {
        unicode: 'F0915',
        name: 'mdi-transition'
      },
      {
        unicode: 'F0916',
        name: 'mdi-transition-masked'
      },
      {
        unicode: 'F05CA',
        name: 'mdi-translate'
      },
      {
        unicode: 'F0E06',
        name: 'mdi-translate-off'
      },
      {
        unicode: 'F0D3E',
        name: 'mdi-transmission-tower'
      },
      {
        unicode: 'F0A79',
        name: 'mdi-trash-can'
      },
      {
        unicode: 'F0A7A',
        name: 'mdi-trash-can-outline'
      },
      {
        unicode: 'F1294',
        name: 'mdi-tray'
      },
      {
        unicode: 'F1295',
        name: 'mdi-tray-alert'
      },
      {
        unicode: 'F1296',
        name: 'mdi-tray-full'
      },
      {
        unicode: 'F1297',
        name: 'mdi-tray-minus'
      },
      {
        unicode: 'F1298',
        name: 'mdi-tray-plus'
      },
      {
        unicode: 'F1299',
        name: 'mdi-tray-remove'
      },
      {
        unicode: 'F0726',
        name: 'mdi-treasure-chest'
      },
      {
        unicode: 'F0531',
        name: 'mdi-tree'
      },
      {
        unicode: 'F0E69',
        name: 'mdi-tree-outline'
      },
      {
        unicode: 'F0532',
        name: 'mdi-trello'
      },
      {
        unicode: 'F0533',
        name: 'mdi-trending-down'
      },
      {
        unicode: 'F0534',
        name: 'mdi-trending-neutral'
      },
      {
        unicode: 'F0535',
        name: 'mdi-trending-up'
      },
      {
        unicode: 'F0536',
        name: 'mdi-triangle'
      },
      {
        unicode: 'F0537',
        name: 'mdi-triangle-outline'
      },
      {
        unicode: 'F147C',
        name: 'mdi-triangle-wave'
      },
      {
        unicode: 'F0BD9',
        name: 'mdi-triforce'
      },
      {
        unicode: 'F0538',
        name: 'mdi-trophy'
      },
      {
        unicode: 'F0539',
        name: 'mdi-trophy-award'
      },
      {
        unicode: 'F0DA4',
        name: 'mdi-trophy-broken'
      },
      {
        unicode: 'F053A',
        name: 'mdi-trophy-outline'
      },
      {
        unicode: 'F053B',
        name: 'mdi-trophy-variant'
      },
      {
        unicode: 'F053C',
        name: 'mdi-trophy-variant-outline'
      },
      {
        unicode: 'F053D',
        name: 'mdi-truck'
      },
      {
        unicode: 'F0CD4',
        name: 'mdi-truck-check'
      },
      {
        unicode: 'F129A',
        name: 'mdi-truck-check-outline'
      },
      {
        unicode: 'F053E',
        name: 'mdi-truck-delivery'
      },
      {
        unicode: 'F129B',
        name: 'mdi-truck-delivery-outline'
      },
      {
        unicode: 'F0788',
        name: 'mdi-truck-fast'
      },
      {
        unicode: 'F129C',
        name: 'mdi-truck-fast-outline'
      },
      {
        unicode: 'F129D',
        name: 'mdi-truck-outline'
      },
      {
        unicode: 'F0727',
        name: 'mdi-truck-trailer'
      },
      {
        unicode: 'F1096',
        name: 'mdi-trumpet'
      },
      {
        unicode: 'F0A7B',
        name: 'mdi-tshirt-crew'
      },
      {
        unicode: 'F053F',
        name: 'mdi-tshirt-crew-outline'
      },
      {
        unicode: 'F0A7C',
        name: 'mdi-tshirt-v'
      },
      {
        unicode: 'F0540',
        name: 'mdi-tshirt-v-outline'
      },
      {
        unicode: 'F0917',
        name: 'mdi-tumble-dryer'
      },
      {
        unicode: 'F11BA',
        name: 'mdi-tumble-dryer-alert'
      },
      {
        unicode: 'F11BB',
        name: 'mdi-tumble-dryer-off'
      },
      {
        unicode: 'F062E',
        name: 'mdi-tune'
      },
      {
        unicode: 'F066A',
        name: 'mdi-tune-vertical'
      },
      {
        unicode: 'F0CD5',
        name: 'mdi-turnstile'
      },
      {
        unicode: 'F0CD6',
        name: 'mdi-turnstile-outline'
      },
      {
        unicode: 'F0CD7',
        name: 'mdi-turtle'
      },
      {
        unicode: 'F0543',
        name: 'mdi-twitch'
      },
      {
        unicode: 'F0544',
        name: 'mdi-twitter'
      },
      {
        unicode: 'F0547',
        name: 'mdi-twitter-retweet'
      },
      {
        unicode: 'F09AF',
        name: 'mdi-two-factor-authentication'
      },
      {
        unicode: 'F0F2D',
        name: 'mdi-typewriter'
      },
      {
        unicode: 'F0BDA',
        name: 'mdi-ubisoft'
      },
      {
        unicode: 'F0548',
        name: 'mdi-ubuntu'
      },
      {
        unicode: 'F10C4',
        name: 'mdi-ufo'
      },
      {
        unicode: 'F10C5',
        name: 'mdi-ufo-outline'
      },
      {
        unicode: 'F07F9',
        name: 'mdi-ultra-high-definition'
      },
      {
        unicode: 'F0549',
        name: 'mdi-umbraco'
      },
      {
        unicode: 'F054A',
        name: 'mdi-umbrella'
      },
      {
        unicode: 'F09B0',
        name: 'mdi-umbrella-closed'
      },
      {
        unicode: 'F13E2',
        name: 'mdi-umbrella-closed-outline'
      },
      {
        unicode: 'F13E1',
        name: 'mdi-umbrella-closed-variant'
      },
      {
        unicode: 'F054B',
        name: 'mdi-umbrella-outline'
      },
      {
        unicode: 'F054C',
        name: 'mdi-undo'
      },
      {
        unicode: 'F054D',
        name: 'mdi-undo-variant'
      },
      {
        unicode: 'F054E',
        name: 'mdi-unfold-less-horizontal'
      },
      {
        unicode: 'F0760',
        name: 'mdi-unfold-less-vertical'
      },
      {
        unicode: 'F054F',
        name: 'mdi-unfold-more-horizontal'
      },
      {
        unicode: 'F0761',
        name: 'mdi-unfold-more-vertical'
      },
      {
        unicode: 'F0550',
        name: 'mdi-ungroup'
      },
      {
        unicode: 'F0ED0',
        name: 'mdi-unicode'
      },
      {
        unicode: 'F06AF',
        name: 'mdi-unity'
      },
      {
        unicode: 'F09B1',
        name: 'mdi-unreal'
      },
      {
        unicode: 'F0551',
        name: 'mdi-untappd'
      },
      {
        unicode: 'F06B0',
        name: 'mdi-update'
      },
      {
        unicode: 'F0552',
        name: 'mdi-upload'
      },
      {
        unicode: 'F1373',
        name: 'mdi-upload-lock'
      },
      {
        unicode: 'F1374',
        name: 'mdi-upload-lock-outline'
      },
      {
        unicode: 'F083D',
        name: 'mdi-upload-multiple'
      },
      {
        unicode: 'F06F6',
        name: 'mdi-upload-network'
      },
      {
        unicode: 'F0CD8',
        name: 'mdi-upload-network-outline'
      },
      {
        unicode: 'F10C6',
        name: 'mdi-upload-off'
      },
      {
        unicode: 'F10C7',
        name: 'mdi-upload-off-outline'
      },
      {
        unicode: 'F0E07',
        name: 'mdi-upload-outline'
      },
      {
        unicode: 'F0553',
        name: 'mdi-usb'
      },
      {
        unicode: 'F129E',
        name: 'mdi-usb-flash-drive'
      },
      {
        unicode: 'F129F',
        name: 'mdi-usb-flash-drive-outline'
      },
      {
        unicode: 'F11F0',
        name: 'mdi-usb-port'
      },
      {
        unicode: 'F1066',
        name: 'mdi-valve'
      },
      {
        unicode: 'F1067',
        name: 'mdi-valve-closed'
      },
      {
        unicode: 'F1068',
        name: 'mdi-valve-open'
      },
      {
        unicode: 'F07FA',
        name: 'mdi-van-passenger'
      },
      {
        unicode: 'F07FB',
        name: 'mdi-van-utility'
      },
      {
        unicode: 'F07FC',
        name: 'mdi-vanish'
      },
      {
        unicode: 'F11E1',
        name: 'mdi-vanity-light'
      },
      {
        unicode: 'F0AE7',
        name: 'mdi-variable'
      },
      {
        unicode: 'F1111',
        name: 'mdi-variable-box'
      },
      {
        unicode: 'F0554',
        name: 'mdi-vector-arrange-above'
      },
      {
        unicode: 'F0555',
        name: 'mdi-vector-arrange-below'
      },
      {
        unicode: 'F0AE8',
        name: 'mdi-vector-bezier'
      },
      {
        unicode: 'F0556',
        name: 'mdi-vector-circle'
      },
      {
        unicode: 'F0557',
        name: 'mdi-vector-circle-variant'
      },
      {
        unicode: 'F0558',
        name: 'mdi-vector-combine'
      },
      {
        unicode: 'F0559',
        name: 'mdi-vector-curve'
      },
      {
        unicode: 'F055A',
        name: 'mdi-vector-difference'
      },
      {
        unicode: 'F055B',
        name: 'mdi-vector-difference-ab'
      },
      {
        unicode: 'F055C',
        name: 'mdi-vector-difference-ba'
      },
      {
        unicode: 'F0893',
        name: 'mdi-vector-ellipse'
      },
      {
        unicode: 'F055D',
        name: 'mdi-vector-intersection'
      },
      {
        unicode: 'F055E',
        name: 'mdi-vector-line'
      },
      {
        unicode: 'F0FE8',
        name: 'mdi-vector-link'
      },
      {
        unicode: 'F055F',
        name: 'mdi-vector-point'
      },
      {
        unicode: 'F0560',
        name: 'mdi-vector-polygon'
      },
      {
        unicode: 'F0561',
        name: 'mdi-vector-polyline'
      },
      {
        unicode: 'F1225',
        name: 'mdi-vector-polyline-edit'
      },
      {
        unicode: 'F1226',
        name: 'mdi-vector-polyline-minus'
      },
      {
        unicode: 'F1227',
        name: 'mdi-vector-polyline-plus'
      },
      {
        unicode: 'F1228',
        name: 'mdi-vector-polyline-remove'
      },
      {
        unicode: 'F074A',
        name: 'mdi-vector-radius'
      },
      {
        unicode: 'F05C6',
        name: 'mdi-vector-rectangle'
      },
      {
        unicode: 'F0562',
        name: 'mdi-vector-selection'
      },
      {
        unicode: 'F0001',
        name: 'mdi-vector-square'
      },
      {
        unicode: 'F0563',
        name: 'mdi-vector-triangle'
      },
      {
        unicode: 'F0564',
        name: 'mdi-vector-union'
      },
      {
        unicode: 'F0A1B',
        name: 'mdi-vhs'
      },
      {
        unicode: 'F0566',
        name: 'mdi-vibrate'
      },
      {
        unicode: 'F0CD9',
        name: 'mdi-vibrate-off'
      },
      {
        unicode: 'F0567',
        name: 'mdi-video'
      },
      {
        unicode: 'F07FD',
        name: 'mdi-video-3d'
      },
      {
        unicode: 'F13D9',
        name: 'mdi-video-3d-off'
      },
      {
        unicode: 'F0ED1',
        name: 'mdi-video-3d-variant'
      },
      {
        unicode: 'F083E',
        name: 'mdi-video-4k-box'
      },
      {
        unicode: 'F0919',
        name: 'mdi-video-account'
      },
      {
        unicode: 'F00FD',
        name: 'mdi-video-box'
      },
      {
        unicode: 'F00FE',
        name: 'mdi-video-box-off'
      },
      {
        unicode: 'F1069',
        name: 'mdi-video-check'
      },
      {
        unicode: 'F106A',
        name: 'mdi-video-check-outline'
      },
      {
        unicode: 'F091A',
        name: 'mdi-video-image'
      },
      {
        unicode: 'F083F',
        name: 'mdi-video-input-antenna'
      },
      {
        unicode: 'F0840',
        name: 'mdi-video-input-component'
      },
      {
        unicode: 'F0841',
        name: 'mdi-video-input-hdmi'
      },
      {
        unicode: 'F0F8C',
        name: 'mdi-video-input-scart'
      },
      {
        unicode: 'F0842',
        name: 'mdi-video-input-svideo'
      },
      {
        unicode: 'F09B2',
        name: 'mdi-video-minus'
      },
      {
        unicode: 'F02BA',
        name: 'mdi-video-minus-outline'
      },
      {
        unicode: 'F0568',
        name: 'mdi-video-off'
      },
      {
        unicode: 'F0BDB',
        name: 'mdi-video-off-outline'
      },
      {
        unicode: 'F0BDC',
        name: 'mdi-video-outline'
      },
      {
        unicode: 'F09B3',
        name: 'mdi-video-plus'
      },
      {
        unicode: 'F01D3',
        name: 'mdi-video-plus-outline'
      },
      {
        unicode: 'F091B',
        name: 'mdi-video-stabilization'
      },
      {
        unicode: 'F0569',
        name: 'mdi-video-switch'
      },
      {
        unicode: 'F0790',
        name: 'mdi-video-switch-outline'
      },
      {
        unicode: 'F0A1C',
        name: 'mdi-video-vintage'
      },
      {
        unicode: 'F0ED2',
        name: 'mdi-video-wireless'
      },
      {
        unicode: 'F0ED3',
        name: 'mdi-video-wireless-outline'
      },
      {
        unicode: 'F056A',
        name: 'mdi-view-agenda'
      },
      {
        unicode: 'F11D8',
        name: 'mdi-view-agenda-outline'
      },
      {
        unicode: 'F056B',
        name: 'mdi-view-array'
      },
      {
        unicode: 'F1485',
        name: 'mdi-view-array-outline'
      },
      {
        unicode: 'F056C',
        name: 'mdi-view-carousel'
      },
      {
        unicode: 'F1486',
        name: 'mdi-view-carousel-outline'
      },
      {
        unicode: 'F056D',
        name: 'mdi-view-column'
      },
      {
        unicode: 'F1487',
        name: 'mdi-view-column-outline'
      },
      {
        unicode: 'F0E6A',
        name: 'mdi-view-comfy'
      },
      {
        unicode: 'F1488',
        name: 'mdi-view-comfy-outline'
      },
      {
        unicode: 'F0E6B',
        name: 'mdi-view-compact'
      },
      {
        unicode: 'F0E6C',
        name: 'mdi-view-compact-outline'
      },
      {
        unicode: 'F056E',
        name: 'mdi-view-dashboard'
      },
      {
        unicode: 'F0A1D',
        name: 'mdi-view-dashboard-outline'
      },
      {
        unicode: 'F0843',
        name: 'mdi-view-dashboard-variant'
      },
      {
        unicode: 'F1489',
        name: 'mdi-view-dashboard-variant-outline'
      },
      {
        unicode: 'F056F',
        name: 'mdi-view-day'
      },
      {
        unicode: 'F148A',
        name: 'mdi-view-day-outline'
      },
      {
        unicode: 'F0570',
        name: 'mdi-view-grid'
      },
      {
        unicode: 'F11D9',
        name: 'mdi-view-grid-outline'
      },
      {
        unicode: 'F0F8D',
        name: 'mdi-view-grid-plus'
      },
      {
        unicode: 'F11DA',
        name: 'mdi-view-grid-plus-outline'
      },
      {
        unicode: 'F0571',
        name: 'mdi-view-headline'
      },
      {
        unicode: 'F0572',
        name: 'mdi-view-list'
      },
      {
        unicode: 'F148B',
        name: 'mdi-view-list-outline'
      },
      {
        unicode: 'F0573',
        name: 'mdi-view-module'
      },
      {
        unicode: 'F148C',
        name: 'mdi-view-module-outline'
      },
      {
        unicode: 'F0728',
        name: 'mdi-view-parallel'
      },
      {
        unicode: 'F148D',
        name: 'mdi-view-parallel-outline'
      },
      {
        unicode: 'F0574',
        name: 'mdi-view-quilt'
      },
      {
        unicode: 'F148E',
        name: 'mdi-view-quilt-outline'
      },
      {
        unicode: 'F0729',
        name: 'mdi-view-sequential'
      },
      {
        unicode: 'F148F',
        name: 'mdi-view-sequential-outline'
      },
      {
        unicode: 'F0BCB',
        name: 'mdi-view-split-horizontal'
      },
      {
        unicode: 'F0BCC',
        name: 'mdi-view-split-vertical'
      },
      {
        unicode: 'F0575',
        name: 'mdi-view-stream'
      },
      {
        unicode: 'F1490',
        name: 'mdi-view-stream-outline'
      },
      {
        unicode: 'F0576',
        name: 'mdi-view-week'
      },
      {
        unicode: 'F1491',
        name: 'mdi-view-week-outline'
      },
      {
        unicode: 'F0577',
        name: 'mdi-vimeo'
      },
      {
        unicode: 'F060F',
        name: 'mdi-violin'
      },
      {
        unicode: 'F0894',
        name: 'mdi-virtual-reality'
      },
      {
        unicode: 'F13B6',
        name: 'mdi-virus'
      },
      {
        unicode: 'F13B7',
        name: 'mdi-virus-outline'
      },
      {
        unicode: 'F0579',
        name: 'mdi-vk'
      },
      {
        unicode: 'F057C',
        name: 'mdi-vlc'
      },
      {
        unicode: 'F0ED4',
        name: 'mdi-voice-off'
      },
      {
        unicode: 'F057D',
        name: 'mdi-voicemail'
      },
      {
        unicode: 'F09B4',
        name: 'mdi-volleyball'
      },
      {
        unicode: 'F057E',
        name: 'mdi-volume-high'
      },
      {
        unicode: 'F057F',
        name: 'mdi-volume-low'
      },
      {
        unicode: 'F0580',
        name: 'mdi-volume-medium'
      },
      {
        unicode: 'F075E',
        name: 'mdi-volume-minus'
      },
      {
        unicode: 'F075F',
        name: 'mdi-volume-mute'
      },
      {
        unicode: 'F0581',
        name: 'mdi-volume-off'
      },
      {
        unicode: 'F075D',
        name: 'mdi-volume-plus'
      },
      {
        unicode: 'F1120',
        name: 'mdi-volume-source'
      },
      {
        unicode: 'F0E08',
        name: 'mdi-volume-variant-off'
      },
      {
        unicode: 'F1121',
        name: 'mdi-volume-vibrate'
      },
      {
        unicode: 'F0A1F',
        name: 'mdi-vote'
      },
      {
        unicode: 'F0A20',
        name: 'mdi-vote-outline'
      },
      {
        unicode: 'F0582',
        name: 'mdi-vpn'
      },
      {
        unicode: 'F0844',
        name: 'mdi-vuejs'
      },
      {
        unicode: 'F0E6D',
        name: 'mdi-vuetify'
      },
      {
        unicode: 'F0583',
        name: 'mdi-walk'
      },
      {
        unicode: 'F07FE',
        name: 'mdi-wall'
      },
      {
        unicode: 'F091C',
        name: 'mdi-wall-sconce'
      },
      {
        unicode: 'F091D',
        name: 'mdi-wall-sconce-flat'
      },
      {
        unicode: 'F041C',
        name: 'mdi-wall-sconce-flat-variant'
      },
      {
        unicode: 'F0748',
        name: 'mdi-wall-sconce-round'
      },
      {
        unicode: 'F091E',
        name: 'mdi-wall-sconce-round-variant'
      },
      {
        unicode: 'F0584',
        name: 'mdi-wallet'
      },
      {
        unicode: 'F0585',
        name: 'mdi-wallet-giftcard'
      },
      {
        unicode: 'F0586',
        name: 'mdi-wallet-membership'
      },
      {
        unicode: 'F0BDD',
        name: 'mdi-wallet-outline'
      },
      {
        unicode: 'F0F8E',
        name: 'mdi-wallet-plus'
      },
      {
        unicode: 'F0F8F',
        name: 'mdi-wallet-plus-outline'
      },
      {
        unicode: 'F0587',
        name: 'mdi-wallet-travel'
      },
      {
        unicode: 'F0E09',
        name: 'mdi-wallpaper'
      },
      {
        unicode: 'F0588',
        name: 'mdi-wan'
      },
      {
        unicode: 'F0F90',
        name: 'mdi-wardrobe'
      },
      {
        unicode: 'F0F91',
        name: 'mdi-wardrobe-outline'
      },
      {
        unicode: 'F0F81',
        name: 'mdi-warehouse'
      },
      {
        unicode: 'F072A',
        name: 'mdi-washing-machine'
      },
      {
        unicode: 'F11BC',
        name: 'mdi-washing-machine-alert'
      },
      {
        unicode: 'F11BD',
        name: 'mdi-washing-machine-off'
      },
      {
        unicode: 'F0589',
        name: 'mdi-watch'
      },
      {
        unicode: 'F058A',
        name: 'mdi-watch-export'
      },
      {
        unicode: 'F0895',
        name: 'mdi-watch-export-variant'
      },
      {
        unicode: 'F058B',
        name: 'mdi-watch-import'
      },
      {
        unicode: 'F0896',
        name: 'mdi-watch-import-variant'
      },
      {
        unicode: 'F0897',
        name: 'mdi-watch-variant'
      },
      {
        unicode: 'F06B1',
        name: 'mdi-watch-vibrate'
      },
      {
        unicode: 'F0CDA',
        name: 'mdi-watch-vibrate-off'
      },
      {
        unicode: 'F058C',
        name: 'mdi-water'
      },
      {
        unicode: 'F0F92',
        name: 'mdi-water-boiler'
      },
      {
        unicode: 'F11B3',
        name: 'mdi-water-boiler-alert'
      },
      {
        unicode: 'F11B4',
        name: 'mdi-water-boiler-off'
      },
      {
        unicode: 'F058D',
        name: 'mdi-water-off'
      },
      {
        unicode: 'F0E0A',
        name: 'mdi-water-outline'
      },
      {
        unicode: 'F058E',
        name: 'mdi-water-percent'
      },
      {
        unicode: 'F12A0',
        name: 'mdi-water-polo'
      },
      {
        unicode: 'F058F',
        name: 'mdi-water-pump'
      },
      {
        unicode: 'F0F93',
        name: 'mdi-water-pump-off'
      },
      {
        unicode: 'F106B',
        name: 'mdi-water-well'
      },
      {
        unicode: 'F106C',
        name: 'mdi-water-well-outline'
      },
      {
        unicode: 'F1481',
        name: 'mdi-watering-can'
      },
      {
        unicode: 'F1482',
        name: 'mdi-watering-can-outline'
      },
      {
        unicode: 'F0612',
        name: 'mdi-watermark'
      },
      {
        unicode: 'F0F2E',
        name: 'mdi-wave'
      },
      {
        unicode: 'F147D',
        name: 'mdi-waveform'
      },
      {
        unicode: 'F078D',
        name: 'mdi-waves'
      },
      {
        unicode: 'F0BDE',
        name: 'mdi-waze'
      },
      {
        unicode: 'F0590',
        name: 'mdi-weather-cloudy'
      },
      {
        unicode: 'F0F2F',
        name: 'mdi-weather-cloudy-alert'
      },
      {
        unicode: 'F0E6E',
        name: 'mdi-weather-cloudy-arrow-right'
      },
      {
        unicode: 'F0591',
        name: 'mdi-weather-fog'
      },
      {
        unicode: 'F0592',
        name: 'mdi-weather-hail'
      },
      {
        unicode: 'F0F30',
        name: 'mdi-weather-hazy'
      },
      {
        unicode: 'F0898',
        name: 'mdi-weather-hurricane'
      },
      {
        unicode: 'F0593',
        name: 'mdi-weather-lightning'
      },
      {
        unicode: 'F067E',
        name: 'mdi-weather-lightning-rainy'
      },
      {
        unicode: 'F0594',
        name: 'mdi-weather-night'
      },
      {
        unicode: 'F0F31',
        name: 'mdi-weather-night-partly-cloudy'
      },
      {
        unicode: 'F0595',
        name: 'mdi-weather-partly-cloudy'
      },
      {
        unicode: 'F0F32',
        name: 'mdi-weather-partly-lightning'
      },
      {
        unicode: 'F0F33',
        name: 'mdi-weather-partly-rainy'
      },
      {
        unicode: 'F0F34',
        name: 'mdi-weather-partly-snowy'
      },
      {
        unicode: 'F0F35',
        name: 'mdi-weather-partly-snowy-rainy'
      },
      {
        unicode: 'F0596',
        name: 'mdi-weather-pouring'
      },
      {
        unicode: 'F0597',
        name: 'mdi-weather-rainy'
      },
      {
        unicode: 'F0598',
        name: 'mdi-weather-snowy'
      },
      {
        unicode: 'F0F36',
        name: 'mdi-weather-snowy-heavy'
      },
      {
        unicode: 'F067F',
        name: 'mdi-weather-snowy-rainy'
      },
      {
        unicode: 'F0599',
        name: 'mdi-weather-sunny'
      },
      {
        unicode: 'F0F37',
        name: 'mdi-weather-sunny-alert'
      },
      {
        unicode: 'F059A',
        name: 'mdi-weather-sunset'
      },
      {
        unicode: 'F059B',
        name: 'mdi-weather-sunset-down'
      },
      {
        unicode: 'F059C',
        name: 'mdi-weather-sunset-up'
      },
      {
        unicode: 'F0F38',
        name: 'mdi-weather-tornado'
      },
      {
        unicode: 'F059D',
        name: 'mdi-weather-windy'
      },
      {
        unicode: 'F059E',
        name: 'mdi-weather-windy-variant'
      },
      {
        unicode: 'F059F',
        name: 'mdi-web'
      },
      {
        unicode: 'F0F94',
        name: 'mdi-web-box'
      },
      {
        unicode: 'F124A',
        name: 'mdi-web-clock'
      },
      {
        unicode: 'F05A0',
        name: 'mdi-webcam'
      },
      {
        unicode: 'F062F',
        name: 'mdi-webhook'
      },
      {
        unicode: 'F072B',
        name: 'mdi-webpack'
      },
      {
        unicode: 'F1248',
        name: 'mdi-webrtc'
      },
      {
        unicode: 'F0611',
        name: 'mdi-wechat'
      },
      {
        unicode: 'F05A1',
        name: 'mdi-weight'
      },
      {
        unicode: 'F0D3F',
        name: 'mdi-weight-gram'
      },
      {
        unicode: 'F05A2',
        name: 'mdi-weight-kilogram'
      },
      {
        unicode: 'F115D',
        name: 'mdi-weight-lifter'
      },
      {
        unicode: 'F09B5',
        name: 'mdi-weight-pound'
      },
      {
        unicode: 'F05A3',
        name: 'mdi-whatsapp'
      },
      {
        unicode: 'F05A4',
        name: 'mdi-wheelchair-accessibility'
      },
      {
        unicode: 'F09B6',
        name: 'mdi-whistle'
      },
      {
        unicode: 'F12BC',
        name: 'mdi-whistle-outline'
      },
      {
        unicode: 'F05A5',
        name: 'mdi-white-balance-auto'
      },
      {
        unicode: 'F05A6',
        name: 'mdi-white-balance-incandescent'
      },
      {
        unicode: 'F05A7',
        name: 'mdi-white-balance-iridescent'
      },
      {
        unicode: 'F05A8',
        name: 'mdi-white-balance-sunny'
      },
      {
        unicode: 'F072C',
        name: 'mdi-widgets'
      },
      {
        unicode: 'F1355',
        name: 'mdi-widgets-outline'
      },
      {
        unicode: 'F05A9',
        name: 'mdi-wifi'
      },
      {
        unicode: 'F05AA',
        name: 'mdi-wifi-off'
      },
      {
        unicode: 'F0E0B',
        name: 'mdi-wifi-star'
      },
      {
        unicode: 'F091F',
        name: 'mdi-wifi-strength-1'
      },
      {
        unicode: 'F0920',
        name: 'mdi-wifi-strength-1-alert'
      },
      {
        unicode: 'F0921',
        name: 'mdi-wifi-strength-1-lock'
      },
      {
        unicode: 'F0922',
        name: 'mdi-wifi-strength-2'
      },
      {
        unicode: 'F0923',
        name: 'mdi-wifi-strength-2-alert'
      },
      {
        unicode: 'F0924',
        name: 'mdi-wifi-strength-2-lock'
      },
      {
        unicode: 'F0925',
        name: 'mdi-wifi-strength-3'
      },
      {
        unicode: 'F0926',
        name: 'mdi-wifi-strength-3-alert'
      },
      {
        unicode: 'F0927',
        name: 'mdi-wifi-strength-3-lock'
      },
      {
        unicode: 'F0928',
        name: 'mdi-wifi-strength-4'
      },
      {
        unicode: 'F0929',
        name: 'mdi-wifi-strength-4-alert'
      },
      {
        unicode: 'F092A',
        name: 'mdi-wifi-strength-4-lock'
      },
      {
        unicode: 'F092B',
        name: 'mdi-wifi-strength-alert-outline'
      },
      {
        unicode: 'F092C',
        name: 'mdi-wifi-strength-lock-outline'
      },
      {
        unicode: 'F092D',
        name: 'mdi-wifi-strength-off'
      },
      {
        unicode: 'F092E',
        name: 'mdi-wifi-strength-off-outline'
      },
      {
        unicode: 'F092F',
        name: 'mdi-wifi-strength-outline'
      },
      {
        unicode: 'F05AC',
        name: 'mdi-wikipedia'
      },
      {
        unicode: 'F0DA5',
        name: 'mdi-wind-turbine'
      },
      {
        unicode: 'F05AD',
        name: 'mdi-window-close'
      },
      {
        unicode: 'F05AE',
        name: 'mdi-window-closed'
      },
      {
        unicode: 'F11DB',
        name: 'mdi-window-closed-variant'
      },
      {
        unicode: 'F05AF',
        name: 'mdi-window-maximize'
      },
      {
        unicode: 'F05B0',
        name: 'mdi-window-minimize'
      },
      {
        unicode: 'F05B1',
        name: 'mdi-window-open'
      },
      {
        unicode: 'F11DC',
        name: 'mdi-window-open-variant'
      },
      {
        unicode: 'F05B2',
        name: 'mdi-window-restore'
      },
      {
        unicode: 'F111C',
        name: 'mdi-window-shutter'
      },
      {
        unicode: 'F111D',
        name: 'mdi-window-shutter-alert'
      },
      {
        unicode: 'F111E',
        name: 'mdi-window-shutter-open'
      },
      {
        unicode: 'F0AE9',
        name: 'mdi-wiper'
      },
      {
        unicode: 'F0DA6',
        name: 'mdi-wiper-wash'
      },
      {
        unicode: 'F1477',
        name: 'mdi-wizard-hat'
      },
      {
        unicode: 'F05B4',
        name: 'mdi-wordpress'
      },
      {
        unicode: 'F05B6',
        name: 'mdi-wrap'
      },
      {
        unicode: 'F0BDF',
        name: 'mdi-wrap-disabled'
      },
      {
        unicode: 'F05B7',
        name: 'mdi-wrench'
      },
      {
        unicode: 'F0BE0',
        name: 'mdi-wrench-outline'
      },
      {
        unicode: 'F0845',
        name: 'mdi-xamarin'
      },
      {
        unicode: 'F0846',
        name: 'mdi-xamarin-outline'
      },
      {
        unicode: 'F05BE',
        name: 'mdi-xing'
      },
      {
        unicode: 'F05C0',
        name: 'mdi-xml'
      },
      {
        unicode: 'F07FF',
        name: 'mdi-xmpp'
      },
      {
        unicode: 'F0624',
        name: 'mdi-y-combinator'
      },
      {
        unicode: 'F0B4F',
        name: 'mdi-yahoo'
      },
      {
        unicode: 'F05C1',
        name: 'mdi-yeast'
      },
      {
        unicode: 'F0680',
        name: 'mdi-yin-yang'
      },
      {
        unicode: 'F117C',
        name: 'mdi-yoga'
      },
      {
        unicode: 'F05C3',
        name: 'mdi-youtube'
      },
      {
        unicode: 'F0848',
        name: 'mdi-youtube-gaming'
      },
      {
        unicode: 'F0847',
        name: 'mdi-youtube-studio'
      },
      {
        unicode: 'F0D40',
        name: 'mdi-youtube-subscription'
      },
      {
        unicode: 'F0448',
        name: 'mdi-youtube-tv'
      },
      {
        unicode: 'F0AEA',
        name: 'mdi-z-wave'
      },
      {
        unicode: 'F0AEB',
        name: 'mdi-zend'
      },
      {
        unicode: 'F0D41',
        name: 'mdi-zigbee'
      },
      {
        unicode: 'F05C4',
        name: 'mdi-zip-box'
      },
      {
        unicode: 'F0FFA',
        name: 'mdi-zip-box-outline'
      },
      {
        unicode: 'F0A23',
        name: 'mdi-zip-disk'
      },
      {
        unicode: 'F0A7D',
        name: 'mdi-zodiac-aquarius'
      },
      {
        unicode: 'F0A7E',
        name: 'mdi-zodiac-aries'
      },
      {
        unicode: 'F0A7F',
        name: 'mdi-zodiac-cancer'
      },
      {
        unicode: 'F0A80',
        name: 'mdi-zodiac-capricorn'
      },
      {
        unicode: 'F0A81',
        name: 'mdi-zodiac-gemini'
      },
      {
        unicode: 'F0A82',
        name: 'mdi-zodiac-leo'
      },
      {
        unicode: 'F0A83',
        name: 'mdi-zodiac-libra'
      },
      {
        unicode: 'F0A84',
        name: 'mdi-zodiac-pisces'
      },
      {
        unicode: 'F0A85',
        name: 'mdi-zodiac-sagittarius'
      },
      {
        unicode: 'F0A86',
        name: 'mdi-zodiac-scorpio'
      },
      {
        unicode: 'F0A87',
        name: 'mdi-zodiac-taurus'
      },
      {
        unicode: 'F0A88',
        name: 'mdi-zodiac-virgo'
      },
      {
        unicode: 'f68c',
        name: 'mdi-blank'
      }
    ]
  }

}

export default iconsHelper
