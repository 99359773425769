export const chatStates = {
  MINIMIZED: 1,
  MAXIMIZED: 2
}

export const chatSizes = {
  NORMAL: 1,
  EXPAND: 2
}

export const messageTypes = {
  own: 2,
  other: 1
}

export default {
  chatSizes,
  chatStates,
  messageTypes
}
