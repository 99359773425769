<template>

  <v-card flat class="full-width fill-height pa-2"  >
    <v-row dense class="fill-height  full-width">
      <v-col cols="12" align-self="center" @click="startFirstCall">
        <v-alert
          class="mb-0 mt-0 text-uppercase"
          text
          dense
          :type="color"
          icon="mdi-phone"
        >
          {{ title }}
        </v-alert>
      </v-col>
      <v-col
        v-for="(n, i) in lines"
        :key="n"
        cols="6"
      >
        <call-line-button :ref='`call-line-${type}`' :index="i" :number="n" :type="type"/>
      </v-col>
    </v-row>
  </v-card>

</template>

<script>
/* Buttons */
import CallLineButton from '@/app/widgets/call-line-widget/components/buttons/CallLineButton'
export default {
  name: 'CallLineComponent',
  props: ['title', 'color', 'lines', 'type'],
  components: {
    CallLineButton
  },
  methods: {
    start (session) {
      this.$refs[`call-line-${this.type}`].some(item => {
        if (!item.$data.active && (session.status === 4 || session.status === 2)) {
          item.$data.session = session
          item.$data.active = true
          item.$data.direction = session._direction
          return true
        }
      })
    },
    end (session) {
      this.$refs[`call-line-${this.type}`].some(item => {
        if (item.$data?.session?.id === session?.id) {
          item.$data.session = null
          item.$data.adr = null
          item.$data.active = false
          return true
        }
      })
    },
    startFirstCall () {
      if (!this.$refs[`call-line-${this.type}`][0]) return
      if (this.$refs[`call-line-${this.type}`][0].$el._ripple.enabled) {
        this.$refs[`call-line-${this.type}`][0].$el.click()
      }
    },
    callListener (e) {
      if (e.eventData.type === this.type) {
        switch (e.eventData.event.session.status) {
          case 2:
            this.start(e.eventData.event.session)
            break
          case 4:
            this.start(e.eventData.event.session)
            break
          case 7:
          case 8:
            this.end(e.eventData.event.session)
            break
        }
      }
    }
  },
  mounted () {
    this.$event.listen('newAdminCall', (e) => {
      this.callListener(e)
    })
    this.$event.listen('newEmergencyCall', (e) => {
      this.callListener(e)
    })
  }
}
</script>

<style scoped>

</style>
