<template>
  <v-container>
    <v-card class="mt-6 pa-4" :dark="$vuetify.theme.dark" color="primary_card">
      <v-row>
        <v-col class="col-6 no-gutters">
          <h4>{{ $t('date_time') }}: {{ dateTimeGetter }}</h4>
          <h4>{{ $t('callback_number') }}: {{ getCallbackNumber() }}</h4>
          <h4>{{ $t('agent') }}: {{ this.authUserGetter.name }}</h4>
        </v-col>
        <v-col class="col-6 no-gutters">
          <h4>{{ $t('address') }}: {{ getAddress() }}</h4>
          <h4>{{ $t('city_st_zip') }}: {{ getCityZip() }}</h4>
          <h4>{{ $t('lat_lon') }}: {{ getLatLng() }}</h4>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <validation-observer ref="observer">
        <v-col class="col-12">
          <h2 class="font-weight-bold py-4">{{ $t('problem') }}</h2>
          <v-alert
            v-if="errorMessage"
            border="left"
            colored-border
            color="error accent-4"
            elevation="2"
          >
            {{ errorMessage }}
          </v-alert>
          <v-form ref="form" novalidate>
            <validation-provider name="incorrect_location" rules="required">
              <v-checkbox dense flat :label="$t('insufficient_incorrect_location_information')" v-model="incorrect_location"/>
            </validation-provider>
            <validation-provider name="no_map" rules="required">
              <v-checkbox dense flat :label="$t('correct_location_information_no_map')" v-model="no_map"/>
            </validation-provider>
            <validation-provider name="incorrect_map" rules="required">
              <v-checkbox dense flat :label="$t('correct_location_information_incorrect_map')" v-model="incorrect_map"/>
            </validation-provider>
            <v-row>
              <v-col class="col-6">
                <validation-provider name="other" rules="required" vid="other">
                  <v-text-field outlined :label="$t('other')" v-model="other" name="other"></v-text-field>
                </validation-provider>
              </v-col>
              <v-col class="col-6">
                <validation-provider name="incorrect_calling_number" rules="required">
                  <v-text-field outlined :label="$t('calling_number_incorrect')" v-model="incorrect_calling_number"></v-text-field>
                </validation-provider>
              </v-col>
              <v-col class="col-6">
                <validation-provider name="location_misrouted" rules="required">
                  <v-text-field outlined :label="$t('location_information_misrouted')" v-model="location_misrouted"></v-text-field>
                </validation-provider>
              </v-col>
              <v-col class="col-6">
                <validation-provider name="esn_incorrect" rules="required">
                  <v-text-field outlined :label="$t('esn_incorrect')" v-model="esn_incorrect"></v-text-field>
                </validation-provider>
              </v-col>
              <v-col class="col-12">
                <validation-provider name="address_incorrect" rules="required">
                  <v-textarea outlined :label="$t('address_incorrect')" v-model="address_incorrect"></v-textarea>
                </validation-provider>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col class="col-12">
          <v-btn class="mr-5" color="passive_button" @click="onCancel()">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="error" @click="createNote()">
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </validation-observer>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import apiService from '@/modules/api/csp'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'IncorrectLocationComponent',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  computed: {
    ...mapGetters('global', ['authUserGetter']),
    ...mapGetters('held', [
      'heldGetter'
    ]),
    ...mapGetters('callInformation', [
      'dateTimeGetter'
    ]),
    ...mapGetters('adr', [
      'subscriberInfoGetter'
    ])
  },
  data: () => ({
    incorrect_location: 0,
    no_map: 0,
    incorrect_map: 0,
    other: '',
    incorrect_calling_number: '',
    location_misrouted: '',
    esn_incorrect: '',
    address_incorrect: '',
    isInvalid: true,
    errorMessage: ''
  }),
  methods: {
    ...mapActions('notifications', ['notificationSnackBarAction']),
    onCancel () {
      this.$emit('close')
    },
    createNote () {
      Object.values(this.$refs.observer.fields).forEach(item => {
        if (item.validated === true && item.invalid === false) this.isInvalid = false
      })
      if (!this.isInvalid && this.$sip.answeredCallSession) {
        const data = {
          date_time: this.dateTimeGetter,
          call_id: this.$sip.answeredCallSession?._request?.call_id,
          callback_number: this.subscriberInfoGetter?.SubscriberData?.vcard?.tel?.uri?.replace('tel:', ''),
          agent: this.authUserGetter.name,
          HNO: this.heldGetter.civicAddress.HNO,
          RD: this.heldGetter.civicAddress.RD,
          STS: this.heldGetter.civicAddress.STS,
          A3: this.heldGetter.civicAddress.A3,
          A1: this.heldGetter.civicAddress.A1,
          PC: this.heldGetter.civicAddress.PC,
          incorrect_location: this.incorrect_location,
          no_map: this.no_map,
          incorrect_map: this.incorrect_map,
          other: this.other,
          incorrect_calling_number: this.incorrect_calling_number,
          location_misrouted: this.location_misrouted,
          esn_incorrect: this.esn_incorrect,
          address_incorrect: this.address_incorrect
        }
        apiService.incorrectLocation.saveIncorrectLocation(data).then(() => {
          this.notificationSnackBarAction({
            text: this.$t('incorrect_location_created'),
            color: 'success',
            show: true
          })
          this.clearForm()
          this.errorMessage = ''
          this.$emit('close')
        }).catch((error) => {
          this.errorMessage = error.response.data.errors
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: error.response.data.message
          })
        })
        this.isInvalid = true
      } else {
        this.errorMessage = this.$t('please_fill_information')
      }
    },
    getCallbackNumber () {
      return this.subscriberInfoGetter?.SubscriberData?.vcard?.tel?.uri?.replace('tel:', '')
    },
    getAddress () {
      return `${this.heldGetter.civicAddress.HNO || ''} ${this.heldGetter.civicAddress.RD || ''} ${this.heldGetter.civicAddress.STS || ''}`
    },
    getCityZip () {
      return `${this.heldGetter.civicAddress.A3 || ''} ${this.heldGetter.civicAddress.A1 || ''} ${this.heldGetter.civicAddress.PC || ''}`
    },
    getLatLng () {
      return `${this.heldGetter.lat || ''} ${this.heldGetter.lng || ''}`
    },
    clearForm () {
      this.incorrect_location = 0
      this.no_map = 0
      this.incorrect_map = 0
      this.other = ''
      this.incorrect_calling_number = ''
      this.location_misrouted = ''
      this.esn_incorrect = ''
      this.address_incorrect = ''
      this.isInvalid = true
      this.errorMessage = ''
    }
  }
}
</script>

<style scoped>

</style>
