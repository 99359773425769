<template>
  <v-btn
      @click="hangup()"
      class="text-icon-button"
      color="error"
      :disabled="!callGetter.hasActiveCall"
      large
      dark
      width="100%"
      height="100%"
      id="hangupCall"
  >
    <v-icon>{{ hangupIcon }}</v-icon>
    <div class="font-weight-bold pt-2">{{ hangupLabel }}</div>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
/* Mixins */
import hangupMixin from '@/mixins/sip/hangup.mixin'
export default {
  name: 'HangUpButton',
  mixins: [hangupMixin],
  data: (vm) => ({
    hangupIcon: 'mdi-phone-hangup',
    hangupLabel: vm.$t('hang_up')
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ])
  }
}
</script>

<style scoped>

</style>
