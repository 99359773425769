import store from '@/store'
import apiService from '@/modules/api/csp'
import localStorageService from '@/util/local-storage-service'
import sendDtmfCodeMixin from '@/mixins/sip/send-dtmf-code.mixin'
import sipHelper from '@/helpers/sip.helper'
export default {
  mixins: [sendDtmfCodeMixin],
  methods: {
    infoRefer (target) {
      target = sipHelper.cleanTarget(target)
      const event = 'event flashhook\r\n'

      this.$sip.answeredCallSession.sendInfo('application/broadsoft; version=1.0', event)

      // const match = !!(e.session._request?.headers?.['X-Trigger']?.[0].raw?.match(/Audiocodes/))
      setTimeout(async () => {
        this.sendDTMF(this.$sip.answeredCallSession, target)
        await store.dispatch('notifications/notificationSnackBarAction', {
          color: 'success',
          icon: 'mdi-webhook',
          text: this.$t('call_flash_hook_transfer'),
          show: true,
          timeout: 5000
        })
      }, 500)

      const data = {
        call_id: this.$sip.answeredCallSession._request.call_id,
        sip_address: store.getters['settings/sipDefaultAddressGetter'].sip_address,
        sip_message: 'application/broadsoft; version=1.0',
        method: 'INFO',
        direction: 'outgoing',
        type: 'text',
        write_time: Date.now(),
        position: localStorageService.getPositionNumber() ?? null
      }

      apiService.callLogs.saveCallLogs(data)
    },
    sendInfo () {
      const event = 'event flashhook\r\n'
      this.$sip.answeredCallSession.sendInfo('application/broadsoft; version=1.0', event)

      const data = {
        call_id: this.$sip.answeredCallSession._request.call_id,
        sip_address: store.getters['settings/sipDefaultAddressGetter'].sip_address,
        sip_message: 'application/broadsoft; version=1.0',
        method: 'INFO',
        direction: 'outgoing',
        type: 'text',
        write_time: Date.now(),
        position: localStorageService.getPositionNumber() ?? null
      }

      apiService.callLogs.saveCallLogs(data)
    }
  }
}
