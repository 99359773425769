<template>
  <data-table-shared :headers="headers" :loading="loading" :items="items" height="" :perPage="100" :hide-footer="true" />
</template>

<script>
/* Components */
import DataTableShared from '@/app/shared/table/DataTableShared'
/* Parser */
import heldXmlParser from '@/modules/api/csp/parsers/held/held-xml.parser'
/* Mixins */
import RearrangeNestedObject from '@/mixins/rearrange-nested-object.mixin'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'CallDetailsHeldComponent',
  mixins: [RearrangeNestedObject],
  components: {
    DataTableShared
  },
  data: (vm) => ({
    loading: false,
    headers: [
      { text: vm.$t('title'), value: 'name' },
      { text: vm.$t('details'), value: 'value' }
    ],
    items: []
  }),
  computed: {
    ...mapGetters('callDetails', [
      'callDetailsGetter'
    ])
  },
  watch: {
    async 'callDetailsGetter.location_url' (val) {
      await this.getHeldInformation(val)
    }
  },
  methods: {
    ...mapMutations('callDetails', [
      'setCallDetailsHeldMutator'
    ]),
    async getHeldInformation (locationUrl) {
      if (locationUrl) {
        this.loading = true
        const data = await heldXmlParser().parse(locationUrl)
        await this.setCallDetailsHeldMutator(data)
        this.getNestedObject(data, 'items', true)
        if (data) this.loading = false
      } else {
        this.items = []
      }
    }
  }
}
</script>

<style scoped>

</style>
