<template>
  <div class="fill-height" :style="getHeight">
    <tabs-shared  :items="getTabs" />
  </div>

</template>

<script>
/* Mixins */
import parentHeightMixin from '@/mixins/parent-height.mixin'
/* Shared */
import tabsShared from '@/app/shared/tabs/TabsShared'
/* Components */
import SpeedDialComponent from '@/app/widgets/speed-dial-widget/components/SpeedDialComponent'
import ContactBookComponent from '@/app/widgets/contact-book-widget/components/ContactBookComponent'
import AgentsComponent from '@/app/widgets/agent-widget/components/AgentsComponent'
import { mapGetters } from 'vuex'
/* Vuex */

export default {
  name: 'SpeedDialTabsComponent',
  mixins: [parentHeightMixin],
  components: {
    tabsShared
  },
  data: (vm) => ({
    tab: null
  }),
  computed: {
    getHeight () {
      if (!this.getWidgetSettings.SpeedDialTabsComponent.fill_height) {
        return 'height: calc(100% - 90px);'
      }
      return ''
    },
    getTabs () {
      return [
        {
          cypress: 'speed_dial',
          tab: this.$t('speed_dial'),
          name: 'speed_dial',
          component: SpeedDialComponent,
          eager: true,
          order: this.getWidgetSettings.SpeedDialTabsComponent.tabs.speed_dial.order,
          visible: this.$can('can_see_speed_dial') && this.getWidgetSettings.SpeedDialTabsComponent.tabs.speed_dial.visible
        },
        {
          cypress: 'contact_book',
          tab: this.$t('contact_book'),
          name: 'contact_book',
          component: ContactBookComponent,
          eager: true,
          order: this.getWidgetSettings.SpeedDialTabsComponent.tabs.contact_book.order,
          visible: this.$can('can_see_contact_book') && this.getWidgetSettings.SpeedDialTabsComponent.tabs.contact_book.visible
        },
        {
          cypress: 'agents',
          tab: this.$t('agents'),
          name: 'agents',
          component: AgentsComponent,
          eager: true,
          order: this.getWidgetSettings.SpeedDialTabsComponent.tabs.agents.order,
          visible: this.$can('can_see_agents') && this.getWidgetSettings.SpeedDialTabsComponent.tabs.agents.visible
        }
      ].sort((a, b) => {
        return a.order - b.order
      })
    },
    ...mapGetters('layout', ['layoutGetter', 'getWidgetSettings'])
  }
}
</script>

<style scoped>
</style>
