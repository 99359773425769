<template>
  <div>
    <DialogShared v-model="formDialog" eager max-width="800" :title="dialogTitle">
      <validation-observer ref="observer" >

        <v-form class="pt-4" @submit.prevent="onSubmit" novalidate >

          <v-row dense>
            <v-col>
              <validation-provider v-slot="{ errors }" name="name" rules="required" cypress="contact_group_name">
                <v-text-field outlined name="name" :label="$t('name')" v-model="form.name" :error-messages="errors" ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>

              <validation-provider v-slot="{ errors }" :name="$t('icon')" >
                <v-autocomplete clearable  autocomplete="false" item-text="label" item-value="icon"  :disabled="loading" :error-messages="errors" v-model="form.icon" :items="getIcons" required outlined cypress="contact_group_type">
                  <template v-slot:item="data" >
                    <v-icon class="mr-2"> {{ data.item.icon }}</v-icon>
                    {{ data.item.label }}
                  </template>

                  <template v-slot:selection="data">
                    <v-icon class="mr-2"> {{ data.item.icon }}</v-icon>
                    {{ data.item.label }}
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-btn v-if="form.colour" class="mb-2" color="red" small @click="onClearColorClick">{{ $t('clear_color')}}</v-btn>
              <v-color-picker class="transparent-background" :dark="$vuetify.theme.dark" hide-canvas hide-inputs v-model="colorPicker"
                              hide-mode-switch hide-sliders
                              show-swatches
                              cypress="contact_group_colors"
              ></v-color-picker>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-btn class="mr-5" color="passive_button" :disabled="loading"  @click="onCancel()" cypress="contact_group_cancel">
                {{ $t('cancel')}}
              </v-btn>
              <v-btn :disabled="loading" type="submit" color="red" cypress="contact_group_create">
                {{ $t('save')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

      </validation-observer>
    </DialogShared>

    <div class="text-right">
      <v-btn @click="onCreate" icon cypress="contact_group_add">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" v-bind="attrs" v-on="on">
              mdi-plus
            </v-icon>
          </template>
          <span> {{ $t('add_new')}}</span>
        </v-tooltip>
      </v-btn>
    </div>

    <v-data-table
      class="px-4 py-2 transparent-background"
      :headers="headers"
      :items="(items && items.value) || []"
      :options.sync="options"
      item-key="id"
      selectable-key="id"
      single-select

      :dark="$vuetify.theme.dark"

      :loading="loading"
      :loading-text="$t('loading')"
      :footer-props="{itemsPerPageOptions : [5,10,15]}"
      @update:options="paginationData"
      :server-items-length="(totalItems && totalItems.value) || 0"
    >

      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>

      <template v-slot:[`item.icon`]="{ item }">
        <v-icon v-if="item.icon">{{item.icon}}</v-icon>
      </template>

      <template v-slot:[`item.action`]="{ item, index }">
        <v-btn class="mr-1" color="green" small @click.stop="onEdit(item)" :cypress="`contact_group_edit_${index}`">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span> {{ $t('edit')}}</span>
          </v-tooltip>
        </v-btn>

        <v-btn class="mr-1" color="error" small @click.stop="onDelete(item)" :cypress="`contact_group_delete_${index}`">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                mdi-delete-outline
              </v-icon>
            </template>
            <span> {{ $t('delete')}}</span>
          </v-tooltip>
        </v-btn>
      </template>

    </v-data-table>
  </div>

</template>

<script>
/* Mixins */
import parentHeight from '@/mixins/parent-height.mixin'
/* Buttons */
import DialogShared from '@/app/shared/dialog/DialogShared'
/* Vuex */
import { mapActions, mapGetters } from 'vuex'

import '@/rules/validation.rules.js'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import apiService from '@/modules/api/csp'

export default {
  name: 'ContactGroupsComponent',
  mixins: [parentHeight],
  props: ['parentElement'],
  components: {
    DialogShared,
    ValidationObserver,
    ValidationProvider
  },
  data: (vm) => ({

    dialogTitle: '',
    loading: false,
    formDialog: false,
    colorPicker: null,
    form: {
      id: null,
      name: '',
      icon: null,
      colour: null
    },
    headers: [
      { text: vm.$t('name'), value: 'name', sortable: false },
      { text: vm.$t('icon'), value: 'icon', sortable: false },
      { text: vm.$t('color'), value: 'colour', sortable: false },
      { text: vm.$t('action'), value: 'action', sortable: false }
    ],
    items: {
      value: []
    },
    totalItems: {
      value: 0
    },
    options: {
      itemsPerPage: 15
    }
  }),

  computed: {
    ...mapGetters('contactBook', ['getContactGroupsGetter']),

    getIcons () {
      return [
        { icon: 'mdi-phone', label: this.$t('phone') },
        { icon: 'mdi-account-group', label: this.$t('account_group') },
        { icon: 'mdi-airplane', label: this.$t('airplane') },
        { icon: 'mdi-ambulance', label: this.$t('ambulance') },
        { icon: 'mdi-bank', label: this.$t('bank') },
        { icon: 'mdi-fire', label: this.$t('fire') },
        { icon: 'mdi-fireplace', label: this.$t('fireplace') },
        { icon: 'mdi-hospital-building', label: this.$t('hospital_building') },
        { icon: 'mdi-hospital', label: this.$t('hospital') },
        { icon: 'mdi-police-badge', label: this.$t('police_badge') },
        { icon: 'mdi-account-hard-hat', label: this.$t('account_hard_hat') },
        { icon: 'mdi-account-supervisor', label: this.$t('account_supervisor') }
      ]
    }
  },

  methods: {
    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),
    ...mapActions('contactBook', ['getContactGroupsAction']),

    onClearColorClick () {
      this.form.colour = null
      this.colorPicker.hex = null
    },

    onEdit (item) {
      this.$refs.observer.reset()
      this.dialogTitle = this.$t('update_contact_group')
      this.form = Object.assign({}, item)
      this.colorPicker.hex = this.form.colour
      this.formDialog = true
    },
    onCreate () {
      this.$refs.observer.reset()
      this.dialogTitle = this.$t('create_contact_group')
      this.form = {
        id: null,
        name: '',
        icon: null,
        colour: null
      }
      this.onClearColorClick()
      this.formDialog = true
    },
    onDelete (item) {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('contact_group') })).then(confirmed => {
        if (confirmed) {
          this.$localCacher.indexDB.contactGroups.delete(item.id)

          apiService.contactGroups.delete(item.id).then((response) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('delete_contact_group')
            })
            this.refreshData()
          }).catch((error) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: error.response.data.message
            })
          })
        }
      })
    },
    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.loading = true

        this.form.colour = this.colorPicker.hex

        if (this.form.id) {
          apiService.contactGroups.edit(this.form.id, this.form).then((response) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('contact_group_updated')
            })
            this.loading = false
            this.refreshData()
          }).catch((error) => {
            this.$refs.observer.setErrors(error.response.data.errors)
            this.formDialog = false
          })
        } else {
          apiService.contactGroups.create(this.form).then((response) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('contact_group_created')
            })
            this.loading = false
            this.refreshData()
          }).catch((error) => {
            this.$refs.observer.setErrors(error.response.data.errors)
            this.loading = false
          })
        }
      }
    },
    async refreshData () {
      this.getContactGroupsAction({})

      this.items = await this.$localCacher.indexDB.contactGroups.get({
        limit: 15
      })

      this.totalItems = await this.$localCacher.indexDB.contactGroups.count()

      this.formDialog = false
    },
    onCancel () {
      this.formDialog = false
    },
    async paginationData (data) {
      this.items = await this.$localCacher.indexDB.contactGroups.get({
        offset: (data.page - 1) * data.itemsPerPage,
        limit: data.itemsPerPage
      })
    }
  },
  async mounted () {
    this.loading = true

    this.items = await this.$localCacher.indexDB.contactGroups.get({
      limit: 15
    }).finally(() => {
      this.loading = false
    })

    this.totalItems = await this.$localCacher.indexDB.contactGroups.count()
  }
}
</script>

<style scoped>

</style>
