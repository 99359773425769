import { mapGetters } from 'vuex'

export default {
  mixins: [],
  computed: {
    ...mapGetters('settings', ['sipDefaultAddressGetter']),
    ...mapGetters('global', ['callGetter'])
  },
  methods: {

    setReadyState (readyState = 'NOT_READY') {
      if (!this.sipDefaultAddressGetter || !this.sipDefaultAddressGetter.id) return

      this.$firestore
        .collection('agents')
        .doc(`${this.sipDefaultAddressGetter.sip_address.toLowerCase()}@${this.sipDefaultAddressGetter.account_id}`)
        .update({
          state: readyState
        })
        .catch((error) => {
          console.error('Error updating document: ', error)
        })
    },

    setNotReadyState () {
      this.setReadyState('NOT_READY')
    },
    setReadySate () {
      this.setReadyState('READY')
    },
    setOnCallState () {
      const callType = this.callGetter.isEmergency ? 'ON_EMERGENCY_CALL' : 'ON_CALL'
      this.setReadyState(callType)
    }

  }
}
