<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        dark
        color="warning"

        @click="resolveCall(data)"
        small
        min-width="0"
        width="30"
        height="30"
        class="mx-1 my-1"
        v-on="on"
      >
        <v-icon small color="white">
          mdi-check
        </v-icon>
      </v-btn>
    </template>
    <span> {{ $t('resolve')}}</span>
  </v-tooltip>
</template>

<script>
/* Vuex */
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CallResolveButton',
  props: ['data'],
  computed: {
    ...mapGetters('settings', ['sipDefaultAddressGetter'])
  },
  methods: {
    ...mapActions('abandonedCalls', ['updateAbandonCallStatusAction']),
    resolveCall (data) {
      this.$confirm('Please confirm that you want to resolve this call').then(confirmed => {
        if (confirmed) {
          const event = { call_from: data.call_from, realm: data.realm, resolution_action: 'resolved' }

          this.updateAbanadonCall(event)
          this.updateAbandonCallStatusAction(event)
        }
      })
    },
    async updateAbanadonCall (data) {
      const batch = this.$firestore.batch()

      const collection = await this.$firestore
        .collection('abandoned_calls')
        .where('realm', '==', this.sipDefaultAddressGetter.realm)
        .where('call_from', '==', data.call_from)
        .get()

      collection.forEach((doc) => {
        batch.delete(doc.ref)
      })

      batch.commit()
    }
  }
}
</script>

<style scoped>

</style>
