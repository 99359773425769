<template>
  <tabs-shared :items="getTabs" :abandonedCounter="getAbandonedGetter"/>
</template>

<script>
/* Mixins */
import parentHeightMixin from '@/mixins/parent-height.mixin'
/* Shared */
import tabsShared from '@/app/shared/tabs/TabsShared'
/* Components */
import CallHistoryTableComponent from '@/app/widgets/call-history-widget/components/CallHistoryComponent'
import AbandonedCallsComponent from '@/app/widgets/abandoned-calls-widget/components/AbandonedCallsComponent'
import FccMasterRegistryComponent from '@/app/widgets/fcc-master-registry-widget/components/FccMasterRegistryComponent'
/* Vuex */
import { mapGetters } from 'vuex'

export default {
  name: 'CallHistoryTabsComponent',
  mixins: [parentHeightMixin],
  components: {
    tabsShared
  },
  data: (vm) => ({
    tab: null
  }),
  computed: {

    getTabs () {
      return [
        {
          tab: this.$t('call_history'),
          name: 'call_history',
          component: CallHistoryTableComponent,
          permission: 'can_see_call_history',
          eager: true,
          order: this.getWidgetSettings.CallHistoryTabsComponent.tabs.call_history.order,
          visible: this.$can('can_see_call_history') && this.getWidgetSettings.CallHistoryTabsComponent.tabs.call_history.visible

        },
        {
          tab: this.$t('abandoned_call'),
          name: 'abandoned_call',
          component: AbandonedCallsComponent,
          permission: 'can_see_abandoned_calls',
          eager: true,
          order: this.getWidgetSettings.CallHistoryTabsComponent.tabs.abandoned_call.order,
          visible: this.$can('can_see_abandoned_calls') && this.getWidgetSettings.CallHistoryTabsComponent.tabs.abandoned_call.visible
        },
        {
          tab: this.$t('fcc_master_registry'),
          name: 'fcc_master_registry',
          component: FccMasterRegistryComponent,
          permission: 'can_see_fcc_master_registry',
          eager: true,
          order: this.getWidgetSettings.CallHistoryTabsComponent.tabs.fcc_master_registry.order,
          visible: this.$can('can_see_fcc_master_registry') && this.getWidgetSettings.CallHistoryTabsComponent.tabs.fcc_master_registry.visible
        }
      ].sort((a, b) => {
        return a.order - b.order
      })
    },
    ...mapGetters('abandonedCalls', ['getAbandonedGetter']),
    ...mapGetters('callHistory', ['getHistoryGetter']),
    ...mapGetters('layout', ['layoutGetter', 'getWidgetSettings'])

  }
}
</script>

<style scoped>
</style>
