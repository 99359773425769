<template>
  <v-btn
    v-if="$can(`can_see_${type}_calls`)"
    :class="
      'v-btn-justify-start call_list no-transform ' + incoming_call_method
    "
    :color="color"
    :disabled="!active"
    block
    dark
    height="100%"
    @click="handleCall()"
    :id="`${type}_at_${index}`"
  >
    <v-icon dark left>
      {{ icon }}
    </v-icon>
    <template class="template">
      <span>
        {{ answered ? timerText : `L${number}` }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span class="mt-4 mx-0" v-bind="attrs" v-on="on">
              {{ address | substringIf(0, 20, "...") }}
            </span>
          </template>
          <span>{{ address }}</span>
        </v-tooltip>
      </span>
    </template>
  </v-btn>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
/* Mixins */
import answerMixin from '@/mixins/sip/answer.mixin'
import timerMixin from '@/mixins/timer.mixin'
import adrMixin from '@/mixins/sip/adr.mixin'
import heldMixin from '@/mixins/sip/held.mixin'
import esrkMixin from '@/mixins/sip/esrk.mixin'

export default {
  name: 'CallLineButton',
  mixins: [answerMixin, timerMixin, adrMixin, heldMixin, esrkMixin],
  props: ['index', 'number', 'type'],
  data: () => ({
    active: false,
    answered: false,
    session: null,
    direction: null,
    color: '',
    blinkingColor: '',
    interval: null,
    icon: 'mdi-phone-hangup',
    adr: null,
    held: null,
    address: '',
    incoming_call_method: 'invite-call'
  }),
  computed: {
    ...mapGetters('global', ['callGetter']),
    ...mapGetters('callLines', ['customCallLinesGetter']),
    ...mapGetters('settings', ['getGlobalSetting']),
    activeCall () {
      return this.callGetter
    }
  },
  watch: {
    direction (val) {
      if (val) {
        this.direction = val
      }
    },
    async active (val) {
      clearInterval(this.interval)
      // Define colors
      const validationForColors = this.getGlobalSetting(
        'validate_colors_for_line_button'
      )?.value.text
      const emergencyCallBlinkColor = this.getGlobalSetting(
        'emergency_call_blink_color'
      )?.value.text
      const globalHoldCallBlinkColor = this.getGlobalSetting(
        'global_hold_color_blink_color'
      )?.value.text
      const adminCallBlinkColor = this.getGlobalSetting(
        'admin_call_blink_color'
      )?.value.text

      // define validations
      let realmBasedValidationExists = false
      let emergencyCallBlinkColorExists = false
      let adminCallBlinkColorExits = false
      if (emergencyCallBlinkColor && emergencyCallBlinkColor.length > 0) {
        emergencyCallBlinkColorExists = true
      }
      if (
        validationForColors &&
        this.session?._request?.from?._display_name?.startsWith(
          validationForColors
        )
      ) {
        realmBasedValidationExists = true
      }
      if (adminCallBlinkColor && adminCallBlinkColor.length > 0) {
        adminCallBlinkColorExits = true
      }

      // LOGIC
      if (val && this.direction === 'outgoing') {
        this.icon = 'mdi-phone-outgoing'
        this.color = 'cyan darken-2'
        return
      }

      if (!val) {
        this.incoming_call_method = 'invite-call'
        this.icon = 'mdi-phone-hangup'
        this.answered = false

        if (
          this.type !== 'admin' &&
          emergencyCallBlinkColorExists &&
          realmBasedValidationExists
        ) {
          this.color = this.blinkingColor = emergencyCallBlinkColor
        } else if (this.type === 'admin' && realmBasedValidationExists) {
          this.color = this.blinkingColor = adminCallBlinkColorExits
            ? adminCallBlinkColor
            : 'error'
        } else {
          this.color = this.blinkingColor = 'error'
        }

        this.address = ''

        return
      }

      if (this.direction !== 'outgoing') {
        this.interval = setInterval(() => {
          this.blinking()
        }, 500)
      }

      if (
        this.type !== 'admin' &&
        emergencyCallBlinkColorExists &&
        realmBasedValidationExists
      ) {
        this.color = this.blinkingColor = emergencyCallBlinkColor
      } else if (this.type === 'admin' && realmBasedValidationExists) {
        this.color = this.blinkingColor = adminCallBlinkColorExits
          ? adminCallBlinkColor
          : 'error'
      } else {
        this.color = this.blinkingColor = 'error'
      }

      if (this.activeCall.isGlobalHoldCall) {
        this.incoming_call_method = 'gh-call'
        if (
          globalHoldCallBlinkColor?.length > 0 &&
          realmBasedValidationExists
        ) {
          this.color = this.blinkingColor =
            globalHoldCallBlinkColor ?? 'warning'
        } else {
          this.color = this.blinkingColor = 'warning'
        }
      }

      this.icon = 'mdi-phone-ring'
    },
    answered (val) {
      if (val) {
        this.$event.dispatch('clear-map-locations')
        this.startTimer()
        this.getEsrkNumber(this.session)
        window.answeredCallSession = this.session
        this.populateAdr(this.adr)
        this.populateHeld()
      } else {
        this.stopTimer()
      }
    },
    async session (val) {
      if (!val) return
      if (this.type === 'admin') {
        const headers = val._request?.headers

        if (headers) {
          for (const key in this.customCallLinesGetter) {
            if (!headers[key]) continue

            headers[key].forEach((item) => {
              if (item.raw === this.customCallLinesGetter[key].value) {
                this.address = this.customCallLinesGetter[key].name
              }
            })
          }
        }
      }

      // Disabled preflight Held and ADR requests
      /* if (this.$can('can_get_preflight_location')) {
        await this.getHeld(val, false, false).then(data => {
          this.held = data
          if (this.session.status === 4) {
            const number = data?.held?.HNO || ''
            const address = data?.held?.RD || ''
            const sts = data?.held?.STS || ''
            this.address = this.type !== 'admin' ? `${number} ${address} ${sts}` : this.address
          }
        })
        await this.getAdr(val).then(data => {
          this.adr = data
        })
      } */
    }
  },
  methods: {
    ...mapMutations('adr', [
      'commentMutator',
      'deviceInfoMutator',
      'providerInfoMutator',
      'serviceInfoMutator',
      'subscriberInfoMutator',
      'adrUpdateMutator'
    ]),
    ...mapMutations('held', ['heldMutator']),
    blinking () {
      if (this.color === this.blinkingColor) {
        this.color = 'grey'
      } else {
        this.color = this.blinkingColor
      }
    },
    handleCall () {
      if (!this.$sip.canAnswer) return
      setTimeout(() => {
        this.$sip.canAnswer = true
      }, 2000)
      if (this.session?.isOnHold()?.local) {
        this.unHoldCall()
      } else {
        this.answerCall()
      }
    },
    answerCall () {
      if (!this.callGetter.hasActiveCall && this.$sip.canAnswer) {
        this.$sip.canAnswer = false
        this.answered = true
        this.answer(this.session)
        this.address = ''
        clearInterval(this.interval)
        this.color = 'success'
        this.icon = 'mdi-phone-in-talk'
      } else return false
    },
    unHoldCall () {
      if (!this.callGetter.hasActiveCall && this.$sip.canAnswer) {
        this.$sip.canAnswer = false
        this.populateAdr()
        this.populateHeld()
        if (this.direction === 'outgoing') {
          this.icon = 'mdi-phone-outgoing'
          this.color = 'cyan darken-2'
        } else {
          this.icon = 'mdi-phone-in-talk'
          this.color = 'success'
        }
        this.session.unhold()
        this.incoming_call_method = 'invite-call'
        const event = new Event(`${this.direction}-confirmed`)
        event.session = this.session
        event.sessionId = this.session.id
        document.dispatchEvent(event)
      }
    },
    onHoldBtnStatus () {
      if (this.session?.isOnHold()?.local) {
        this.icon = 'mdi-play'
        this.color = 'blue'
        this.incoming_call_method = 'on-hold-call'
      }
    },
    async populateAdr (adr) {
      if (adr) {
        await this.clearAdr()
        setTimeout(async () => {
          await this.commentMutator(this.adr?.comment)
          await this.deviceInfoMutator(this.adr?.deviceInfo)
          await this.providerInfoMutator(this.adr?.providerInfo)
          await this.serviceInfoMutator(this.adr?.serviceInfo)
          await this.subscriberInfoMutator(this.adr?.subscriberInfo)
          await this.adrUpdateMutator()
        }, 500)
      } else await this.getAdr(this.session, true)
    },
    async populateHeld () {
      await this.getHeld(this.session, true)
    }
  },
  mounted () {
    this.$event.listen('on-hold-call', async (e) => {
      this.onHoldBtnStatus()
    })
  }
}
</script>

<style scoped>
.no-transform {
  text-transform: none !important;
}
</style>
