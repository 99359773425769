import EmergencyCallDataParser from '@/modules/api/csp/parsers/emergency-call-data.parser'
import adrXmlParser from '@/modules/api/csp/parsers/adr/adr-xml.parser'

export default function adrParser (session) {
  return {

    emergencyCallDataParser: null,

    init () {
      this.emergencyCallDataParser = new EmergencyCallDataParser().parseAdr(session?._request?.headers?.['Call-Info'])
    },

    async parse () {
      this.init()
      return {
        comment: await this.comment(),
        subscriberInfo: await this.subscriberInfo(),
        serviceInfo: await this.serviceInfo(),
        deviceInfo: await this.deviceInfo(),
        providerInfo: await this.providerInfo()
      }
    },

    async comment () {
      if (session._request.multipart && session._request.multipart['application/emergencycalldata.comment+xml']) {
        return adrXmlParser().parseADR(session._request.multipart['application/emergencycalldata.comment+xml'][0], 'Comment')
      }

      return this.emergencyCallDataParser?.comment_url ? await adrXmlParser().parse(this.emergencyCallDataParser?.comment_url, 'Comment') : null
    },

    async subscriberInfo () {
      if (session._request.multipart && session._request.multipart['application/emergencycalldata.subscriberinfo+xml']) {
        return adrXmlParser().parseADR(session._request.multipart['application/emergencycalldata.subscriberinfo+xml'][0], 'SubscriberInfo')
      }

      return this.emergencyCallDataParser?.subscriber_info_url ? await adrXmlParser().parse(this.emergencyCallDataParser?.subscriber_info_url, 'SubscriberInfo') : null
    },

    async serviceInfo () {
      if (session._request.multipart && session._request.multipart['application/emergencycalldata.serviceinfo+xml']) {
        return adrXmlParser().parseADR(session._request.multipart['application/emergencycalldata.serviceinfo+xml'][0], 'ServiceInfo')
      }

      return this.emergencyCallDataParser?.service_info_url ? await adrXmlParser().parse(this.emergencyCallDataParser?.service_info_url, 'ServiceInfo') : null
    },

    async deviceInfo () {
      if (session._request.multipart && session._request.multipart['application/emergencycalldata.deviceinfo+xml']) {
        return adrXmlParser().parseADR(session._request.multipart['application/emergencycalldata.deviceinfo+xml'][0], 'DeviceInfo')
      }

      return this.emergencyCallDataParser?.device_info_url ? await adrXmlParser().parse(this.emergencyCallDataParser?.device_info_url, 'DeviceInfo') : null
    },

    async providerInfo () {
      if (session._request.multipart && session._request.multipart['application/emergencycalldata.providerinfo+xml']) {
        return adrXmlParser().parseADR(session._request.multipart['application/emergencycalldata.providerinfo+xml'][0], 'ProviderInfo')
      }

      return this.emergencyCallDataParser?.provider_info_url ? await adrXmlParser().parse(this.emergencyCallDataParser?.provider_info_url, 'ProviderInfo') : null
    }

  }
}
