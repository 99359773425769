<template>
  <v-btn
    class="text-icon-button"
    :color="irrState ? 'grey' : 'primary'"
    large
    dark
    width="100%"
    height="100%"
    @click="load"
    >
    <v-icon>{{ irrIcon }}</v-icon>
    <div class="font-weight-bold pt-2"> {{ $t('irr_instant')}} <br/> {{ $t('retrieval')}}</div>
    <DialogShared max-width="1000" :title="$t('call_recordings')" v-model="showDialog">
      <v-row class="align-center">
        <v-col>
          <v-text-field
            name="call_id"
            v-model="call_id"
            :label="$t('call_id')"
            clearable
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            name="callee_id_number"
            v-model="callee_id_number"
            :label="$t('callee_id_number')"
            clearable
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            name="caller_id_number"
            v-model="caller_id_number"
            :label="$t('caller_id_number')"
            clearable
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn color="blue" @click="loadRecordings">{{ $t('filter') }}</v-btn>
        </v-col>
      </v-row>
      <div>
        <v-progress-linear indeterminate rounded color="amber" height="20" v-if="downloading"></v-progress-linear>
        <br />
          <audio ref="playRecordedAudio" style="width: 100%" controls :src="file" v-show="!downloading" :title="audioFileTitle"></audio>
        <br />
      </div>
      <v-data-table
        :headers="headers"
        :items="recordings"
        disable-sort
        :loading="loading"
        :items-per-page="!useTablePager ? -1 : 10"
        :hide-default-footer="!useTablePager"
      >
        <template v-slot:body="{ items }" v-if="recordings.length">
          <tbody>
            <tr :class="key === selectedRow ? 'selected-row' : ''" v-for="(item, key) in items" :key="item.call_id">
              <td>{{ item.callee_id_number }}</td>
              <td>{{ item.caller_id_number }}</td>
              <td>{{ convertEgressDate(item.start) }}</td>
              <td>
                <v-btn color="blue" @click="() => handleClick(key, item)" fab small>
                  <v-icon rounded>mdi-play</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <div v-if="!useTablePager">
        <v-btn color="blue" @click="onPrev" class="ma-2" :disabled="!kazooPager.prevKeys.length">{{ $t('prev') }}</v-btn>
        <v-btn color="blue" @click="onNext" class="ma-2" :disabled="kazooPager.nextKey === undefined">{{ $t('next') }}</v-btn>
      </div>
    </DialogShared>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
import DialogShared from '@/app/shared/dialog/DialogShared'
import CONSTANTS from '@/constants'

/* Mixins */
import recordingsMixin from '@/mixins/sip/recordings.mixin'

export default {
  name: 'IrrInstantButton',
  mixins: [recordingsMixin],
  components: { DialogShared },
  data: (vm) => ({
    selectedRow: null,
    loading: true,
    headers: [
      {
        text: vm.$t('Callee'),
        value: 'callee_id_number'
      },
      {
        text: vm.$t('Caller'),
        value: 'caller_id_number'
      },
      {
        text: vm.$t('Date'),
        value: 'start'
      },
      {
        text: vm.$t('action'),
        value: 'action'
      }
    ],
    showDialog: false,
    disableIrr: false,
    irrState: false,
    irrIcon: 'mdi-microphone-off'
  }),
  computed: {
    ...mapGetters('global', [
      'sipGetter',
      'callGetter'
    ]),
    ...mapGetters('settings', ['sipDefaultAddressGetter', 'getGlobalSetting'])
  },
  watch: {
    showDialog () {
      if (!this.showDialog) this.clear()
    },
    downloading () {
      if (this.downloading) return

      this.$refs.playRecordedAudio.addEventListener('play', this.onPlay)
      this.$refs.playRecordedAudio.addEventListener('pause', this.onPause)
      this.$refs.playRecordedAudio.addEventListener('ended', this.onPause)
    }
  },
  methods: {
    async load () {
      this.loadRecordings()

      this.showDialog = true
    },
    convertEgressDate (date) {
      return this.$moment.unix(date - CONSTANTS.KAZOO_TIME_STAMP_DIFF).format('MM/DD/YYYY HH:mm:ss')
    },
    onPlay () {
      this.$event.dispatch('serialHooksOn')
    },
    onPause () {
      if (this.callGetter.hasActiveCall) return

      this.$event.dispatch('serialHooksOff')
    }
  },
  mounted () {
    this.$event.listen('incoming-progress', () => {
      if (this.$refs.playRecordedAudio) this.$refs.playRecordedAudio.pause()
    })
  }
}
</script>

<style scoped>

</style>
