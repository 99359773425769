<template>
  <div class="text-center" v-if="$can('can_see_dial_pad')">

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn id="button_NumpadSubtract"
          color="red"
          fab
          fixed
          bottom
          :left="!getDialpadPosition"
          :right="getDialpadPosition"
          style="z-index: 99999"
          v-on="on"
          @click="dialPadControl"
        >
          <v-icon fab>
            mdi-dialpad
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('dial_pad')}}</span>
    </v-tooltip>

    <transition name="scroll-x-transition">
      <div
        v-show="dialPadStatusGetter"
        :class="{
          'dialpad': true,
          'dialpad-left': !getDialpadPosition,
          'dialpad-right': getDialpadPosition,
        }"
      >
        <v-sheet elevation="5" height="530px" width="348px" left :dark="$vuetify.theme.dark">
          <div class="pa-0 ma-0">
            <dial-pad-component></dial-pad-component>
          </div>
        </v-sheet>
      </div>
    </transition>
  </div>
</template>

<script>
import DialPadComponent from '@/app/widgets/dial-pad-widget/components/DialPadComponent'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DialPadButton',
  components: {
    DialPadComponent
  },
  data: () => ({

  }),

  computed: {

    ...mapGetters('dialPad', [
      'dialPadStatusGetter'
    ]),

    ...mapGetters('settings', ['getUserSetting']),

    getDialpadPosition () {
      try {
        return this.getUserSetting('dial-pad-on-right').value.active
      } catch (e) {
        return false
      }
    }

  },

  methods: {
    ...mapActions('dialPad', [
      'dialPadStatusAction'
    ]),
    dialPadControl () {
      this.dialPadStatusAction()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/components/dialpadbutton';
</style>
