<template>
  <span>Details</span>
</template>

<script>
export default {
  name: 'CallDetailsExtraDetailsComponent'
}
</script>

<style scoped>

</style>
