<template>
  <v-btn
    :dark="$vuetify.theme.dark"
    color="success"
    class="dial-pad-keys dial_btn_transfer"
    @click.once="callToNumber"
    tile
    block
    height='80px'
    :disabled="disabled"
    :key="buttonKey"
    id="button_Enter"
  >
    <v-icon small color="white">
      mdi-phone
    </v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
/* Mixins */
import callMixin from '@/mixins/sip/call.mixin'
import clickOnceRefreshMixin from '@/mixins/click-once-refresh.mixin'

export default {
  name: 'DialPadCallButton',
  mixins: [callMixin, clickOnceRefreshMixin],
  data: () => ({
    buttonKey: null
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter',
      'calling',
      'sipGetter'
    ]),
    ...mapGetters('dialPad', [
      'callPhoneNumberGetter'
    ]),
    disabled () {
      return (!this.sipGetter.registered || !this.callPhoneNumberGetter?.length) || this.callGetter.hasActiveCall || this.callGetter.calling
    }
  },
  methods: {
    callToNumber () {
      this.call(this.callPhoneNumberGetter)
      this.refreshButton()
    }
  },
  mounted () {
    this.buttonKey = this.$uuidKey()
  }
}
</script>

<style scoped>

</style>
