<template>
  <v-tooltip :key="buttonKey" bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        dark
        color="red accent-2"
        @click.once="infoReferToNumber"
        small
        min-width="0"
        width="30"
        height="30"
        class="mx-1 my-1"
        v-on="on"
        :disabled="isDisabled || !$can('can_send_hook_flash')"
        :key="buttonKey"
      >
        <v-icon small color="white">
          mdi-flash
        </v-icon>
      </v-btn>
    </template>
    <span> {{ $t('call_flash_hook_transfer')}}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'
/* Mixins */
import InfoReferMixin from '@/mixins/sip/info-refer-mixin'
import clickOnceRefreshMixin from '@/mixins/click-once-refresh.mixin'

export default {
  name: 'CallInfoReferButton',
  props: ['dialNumber'],
  mixins: [InfoReferMixin, clickOnceRefreshMixin],
  data: () => ({
    buttonKey: null
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ]),
    isVisible () {
      return this.callGetter.hasActiveCall
    },
    isDisabled () {
      return !(this.callGetter.hasActiveCall && !this.callGetter.transferring)
    }
  },
  methods: {
    infoReferToNumber () {
      this.infoRefer(this.dialNumber)
      this.refreshButton()
    }
  },
  mounted () {
    this.buttonKey = this.$uuidKey()
  }
}
</script>

<style scoped>

</style>
