var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"px-4 py-2 transparent-background",attrs:{"cypress":"speed_dial_contact_list","headers":_vm.headers,"items":(_vm.items && _vm.items.value) || [],"options":_vm.options,"item-key":"id","selectable-key":"id","single-select":"","loading-text":_vm.$t('loading'),"dense":"","items-per-page":10,"dark":_vm.$vuetify.theme.dark,"height":300,"server-items-length":(_vm.totalItems && _vm.totalItems.value) || 0},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mr-8",attrs:{"disabled":_vm.loading,"clearable":"","cypress":"speed_dial_search_contact","prepend-icon":"mdi-magnify","label":_vm.$t('search')},on:{"click:clear":_vm.clearSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-btn',{attrs:{"slot":"append-outer","color":"primary","elevation":"0","disabled":_vm.loading,"cypress":"speed_dial_find_contact"},on:{"click":function($event){return _vm.searchContactBook()}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],1)]},proxy:true},{key:"item.contact_permission",fn:function(ref){
var item = ref.item;
return [(item.contact_permission === 'private')?_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-lock-open-remove-outline")])],1):_vm._e(),(item.contact_permission === 'public')?_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-lock-open-check-outline")])],1):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.phone.name}},[_vm._v(" "+_vm._s(_vm._f("substringIf")(item.phone.name,0,40,'...'))+" ")])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.phone.phone}},[_vm._v(" "+_vm._s(item.phone.phone)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1 my-1",attrs:{"color":"primary","small":"","min-width":"0","width":"30","height":"30"},on:{"click":function($event){$event.stopPropagation();return _vm.bind(item)}}},on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('bind_contact')))])])]}}],null,true)},[_vm._v(" > ")])}
var staticRenderFns = []

export { render, staticRenderFns }