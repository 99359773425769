<template>
  <v-row v-if="$can('can_see_map')" class="fill-height full-width" dense>

    <v-navigation-drawer
      enable-resize-watcher
      v-model="drawer"
      hide-overlay
      temporary
      style="position:absolute;"
    >
      <v-list flat class="list transparent">
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            Map Layers
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            PSAP 911
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.PSAP911.display" @click="layerSwitcher('PSAP911')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            Police Departments
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.policeDepartments.display" @click="layerSwitcher('policeDepartments')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            Fire Stations
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.fireStations.display" @click="layerSwitcher('fireStations')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            Hospitals
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.hospitals.display" @click="layerSwitcher('hospitals')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            USA Parks
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.parks.display" @click="layerSwitcher('parks')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            Recent Earthquakes
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.earthquakes.display" @click="layerSwitcher('earthquakes')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            USA Airports
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.airports.display" @click="layerSwitcher('airports')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            USA Current Wildfires
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.wildfires.display" @click="layerSwitcher('wildfires')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="font-weight-small">
            North American Rail Network Lines
          </v-list-item-content>
          <v-list-item-action>
            <v-switch dense flat inset v-model="filter.railLines.display" @click="layerSwitcher('railLines')"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </v-navigation-drawer>
    <div id="open_filter_panel">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="rounded-0"
            color="white"
            fab
            v-bind="attrs"
            x-small
            @click.stop="drawer = !drawer"
            v-on="on"
          >
            <v-icon color="grey">mdi-filter</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("map_filters") }}</span>
      </v-tooltip>
    </div>
    <v-col id="map-container" cols="12 py-0 d-flex"></v-col>
    <div id="open_map_button">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="rounded-0"
            color="white"
            fab
            v-bind="attrs"
            x-small
            @click="openMap"
            v-on="on"
          >
            <v-icon color="grey">mdi-window-maximize</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("open_map") }}</span>
      </v-tooltip>
    </div>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { loadModules } from 'esri-loader'
/* Mixins */
import mapLocationMixin from '@/mixins/map-location.mixin'

export default {
  name: 'MapsTab',
  data: () => ({
    graphic: null,
    esriRequest: null,
    locator: null,
    SpatialReference: null,
    Point: null,
    CoordinateFormatter: null,
    TextSymbol: null,
    lng: -105,
    lat: 43.95,
    zoom: 3.2,
    themeMode: 'dark-gray-vector',
    drawer: null,
    FeatureLayer: null,
    filter: {
      parks: {
        url: 'https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Parks/FeatureServer/0',
        display: false
      },
      earthquakes: {
        url: 'https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/USGS_Seismic_Data_v1/FeatureServer/0',
        display: false
      },
      airports: {
        url: 'https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Airports_by_scale/FeatureServer/1',
        display: false
      },
      wildfires: {
        url: 'https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/USA_Wildfires_v1/FeatureServer/0',
        display: false
      },
      railLines: {
        url: 'https://services.arcgis.com/xOi1kZaI0eWDREZv/arcgis/rest/services/NTAD_North_American_Rail_Network_Lines_Passenger_Rail/FeatureServer/0',
        display: false
      },
      PSAP911: {
        url: 'https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/PSAP_911_Service_Area_Boundaries/FeatureServer/0',
        display: false
      },
      policeDepartments: {
        url: 'https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/Local_Law_Enforcement_Locations/FeatureServer/0',
        display: false
      },
      fireStations: {
        url: 'https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/Fire_Station/FeatureServer/0',
        display: false
      },
      hospitals: {
        url: 'https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/Hospital/FeatureServer/0',
        display: false
      }
    }
  }),
  mixins: [mapLocationMixin],
  computed: {
    ...mapGetters('held', ['heldGetter']),
    ...mapGetters('global', ['callGetter'])
  },
  watch: {
    'heldGetter.lat' () {
      this.setLocations(this.graphic, this.heldGetter)
    },
    async 'callGetter.clearingMap' (val) {
      if (val) {
        this.clearLocation()
        await this.clearingMapAction(false)
      }
    }
  },
  methods: {
    ...mapActions('global', ['clearingMapAction']),
    loadMap () {
      loadModules(
        [
          'esri/Map',
          'esri/views/MapView',
          'esri/Graphic',
          'esri/config',
          'esri/widgets/Search',
          'esri/widgets/Fullscreen',
          'esri/widgets/BasemapToggle',
          'esri/widgets/Popup',
          'esri/request',
          'esri/rest/locator',
          'esri/geometry/SpatialReference',
          'esri/geometry/Point',
          'esri/geometry/coordinateFormatter',
          'esri/symbols/TextSymbol',
          'esri/widgets/BasemapGallery',
          'esri/layers/FeatureLayer',
          'esri/widgets/Expand',
          'esri/widgets/BasemapGallery/support/LocalBasemapsSource',
          'esri/Basemap'
        ],
        {
          css: true
        }
      ).then(
        ([
          ArcGISMap,
          MapView,
          Graphic,
          esriConfig,
          Search,
          Fullscreen,
          BasemapToggle,
          Popup,
          esriRequest,
          locator,
          SpatialReference,
          Point,
          CoordinateFormatter,
          TextSymbol,
          BasemapGallery,
          FeatureLayer,
          Expand,
          LocalBasemapsSource,
          Basemap
        ]) => {
          this.graphic = Graphic
          this.esriRequest = esriRequest
          this.locator = locator
          this.SpatialReference = SpatialReference
          this.Point = Point
          this.CoordinateFormatter = CoordinateFormatter
          this.TextSymbol = TextSymbol
          this.FeatureLayer = FeatureLayer

          esriConfig.apiKey = process.env.VUE_APP_ESRI_MAP_API_KEY

          const map = new ArcGISMap({
            basemap: (localStorage.getItem('map-style')) ? localStorage.getItem('map-style') : this.themeMode
          })

          this.view = new MapView({
            map: map,
            center: [this.lng, this.lat],
            zoom: this.zoom,
            container: document.getElementById('map-container')
          })

          this.view.ui.add(
            document.getElementById('open_map_button'),
            'top-left'
          )
          this.addSearch(Search)
          this.mapToggle(BasemapToggle)
          this.fullScreen(Fullscreen)
          this.setBaseMapsSwitcher(BasemapGallery, Expand, LocalBasemapsSource, Basemap)
          this.view.ui.add(
            document.getElementById('open_filter_panel'),
            'top-right'
          )
          this.setActiveLayers(['PSAP911', 'policeDepartments', 'fireStations', 'hospitals', 'parks', 'earthquakes', 'airports', 'wildfires', 'railLines'])
          this.view.map.watch('basemap', () => {
            localStorage.setItem('map-style', this.view.map.basemap.id)
          })
          this.view.when(() => {
            this.view.on('click', (event) => {
              if (event.button === 2) {
                this.reverseAddressOnClick(this.Point, this.SpatialReference, event.mapPoint.latitude, event.mapPoint.longitude, event)
              }
            })
          })
        }
      )
    },
    openMap () {
      window.open(`/map/${this.map.id}`, '_blank')
    },
    layerSwitcher (layerName) {
      if (this.filter[layerName].display) {
        this.displayLayers(this.FeatureLayer, this.filter[layerName].url, layerName)
        localStorage.setItem(layerName, this.filter[layerName].display)
      } else {
        this.removeLayers(layerName)
        localStorage.setItem(layerName, this.filter[layerName].display)
      }
    },
    setActiveLayers (layerList) {
      layerList.forEach(item => {
        const filterStatus = localStorage.getItem(item)
        if (filterStatus === 'true') {
          this.filter[item].display = filterStatus
          this.displayLayers(this.FeatureLayer, this.filter[item].url, item)
        }
      })
    }
  },
  async mounted () {
    this.loadMap()
    await this.createMap()

    this.$event.listen('clear-map-locations', () => {
      this.clearLocation()
    })
  }
}
</script>
<style lang="scss">
.esri-popup__main-container {
  background-color: #1e1e1e !important;
}

.esri-feature__size-container {
  background-color: #f0f0f0 !important;
}

.esri-map-row {
  border-bottom: 1px solid #cdcdcd;
}
</style>
