<template>
  <data-table-shared :headers="headers" :items="values" :loading="loading" :show-expand="true" :single-expand="true"  :expanded="expanded" :perPage="10" :hide-footer="false">
    <template v-slot:[`item.date_time`]="{ item }">
      {{ item.date_time | dateFormatter }}
    </template>
    <template v-slot:expanded-item="{ item }">
      <td colspan="4" class="pa-0">
        <v-simple-table fixed-header class="expanded-table">
          <tbody>
          <tr class="pa-0">
            <td>
              {{ $t('date_time')}}: {{ item.date_time | dateFormatter }}
            </td>
          </tr>
          <tr class="pa-0">
            <td>
              {{ $t('callback_number')}}: {{ item.callback_number }}
            </td>
          </tr>
          <tr class="pa-0">
            <td>
              {{ $t('agent')}}: {{ item.agent }}
            </td>
          </tr>
          <tr class="pa-0">
            <td>
              {{ $t('address') }}: {{ item.HNO || '' }} {{ item.RD || '' }} {{ item.STS || '' }}
            </td>
          </tr>
          <tr class="pa-0">
            <td>
              {{ $t('city_st_zip') }}: {{ item.A3 || '' }} {{ item.A1 || '' }} {{ item.PC || '' }}
            </td>
          </tr>
          <tr class="pa-0">
            <td>
              {{ $t('insufficient_incorrect_location_information') }}: {{ (item.incorrect_location === 1) ? 'true' : false }}
            </td>
          </tr>
          <tr class="pa-0">
            <td>
              {{ $t('correct_location_information_no_map') }}: {{ (item.no_map === 1) ? 'true' : false }}
            </td>
          </tr>
          <tr class="pa-0">
            <td>
              {{ $t('correct_location_information_incorrect_map') }}: {{ ( item.incorrect_map=== 1) ? 'true' : false }}
            </td>
          </tr>
          <tr class="pa-0">
            <td>
              {{ $t('other') }}: {{ item.other }}
            </td>
          </tr>
          <tr class="pa-0">
            <td>
              {{ $t('calling_number_incorrect') }}: {{ item.incorrect_calling_number }}
            </td>
          </tr>
          <tr class="pa-0">
            <td>
              {{ $t('location_information_misrouted') }}: {{ item.location_misrouted }}
            </td>
          </tr>
          <tr class="pa-0">
            <td>
              {{ $t('esn_incorrect') }}: {{ item.esn_incorrect }}
            </td>
          </tr>
          <tr class="pa-0">
            <td>
              {{ $t('address_incorrect') }}: {{ item.address_incorrect }}
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </td>
    </template>
  </data-table-shared>
</template>

<script>
import apiService from '@/modules/api/csp'
import { mapGetters, mapActions } from 'vuex'
/* Components */
import DataTableShared from '@/app/shared/table/DataTableShared'
export default {
  name: 'CallDetailsIncorrectLocationComponent',
  components: {
    DataTableShared
  },
  data: (vm) => ({
    expanded: [],
    loading: false,
    headers: [
      {
        text: vm.$t('date_time'),
        value: 'date_time'
      },
      {
        text: vm.$t('callback_number'),
        value: 'callback_number'
      },
      {
        text: vm.$t('agent'),
        value: 'agent'
      }
    ],
    values: []
  }),
  computed: {
    ...mapGetters('callDetails', [
      'callDetailsGetter'
    ])
  },
  watch: {
    async 'callDetailsGetter' (val) {
      if (Object.keys(val).length) {
        await this.getIncorrectLocations()
      }
    }
  },
  methods: {
    ...mapActions('notifications', ['notificationSnackBarAction']),
    getIncorrectLocations () {
      apiService.incorrectLocation.getIncorrectLocations(this.callDetailsGetter.call_id).then((result) => {
        this.values = result.data
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.notificationSnackBarAction({
          text: this.$t('api_error_incorrect_location_not_loaded'),
          color: 'error',
          show: true
        })
      })
    }
  }
}
</script>

<style scoped>
.expanded-table td{
  font-size: 0.75rem !important;
  height: 30px !important;;
}
.expanded-table td:last-child{
  width: 210px !important;
}
</style>
