<template>
  <v-badge
    bordered
    color="primary"
    :content="rebidCounter"
    overlap
    :value="rebidCounter"
    class="full-width"
  >
    <v-btn
      class="pa-2"
      dark
      block
      height="100%"
      color="success"
      @click="rebidData"
      :disabled="!callGetter.hasActiveCall"
      :loading="loading"
    >
      <v-icon color="white">mdi-refresh</v-icon>
    </v-btn>
  </v-badge>
</template>

<script>
import { mapGetters } from 'vuex'
/* Mixins */
import heldMixin from '@/mixins/sip/held.mixin'

export default {
  name: 'HeldRebidButton',
  mixins: [heldMixin],
  data: () => ({
    loading: false,
    counter: 0,
    interval: null
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ]),
    ...mapGetters('settings', ['getUserSetting', 'getGlobalSetting']),
    rebidCounter: {
      get: function () {
        return this.counter
      },
      set: function (val) {
        this.counter >= 99 ? this.counter = '99+' : this.counter++
      }
    }
  },
  watch: {
    'callGetter.hasActiveCall' (val) {
      if (!val) {
        this.counter = 0
        window.rebidCounter = 0
      }
    },
    'callGetter.isEmergency' (val) {
      if (val) {
        this.automaticRebid()
      } else {
        this.clearAutomaticRebid()
      }
    }
  },
  methods: {
    async rebidData () {
      this.loading = true
      await this.clearHeld()
      this.rebidCounter++
      window.rebidCounter = this.rebidCounter
      await this.getHeld(this.$sip.answeredCallSession, true, true).finally(() => {
        this.loading = false
        this.$event.dispatch('held_was_updated')
      })
    },
    automaticRebid () {
      if (this.getGlobalSetting('automatic_location_update').value.active && this.$can('can_rebid_held_information')) {
        this.interval = setInterval(() => {
          this.rebidData()
        }, this.getGlobalSetting('automatic_location_update').value.value * 1000)
      }
    },
    clearAutomaticRebid () {
      clearInterval(this.interval)
    }
  }
}
</script>

<style scoped>

</style>
