<template>
  <v-card flat height="560">
    <v-row class="fill-height" dense>
      <v-col id="details-map-container" cols="12 py-0 d-flex"></v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'

import { loadModules } from 'esri-loader'
/* Mixins */
import mapLocationMixin from '@/mixins/map-location.mixin'

export default {
  name: 'CallDetailsMapComponent',
  mixins: [mapLocationMixin],
  data: () => ({
    graphic: null,
    lng: -105,
    lat: 43.95,
    zoom: 2.9
  }),
  computed: {
    ...mapGetters('callDetails', ['callDetailsHeldGetter'])
  },
  watch: {
    'callDetailsHeldGetter.lat' (val) {
      if (val) {
        this.setLocations(
          this.graphic,
          this.callDetailsHeldGetter.lat,
          this.callDetailsHeldGetter.lng
        )
      } else {
        this.clearLocation()
      }
    }
  },
  methods: {
    loadMap () {
      loadModules(
        [
          'esri/Map',
          'esri/views/MapView',
          'esri/Graphic',
          'esri/config',
          'esri/widgets/Search',
          'esri/widgets/Fullscreen'
        ],
        {
          css: true
        }
      ).then(
        ([ArcGISMap, MapView, Graphic, esriConfig, Search, Fullscreen]) => {
          this.graphic = Graphic
          esriConfig.apiKey = process.env.ESRI_MAP_API_KEY
          const map = new ArcGISMap({
            basemap: 'gray-vector'
          })
          this.view = new MapView({
            map: map,
            center: [this.lng, this.lat],
            zoom: this.zoom,
            container: document.getElementById('details-map-container')
          })
          this.addSearch(Search)
          this.fullScreen(Fullscreen)
        }
      )
    }
  },
  mounted () {
    this.loadMap()
  }
}
</script>
<style lang="scss" scoped></style>
