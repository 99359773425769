<template>
  <v-tooltip v-if="isVisible" :key="buttonKey" bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        dark
        color="success"
        small
        min-width="0"
        @click.once="callToNumber"
        width="30"
        height="30"
        class="mx-1 my-1"
        v-on="on"
        :disabled="isDisabled"
        :key="buttonKey"
      >
        <v-icon small color="white">
          mdi-phone
        </v-icon>
      </v-btn>
    </template>
    <span>{{ $t('call')}}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'
/* Mixins */
import callMixin from '@/mixins/sip/call.mixin'
import clickOnceRefreshMixin from '@/mixins/click-once-refresh.mixin'
export default {
  name: 'CallBackButton',
  mixins: [callMixin, clickOnceRefreshMixin],
  props: ['dialNumber', 'conferenceCall'],
  data: () => ({
    buttonKey: null
  }),
  computed: {
    ...mapGetters('global', [
      'sipGetter', 'callGetter'
    ]),
    isVisible () {
      return !this.callGetter.hasActiveCall
    },
    isDisabled () {
      return !(this.sipGetter.registered && !this.callGetter.hasActiveCall && !this.callGetter.calling)
    }
  },
  methods: {
    callToNumber () {
      this.call(this.dialNumber, [], {}, this.conferenceCall)
      this.refreshButton()
    }
  },
  mounted () {
    this.buttonKey = this.$uuidKey()
  }
}
</script>

<style scoped>

</style>
